// This file exports a single function, `callServerIncrementally`.
// It can be used by our tools that request information about a chunk of text and receive a JSON array with information about each token,
// specifically when every member of the JSON array has a `word` property.
// This is currently true of morphology and the nakdan.
// The idea is to break the text into chunks and make smaller requests from the server, each request with extra words for context.
// The context words are thrown away before returning the data to our application.

// it appears that browsers don't actually send a request until JS stops running
// this artificially makes our JS stop running so the request can get sent
function yieldMainThread() {
    return new Promise(resolve => {
        setTimeout(() => resolve(), 0)
    })
}

// This function is the main request loop.
// It's a little easier to read using `async`.
// The idea is that it calls the server one chunk at a time, but it starts `concurrency` requests before beginning to
// wait for results. Every time a response arrives, it sends an additional request before processing the response.
async function issueRequests(requests, requestFunction, responseFunction, progressFunction, concurrency) {
    let resultPromises = []
    let waitingRequests = []

    // this function simply takes a single request off the queue and adds the API call promise to `resultPromises`,
    // and the request object to `waitingRequests`
    function startRequest() {
        // check if there's anything left to do
        if (requests.length === 0) return
        let request = requests.shift()
        // there may or may not be a prefix or suffix, which are the extra contextual data
        const text = (request.prefix || '') + request.body + (request.suffix || '')
        resultPromises.push(requestFunction(text))
        waitingRequests.push(request)
    }

    const totalRequests = requests.length
    let finishedRequests = 0

    for(let i = 0; i < concurrency; i++) {
        startRequest()        
    }

    while (waitingRequests.length > 0) {
        // await waits for the server to respond.
        // We only wait for the next chunk - although technically, a later chunk might arrive earlier, we
        // don't bother waiting for it out of order, since this is complicated enough as it is. The browser won't give
        // us a result until we wait for it.
        const result = await resultPromises.shift()
        const request = waitingRequests.shift()
        // before we deal with this response, start the next request
        startRequest()
        // we need to wait until the request really goes to the server
        await yieldMainThread()
        // must be an array, made of objects, that have a property `word`, that match our request text
        const response = result.data
        // throw away objects that match our contextual data
        // we deliberately made our contextual data fall on the boundary of a token, so we don't have to chop up tokens
        // or anything.
        if (request.prefix) { 
            let i = 0
            while (i < request.prefix.length) {
                i += response.shift().word.length
            }
        }
        if (request.suffix) { 
            let i = 0
            while (i < request.suffix.length) {
                i += response.pop().word.length
            }
        }
        // now that we've trimmed the contextual data away, call the main app with the real data
        // we also pass a flag to tell the app if this is the last request
        responseFunction(response, waitingRequests.length === 0)
        // the app may want to know how many chunks we've processed out of how many in total
        // if so, call the callback
        if (progressFunction) {
            finishedRequests++
            progressFunction(finishedRequests, totalRequests)
        }
    }
}

const CHUNK_SIZE = 400
// this function takes a lot of parameters, so to give them names, we accept a single object containing all of them.
// Three parameters are required:
// text - this is the request text
// requestFunction - we generate a chunk of text, and then call this function, which calls the server. It must return an
//   axios-style promise, meaning that when the promise resolves, we get results in response.data
// responseFunction - after we trim the contextual data from the server's response, we call this to give the chunk of
//   server data back to the app
// There are two optional parameters:
// progressFunction - called after we receive a chunk. It receives two numbers: how many chunks have we received so far,
//   and how many in total. It can use this to let the user know how things are going.
// concurrency - how many requests should be sent to the server simultaneously
export function callServerIncrementally(options) {
    const text = options.text
    const requestFunction = options.requestFunction
    const responseFunction = options.responseFunction
    const progressFunction = options.progressFunction
    const concurrency = options.concurrency || 2
    // split on spaces preceeded by a Hebrew letter or nikud, since that's guaranteed to be the boundary of a token
    // note that this might have nothing to do with the server's idea of tokens other than that - the server will break other places, too.
    const words = text.split(/(?<=[\u05b0-\u05bd\u05c1\u05c2\u05c7\u05d0-\u05ea]) /)
    const requests = []
    for(let i=0; i < words.length; i+= CHUNK_SIZE) {
        const lastRequest = i + (CHUNK_SIZE * 2) >= words.length
        const firstRequest = i === 0
        const request = {
            prefix: firstRequest ? null : ' ' + words.slice(i - 20, i).join(' '),
            body: (firstRequest ? '' : ' ') + words.slice(i, lastRequest ? words.length : i + CHUNK_SIZE).join(' '),
            suffix: lastRequest ? null : ' ' + words.slice(i + CHUNK_SIZE, i + CHUNK_SIZE + 20).join(' ')
        }
        requests.push(request)
        if (lastRequest) break
    }
    return issueRequests(requests, requestFunction, responseFunction, progressFunction, concurrency)
}
