<template>
  <i v-if="name === currentKey && asc" class="fas fa-sort-up"></i>
  <i v-else-if="name === currentKey" class="fas fa-sort-down"></i>
  <i v-else class="fas fa-sort text-secondary"></i>
</template>

<script>
  export default {
    name: 'sort',
    props: {
      name: {},
      currentKey: {},
      asc: {
        default: true
      }
    }
  }
</script>

<style scoped>

</style>