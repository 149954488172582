<template>
    <b-modal
            id="keyboard-shortcuts"
            ref="keyboard-shortcuts"
            :visible="showShortcutsObj.showShortcuts"
            @hidden="showShortcutsObj.showShortcuts = false"
            centered
            ok-only
            size="lg"
            :ok-title="hebrew ? 'סגור' : 'Close'"
            ok-variant="secondary" no-stacking>
        <template slot="modal-header">
            <div>
                <span class="modal-header-title">{{hebrew ? 'קיצורי מקלדת' : 'Keyboard Shortcuts'}}</span></div>
        </template>
        <div class="keyboard-shortcuts-content">
            <div v-for="(lineDataSet, li) in lineDataSets" 
                    :key="'shortcut-line-'+(li+1)"
                    class="row align-content-center py-2"
                    v-bind:class="{'lighted-row': li % 2 === 0}">
                <div class="col-3">
                    <div class="d-flex">
                        <span v-for="(groupingItem, g) in lineDataSet.groupingItems" 
                                :key="'grouping-item-'+li+'-'+g"
                                :class="{'key-divider': groupingItem.type === 'divider'}">
                            <div v-if="groupingItem.type === 'group'" class="d-inline-flex align-items-center" v-bind:class="{'flex-row-reverse':!hebrew}">
                                <div v-for="(itemOfGroup, i) in groupingItem.itemsOfGroup" :key="'item-'+i+'-of-group-'+li+'-'+g">
                                    <button v-if="itemOfGroup.type === 'key'" type="button" class="btn btn-link keyboard-btn">{{ itemOfGroup.text }}</button>
                                    <button v-if="itemOfGroup.type === 'arrow-key'" type="button" class="btn btn-link keyboard-btn">
                                        <i class="fa fa-lg" v-bind:class="'fa-caret-'+itemOfGroup.direction"></i>
                                    </button>
                                    <span v-if="itemOfGroup.type === 'plus'" class="mx-1">+</span>
                                    <span v-if="itemOfGroup.type === 'text'">{{ itemOfGroup.text }}</span>
                                    <span v-if="itemOfGroup.type === 'pointer'" class="mx-1">
                                        <i class="fa" v-bind:class="'fa-angle-'+itemOfGroup.direction"></i>
                                    </span>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-9 align-self-center">
                    <p class="mb-0">{{ hebrew ? lineDataSet.explanationText : lineDataSet.explanationTextEng}}
                        <span v-if="showingKeyboardDisplays && 'advancedKeyboardDisplay' in lineDataSet">
                            <b-button v-b-modal="'nikud-whole-keyboard-popup-'+lineDataSet.advancedKeyboardDisplay"
                                size="sm" variant="link" class="text-primary px-1">תצוגה מקדימה</b-button>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'KeyboardShortcuts',
    props: ['showShortcutsObj', 'lineDataSets', 'showingKeyboardDisplays'],
    computed: {
        hebrew () {
        return this.$settings.hebrew
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .keyboard-btn {
        border: 1px solid #D1D0D0;
        border-radius: 3px;
        padding: 4px 8px;
        min-width: 30px;
        height: 28px;
        font-size: 16px;
        line-height: 2px;
        background: #fff;
        box-shadow: 0 1px 0 0 rgba(0,0,0,.17);
        margin-left: 1px;
        cursor: text !important;
    }
    .keyboard-btn:focus, .keyboard-btn:active
    {
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, .17) !important;
    }
    .key-divider{
        width: 1px;
        background: #E3E3E3;
        margin: 4px;
        height: 20px;
        vertical-align: middle;
    }
    .lighted-row {
        background: #F6F6F6;
    }
    .keyboard-shortcuts-content {
        height: 360px;
        overflow-y: auto;
        overflow-x: hidden;
        margin: 0 -15px;
        padding: 0 15px;
    }

</style>
