import {Actions, StateChanges} from './constants'
import {createFirebaseRefs, firebaseStopSync} from './firebaseModule'
import {signOut} from 'shared/account/js/sso.js'

export default {
  [Actions.PROCESS_LOGIN]: ({ commit }) => {
    createFirebaseRefs(commit)
    commit(StateChanges.LOGIN_COMPLETE)
  },
  [Actions.LOGOUT]: ({ commit }) => {
    commit(StateChanges.LOGGED_OUT)
    firebaseStopSync()
    signOut()
  }
}
