<template>
<div>
  <b-modal 
    v-model="show" 
    id="save-error-modal"
    ref="save-error-modal"
    :centered="true"
    no-fade
    footer-class=""
    :ok-title="hebrew ? 'שלח' : 'Send'"
    :cancel-title="hebrew ? 'ביטול' : 'Cancel'"
    @show="$emit('show-error')"
    @cancel="resetData"
    @ok="submit"> <!-- if/when we support english we'll need a footer class to fix ok and cancel positions (like save-or-saving-or-saved-footer) -->
    <template slot="modal-header"><div>{{ hebrew ? 'הופס, יש פה תקלה' : 'Oops, save failed' }}</div></template>
        <div v-if="hebrew">
            מצטערים, אבל לא הצלחנו לבצע שמירה של השינויים.<br>
        שלח לנו הודעה עם תאור השגיאה כדי שנטפל בבעיה.
        </div>
        <div v-else>
            We were unable to save changes.<br>
            Please send us a message with the error so that we can resolve the problem.
        </div>
        <form
            class="pt-4"
            id="failed-save-form"
            ref="failed-save-form"
            action="https://formspree.io/f/xvovdkvj" target="_blank" method="POST">
            <b-form-group
                :label="hebrew ? 'דואר אלקטרוני (שלך)' : 'Email'"
                label-for="_replyto"
                :invalid-feedback="invalidFeedback"
                :state="state"
            >
                <b-form-input v-model="email" required name="_replyto" type='email'/>
            </b-form-group>
            <input type="hidden" name="eror_string" :value="errorMsg"/>
        </form>
        <p class="mb-0">{{hebrew ? 'תאור השגיאה' : 'Error description'}}</p>
        <div class="bg-background py-1 px-2 error-description text-muted text-left">
            <small>{{errorMsg}}</small>
        </div>
        <b-btn
            v-if="showDownload"
            variant="link"
            @click="copyErrorText"
            class="position-absolute copy-error text-primary bg-transparent shadow-none text-underline p-0">
            {{hebrew ? 'העתק שגיאה' : 'Copy error'}}
        </b-btn>
        <div class="form-bottom p-2 position-absolute w-100 rounded" :class="{'bg-background' : showDownload}">
            <span v-if="showDownload">
                <i-download class="mx-1 position-relative"></i-download>
                {{hebrew ? 'להורדת הקובץ,' : 'To download file'}}
                <b-btn
                    variant="link"
                    class="text-primary bg-transparent shadow-none text-underline p-0"
                    @click="$emit('download-file')">{{hebrew ? 'לחץ כאן' : 'Click here'}}
                </b-btn>
            </span>  
            <span v-else class="px-3 py-1 d-block"> <!--continue with next function call-->
                {{hebrew ? 'כדי לדלג על השמירה, ' : 'To continue, '}}
                <span 
                    class="text-primary text-underline" style="cursor:pointer" 
                    @click="runNextAction">{{hebrew ? 'לחץ כאן' : 'Click here'}}
                </span>
            </span>
        </div>
      </b-modal>
    </div>
  </template>
  <script>
    export default {
    name: 'FormSaveError',
     props: {
      errorMsg: {},
      showForm: {},
      showDownload: {}
    },
    data() {
      return {
        email: '',
        submitted: false,
      }
    },
    methods: {
      runNextAction () {
        this.resetData()
        this.$emit('call-next')
      },
      resetData () {
        this.email = ''        
        this.submitted = false
        const newStatus = Object.assign({}, this.$store.state.dropbox.status)
        newStatus.saving = false
        this.$store.commit('SET_DROPBOX_STATUS', newStatus)
        this.$nextTick(() => {
          this.$emit('clear-error')
        })  
      },
      submit (bvModalEvt) {
        bvModalEvt.preventDefault()
        this.submitted = true
        if (this.$refs['failed-save-form'].checkValidity()) {
          this.$refs['failed-save-form'].submit()
          this.$nextTick(() => {
            this.$refs['save-error-modal'].hide()
            this.resetData()            
          })
        }
      },
      copyErrorText () {
        const el = document.createElement('textarea')
        const s = document.createTextNode(this.errorMsg)
        el.id = 'tempText'
        el.readOnly = true
        el.contentEditable = true
        el.classList.add("position-absolute")
        el.appendChild(s)
        document.getElementById('save-error-modal').getElementsByClassName("modal-body")[0].appendChild(el)
        document.getElementById('tempText').focus()
        document.getElementById('tempText').select()
        document.execCommand("copy")
        this.$emit('text-copied')
        document.getElementById('save-error-modal').getElementsByClassName("modal-body")[0].removeChild(el)
      },
    },
    computed: {
      hebrew () {
        return this.$settings && this.$settings.hebrew
      },
      show: {
        get() {
            return this.showForm;
        },
        set() {
            
        }
      },            
      invalidFeedback() {
        if (!this.state) {
          return this.hebrew ? 'יש להזין כתובת דוא״ל' : 'Please enter a valid email address'
        } else {
          return ''
        }
      },
      state() {
        return !this.submitted || this.email.length >= 5 && this.email.includes('@') ? true : false
      },
     }
    } 
  </script>
  <style scoped lang="scss">
  .form-bottom {
    right: 0;
    bottom: -113px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    background: #d6ecff;
    button {
      font-size: 16px;
    }
    i {
      font-size: 20px;
      top: 2px;
    }
  }
  .copy-error {
    bottom: -40px;
  }
  .error-description {
    height: 70px;
    overflow-y: scroll;
  }
</style>