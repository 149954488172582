<template>
<span v-show="!(tokenPart.textPartObj.hideable) || showHideables"
      class="clickable-token"
      :class="tokenClasses"
      :style="tokenPart.textPartObj.hideable ? 'color: ' + (tokenPart.textPartObj.useAlternateHideableColor
              ? alternateHideableDisplayColor : hideableDisplayColor)
           : tokenPart.textPartObj.prefix ? 'color: red' : ''"
      :id="'span-A-tokenPart-' + tokenPart.textPartIndex + '-ofToken-' + tokenPart.tokenIndex + '-inPar-' + p"
      @click="tokenClicked(tokenPart.tokenIndex, $event)"
      >
        <span v-for="(letterString, le) in (tp => {
                        let tpString = tp.textPartObj.part
                        if (tp.textPartObj.hasOwnProperty('firstIndexWithinPart')) {
                          tpString = tokenPart.textPartObj.part.slice(tp.textPartObj.firstIndexWithinPart,
                                                                      tp.textPartObj.lastIndexWithinPart + 1)
                        }
                        tpString = tpString.replace(/ֽ/, '')
                        if (textPanelStyles.appFeatures.hideablesSuffixesDuplicated && showHideables
                            && !(tp.textPartObj.hideable) && tpInP < (paragraph.length - 1)) {
                          const nextTextPartObj = paragraph[tpInP + 1].textPartObj
                          if (nextTextPartObj.hideable
                              && tpString[tpString.length - 1] === nextTextPartObj.part[nextTextPartObj.part.length - 1]) {
                            tpString = tpString.slice(0, -1)
                          }
                        }
                        return tpString.split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ])/)
                      })(tokenPart)"
              :id="'span-L-tokenPart-' + tokenPart.textPartIndex + '-ofToken-' + tokenPart.tokenIndex + '-letterpos-' + (le + tokenPart.lettersInTokenBeforeCurrentPart)"
              :style="letterCursorHere && !letterAddingMode && isCurrentToken
                    && currentLetterIndex === le + tokenPart.lettersInTokenBeforeCurrentPart
                      ? 'background-color: ' + cursorColor + '; border-radius: 5px' : ''"
              :key="'par-'+p+'-tokenPart-'+tpInP+'-letterString-'+le"
        >{{ letterString === '\n' ? 
                  (showLineBreakChar && !letterAddingMode && isCurrentToken
                    && currentLetterIndex === le + tokenPart.lettersInTokenBeforeCurrentPart
                   ? '↳' : '')
                : letterString.replace(/וֹ/g, '\ufb4b') }}<span
            v-if="letterAddingMode && isCurrentToken 
                        && currentLetterIndex === le + tokenPart.lettersInTokenBeforeCurrentPart"
            :style="letterAddingCursorStyle">|</span>
        </span>
      </span>
</template>
<script>
import styleGlobals from 'shared/js/forTextPanel/styleGlobals'

export default {
  name: 'TextPanelToken',
  props: {
    tokenPart: {},
    tpInP: {},
    currentLetterIndex: {},
    isCurrentToken: {},
    letterAddingMode: {},
    p: {},
    paragraph: {},
    showHideables: {},
    showLineBreakChar: {},
    textPanelStyles: {},
    nonConfidentMarking: {}
  },
  computed: {
    tokenClasses() {
      return this.getTokenClasses(this.tokenPart.tokenObj)
    },
    cursorColor () {
      return styleGlobals.cursorColor
    },
    hideableDisplayColor () {
      return styleGlobals.hideableColor
    },
    alternateHideableDisplayColor () {
      return styleGlobals.alternateHideableColor
    },
    letterAddingCursorStyle () {
      return {'background-color': styleGlobals.cursorColor, 'color': 'cyan'}
    },
    letterCursorHere () {
      return this.textPanelStyles.textPanel.USE_LETTER_CURSOR
    }
  },
  methods: {
    getTokenClasses (tokenObj) {
      let classes = []
      if (this.isCurrentToken) {
        classes.push('current-token')
      } else if (tokenObj.altered) {
        classes.push('altered-token')
      } else if (this.nonConfidentMarking && tokenObj.apiNonConfident) classes.push('api-non-confident')
      if (this.textPanelStyles.appFeatures.showPasukFont) {
        if (tokenObj.isPasuk) {
          classes.push('f-koren')
        }
      }
      if (this.nonConfidentMarking && tokenObj.markedNonConfident) {
        classes.push('marked-non-confident')
      }
      if (tokenObj.userClasses) classes = classes.concat(tokenObj.userClasses)
      return classes
    },
    tokenClicked(tokenIndex, e) {
      /* in the commit where this comment is added, the uncommented code LEFT in this method was not REWRITTEN,
         just that it stopped being "conditional", resulting in a change in INDENTATION */
      // console.log(e.target.attributes.id.value)
      let currentLetterIndex = 0
      if (this.textPanelStyles.textPanel.CAN_CLICK_ON_LETTERS && e.target.attributes.id.value.includes('letter')) {
        currentLetterIndex = parseInt(e.target.attributes.id.value.split('-')[7]) // skips 'span-L-tokenPart-tp-ofToken-t-letterpos-' prefix
      }
      const tokenSelectionObj = {
        tokenIndex: tokenIndex,
        letterIndex: currentLetterIndex,
        trigger: 'click'
      }
      this.$emit('token-selection', tokenSelectionObj)
    }
  }
}
</script>
<style scoped lang="scss">
  .clickable-token {
    cursor: pointer;
    /* border-radius: 7px; ("temporarily" cancelled b/c of "token-part" effect) */
  }
  .current-token {
    background-color: #d6ecff;
  }
  .altered-token {
    color: #bd10e0;
  }
  .api-non-confident {
    color: darkgray;
  }
  .marked-non-confident {
    border: solid red 1px;
  }
  .click-event-root * {
    pointer-events: none;
  }
</style>