<template>
  <div :class="{space: !para.hasForcedBreak}">
      <TextPanelToken v-for="(token, ti) in para.words"
            :key="ti"
            :display-token="token"
            :allow-single-letter="allowSingleLetter"
            @select="i => $emit('select', i)"
            @dblclick="i => $emit('dblclick', i)"
            >
      </TextPanelToken>
  </div>
</template>

<script>
import TextPanelToken from './TextPanelToken'
export default {
  name: 'TextPanelPara',
  components: {TextPanelToken},
  props: {
    para: {},
    allowSingleLetter: Boolean
  }
}
</script>

<style scoped>
.space {
  padding-bottom: 20px;
}
</style>
