<template>
  <b-modal
    id="how-modal"
    v-model="show" 
    ok-only 
    centered 
    size="lg" 
    footer-class="border-top d-none d-sm-block"
    header-class="d-none d-sm-block"
    ok-title="סגור"
    ok-variant="secondary"
    >
    <template slot="modal-header">איך זה עובד</template>
    <div class="how-to-text">
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "about-dialog",
  props: ["value"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.how-to-text {
    max-height: 70vh;
    overflow: auto;
  h3{
    text-decoration: underline;
  }
}
</style>
