/* ***************************************
This mixin "exposes" the methods:
  * getAdjacentParagraphTokenIndex() [for TextPanel]
  * moveToAdjacentLine() [for TextPanel]
(all else should be considered "internal")
*************************************** */

import $ from 'jquery'
import styleGlobals from 'shared/js/forTextPanel/styleGlobals'
import {getLetterCount, getTokenPartIndex} from './utils'

const LINE_DELTA = styleGlobals.MIN_FONT_SIZE - 5 // attempt to account for font size differences

export default {
  name: 'specialNavigationMixins',
  methods: {
    getAdjacentParagraphTokenIndex (inc) {
      var found = false
      var tokenIndex = this.currentTokenIndex
      const tokensArrayLength = this.allTokens.length
      while (!found && tokenIndex >= 0 && tokenIndex < tokensArrayLength) {
        const tokenObj = this.allTokens[tokenIndex]
        var textToTest = tokenObj.text
        if (Array.isArray(tokenObj.text)) {
          textToTest = tokenObj.text.map(p => p.part).join('')
        }
        if ((textToTest).includes('\n')) {
          found = true
          if (tokenIndex < tokensArrayLength - 1 && textToTest.lastIndexOf('\n') === textToTest.length - 1) {
            if (tokenIndex === this.currentTokenIndex - 1) {
              found = false
              tokenIndex += inc
            } else tokenIndex += 1
          }
        } else tokenIndex += inc
      }
      const toMoveTo = found ? tokenIndex : (inc > 0 ? tokensArrayLength - 1 : 0)
      // console.log('toMoveTo:')
      // console.log(toMoveTo)
      return toMoveTo
    },
    getPos (tokenObj, tokenIndex, letterIndex) {
      const tokenPartIndex = getTokenPartIndex(tokenObj, letterIndex)
      const span = $('#span-L-tokenPart-' + tokenPartIndex
        + '-ofToken-' + tokenIndex + '-letterpos-' + letterIndex)
      return (span.is(':visible')) ? span.position() : 'invisible'
    },
    moveToAdjacentLine (inc) {
      var found = false
      var tokenIndex = this.currentTokenIndex
      var letterIndex = typeof(this.currentLetterIndex) === 'number' ? this.currentLetterIndex : 0
      var tokenObj = this.allTokens[tokenIndex]
      const posToReach = this.getPos(tokenObj, tokenIndex, letterIndex)
      var previousPos = posToReach
      var reachedNextLine = false
      var tokenText = tokenObj.text
      if (Array.isArray(tokenObj.text)) {
        tokenText = tokenObj.text.map(p => p.part).join('')
      }
      var totalLength = getLetterCount(tokenText)
      var previousTotalLength
      const tokensArrayLength = this.allTokens.length
      while (!found && tokenIndex >= 0 && tokenIndex < tokensArrayLength) {
        letterIndex += inc
        if (letterIndex >= totalLength || letterIndex < 0) {
          tokenIndex += inc
          if (tokenIndex >= 0 && tokenIndex < tokensArrayLength) {
            if (inc > 0) previousTotalLength = totalLength
            tokenObj = this.allTokens[tokenIndex]
            tokenText = tokenObj.text
            if (Array.isArray(tokenObj.text)) {
              tokenText = tokenObj.text.map(p => p.part).join('')
            }
            totalLength = getLetterCount(tokenText)
          }
          letterIndex = inc < 0 ? totalLength - 1 : 0
        }
        if (tokenIndex >= 0 && tokenIndex < tokensArrayLength) {
          const pos = this.getPos(tokenObj, tokenIndex, letterIndex)
          // console.log('pos:')
          // console.log(pos)
          // console.log('posToReach:')
          // console.log(posToReach)
          var reachedNextLineThisTime = false // note that "next" means in whichever direction we're trying to go
          const previousPosPassed = (pos !== 'invisible' && inc > 0)
            ? previousPos.top < (pos.top - LINE_DELTA) : previousPos.top > (pos.top + LINE_DELTA)
          if (!reachedNextLine && previousPosPassed) reachedNextLineThisTime = true
          if (reachedNextLineThisTime) reachedNextLine = true
          const posLeftReached = (pos !== 'invisible' && inc > 0)
            ? pos.left < posToReach.left : pos.left > (posToReach.left - 10)
          if (reachedNextLine && posLeftReached) {
            found = true
          } else {
            if (inc > 0 && reachedNextLine && !reachedNextLineThisTime 
                && previousPos.top < (pos.top - LINE_DELTA)) {
              found = true
              letterIndex -= 1
              if (letterIndex < 0) {
                tokenIndex -= 1
                letterIndex = previousTotalLength - 1
              }
            } else previousPos = pos
          }
        }
      }
      const tokenToMoveTo = found ? tokenIndex 
        : (inc > 0 ? tokensArrayLength - 1 : 0)
      const letterToMoveTo = found ? letterIndex : (inc > 0 ? totalLength - 1 : 0)
      const whetherMoving = tokenToMoveTo !== this.currentTokenIndex || letterToMoveTo !== this.currentLetterIndex
      if (whetherMoving) {
        this.currentTokenIndex = tokenToMoveTo
        this.currentLetterIndex = letterToMoveTo
        this.$emit('token-selection', {
          tokenIndex: tokenToMoveTo,
          letterIndex: letterToMoveTo
        })
      }
      return whetherMoving
    }
  }
}
