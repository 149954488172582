<template>
  <keyboard-shortcuts
    :show-shortcuts-obj="showShortcutsObj"
    :line-data-sets="lineDataSets" />
</template>
<script>
import KeyboardShortcuts from 'shared/components/KeyboardShortcuts/KeyboardShortcuts'

const lineDataSets = [
{
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'arrow-key', direction: 'right' },
        { type: 'arrow-key', direction: 'left' }
      ] }
    ],
    explanationText: 'מעבר בין מילים',
    explanationTextEng: 'Skip between words'
  }, {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'arrow-key', direction: 'down' },
        { type: 'arrow-key', direction: 'up' }
      ] }
    ],
      explanationText: 'מעבר בין אפשרויות מורפולוגיות',
      explanationTextEng: 'Choose from Morphology options list'
  },
  {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
          { type: 'arrow-key', direction: 'down' },
          { type: 'arrow-key', direction: 'up' },
          { type: 'plus' },
          { type: 'key', text: 'Shift' }
      ] }
    ],
    explanationText: 'מעבר בין שורות',
    explanationTextEng: 'Skip between lines'
  },  
  {
    groupingItems: [
        { type: 'group', itemsOfGroup: [
            { type: 'arrow-key', direction: 'down' },
            { type: 'arrow-key', direction: 'up' },
            { type: 'plus' },
            { type: 'key', text: 'Shift' },
            { type: 'plus' },
            { type: 'key', text: 'Ctrl' }
        ] }
    ],
    explanationText: 'מעבר בין פסקאות',
    explanationTextEng: 'Skip between paragraphs'
  },  
  {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'E' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' }
      ] }
    ],
    explanationText: 'שכפול פרוט מורפולוגי נוכחי'
  }, {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'M' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' }    
      ] }
    ],
    explanationText: 'יצירת פרוט מורפולוגי חדש'
  },
{
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: '+' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' }
      ] }
    ],
    explanationText: 'הגדלת טקסט',
    explanationTextEng: 'Increase font size'
  }, {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: '-' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' }
      ] }
    ],
    explanationText: 'הקטנת טקסט',
    explanationTextEng: 'Decrease font size'
  },
  {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'Q' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' },
        { type: 'plus' },
        { type: 'key', text: 'Ctrl' }
      ] }
    ],
    explanationText: 'מעבר לתיבת חיפוש שמסננת את האפשרויות המורפולוגיות'
  },{
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'L' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' }
      ] }
    ],
    explanationText: 'הצגת אפשרויות מורפולוגיות שאינן תואמות לניקוד'
  },{
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'K' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' },
      ] }
    ],
    explanationText: 'הצגת אפשרויות מורפולוגיות התואמות לניקוד'
   }, {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'N' },
        { type: 'plus' },
        { type: 'key', text: 'Alt' }
      ] }
    ],
    explanationText: 'טעינת טקסט חדש'
  }, 
  {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: '/' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' }
      ] }
    ],
    explanationText: "סימון מילה / טבלה כמסופק"
  },
  {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: '?' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' }
      ] }
    ],
    explanationText: "סימון מילה / טבלה כמסופק"
  }, {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: '!' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' }
      ] }
    ],
    explanationText: "סימון מילה או טבלה כלא רלוונטית לתיוג"
  }, {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'H' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' },
        { type: 'plus' },
        { type: 'key', text: 'Ctrl' }
      ] }
    ],
    explanationText: "הוספת הערה"
  },
 {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'E' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' },
        { type: 'plus' },
        { type: 'key', text: 'Ctrl' }
      ] }
    ],
    explanationText: "מעבר לניווט למספר מילה"    
  },
  {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'K' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' },
        { type: 'plus' },
        { type: 'key', text: 'Ctrl' }
      ] }
    ],
    explanationText: "הצגת מורפוליגיות מקוצרות"
  },
  {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'Shift' },
        { type: 'plus' },
        { type: 'key', text: ']' }
      ] }
    ],
    explanationText: "הגדלת מספר התחיליות במילה"
  },
  {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'Shift' },
        { type: 'plus' },
        { type: 'key', text: '[' }
      ] }
    ],
    explanationText: "הקטנת מספר התחיליות במילה"
  },
  {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'Space' }
      ] }
    ],
    explanationText: "הוספת אפשרות מורפולוגית למאגר"
  },
  {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'A' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' },
        { type: 'plus' },
        { type: 'key', text: 'Ctrl' }
      ] }
    ],
    explanationText: "שינו אופציה מAccusative לNominative ולהיפך"
  },
  {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'P' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' }
      ] }
    ],
    explanationText: "הסרה והוספה של לקסמה"
  }, 
   {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'C' },
        { type: 'plus' },
        { type: 'key', text: 'Ctrl' }
      ] }
    ],
    explanationText: 'העתקת טקסט',
    explanationTextEng: 'Copy text'
  },
  {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'D' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' }
      ] }
    ],
    explanationText: 'הורדת נתונים',
    explanationTextEng: 'Download file'
  }, 
  {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'U' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' },
        { type: 'plus' },
        { type: 'key', text: 'Ctrl' }
      ] }
    ],
    explanationText: 'יצירת קבוצה',
    explanationTextEng: 'Create Group'
  },           
  /*,{
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'F' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' },
        { type: 'plus' },
        { type: 'key', text: 'Ctrl' }
      ] }
    ],
    explanationText: "פתיחת תיבת החיפוש"
  },    
   
   
 {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'D' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' }
      ] }
    ],
    explanationText: 'ייצוא קובץ',
    explanationTextEng: 'Download file'
  }, /* {
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'Z' },
        { type: 'plus' },
        { type: 'key', text: 'Ctrl' }
      ] }
    ],
    explanationText: 'ביטול פעולה',
    explanationTextEng: 'Undo operation'
  })
  lineDataSets.push({
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'Y' },
        { type: 'plus' },
        { type: 'key', text: 'Ctrl' }
      ] }
    ],
    explanationText: 'שחזור פעולה',
    explanationTextEng: 'Redo operation'
  }) */ 
]
if (process.env.VUE_APP_MORPHOLOGY_PARTNERS) {
  lineDataSets.push({
  
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'S' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' },
        { type: 'plus' },
        { type: 'key', text: 'Ctrl' }
      ] }
    ],
    explanationText: "שמירה"
  } 
)
}
if (!process.env.VUE_APP_MORPHOLOGY_PARTNERS) {
  lineDataSets.push({
  
    groupingItems: [
      { type: 'group', itemsOfGroup: [
        { type: 'key', text: 'S' },
        { type: 'plus' },
        { type: 'key', text: 'Shift' },
        { type: 'plus' },
        { type: 'key', text: 'Ctrl' }
      ] }
    ],
    explanationText: "שמירה בDropbox"
  } 
)
}

export default {
  name: 'KeyboardShortcutsContainer',
  components: {KeyboardShortcuts},
  props: ['showShortcutsObj'],
  data () {
    return {
      lineDataSets: lineDataSets
    }
  }
}
</script>
<style scoped lang="scss">
</style>
