<template>
  <div class="about-text">
    <h3>התחברות ל-Dropbox</h3>
    <p>מתחברים דרך הקישור למעלה לתיקיית ה-Dropbox שלכם. לאחר ההתחברות תוכלו לגשת מהמסך של ניהול פרויקטים, לכל הקבצים
      והתיקיות ב-Dropbox.</p>

    <h3>העלאת קבצים והורדת קבצים</h3>
    <p>העלאה והורדה של קבצים מתבצעת דרך תיקיית ה Dropbox שלכם. לתיקייה תוכלו לגשת דרך האתר של Dropbox או התיקייה של
      Dropbox שאצלכם במחשב.</p>

    <h3>עבודה על קובץ מתוך ה-Dropbox</h3>
    <p>היכנסו למסך של ניהול פרויקטים,שם תוכלו לראות את כל התיקיות שיש לכם ב-Dropbox,ובחרו את הקובץ שעליו תרצו לעבוד.</p>
    <p>אנחנו נשכפל את הקובץ שבחרתם ונבצע עליו את השינויים שתערכו.</p>

    <h3>שמירת שינויים וסיום עבודה על הפרויקט</h3>
    <p>עבדתם על הקובץ ואתם מעוניינים לשמור את השינויים?</p>
    <p>לחצו על הכפתור ״שמור ב-Dropbox״, שנמצא בסרגל הכלים מתחת לאזור הטקסט.</p>
    <p>אחרי הלחיצה ייפתח חלון שבו תוכלו ללחוץ על שמירה וגם לסמן שסיימתם לעבוד על הפרויקט על ידי שתסמנו ״הפרויקט
      הושלם״.</p>
    <p></p>
    <p>- שמירת שינויים: בשמירת שינויים אנחנו נשמור על השינויים שביצעתם עד כה, כך שתוכלו להמשיך לעבוד על הפרויקט מאוחר יותר.</p>
    <p>הקובץ ישמר ב-Dropbox בתיקייה שנקראת "saved", אבל במסך של ניהול פרוייקטים אם קובץ וקיים גירסה ב"saved", הגירסה ששמרתם נפתח באופן אוטומטי.</p>
    <p></p>
    <p>- סיום עבודה על פרויקט: סימון של פרויקט שהושלם יכול להתבצע גם דרך המסך של "ניהול פרויקטים". לצד שם הקובץ בעמודה "הושלם" יש כפתור שיש ללחוץ עליו על מנת לסמן שהפרויקט הושלם.</p>
    <p>אם הקובץ הוגדר כפרויקט שהושלם הקובץ ישמר ב-Dropbox בתיקייה שנקראת "Completed".</p>
    
  </div>
</template>

<script>
export default {
  name: 'AboutDropbox'
}
</script>