<template>
  <b-modal
    v-model="show"
    static
    id="account-needed"
    no-fade
    size="sm"
    hide-header
    hide-footer
    @show="positionDialog()"
    @hide="hiddenEvt()"
    body-class="p-2"
  >
    <small v-if="add">
    כדי להוסיף אפשרות מורפולוגית,<br/>
    יש להתחבר לחשבון האישי
    </small>
    <small v-if="remove">
      כדי לסמן מילה כלא רלוונטית,<br/>
      יש להתחבר לחשבון האישי
    </small>
    <small v-if="removeMessage">
      כדי למחוק אפשרות זו,<br/>
      יש לבחור אפשרות אחרת.
    </small>
    <small v-if="showNegativeMessage">
      לא ניתן לסמן אפשרות זו כפגומה, כיון שהיא בשימוש
    </small>
  </b-modal>
</template>
<script>
export default {
  name: "AccountNeededPopup",
  props: ["value", "top" , "left", "add", "remove", "removeMessage", "showNegativeMessage"],
  data() {
    return {
      checked: false
    }
  },
  methods: {
    hiddenEvt () {
      this.$store.state.canDelete = true
      const active = document.querySelector('.action-clicked');
      if(active){
        active.classList.remove('action-clicked')
      }
    },
    positionDialog () {
      document.getElementsByClassName("modal-dialog")[0].style.marginLeft = this.left+'px'
      document.getElementsByClassName("modal-dialog")[0].style.top = this.top-80+'px'
    }  
  },
  computed: {
    hebrew () {
      return this.$settings.hebrew
    },    
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
}
</script>
<style lang="scss">
  #account-needed {
    .modal-dialog {
      box-shadow: 0 1px 4px 0 rgba(0,0,0,.3);
      background-color: #fff;
      position: relative;
      width: 200px;
      margin-left: 0;
      border-radius: 2px;      
    }
  }  
  #account-needed___BV_modal_backdrop_ {
     opacity: 0!important;
   }
</style>