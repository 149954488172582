export const Messages = {
  // messages in both directions
  DICTA_ACCOUNTS_CONNECT: 'DICTA_ACCOUNTS_HELLO',
  DICTA_ACCOUNTS_SEND_TOKEN: 'DICTA_ACCOUNTS_SEND_TOKEN',
  // messages from a dicta site
  DICTA_ACCOUNTS_SIGN_OUT: 'DICTA_ACCOUNTS_SIGN_OUT',
  DICTA_ACCOUNTS_GET_TOKEN: 'DICTA_ACCOUNTS_GET_TOKEN',
  // messages from accounts.dicta
  DICTA_ACCOUNTS_LOGIN_STATUS: 'DICTA_ACCOUNTS_LOGIN_STATUS',
  DICTA_ACCOUNTS_LOGOUT_REDIRECT: 'DICTA_ACCOUNTS_LOGOUT_REDIRECT'
}
export const Status = {
  NEW: 'NEW',
  SIGNED_IN: 'SIGNED_IN',
  SIGNED_OUT: 'SIGNED_OUT'
}
