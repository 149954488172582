<template>
  <div class="morphology-table bg-white mt-3 px-3 py-1" :style="{ height: resizeHeight + 'px' }" ref="table"
    v-hotkey="keymap">
    <div class="h-100 position-relative">
      <div class="position-absolute w-100 h-100 disabled" v-if="isNeutralized"></div>
      <div class="row">
        <div class="col-sm-6">
          <morph-filter :filtered="filterArr" :morphOptions="filterMrophOptions" :forceHebrew="true"
            @toFilter="filterResults">
          </morph-filter>
        </div>
        <div class="col-sm-2 p-0 zoom">
          <span v-b-tooltip.hover title="הגדל טקסט בטבלה">
            <button id="font-larger" class="px-2 bg-secondary rounded-right" @click="changeFontSize('pos')"
              :disabled="fontSize > 25">
              <h2 class="m-0">A</h2>
            </button>
          </span>
          <span v-b-tooltip.hover title="הקטן טקסט בטבלה">
            <button id="font-smaller" class="px-2 bg-secondary rounded-left" @click="changeFontSize('ng')"
              :disabled="fontSize < 13">
              A
            </button>
          </span>
          <button id="options-with-nikkud" class="bg-secondary rounded-left f-narkis p-0  mr-2 d-none"
            @click="setNikudDisplay(true)" :class="{ 'btn-active': withNikud }" v-b-tooltip.hover
            title="תוצאות עם ניקוד (Shift+K)">
            <h3>אָ</h3>
          </button>
          <button id="options-no-nikkud" class="bg-secondary f-narkis rounded-right p-0 last d-none"
            :class="{ 'btn-active': !withNikud }" @click="setNikudDisplay(false)" v-b-tooltip.hover
            title="תוצאות ללא ניקוד (Shift+L)">
            <h3>א</h3>
          </button>
        </div>
        <div class="col-sm-4">
          <button class="d-block mr-auto pt-2 text-primary bg-transparent shadow-none border-0"
            :class="{ 'disabled': isSep }" id="new-morphology-link" @click="openMorphologyPopup">
            <span v-b-tooltip.hover title="Shift+M" class="pl-1">צור מורפולוגיה</span>
            <i-arrow-alt-circle-left></i-arrow-alt-circle-left>
          </button>
        </div>
      </div>
      <div class="table-wrap">
        <!--:removeMessage="!canDeleteMorph" -->
        <AccountNeededPopup v-if="showAccountNeededPopup" v-model="showAccountNeededPopup" :top="posTop" :left="posLeft"
          :add="showAddMessage" :remove="showRemoveMessage" :showNegativeMessage="showNegativeMessage" />
        <small v-if="nikudMorphs.length > 0" class="toggle-no-nikud d-block" :class="{ 'active': !hideNikudRows }"
          ref="toggleNikudBtn" @click="toggleNikudVisible">
          <div class="px-3 py-2 d-block">
            <i class="fas ml-1" :class="{ 'fa-angle-down': !hideNikudRows, 'fa-angle-up': hideNikudRows }"
              ref="nikudBtnIcon"></i>
            מורפולוגיות מנוקדות <span class="text-muted">{{ withNikudCount }}</span>
          </div>
        </small>
        <table class="table position-relative">
          <tr :style="{ 'font-size': fontSize + 'px' }" :class="{
            'row-with-btn': showToggleNikud(mli),
            'row-active': isRowActive(morphLine[1]),
            'disabled-row': morphLine[1].indexForRestore != null,
            'd-none': hideNikudRows && mli < withNikudCount || toggleNoNikud
          }" v-for="(morphLine, mli) in morphOptions" ref="rowItem"
            :id="morphLine[1].optionIndex ? morphLine[1].optionIndex : mli" :key="mli">
            <td :class="{ 'with-btn': showToggleNikud(mli) }" class="f-narkis" @click="setSelectedMorph(morphLine[1])">
              <small v-if="showToggleNikud(mli)" @click.stop="setNikudDisplay(!withNikud)" class="hide-no-nikud d-block"
                ref="toggleNikudDisplay" @mouseover="noNikudHover(mli)" @mouseleave="noNikudHoverOut(mli)">
                <i class="fas fa-angle-down ml-1"></i>מורפולוגיות לא מנוקדות
                <span class="text-muted">{{ withOutNikudCount }}</span>
              </small>
              <!-- [
                return [Object.values(obj.morphOptions).flat().filter(ms => ms !== 'Unspecified' && ms !== ''), obj, starred, show]
                  0: morpharray, 0
                  1: obj.word,  1
                  2: obj.prefixLength, 1
                  3: obj.flag, 1
                  4 obj.aramaic, 1
                  5: obj.lexEnabled, 1
                  6: obj.returnedWord, 1
                  7: starred, 2
                  8: show, 3
                  9: fromServer,  1
                  10: optionIndex] 1 -->
              <span v-if="morphLine[1].prefixLength > 0">
                <span
                  v-for="(currentItem, cli) in morphLine[1].returnedWord.replace('|', '').split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ\|])/)"
                  :key="cli" class="d-inline-block" :class="{ 'prefix': cli < morphLine[1].prefixLength }">
                  {{ currentItem }}
                  <span class="prefix-sep position-relative" v-if="cli === morphLine[1].prefixLength - 1">|</span>
                </span>
              </span>
              <span v-else>
                {{ morphLine[1].returnedWord }}
              </span>
              <span v-if="morphLine[1]" :class="{ 'disabled-text': !morphLine[1].lexEnabled }">
                [{{ morphLine[1].lexEnabled ? morphLine[1].word : 'לא ידוע' }}
                <span class="font-italic" v-if="morphLine[1].aramaic">-ארמית</span>
                ]
              </span>
            </td>
            <td :class="{ 'with-btn': showToggleNikud(mli) }" @click="setSelectedMorph(morphLine[1])"
              class="border-0 text-left pl-2" v-html="printMorphArray(morphLine[0], morphLine[1].flag).join()"></td>
            <td :class="{ 'with-btn': showToggleNikud(mli) }" class="text-left pl-2"
              style="width:140px;padding-top:4px;">

              <!--delete, if user created
              <i class="fas fa-trash-alt d-inline-block ml-1 invisible"
                v-if="!morphLine[1].fromServer && morphLine[2]!=='true'"
                @click="deleteMorphology(morphLine[1].optionIndex ? morphLine[1].optionIndex : mli, morphLine[1])"/>
              -->
              <!--if from server, show locked icon -->
              <i v-if="morphLine[1].fromServer" class="fas fa-lock d-inline-block ml-1 invisible" />

              <!-- add, to unwanted list -->
              <i @click="unwantedMorphology(morphLine[1])" class="fas fa-sort-amount-down d-inline-block ml-1 invisible"
                v-if="morphLine[1].fromServer && morphLine[1].indexForRestore == null && morphLine[2] !== 'pinned'" />
              <i @click="restoreMorphology(morphLine[1])" class="fas fa-sort-amount-up d-inline-block ml-1 invisible"
                v-if="morphLine[1].indexForRestore != null" />
              <!--edit, if user created -->
              <i @click="editOrDuplicate(morphLine[1], morphLine[1].optionIndex, true)" v-if="!morphLine[1].fromServer"
                v-b-tooltip.hover title="עריכה" class="fas fa-pencil-alt d-inline-block ml-1 invisible" />
              <!--duplicate-->
              <i @click="editOrDuplicate(morphLine[1], morphLine[1].optionIndex)" class="far fa-clone invisible ml-1"
                v-b-tooltip.hover title="שכפול" />
              <!--Add/remove from dictionary (pin) -->
              <i-star class="text-primary invisible ml-1" v-if="morphLine[2] !== 'true' && morphLine[2] !== 'pinned'"
                @click="saveMorphology(morphLine[1].optionIndex, morphLine[1])" />
              <i class="fas fa-star text-primary d-inline-block" v-if="morphLine[2] === 'true'"
                @click="removeFromSaved(morphLine[1])" />
              <i class="fas fa-thumbtack invisible" v-if="morphLine[2] !== 'true' && morphLine[2] !== 'pinned'"
                @click="saveMorphology(morphLine[1].optionIndex, morphLine[1], true)">
              </i>
              <i class="fas fa-thumbtack text-primary d-inline-block" v-if="morphLine[2] === 'pinned'"
                @click="removeFromSaved(morphLine[1], true)" />

            </td>
          </tr>
        </table>
        <small v-if="withNikud && allMorphOptions.length > nikudMorphs.length || toggleNoNikud"
          @click="setNikudDisplay(!withNikud)" class="bg-white w-100 toggle-no-nikud bottom-btn px-3 py-2 d-block"
          ref="toggleNikudDisplay">
          <i class="fas fa-angle-up ml-2"></i>מורפולוגיות לא מנוקדות
          <span class="text-muted">{{ withOutNikudCount }}</span>
        </small>
        <small v-if="nikudMorphs.length == allMorphOptions.length"
          class="bg-white w-100 toggle-no-nikud bottom-btn px-3 py-2 d-block" @click="toggleIcon">
          <i class="fas fa-angle-up ml-1" ref="noNIkudIcon"></i>מורפולוגיות לא מנוקדות
          <span class="text-muted">0</span>
        </small>
      </div>
    </div>
    <MorphologyPopup ref="morphPopup" v-if="showMorphologyPopup" v-model="showMorphologyPopup"
      @morph-updated="morphAdded" @showLoginPopup="showLoginPopup = true"
      :currentMorphObject="editMode || duplicateMode ? morphItemForPopup : blankMorphOption"
      :currentMorphData="morphData" :newMorph="editMode || duplicateMode ? false : true" :groupRange="groupRange"
      :editMode="editMode" :currentWordIndex="currentWordIndex"
      :canDelete="canDeleteObj(morphItemForPopup) && editMode">
    </MorphologyPopup>
    <div id="item-deleted-msg" class="notify-msg" style="display:none;">
      האפשרות נמחקה בהצלחה
    </div>
    <div id="item-added-msg" class="notify-msg" style="display:none;">
      המורפולוגיה נוספה למאגר
    </div>
    <div id="item-removed-msg" class="notify-msg" style="display:none;">
      המורפולוגיה הוסרה מהמאגר
    </div>
    <LoginNeededPopup v-model="showLoginPopup" @cancelled="submit" />
  </div>
</template>
<script>
//import MorphologyPopup from '@/components/MorphologyPopup.vue'
import MorphologyPopup from '@/components/MorphologyPopup'
import LoginNeededPopup from '@/components/LoginNeededPopup.vue'
import AccountNeededPopup from '@/components/AccountNeededPopup.vue'
import modalOpen from 'shared/js/modalOpen'
import MorphFilter from '@/components/MorphFilter.vue'
import morphologyMixins from '@/mixins/morphologyMixins'
import { stringWithoutNikud } from 'shared/js/commonHebrew'

import _ from 'lodash'
export default {
  name: 'MorphologyTable',
  data() {
    return {
      showMorphologyPopup: false,
      morphItemForPopup: null,
      initialHeight: 0,
      currentIndex: 0,
      filterArr: [],
      fontSize: 16,
      blankMorphOption: {},
      editMode: false,
      duplicateMode: false,
      showAccountNeededPopup: false,
      showAddMessage: false,
      showRemoveMessage: false,
      posLeft: 88,
      posTop: 200,
      rowIndex: 0,
      hideNikudRows: false,
      toggleNoNikud: false,
      showNegativeMessage: false,
      firstVisit: true,
      showLoginPopup: false
    }
  },
  mixins: [morphologyMixins],
  props: ['changePos', 'dblClick'],
  components: { MorphologyPopup, MorphFilter, AccountNeededPopup, LoginNeededPopup },
  mounted() {
    this.initialHeight = this.$refs.table.clientHeight
  },
  methods: {
    isRowActive(option) { //this.morphOptions.length === 1 || would be for groups with only one option
      return this.morphOptions.length === 1 || _.isEqual(_.omit(option, ['optionIndex', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']), _.omit(this.selectedMorph, ['optionIndex', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']))
    },
    morphAdded() {
      this.$nextTick(() => {
        this.scrollToSelected(1)
      })
      this.hideNikudRows = false
    },
    showToggleNikud(index) {
      return this.allMorphOptions.length > this.withNikudCount &&
        !this.withNikud && this.withNikudCount === index
    },
    noNikudHover(index) {
      if (this.$refs.rowItem[index].classList.contains('row-with-btn'))
        this.$refs.rowItem[index].classList.add('btn-hovered')
    },
    noNikudHoverOut(index) {
      if (this.$refs.rowItem[index].classList.contains('row-with-btn'))
        this.$refs.rowItem[index].classList.remove('btn-hovered')
    },
    toggleIcon() {
      this.$refs.noNIkudIcon.classList.toggle('fa-angle-up')
      this.$refs.noNIkudIcon.classList.toggle('fa-angle-down')
    },
    toggleNikudVisible() {
      this.hideNikudRows = !this.hideNikudRows
    },
    difference(object, base) { // TODO: Remove after testing
      function changes(object, base) {
        return _.transform(object, function (result, value, key) {
          if (!_.isEqual(value, base[key])) {
            result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
          }
        })
      }
      return changes(object, base);
    },
    toggleLexOrST(obj, lex) { //Toggle Lex or Suffix Type
      var editable = false
      if (editable) {
        this.$store.commit("TOGGLE_LEX_ENABLED", obj.optionIndex)
      } else {
        //Duplicate
        let newMorphOption = _.cloneDeep(obj)
        newMorphOption.fromServer = false
        if (lex) {
          newMorphOption.lexEnabled = !newMorphOption.lexEnabled
        } else {
          if (newMorphOption.morphOptions.suffixType === 'Nominative') {
            newMorphOption.morphOptions.suffixType = 'Accusative'
          } else {
            if (newMorphOption.morphOptions.suffixType === 'Accusative') {
              newMorphOption.morphOptions.suffixType = 'Nominative'
            }
          }

        }
        //if changing suffix type ensure it is either accusative or nominative
        if (newMorphOption.morphOptions.suffixType === 'Nominative' || newMorphOption.morphOptions.suffixType === 'Accusative' || lex) {
          let selectedIndex = _.findIndex(this.allMorphOptions, (e) => {
            return _.isEqual(_.omit(e, ['optionIndex', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']), _.omit(newMorphOption, ['optionIndex', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']))
          })
          if (selectedIndex < 0) { //only add if doesn't exist
            this.$store.commit("ADD_MORPH_OPTION", newMorphOption)
            this.$store.commit("SET_SELECTED_MORPH", newMorphOption)
            if (this.userLoggedIn) {
              this.$store.commit("UPDATE_USER_DICTIONARY", {
                contextData: { morphObj: newMorphOption, operation: 'add' }
              }
              )
            }
          } else {
            //already exists remove extra option from dictionary and table
            this.removeFromSaved(this.selectedMorph, true)
            if (!obj.fromServer) {
              let currentSelected = this.selectedMorph
              this.setMorphCurrentIndex(selectedIndex)
              this.deleteMorphology(currentSelected.optionIndex, currentSelected)
            }
          }
        }
      }
    },
    setSelectedMorph(obj) {
      if (obj.indexForRestore === undefined) { //only if not in negative morph list
        if (this.groupId) {
          this.$store.commit("SET_GROUP_SELECTED_MORPH", obj)
        } else {
          this.$store.commit("SET_SELECTED_MORPH", obj)
        }
      }
    },
    setNikudDisplay(nikud) {
      if (!nikud) {
        //scroll to top of no nikud list
        this.scrollToSelected(this.withNikudCount - 1, 'start')
      } else {
        //scroll to top of table
        this.scrollToSelected(0)
      }
      if (!this.withNikud && this.nikudMorphs.length === 0) {
        this.toggleNoNikud = !this.toggleNoNikud
      }
      this.$store.commit("SET_WORD_NIKUD", nikud)
    },
    toggleTermNames() {
      this.$store.commit("SET_TERM_NAMES")
    },
    newWordSelected() {
      this.hideNikudRows = false
      this.toggleNoNikud = false
      this.filterArr = []
      if (this.morphOptions.length > 0 && !this.isNeutralized)
        this.scrollToSelected(this.currentMorphIndex)
      if (!this.selectedMorph && !this.isSep) {
        this.firstVisit = true
        this.$store.commit("SET_SELECTED_MORPH", this.allMorphOptions[0])
      } else {
        this.firstVisit = false
      }
      if (!this.isSep)
        this.syncData()
    },
    syncData() {
      //if selected morph, ensure it is in options, if not - add if not a group object
      if (!this.groupId && this.currentMorphWordObj.selectedMorph && Object.keys(this.currentMorphWordObj.selectedMorph).length > 0) {
        //let selectedIndex = _.findIndex(this.allMorphOptions, this.currentMorphWordObj.selectedMorph)
        let selectedIndex = _.findIndex(this.allMorphOptions, (e) => {
          return _.isEqual(_.omit(e, ['optionIndex', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']), _.omit(this.currentMorphWordObj.selectedMorph, ['optionIndex', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']))
        })
        if (selectedIndex < 0) { //add to second place on list
          this.$store.commit('INSERT_MORPH_OPTION', this.currentMorphWordObj.selectedMorph)
        }
      }

      if (!this.groupId && this.firstVisit) {
        //check if word belongs to group and create group
        let possibleGroup = _.pickBy(this.savedOptions, (value, key) => key.split(' ')[0].toString().includes(this.currentWord) && key.split(' ')[0].toString().includes('_'))
        let possibleGroupArr = Object.keys(possibleGroup)
        possibleGroupArr.forEach(element => {
          let groupVals = element.replace(' ()', '').split('_')
          let counter = 0
          let clickedFoundIndex = groupVals.indexOf(this.morphData[this.currentWordIndex].text)
          let nextWordId = this.currentWordIndex - (clickedFoundIndex * 2)

          groupVals.forEach(wd => {
            if (this.morphData[nextWordId].text === wd) {
              counter++
              nextWordId = nextWordId + 2
            }
          })


          if (counter === groupVals.length && groupVals.length > 1) {
            let groupRange = []
            for (let i = this.currentWordIndex - (clickedFoundIndex * 2); i <= nextWordId - 2; i++) {
              groupRange.push(i)
            }
            this.$store.commit('SET_GROUP', { groupRage: groupRange, showToast: false })
          }
        })
      }

      let savedMorphs = _.pickBy(this.savedOptions, (value, key) => _.isEqual(key.split(' ')[0], this.currentWord))
      if (this.groupId) {
        savedMorphs = _.pickBy(this.savedOptions, (value, key) => _.isEqual(key.split(' ')[0], this.currentGroupWord))
        //_.pickBy(this.savedOptions, (value, key) => console.log(key.split(' ')[0]))
      }
      var data = Object.values(savedMorphs).flat()
      _.uniqWith(data, _.isEqual);
      //get dictionary items by word
      if (data) {
        data.forEach(element => {
          //let indexFound = _.findIndex(this.allMorphOptions, element)
          let indexFound = _.findIndex(this.allMorphOptions, (e) => {
            return _.isEqual(_.omit(e, ['optionIndex', 'flag', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']), _.omit(element, ['optionIndex', 'flag', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']))
          })
          if (this.groupId) {
            indexFound = _.findIndex(this.currentMrophGroup.groupData, (e) => {
              return _.isEqual(_.omit(e, ['optionIndex', 'flag', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']), _.omit(element, ['optionIndex', 'flag', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']))
            })
          }
          if (indexFound > -1) { //TODO
            if (element.pinned && this.firstVisit) { //we only pin going forward
              this.$store.commit("MOVE_MORPH_OPTION_TO_TOP", {
                contextData: { index: indexFound, pinned: true }
              })
            }
            else if (indexFound > 1 && !element.pinned)
              this.$store.commit("MOVE_MORPH_OPTION_TO_TOP", {
                contextData: { index: indexFound, pinned: false }
              }) //move to first or second row
          } else if (indexFound == -1) {
            element.onlyNikud = true
            //add word from dictionary to options
            if (element.pinned && !this.firstVisit) { //add pinned words to dictionary but unpin if not first visit
              delete element.pinned
              delete element.indexForUnpin
            }
            if (this.groupId) {
              this.$store.commit('ADD_GROUP_MORPH_OPTION', element)
            } else {
              this.$store.commit('INSERT_MORPH_OPTION', element)
            }
            if (element.pinned)
              this.setSelectedMorph(element)
            //}
          }
        })
      }
      //add words to no nikkud list - strip nikud (exclude savedMorphs already added)
      //set onlyNikud to false add to list
      let remainingMorphs = _.pickBy(this.savedOptions, (value, key) => !_.includes(key, this.currentWord))
      let noNikudMorphs = _.pickBy(remainingMorphs, (value, key) => (stringWithoutNikud(key.replace(/ *\([^)]*\) */g, "")).trim() === stringWithoutNikud(this.currentWord)))
      let noNikudMorphsArr = Object.values(noNikudMorphs).flat()
      noNikudMorphsArr.forEach(element => {
        let nIndexFound = _.findIndex(this.allMorphOptions, (e) => {
          return _.isEqual(_.omit(e, ['returnedWord', 'word', 'optionIndex', 'flag', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']), _.omit(element, ['returnedWord', 'word', 'optionIndex', 'flag', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']))
        })
        if (this.groupId) {
          nIndexFound = _.findIndex(this.currentMrophGroup.groupData, (e) => {
            return _.isEqual(_.omit(e, ['returnedWord', 'word', 'optionIndex', 'flag', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']), _.omit(element, ['returnedWord', 'word', 'optionIndex', 'flag', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']))
          })
        }
        if (nIndexFound == -1) {
          //element.onlyNikud = false
          if (element.pinned)
            this.$store.commit("MOVE_MORPH_OPTION_TO_TOP", {
              contextData: { index: element.optionIndex, pinned: true }
            })
          else
            this.$store.commit('PUSH_MORPH_OPTION', element)
        }
      })
      //check negative entries
      let negativeMorphs = _.pickBy(this.hideOptions, (value, key) => _.isEqual(stringWithoutNikud(key.split(' ')[0]), stringWithoutNikud(this.currentWord)))

      if (this.groupId) {
        negativeMorphs = _.pickBy(this.hideOptions, (value, key) => _.isEqual(stringWithoutNikud(key.split(' ')[0]), stringWithoutNikud(this.currentGroupWord)))
        //_.pickBy(this.savedOptions, (value, key) => console.log(key.split(' ')[0]))
      }
      var negdata = Object.values(negativeMorphs).flat()
      _.uniqWith(negdata, _.isEqual);
      //get dictionary items by word

      if (this.firstVisit) { //we only apply negative morphs going forward
        if (negdata) {
          negdata.forEach(element => {
            //let indexFound = _.findIndex(this.allMorphOptions, element)
            let indexFound = _.findIndex(this.allMorphOptions, (e) => {
              return _.isEqual(_.omit(e, ['optionIndex', 'fromServer', 'flag', 'indexForRestore', 'pinned', 'indexForUnpin']), _.omit(element, ['optionIndex', 'fromServer', 'flag', 'indexForRestore', 'pinned', 'indexForUnpin']))
            })
            if (this.groupId) {
              indexFound = _.findIndex(this.currentMrophGroup.groupData, (e) => {
                return _.isEqual(_.omit(e, ['optionIndex', 'fromServer', 'flag', 'indexForRestore', 'pinned', 'indexForUnpin']), _.omit(element, ['optionIndex', 'fromServer', 'flag', 'indexForRestore', 'pinned', 'indexForUnpin']))
              })
            }
            if (indexFound > -1) {
              let toIndex = this.allMorphOptions[indexFound].onlyNikud ? this.nikudMorphs.length - 1 : this.allMorphOptions.length
              this.$store.commit("MOVE_MORPH_OPTION_TO_BOTTOM", {
                contextData: { index: indexFound, toIndex: toIndex }
              })
              this.setMorphCurrentIndex(this.selectedMorph.optionIndex)
            }
          })
        }
      }
      //retore unhidden morphologies
      let hiddenMorphs = this.allMorphOptions.filter(option => option.indexForRestore >= 0)
      if (hiddenMorphs.length > 0) {
        //Check if still on negative list. If not on list, restore
        hiddenMorphs.forEach(element => {
          //let indexFound = _.findIndex(this.allMorphOptions, element)
          let indexFound = _.findIndex(negdata, (e) => {
            return _.isEqual(_.omit(e, ['optionIndex', 'fromServer', 'flag', 'indexForRestore', 'pinned', 'indexForUnpin']), _.omit(element, ['optionIndex', 'fromServer', 'flag', 'indexForRestore', 'pinned', 'indexForUnpin']))
          })
          if (indexFound < 0) {
            //not on list, restore
            this.restoreMorphology(element)
            if (this.firstVisit) {
              this.setSelectedMorph(this.allMorphOptions[0]) //set selected morph to first option
            }
          }
        })

      }
    },
    setPos(index) {
      this.posTop = document.getElementById(index).getBoundingClientRect().y
      this.posLeft = document.getElementById(index).getBoundingClientRect().x
      document.getElementById(index).classList.add('action-clicked')
    },
    deleteMorphology(index, obj) {
      this.setPos(index)
      if (this.canDeleteMorph) {
        if (this.groupId) {
          this.$store.commit("REMOVE_GROUP_MORPHOLOGY", { contextData: { morph: obj, morphIndex: index } })
        } else {
          this.$store.commit("REMOVE_MORPHOLOGY", { contextData: { morph: obj, morphIndex: index } })
        }
      }
    },
    doesInclude(arr, arr2) {
      return arr2.every(i => arr.includes(i))
    },
    scrollToSelected(index, start) {
      if (!start)
        start = 'center'
      setTimeout(() => {
        var rows = document.querySelectorAll('table tr')
        if (rows[index]) {
          rows[index].scrollIntoView({
            behavior: 'smooth',
            block: start
          })
        }
      }, 40)
    },
    saveMorphToFavorites() {
      //if space pressed, add to dictionary if not on nikud list so it always appears
      this.saveMorphology(this.currentMorphIndex, this.selectedMorph)
    },
    setMorphCurrentIndex(index) { //for up down arrow navigation
      if (!this.currentMorphObject.indexForRestore) { //only if not in negative morph list
        if (this.groupId) {
          this.$store.commit("SET_CURRENT_GROUP_MORPH_INDEX", index)
          this.$store.commit("SET_GROUP_SELECTED_MORPH", this.currentMorphObject)
        } else {
          this.$store.commit("SET_CURRENT_MORPH_INDEX", index) //for replacing existing array option
          this.$store.commit("SET_SELECTED_MORPH", this.currentMorphObject)
        }
      }
      this.scrollToSelected(index)
    },
    changeFontSize(type) {
      if (type === 'pos') {
        if (this.fontSize <= 25) {
          this.fontSize++
        }
      } else {
        if (this.fontSize >= 13) {
          this.fontSize--
        }
      }
    },
    removeFromSaved(obj, hideMessage) {
      //if pinned, remove pin
      if (hideMessage) {
        this.$store.commit("UPDATE_USER_DICTIONARY", {
          contextData: { morphObj: obj, operation: 'removeNoMessage' }
        }
        )
      } else {
        this.$store.commit("UPDATE_USER_DICTIONARY", {
          contextData: { morphObj: obj, operation: 'remove' }
        }
        )
      }
    },
    unwantedMorphology(obj) {
      this.removeFromSaved(obj) //remove from dictionary
      //only if not selected anywhere
      let counter = 0
      this.morphData.map(element => {
        if (_.isEqual(_.omit(element.selectedMorph, ['optionIndex', 'fromServer', 'pinned', 'indexForUnpin', 'indexForRestore']), _.omit(obj, ['optionIndex', 'fromServer', 'pinned', 'indexForUnpin', 'indexForRestore']))) {
          if (element.actualIndex != this.currentWordIndex) {
            counter++
          } else {
            let currentRowElement = document.getElementsByClassName("row-active")[0]
            if (currentRowElement) {
              if (currentRowElement.nextElementSibling && currentRowElement.nextElementSibling.tagName === 'TR') {
                if (this.allMorphOptions[currentRowElement.nextElementSibling.id].indexForRestore === undefined) {
                  this.nextItem()
                }
                else {
                  counter++
                }
              } else {
                counter++
              }
            }
          }
        }
      })
      if (counter == 0) {
        this.$store.commit("UPDATE_USER_DICTIONARY", {
          contextData: { morphObj: obj, operation: 'hide' }
        }
        )
        let toIndex = obj.onlyNikud ? this.nikudMorphs.length - 1 : this.allMorphOptions.length - 1
        this.$store.commit("MOVE_MORPH_OPTION_TO_BOTTOM", {
          contextData: { index: obj.optionIndex, toIndex: toIndex }
        })
      } else {
        this.showNegativeMessage = true
        this.showAddMessage = false
        this.showRemoveMessage = false
        this.setPos(obj.optionIndex)
        this.showAccountNeededPopup = true
      }
    },
    restoreMorphology(obj) {
      this.$store.commit("UPDATE_USER_DICTIONARY", {
        contextData: { morphObj: obj, operation: 'unhide' }
      }
      )
      //this.setMorphCurrentIndex(this.selectedMorph ? this.selectedMorph.optionIndex : 0)
    },
    saveMorphology(index, obj, pinned) {
      //this.firstVisit = true
      if (this.userLoggedIn) {
        if (obj.indexForRestore !== undefined) {
          this.restoreMorphology(obj)
        }
        if (obj.onlyNikud) {
          if (pinned) {
            this.removeFromSaved(this.allMorphOptions[0], true, true)
            obj.pinned = true
            obj.indexForUnpin = obj.optionIndex
            this.setSelectedMorph(obj)
            this.$nextTick(() => {
              this.$store.commit("MOVE_MORPH_OPTION_TO_TOP", {
                contextData: { index: obj.optionIndex, pinned: true }
              })
            })
          }
          this.$store.commit("UPDATE_USER_DICTIONARY", {
            contextData: { morphObj: obj, operation: 'add' }
          }
          )
          if (index > 1 && !pinned)
            this.$store.commit("MOVE_MORPH_OPTION_TO_TOP", {
              contextData: { index: obj.optionIndex, pinned: false }
            })
        }
        else {
          //if word with incorrect nikkud, duplicate morphology, change nikud
          let newMorphOption = _.cloneDeep(obj)
          if (pinned) {
            this.removeFromSaved(this.allMorphOptions[0], true, true)
            newMorphOption.pinned = true
            newMorphOption.indexForUnpin = obj.optionIndex
          }
          this.$store.commit("ADD_MORPH_OPTION", newMorphOption)
          this.$store.commit("SET_SELECTED_MORPH", newMorphOption)
          this.$store.commit("UPDATE_USER_DICTIONARY", {
            contextData: { morphObj: newMorphOption, operation: 'add' }
          }
          )
        }

        this.hideNikudRows = false
        this.scrollToSelected(this.currentMorphIndex)
      } else {
        this.setPos(index)
        this.showAddMessage = true
        this.showRemoveMessage = false
        this.showNegativeMessage = false
        this.showAccountNeededPopup = true
      }
    },
    editOrDuplicate(obj, index, edit) {
      if (obj.indexForRestore !== undefined && edit) {
        this.restoreMorphology(obj)
      }
      if (!this.groupId) {
        //this.$store.commit("SET_SELECTED_MORPH", obj)
      } else {
        //this.$store.commit("SET_GROUP_SELECTED_MORPH", obj)
      }
      if (edit) {
        this.editMode = true
        this.duplicateMode = false
        if (this.groupId) {
          //this.$store.commit("SET_CURRENT_GROUP_MORPH_INDEX", index)
        } else {
          //this.$store.commit("SET_CURRENT_MORPH_INDEX", index)
        }
      }
      else {
        this.duplicateMode = true
        this.editMode = false
      }
      this.morphItemForPopup = obj
      this.showMorphologyPopup = true
    },
    submit() {
      this.$refs.morphPopup.submit()
    },
    openMorphologyPopup() {
      if (!this.isSep) {
        //create new blank morphoption
        this.blankMorphOption = {
          morphOptions: {
            partOfSpeech: "POS_UNKNOWN",
            gender: "Unspecified",
            person: ['Unspecified'],
            number: "Unspecified",
            tense: ['Unspecified'],
            nounType: "Unspecified",
            positivity: "Unspecified",
            passivity: "Unspecified",
           /*  nominalized: "Unspecified",
            continued: "Unspecified", */
            conjunctionType: "Unspecified",
            status: "Unspecified",
            detType: "Unspecified",
            detDef: "Unspecified",
            prefixes: ['Unspecified'],
            suffix: "Unspecified",
            suffixNumber: "Unspecified",
            suffixPerson: "Unspecified",
            suffixGender: "Unspecified",
            suffixType: "Unspecified"
          }, word: '', fromServer: false, returnedWord: this.currentWord, flag: [], prefixLength: 0, aramaic: false, lexEnabled: false, onlyNikud: true
        }
        this.editMode = false
        this.duplicateMode = false
        this.morphItemForPopup = this.blankMorphOption
        this.showMorphologyPopup = true
      }
    },
    selectFirstVisibleRow() {
      let rows = document.querySelectorAll('table tr')
      this.currentIndex = rows[0].id
    },
    nextItem() {
      let ddList = document.getElementsByClassName("vbt-autcomplete-list")[0]
      if (!ddList.hasChildNodes() || ddList.style.display === 'none') {
        document.activeElement.blur()
        let currentRowElement = document.getElementsByClassName("row-active")[0]
        if (currentRowElement) {
          if (currentRowElement.nextElementSibling) {
            if (this.allMorphOptions[currentRowElement.nextElementSibling.id].indexForRestore === undefined)
              this.currentIndex = currentRowElement.nextElementSibling.id
            else {
              if (currentRowElement.nextElementSibling.nextElementSibling && !this.withNikud) {
                let followingId = currentRowElement.nextElementSibling.nextElementSibling.id
                if (!this.allMorphOptions[followingId].indexForRestore)
                  this.currentIndex = followingId
                else
                  this.selectFirstVisibleRow()
              } else {
                this.selectFirstVisibleRow()
              }
            }
          } else {
            this.selectFirstVisibleRow()
            //this.currentIndex = 0
          }
          this.setMorphCurrentIndex(this.currentIndex)
        }
      }
    },
    prevItem() {
      let ddList = document.getElementsByClassName("vbt-autcomplete-list")[0]
      if (!ddList.hasChildNodes() || ddList.style.display === 'none') {
        document.activeElement.blur()
        let currentRowElement = document.getElementsByClassName("row-active")[0]
        if (currentRowElement) {
          if (currentRowElement.previousElementSibling) {
            this.currentIndex = currentRowElement.previousElementSibling.id
          } else {
            let rows = document.querySelectorAll('table tr')
            currentRowElement = rows[rows.length - 1]
            this.currentIndex = currentRowElement.id
          }
          while (this.allMorphOptions[this.currentIndex].indexForRestore) {
            this.currentIndex = this.currentIndex - 1
          }
          this.setMorphCurrentIndex(this.currentIndex)
        }
      }
    },
    filterResults(val) {
      this.filterArr = val
      if (this.filterArr.length > 0) {
        this.setNikudDisplay(false)
      }
    },
    canDeleteObj(obj) {
      return this.dictionaryButtons(obj) !== 'true' && !obj.fromServer
    },
    dictionaryButtons(obj) {
      let aramaic = obj.aramaic ? '-ארמית' : ''
      let wordKey = obj.returnedWord.replace(/\|/g, '') + ' (' + obj.word + aramaic + ')'
      var starred = 'none'
      if (!obj.fromServer) {
        starred = 'false'
      }
      if (this.savedOptions && this.userLoggedIn) {
        if (this.savedOptions[wordKey]) {
          let data = this.savedOptions[wordKey]
          let indexFound = _.findIndex(data, (e) => {
            return _.isEqual(_.omit(e, ['optionIndex', 'fromServer', 'pinned', 'indexForUnpin', 'flag', 'onlyNikud']), _.omit(obj, ['optionIndex', 'fromServer', 'pinned', 'indexForUnpin', 'flag', 'onlyNikud']))
          })

          //check order of operations when pinning, removing pin etc
          if (indexFound > -1) {
            if (obj.pinned)
              starred = 'pinned'
            else
              starred = 'true'
          }
        }
      }
      return starred
    }
  },
  computed: {
    keymap() {
      return {
        'down': () => {
          if (!(modalOpen())) this.nextItem()
        },
        'up': () => {
          if (!(modalOpen())) this.prevItem()
        },
        'shift+e': () => {
          if (!(modalOpen())) {
            document.activeElement.blur()
            this.editOrDuplicate(this.selectedMorph, this.currentMorphIndex)
          }
        },
        'shift+m': () => {
          if (!(modalOpen())) {
            document.activeElement.blur()
            this.openMorphologyPopup()
          }
        },
        'shift+l': () => {
          document.activeElement.blur()
          //if(this.$refs.nikudBtnIcon.classList.contains('fa-angle-down'))
          //  this.toggleNikudVisible()
          this.setNikudDisplay(!this.withNikud)
        },
        'shift+k': () => {
          document.activeElement.blur()
          this.toggleNikudVisible()
          this.setNikudDisplay(true)
        },
        'shift+j': () => {
          document.activeElement.blur()
          this.toggleNikudVisible()
          this.setNikudDisplay(true)
        },
        'shift+ctrl+k': () => {
          this.toggleTermNames()
        },
        'shift+ctrl+a': () => {
          document.activeElement.blur()
          this.toggleLexOrST(this.selectedMorph)
        },
        'shift+p': () => {
          document.activeElement.blur()
          event.preventDefault()
          this.toggleLexOrST(this.selectedMorph, true)
        },
        '.': (event) => {
          if (!(modalOpen())) {
            document.activeElement.blur()
            event.preventDefault()
            this.saveMorphToFavorites()
          }
        },
        '/': (event) => {
          if (!(modalOpen())) {
            document.activeElement.blur()
            event.preventDefault()
            this.saveMorphToFavorites()
          }
        }
      }
    },
    filterMrophOptions() {
      return _.spread(_.union)(this.$store.state.morphologyApp.morphData[this.currentWordIndex].options.map(function (obj) {
        return Object.values(obj.morphOptions).flat()
      }))
    },
    showShortTerms() {
      return this.$store.state.shortMorphTerms
    },
    withNikud() {
      if (this.currentMorphWordObj.morphArrs[0] && this.currentMorphWordObj.morphArrs[0].morph == -1 && this.nikudMorphs.length == 1) {
        return false
      } else {
        return (this.currentMorphWordObj.withNikud && this.nikudMorphs.length > 0)
      }
    },
    resizeHeight() {
      return document.body.classList.contains('d-header-none') ? this.changePos + 76 : this.changePos
    },
    currentGroupWord() {
      if (this.groupId) {
        if (this.groupRange) {
          let newWord = ''
          this.groupRange.forEach(element => {
            if (!this.morphData[element].sep)
              newWord += this.morphData[element].text + '_'
          })
          return newWord.slice(0, -1)
        }
      }
      return ''
    },
    morphData() {
      return this.$store.state.morphologyApp.morphData
    },
    currentWord() {
      return this.morphData ? this.currentMorphWordObj.text : ''
    },
    currentLex() {
      return this.$morphData ? this.currentMorphObject.word : ''
    },
    aramaic() {
      return this.morphData ? this.currentMorphObject.aramaic ? '-ארמית' : '' : ''
    },
    currentWordIndex() {
      return this.$store.state.morphologyApp.currentWordIndex
    },
    isNeutralized() {
      return this.morphData.length > 0 ? this.currentMorphWordObj.neutralized : false
    },
    groupId() {
      return this.currentMorphWordObj.groupId !== null
    },
    groupRange() {
      return this.currentMorphWordObj.groupRange ? this.currentMorphWordObj.groupRange : null
    },
    currentMrophGroup() {
      return this.morphData[this.currentMorphWordObj.groupId]
    },
    currentMorphWordObj() {
      return this.morphData[this.currentWordIndex]
    },
    isSep() {
      return this.currentMorphWordObj.sep
    },
    selectedMorph() {
      if (this.groupId)
        return this.currentMrophGroup.selectedGroupMorph
      else
        return this.currentMorphWordObj.selectedMorph && Object.keys(this.currentMorphWordObj.selectedMorph).length > 0 ? this.currentMorphWordObj.selectedMorph : null//this.currentMorphObject
    },
    currentMorphIndex() {
      return !this.groupId ? this.currentMorphWordObj.selctedMorphIndex :
        this.currentMrophGroup.selectedGroupMorphIndex
    },
    optionsLength() {
      return !this.groupId ? this.morphOptions.length :
        this.currentMrophGroup.groupData.length
    },
    currentMorphObject() {
      return !this.groupId ? this.allMorphOptions[this.currentMorphIndex] :
        this.currentMrophGroup.groupData[this.currentMorphIndex]
    },
    allMorphOptions() {
      return !this.groupId ? this.currentMorphWordObj.options : this.currentMrophGroup.groupData
    },
    allMorphOptionsWithKey() {
      return this.allMorphOptions.map((x, key) => {
        x.optionIndex = key
        return x
      })
    },
    withNikudCount() {
      if (this.filterArr.length > 0) {
        return this.morphFilteredUinque.filter((obj) => obj.onlyNikud).length
      } else {
        return this.morphUnfilteredUinque.filter((obj) => obj.onlyNikud).length
      }
    },
    withOutNikudCount() {
      if (!this.groupId) {
        if (this.filterArr.length > 0) {
          return this.morphFilteredUinque.filter((obj) => !obj.onlyNikud).length
        } else {
          return this.allMorphOptionsWithKey.filter((obj) => !obj.onlyNikud).length
        }
      } else {
        return this.allMorphOptionsWithKey.filter((obj) => !obj.onlyNikud).length
      }
    },
    morphOptions() {
      if (this.filterArr.length > 0 && this.morphFiltered.length > 0) {
        return this.morphFilteredUinque.map((obj) => {
          let starred = this.dictionaryButtons(obj)
          return [Object.values(obj.morphOptions).flat().filter(ms => ms !== 'Unspecified' && ms !== '' && ms !== undefined), obj, starred]
        })
      } else if (this.morphUnfiltered.length > 0) {
        return this.morphUnfilteredUinque.map((obj) => {
          let starred = this.dictionaryButtons(obj)
          return [Object.values(obj.morphOptions).flat().filter(ms => ms !== 'Unspecified' && ms !== '' && ms !== undefined), obj, starred]
        })
      } else {
        return []
      }

    },
    userMorphs() {
      return this.allMorphOptionsWithKey.filter(option => !option.fromServer)
    },
    nikudMorphs() {
      return this.allMorphOptionsWithKey.filter(option => option.onlyNikud)
    },
    morphUnfilteredUinque() {
      if (!this.groupId) {
        if (this.userMorphs.length > 0) {
          //let m = [... this.morphUnfiltered].reverse()
          //hide duplicates, reverse so dicta option gets hidden
          //return m.filter((v,i,a)=>a.findIndex(t=>(t.word === v.word && t.aramaic===v.aramaic  && t.flag===v.flag && parseInt(t.prefixLength)===parseInt(v.prefixLength) && _.isEqual(t.morphOptions, v.morphOptions)))===i).reverse()
          //return m.filter((v,i,a)=>a.findIndex(t=>(parseInt(t.prefixLength)===parseInt(v.prefixLength) &&_.isEqual(t.morphOptions, v.morphOptions) &&_.isEqual(t.flag, v.flag)))===i).reverse()
          //return _.uniqBy(this.morphUnfiltered, v => JSON.stringify([v.prefixLength, v.word, v.aramaic, v.flag, v.morphOptions]).join()).reverse()
          //return this.morphUnfiltered
          //return this.morphUnfiltered
          return _.uniqBy(this.morphUnfiltered, function (elem) {
            return JSON.stringify(_.omit(elem, ['optionIndex', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']));
          })
        } else {
          return this.morphUnfiltered
        }
      } else {
        return this.currentMrophGroup.groupData
      }
    },
    morphFilteredUinque() {
      if (this.userMorphs.length > 0) {
        //let m = [... this.morphFiltered].reverse()
        //return m.filter((v,i,a)=>a.findIndex(t=>(t.word === v.word && t.aramaic===v.aramaic && _.isEqual(t.flag, v.flag) && parseInt(t.prefixLength)===parseInt(v.prefixLength) && _.isEqual(t.morphOptions, v.morphOptions)))===i).reverse()
        return _.uniqBy(this.morphFiltered, function (elem) {
          return JSON.stringify(_.omit(elem, ['optionIndex', 'fromServer', 'pinned', 'indexForUnpin', 'onlyNikud']));
        })
      } else {
        return this.morphFiltered
      }
    },
    morphUnfiltered() {
      return this.allMorphOptionsWithKey ?
        this.withNikud ? (this.nikudMorphs.length > 0 ? this.nikudMorphs : this.allMorphOptionsWithKey)
          : this.allMorphOptionsWithKey : []
    },
    morphFiltered() { //for filtering
      return this.morphUnfiltered.length > 0 ? this.morphUnfiltered.filter(fs => this.doesInclude(Object.values(fs.morphOptions).flat(), this.filterArr)) : []
    },
    currentDictionary() {
      return this.$store.state.account.isLoggedIn ?
          this.$store.state.account.sync.morphologyDictionary :
          this.$store.state.tempUserDictionary
    },
    savedOptions() {
      return this.currentDictionary.entryPresent
    },
    hideOptions() {
      return this.currentDictionary.negativeEntryPresent
    },
    userLoggedIn() {
      return this.$store.state.account && this.$store.state.account.userData
    },
    canDeleteMorph() {
      return this.$store.state.canDelete
    },
    loading() {
      return this.$store.state.nakdanApiState
    }
  },
  watch: {
    currentWordIndex() {
      this.newWordSelected()
    },
    groupId(val) {
      if (val) {
        this.syncData()
      }
    },
    loading(val) {
      if (val === 'Update complete') {
        this.newWordSelected()
        this.$store.commit('NAKDAN_COMPLETE')
      }
    }
  }
}
</script>
<style lang="scss">
i.flag {
  &::after {
    color: #909eac !important;
    content: "\f024";
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    font-size: 85%;
    font-style: normal;
    margin: 0 5px 0 3px;
  }
}
</style>
<style lang="scss" scoped>
.disabled {
  background: rgba(255, 255, 255, 0.4);
  z-index: 999;
}

#new-morphology-link {

  span,
  i {
    cursor: pointer;
  }

  &.disabled {
    cursor: auto;
    opacity: 0.7
  }
}

.morphology-table {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  border-radius: 6px;
  max-height: 71vh;

  .disabled-text {
    color: #949494;
    //text-decoration: line-through;
  }

  .disable-option {
    color: #c0c0c0 !important;
    width: 100%;
    //display: none;
  }

  .zoom button {
    margin-top: 5px;
    line-height: 28px;
    height: 28px;
    border: none;
    vertical-align: middle;

    &:hover {
      color: var(--primary);
    }

    &:first-child {
      border-left: 1px solid #d8d8d8;
      position: relative;
      top: 1px;
    }

    &.f-narkis {
      height: 29px;
      width: 28px;
      border: 3px solid;

      &.last {
        &::after {
          content: "";
          position: absolute;
          right: -4px;
          top: -3px;
          height: 29px;
          width: 1px;
          background: #d8d8d8;
        }
      }

      border-color: var(--secondary);
      position: relative;
      top: -2px;

      h3 {
        font-size: 19px;
        line-height: 17px;
      }
    }

    &.btn-active,
    &:hover {
      background: #f6f6f6 !important;
      color: #000;
    }
  }

  .table-wrap {
    overflow-y: scroll;
    height: calc(100% - 50px);
    border-radius: 6px;
    border: solid 1px #d8d8d8;
  }

  .toggle-no-nikud {
    &.active {
      border-bottom: solid 1px #d8d8d8;
    }

    // display: table-header-group;
    i {
      font-size: 17px;
    }

    cursor: pointer;

    &.bottom-btn {
      border-top: solid 1px #d8d8d8;
      border-bottom: solid 1px #d8d8d8;
      margin-top: -16px;
      font-family: arimo;
    }
  }

  .table {
    //min-height: 40px;
    max-height: calc(100% - 52px);
    overflow-y: scroll;

    tr:not(.btn-hovered) {
      &:hover {
        background: #e3e3e3 !important;
      }

      &:hover,
      &.action-clicked {
        td i.invisible {
          visibility: visible !important;
        }
      }
    }

    tr {
      cursor: pointer;

      &:nth-child(odd) {
        background: var(--background);
      }

      &.row-active {
        background-color: #d6ecff;
      }

      &.row-with-btn {
        border-top: 1px solid #d8d8d8;
      }

      &.disabled-row {
        color: silver;
      }

      td {
        padding: 6px 15px 5px;
        border-top: none;

        &.with-btn {
          padding-top: 41px !important;
          position: relative;

          &::before {
            content: '';
            width: 100%;
            height: 37px;
            background: #fff;
            top: 0;
            position: absolute;
            right: 0;
          }

          &:first-of-type {
            &::before {
              width: calc(10px + 100%);
            }
          }

          &::after {
            content: '';
            width: 100%;
            height: 1px;
            background: #d8d8d8;
            top: 37px;
            position: absolute;
            right: 0;
          }

          .hide-no-nikud {
            margin-top: -37px;
            font-family: arimo;
            margin-right: -3px;
            position: relative;
            z-index: 1;
            height: 37px;

            &::after {
              position: absolute;
              background: white;
              height: 30px;
              right: 230px;
              width: 740px;
              content: '';
              top: -2px;
            }

            i {
              color: black;
              font-size: 17px;
            }
          }
        }

        &.f-narkis {
          width: 240px;
          border-left: 1px solid var(--secondary);

          .prefix-sep {
            right: -2px;
          }
        }

        .fas,
        .far {
          color: #989a97;
          font-size: 12px;
          border-radius: 15px;
          width: 20px;
          text-align: center;
          padding: 4px;

          &.fa-lock {
            &:hover {
              background: none;
              cursor: auto;
            }
          }

          &:hover {
            background: #fbfbfb;
          }
        }
      }
    }

  }
}
</style>
