import accountMutations from './accountMutations'
import accountActions from './accountActions'

const accountModule = {
  state: {
    isLoggedIn: false,
    userData: null,
    loginInProgress: false,
    firebaseLoaded: false,
    sync: {}
  },
  mutations: {
    ...accountMutations
  },
  actions: {
    ...accountActions
  }
}

export {accountModule}