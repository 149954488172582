<template>
  <span></span>
<!--  <div v-if="$store.state.account.loginInProgress" class="login-modal-bg" @click.self="closeDialog">-->
<!--    <div class="login-modal">-->
<!--      <a class="popup-close" @click="closeDialog" href="#"><i class="fa fa-times"></i></a>-->
<!--      <div>-->
<!--        <h2 class="login-title"><strong>היכנס לחשבון אישי</strong><br />(כדי להשתמש במאגר מילים)</h2>-->
<!--        &lt;!&ndash;-->
<!--        <div style="text-align: center">-->
<!--          <b-button class="btn-lg" variant="primary" @click="dummyLogin">לחץ להיכנס "בכאילו" לחשבון</b-button>-->
<!--        </div>-->
<!--        &ndash;&gt;-->
<!--      </div>-->
<!--      <div id="login-anchor">-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import {StateChanges, Actions} from '../store/constants'
// import savedExperiments from '../store/savedExperimentUtils'

export default {
  name: 'Login',
  methods: {
    closeDialog() {
      this.$store.commit(StateChanges.LOGIN_CANCELLED)
    },
    dummyLogin() {
      // this.$store.commit(StateChanges.INITIALIZE_APPLICATION)
      this.$store.dispatch(Actions.PROCESS_LOGIN)
    }
  }
}
</script>
<style scoped>
  .popup-close{
    position: absolute;
    color: #000;
    right: 20px;
    top: 10px;
  }
  .login-modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* has to be higher than all the other dialogs, which really should be fixed */
    z-index: 999999999;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .login-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 60vh;
    width: 30vw;
    background: white;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 #4c4c4c, 0 0 0 1px rgba(63, 63, 68, 0.05);
  }
  .login-title {
    text-align: center;
    padding: 30px;

  }
</style>
