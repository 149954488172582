<template>
  <div class="text-and-tools-panel" v-hotkey="keymap"> <!--class="right-pane"-->
    <mobile-text-panel-tools-header
      :text-panel-tool-props="textPanelToolProps"
      @back-to-text-prep="(disablePrompt) => $emit('back-to-text-prep', disablePrompt)"
      @copy="copyToClipboard"
      @undo="$emit('undo')"
      @redo="$emit('redo')"
      @change-font-size="(newFontSize) => { fontSize = newFontSize }"
      @change-show-hideables="(showHideables) => $emit('change-show-hideables', showHideables)"
      @update-copy-text="updateCopyText(tokens, showHideables)"
      v-if="textPanelStyles.tools.HEADER" />
    <slot name="textpanel"
          :fstyle="{'font-size': fontSize + 'px'}"
    >
      <text-panel
        ref="textPanel"
        :tokens="tokens"
        :auto-navigation-obj="autoNavigationObj"
        :letter-adding-mode="letterAddingMode"
        :non-confident-marking="nonConfidentMarking"
        :show-hideables="showHideables"
        :font-size="fontSize"
        @token-selection="(tokenSelectionObj) => $emit('token-selection', tokenSelectionObj)"
        @range-selection="(selectionRange) => { currentSelection = selectionRange; $emit('range-selection', selectionRange) }"
        :navigation-keys-between-editable-tokens-only="navigationKeysBetweenEditableTokensOnly"
        :text-panel-styles="textPanelStyles" />
    </slot>
    <text-panel-toolbar
      :text-panel-tool-props="textPanelToolProps"
      @back-to-text-prep="(disablePrompt) => $emit('back-to-text-prep', disablePrompt)"
      @copy="copyToClipboard"
      @update-copy-text="updateCopyText(tokens, showHideables)"
      :download-types="downloadTypes"
      :internal-nakdan="internalNakdan"
      :selectedWordNum="selectedWordNum"
      :loading="loading"
      :results-length="resultsLength"
      @get-word-num="(wordNum) => $emit('get-word-num', wordNum)"
      @download="(downloadOptions) => $emit('download', downloadOptions)"
      @text-copied="showCopiedTextMessage"
      @undo="$emit('undo')"
      @redo="$emit('redo')"
      @saving-and-completing="(statusObj) => $emit('saving-and-completing', statusObj)"
      @change-font-size="(newFontSize) => { fontSize = newFontSize }"
      @change-show-hideables="(newShowHideables) => $emit('change-show-hideables', newShowHideables)"
      @open-free-edit-popup="$emit('open-free-edit-popup')"
      @set-flag="$emit('set-flag')"
      :disable-keymap="disableKeymap"
      v-if="textPanelStyles.tools.BOTTOM">
      <slot name="extended-toolbar" />
    </text-panel-toolbar>
    <slot />
    <div id="text-copied-msg" class="notify-msg" style="display:none;">
      <p class="f white">text will be filled in by code</p>
    </div>
  </div>
</template>

<script>
import modalOpen from 'shared/js/modalOpen'
import $ from 'jquery'
import styleGlobals from "shared/js/forTextPanel/styleGlobals"
import MobileTextPanelToolsHeader from './MobileTextPanelToolsHeader.vue'
import TextPanel from './TextPanel'
import TextPanelToolbar from './TextPanelToolbar'
import {processSpecialDisplayProps} from 'shared/js/processSpecialDisplayProps'

const DEFAULT_FONT_SIZE = styleGlobals.DEFAULT_FONT_SIZE
var copyToClipboardRecursionPreventor = false

export default {
  name: 'TextAndToolsPanel',
  props: {
    tokens: {},
    autoNavigationObj: {
      default: () => {
        return {
          command: null,
          arguments: { tokenIndex: null, letterIndex: null }
        }
      }
    },
    loading: { default: false },
    resultsLength: {default: 0},
    letterAddingMode: { default: false }, // (until here: for text panel)
    disableBackToTextPrepPrompt: { default: false },
    allowShowingHideables: { default: false },
    hideablesName: { default: 'אימות קריאה' },
    hideablesExportSettings: {
      default: () => {
        return {
          USER_CONTROLLED: false,
          FOLLOW_DISPLAY: true
        }
      }
    },
    downloadTypes: { default: () => { return ['text'] } },
    originalDocumentSource: { default: () => { return { type: null } } },
    showSavedStateMessage: { default: false },
    multiPaneOpen: { default: false },
    canUndo: { default: false },
    canRedo: { default: false }, // (until here: for tools)
    internalNakdan: { default: false },
    selectedWordNum: { default: 1 },
    showHideables: { default: false },
    showCopyWithSelected: { default: false },
    nonConfidentMarking: { default: false },
    alwaysShowTextCopiedMessage: { default: true },
    navigationKeysBetweenEditableTokensOnly: { default: true },
    textPanelStyles: {
      default: () => {
        return {
          Mobile: window.innerWidth < 992,
          Desktop: window.innerWidth >= 992,
          textPanel: {
            USE_LETTER_CURSOR: false,
            SHOW_LINE_BREAK_CHAR: false,
            CAN_CLICK_ON_LETTERS: false,
            START_BY_SINGLE_CLICK: false,
            START_BY_DOUBLE_CLICK: false
          },
          appFeatures: {
            showPasukFont: false,
            hideablesSuffixesDuplicated: false,
            editNikudPopup: { ENABLE: false, PRESENT: false }
          },
          tools: {
            BOTTOM: true, HEADER: false, USE_DOWNLOAD_POPUP: true
          }
        }
      } // (until here: for both textPanel AND tools)
    },
    textPanelRef: null,
    disableKeymap: { default: false }
  },
  components: {
    MobileTextPanelToolsHeader,
    TextPanel,
    TextPanelToolbar
  },
  data () {
    return {
      currentSelection: [],
      copyText: '',
      fontSize: DEFAULT_FONT_SIZE
    }
  },
  computed: {
    textPanelToolProps () {
      return {
        textPanelStyles: this.textPanelStyles,
        hideablesExportSettings: this.hideablesExportSettings,
        copyText: this.copyText,
        downloadTypes: this.downloadTypes,
        originalDocumentSource: this.originalDocumentSource,
        canUndo: this.canUndo,
        canRedo: this.canRedo,
        multiPaneOpen: this.multiPaneOpen,
        fontSize: this.fontSize,
        allowShowingHideables: this.allowShowingHideables,
        hideablesName: this.hideablesName,
        showHideables: this.showHideables,
        showCopyWithSelected: this.showCopyWithSelected,
        showSavedStateMessage: this.showSavedStateMessage,
        disableBackToTextPrepPrompt: this.disableBackToTextPrepPrompt
      }
    },
    selectedWordIndex () {
      return this.$store.state.morphologyApp ? this.$store.state.morphologyApp.currentWordIndex : 0
    },
    keymap () {
      if (this.disableKeymap) return {}
      return {
        'ctrl+shift+L': () => { if (!(modalOpen())) this.copyToClipboard('with-hideables', 'keep-metegs') }
      }
    }
  },
  methods: {
    navigate(dest) {
      if (this.textPanelRef) {
        this.textPanelRef.navigate(dest)
      } else if (this.$refs.textPanel) {
        this.$refs.textPanel.navigate(dest)
      }
    },
    showCopiedTextMessage () {
      if ($('body').hasClass('he')) {
        $("#text-copied-msg p").text('הטקסט הועתק בהצלחה')
      } else {
        $("#text-copied-msg p").text('Text copied successfully')
      }
      $("#text-copied-msg").fadeIn()
      setTimeout(() => { $("#text-copied-msg").fadeOut() }, 2000)
    },
    updateCopyText (tokensArray, withHideables, metegs = 'no-metegs') {
      this.copyText = processSpecialDisplayProps(tokensArray, withHideables,
        this.textPanelStyles.appFeatures.hideablesSuffixesDuplicated, this.nonConfidentMarking, metegs)
      if (this.showCopyWithSelected && withHideables) {
        //morphology + copy selected morphology checkbox checked
        if (this.currentSelection.length == 0) {
          //copy selected word only instead of entire text
          tokensArray = [this.tokens[this.selectedWordIndex]]
        }
        this.copyText = ''
        let groupToken = {}
        tokensArray.forEach((element, index) => {
          if (!element.sep) {
            let selectedMorph = ''
            if (tokensArray[index].groupId) {
              //Add only first word in grouped text
              if (JSON.stringify(groupToken) !== JSON.stringify(this.tokens[tokensArray[index].groupId])) {
                groupToken = this.tokens[tokensArray[index].groupId]
                selectedMorph = groupToken.selectedGroupMorph ? groupToken.selectedGroupMorph.morphOptions : ''
                groupToken.groupRange.forEach(element => {
                this.copyText += this.tokens[element].text
              })
             }
            } else {
              selectedMorph = element.selectedMorph.morphOptions ? element.selectedMorph.morphOptions : element.options[element.selctedMorphIndex] ? element.options[element.selctedMorphIndex].morphOptions : ''
              let selectedLex = selectedMorph !== '' ? element.selectedMorph.word ? element.selectedMorph.word : element.options[element.selctedMorphIndex].word : ''
              this.copyText +=  element.text + '[' + selectedLex + ']'
            }
            let selectedOptions = selectedMorph !== '' ? Object.values(selectedMorph).flat().filter(ms => ms !== 'Unspecified' && ms !== '' && ms !== undefined).join(', ') : ''
            this.copyText += ' '+selectedOptions
          } else {
            this.copyText += '\n'
          }
        })
      }
    },
    preCopy (withHideables, metegs) {
      this.copyingElement = $("<textarea>")
      $("body").append(this.copyingElement)
      this.copyingElement.contentEditable = true
      var tokensToCopy = this.currentSelection.length
        ? this.tokens.filter((t, i) => this.currentSelection.includes(i)) : this.tokens
      this.updateCopyText(tokensToCopy, withHideables, metegs)
      var isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);

      if (isiOSDevice) {
          var referenceNode = document.getElementsByTagName("textarea")[0]
          document.getElementsByTagName("textarea")[0].value = this.copyText
          var editable = referenceNode.contentEditable;
          var readOnly = referenceNode.readOnly;
          referenceNode.contentEditable = 'true';
          referenceNode.readOnly = 'false';
          var range = document.createRange();
          range.selectNodeContents(referenceNode)
          var sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
          referenceNode.setSelectionRange(0, 999999);
          referenceNode.contentEditable = editable;
          referenceNode.readOnly = readOnly

      } else this.copyingElement.val(this.copyText).select()
    },
    postCopy () {
      this.copyingElement.remove()
      this.copyingElement = null
      if (this.alwaysShowTextCopiedMessage)
        this.showCopiedTextMessage()
      this.$emit('text-copied')
    },
    copyToClipboard (copyWithHideables, mode = 'no-metegs') {
      if (!(this.copyingElement || copyToClipboardRecursionPreventor)) {
        this.preCopy(copyWithHideables, mode)
        copyToClipboardRecursionPreventor = true
        document.execCommand("copy")
        copyToClipboardRecursionPreventor = false
        this.postCopy()
      }
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.text-and-tools-panel {
  height: 100%;
  @media (max-width: 767px) {
    height: 85vh;
  }
  display: flex;
  flex-direction: column;
}
</style>
