import $ from 'jquery'
import modalOpen from 'shared/js/modalOpen'

export default {
  name: 'textPanelToolMixins',
  data () {
    return {
      showBackToTextPrepPrompt: false
    }
  },
  computed: {
    hebrew () {
      return this.$settings.hebrew
    },      
    canUndo () {
      return this.textPanelToolProps.canUndo
    },
    canRedo () {
      return this.textPanelToolProps.canRedo
    },
    backToTextPrepPromptProps () {
      return {
        showBackToTextPrepPrompt: this.showBackToTextPrepPrompt,
        mobileStyle: this.textPanelToolProps.textPanelStyles.Mobile
      }
    }
  },
  methods: {
    undo () {
      if (!this.textPanelToolProps.multiPaneOpen) {  
        this.$emit('undo')
      }  
      document.activeElement.blur()
    },
    redo () {
      if (!this.textPanelToolProps.multiPaneOpen) {  
        this.$emit('redo')
      }  
      document.activeElement.blur()
    },
    copyIfNoModalOpen () {
      if (!(modalOpen())) this.copy()
    },    
    backToTextPrep () {
      if (!this.textPanelToolProps.multiPaneOpen) {
        document.activeElement.blur()
        if (!(this.textPanelToolProps.disableBackToTextPrepPrompt)) { 
          this.showBackToTextPrepPrompt = true 
        } else this.actuallyBackToTextPrep()
      }
    },
    actuallyBackToTextPrep (disablePrompt = false) {
      this.showBackToTextPrepPrompt = false // probably no longer needed here
      setTimeout(() => {
        this.$emit('back-to-text-prep', disablePrompt) // no need to disable - this code only called if ALREADY disabled
      }, 1) // wait until the previous operation finishes if necessary
    }
  },
  mounted () {
    $(document).bind('copy', this.copyIfNoModalOpen)
  }
}
