<template>
  <b-modal v-if="entry && entry.name && entry.dictaFiles.length > 0"
           size="md"
           :title="hebrew ? 'איפוס קובץ' : 'Reset File'"
           hide-header-close
           footer-class="bordered-footer border-top"
           :ok-title="hebrew ? 'איפוס' : 'Reset'"
           ok-variant="secondary"
           cancel-variant="primary"
           body-class="scroll-modal"
           :cancel-title="hebrew ? 'ביטול' : 'Cancel'"
           ref="confirmDelete"
           auto-focus-button="cancel"
           :ok-disabled="running"
           :cancel-disabled="running"
           @ok="actuallyDelete">
    <div v-if="hebrew">
      האם באמת רוצים לאפס את הקובץ "{{entry.name}}"?
    </div>
    <div v-else>
      Do you really want to reset the file "{{entry.name}}"?
    </div>
    <spinner v-if="running"></spinner>
  </b-modal>
</template>
<script>
import {dropboxDelete} from "./dropbox_utils"
import Spinner from "../components/spinner"

export default {
  name: 'ConfirmDelete',
  components: {Spinner},
  data() {
    return {
      entry: null,
      running: false
    }
  },
  computed: {
    hebrew () {
      return this.$settings.hebrew
    }
  },
  methods: {
    confirmDelete(entry) {
      this.entry = entry
      this.$nextTick(() => {
        this.$refs.confirmDelete.show()
      })
    },
    actuallyDelete() {
      this.running = true
      const promises = this.entry.dictaFiles.map(file => dropboxDelete(file))
      Promise.all(promises).then(() => {
        this.running = false
        this.$refs.confirmDelete.hide()
        this.entry.dictaFiles = []
      })
    }
  }
}
</script>
