<template>    
  <div class="search-dialog bg-white position-relative" v-hotkey="keymap">  
    <input
      v-model="searchWord"
      ref="searchInput"
      type="text" 
      class="border border-dark"
      @keyup="handleSearch"
    />
    <div class="position-absolute">
      <span v-if="matchesFound.length > 0">
        {{foundIndex+1}}/{{matchesFound.length}}
      </span>
      <span v-if="matchesFound.length==0 && ((this.searchWord && this.searchWord.trim().length > 0))">
        0
      </span>
    </div>
    <button 
      class="next rounded-circle p-0 border-0 mr-2"
      :disabled="matchesFound.length < 1 || foundIndex+1==matchesFound.length"
      @click="nextFound"
      >
      <i class="fas fa-angle-down"></i>
    </button>
    <button 
      class="prev rounded-circle p-0 border-0"
      :disabled="matchesFound.length <= 0 || foundIndex==0"
      @click="prevFound"
      >
      <i class="fas fa-angle-up"></i>
    </button>
  </div>
</template>
<script>
  import {NIKUD, stringWithoutNikud} from 'shared/js/commonHebrew'
  import _ from 'lodash'
  export default {
    name: "SearchDialog",
    data () {
      return {
        searchWord: '',
        matchesFound: [],
        foundIndex: -1
      }
    },
    mounted () {
      this.setSearchFocus()
    },
    computed: {
      keymap () {
        return {
          'enter': () => {
            document.activeElement.blur()
            this.nextFound()
          }
        }
      }, 
      morphResults () {
        return this.$store.state.morphologyApp.morphData.map(obj => {
          return Object.assign( {
            actualIndex: obj.actualIndex,           
            text: obj.text
          })
        })
      },
      searchTermNikud: function() {
        return this.searchWord.includes(NIKUD.HATAF_SEGOL) ||  this.searchWord.includes(NIKUD.HATAF_PATAH) ||
        this.searchWord.includes(NIKUD.HATAF_QAMATZ) ||  this.searchWord.includes(NIKUD.HIRIQ) ||
        this.searchWord.includes(NIKUD.TSERE) ||  this.searchWord.includes(NIKUD.SEGOL) ||
        this.searchWord.includes(NIKUD.PATAH) ||  this.searchWord.includes(NIKUD.QAMATZ) ||
        this.searchWord.includes(NIKUD.HOLAM) ||  this.searchWord.includes(NIKUD.QUBUTZ) ||
        this.searchWord.includes(NIKUD.SHEVA) ||  this.searchWord.includes(NIKUD.QAMATZ_QATAN)
      }      
    },
    methods: {
      prevFound () {
       this.foundIndex--  
       this.$emit('nav-to-search', this.matchesFound[this.foundIndex].actualIndex)
      },
      nextFound () {   
      // if(this.matchesFound.length > 1 && this.foundIndex+1 < this.matchesFound.length) {
        this.foundIndex++       
        this.$emit('nav-to-search', this.matchesFound[this.foundIndex].actualIndex)
      // }
      },
      setSearchFocus () {
        document.activeElement.blur()
        this.$nextTick(function () {        		
          this.$refs.searchInput.focus()
        })
      },
      search () {
        if (this.searchWord && this.searchWord.trim().length) {
          this.matchesFound = []         
          if(!this.searchTermNikud)  {  
            this.matchesFound = this.morphResults.filter(c => stringWithoutNikud(c.text).includes(this.searchWord))
          } else {
            this.matchesFound = this.morphResults.filter(c => c.text.includes(this.searchWord))  
          }
          if(this.matchesFound.length > 0) {
            this.$emit('highlight', this.searchWord)
          } else {
            this.$emit('highlight', '')
          }
        } else {
          this.$emit('highlight', '')
          this.matchesFound = []  
        }
      },   
      handleSearch: _.debounce(function() {          
        this.search()
       } , 450)      
      
    }
  }
</script>
<style lang="scss" scoped>
  .search-dialog {
    padding: 10px;  
    width: 315px;
    height: 57px;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    .position-absolute {
      top: 16px;
      left: 80px;
    }
    input {
      width: 230px;
      height: 36px;
      border-radius: 4px;
      padding: 0 10px;
      &:focus {
        outline: none;
      }
    }
    button {
      background: transparent;
      box-shadow: none;
      width: 28px;
      height: 28px;
      &:hover {
        background: #f6f6f6;
      }
    }
  }
</style>     