export const Mutations = {
  SET_DROPBOX_STATUS: 'SET_DROPBOX_STATUS',
  SET_PROJECT_SETTINGS: 'SET_PROJECT_SETTINGS',
  SET_DROPBOX_LOADING: 'SET_DROPBOX_LOADING'
}

export const INTERNAL_DROPBOX_LOAD = Symbol()
export const dropboxModule = {
  state: {
    status: null,
    projectSettings: null,
    dropboxLoading: false
  },
  mutations: {
    [Mutations.SET_DROPBOX_STATUS](state, payload) {
      const isInternal = payload?.fileChange === INTERNAL_DROPBOX_LOAD
      const status = isInternal ? payload.status : payload
      if (!isInternal &&
        state.status && status &&
        state.status.path_display !== status.path_display
      ) {
        throw 'Only dropboxLoad is allowed to change the filename in dropboxStatus!'
      }
      if (!state.status && status && status.saving) {
        // The old status was null, yet the new status is already saving
        // - this means that the status is actually being loaded from browser storage, and was in the middle of a save.
        // Reset it and mark it as unsaved.
        status.saving = false
        status.edited = true
      }
      state.status = status
    },
    [Mutations.SET_DROPBOX_LOADING](state, status) {
      state.dropboxLoading = status
    },
    [Mutations.SET_PROJECT_SETTINGS](state, settings) {
      state.projectSettings = settings
    },
  }
}
