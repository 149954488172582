<template>
  <span v-if="$store.state.account.isLoggedIn" class="user-auth tooltip-holder">
    <b-dropdown
      ref="dropdown"
      menu-class="login-dropdown-menu"
      class="dropdown login-dropdown"
      variant="hello"
      no-caret
      right
    >
      <template slot="button-content" class="pr-1" href="#">
        <span class="login-button">
          <i-user></i-user>
          שלום, {{ headerDisplayName }} ...
        </span>
      </template>
      <b-dropdown-header>
        <div class="user-data-bar">
          <b-row no-gutters>
            <b-col cols="3">
              <div class="user-circle-div">
                <i-user-circle class="i-user-circle"></i-user-circle>
              </div>
            </b-col>
            <b-col cols="9">
              <div class="fit-text" :title="localUserData.name">{{localUserData.name}}</div>
              <div
                class="fit-text email-alignment"
                :title="localUserData.email"
              >{{localUserData.email}}</div>
            </b-col>
          </b-row>
        </div>
      </b-dropdown-header>
      <li v-for="menuItem in menu" :key="menuItem.title" class="login-dropdown-item dropdown-item p-0">
        <span
          class="show-user-settings px-1 mx-2 py-2 d-block border-bottom border-secondary"
          @click="menuItemClicked(menuItem)"
        >
          <small v-if="menuItem.title">{{menuItem.title}}</small>
          <keep-alive v-if="menuItem.component">
            <component v-bind:is="menuItem.component"></component>
          </keep-alive>
        </span>
      </li>

      <li v-if="showChangePasswordOption" class="login-dropdown-item dropdown-item p-0">
        <div px-3 py-2>
          <span class="login-dropdown-link" @click="showPasswordPopup = true">
            <i-pencil-alt class="pl-2"></i-pencil-alt>שנה סיסמא
          </span>
        </div>
      </li>
      <li class="login-dropdown-item dropdown-item p-0">
        <div>
          <span class="login-dropdown-link mx-2 px-1 py-2 d-block" @click="logoutClicked">
            <small>
              <i-share-square class="float-icon mt-1"></i-share-square>{{t('צא מהחשבון', 'Exit Account')}}
            </small>
          </span>
        </div>
      </li>
      <b-modal
        v-if="showUnsavedChangesPopup"
        :visible="showUnsavedChangesPopup"      
        id="logout-modal"
        centered         
        size="sm"
        hide-backdrop          
        hide-header
        body-class="text-center"
        footer-class="logout-modal-footer border border-top border-secondary py-2"
        :ok-title="t('הבנתי', 'Ok')"
        :cancel-title="t('ביטול', 'Cancel')"
        @hide="showUnsavedChangesPopup=false"
        @ok="signOut()"
      >יציאה מהחשבון,<br> תמחוק את הטקסט הקיים
      </b-modal>  
    </b-dropdown>
    <!-- @click="showUserInfo = !showUserInfo" -->
    <!--
      <div v-if="showUserInfo" class="login-tooltip link">
        שלום, {{$store.state.account.username}} &nbsp;&nbsp;&nbsp;&nbsp;<button class="sign-out" @click="signOut">Sign Out</button>
      </div>
    -->
    <div v-if="showUserInfo" class="login-tooltip-bg" @click="showUserInfo = false"></div>
    <span class="spacer">|</span>
    <b-modal
      v-model="showPasswordPopup"
      id="change-password-popup"
      size="sm"
      centered
      hide-header
      ok-title="שנה סיסמא"
      cancel-title="ביטול"
      footer-class="change-password-footer"
      @ok="actuallyChangePassword"
    >
      <p class="text-center mb-1">הזן סיסמא חדשה:</p>
      <p class="text-center mb-0">
        <input dir="ltr" class="p-1" v-model="newPassword" />
      </p>
    </b-modal>
    <div v-if="waitingForFirebaseMessage" class="spinner">
      <spinner></spinner>
    </div>
  </span>
  <span v-else class="link">
    <span class="login-button" @click="loginClicked">
      <i-user></i-user>
      {{hebrew ? 'התחבר / הירשם' : 'Register / Sign in'}}
    </span>
    <span class="spacer">|</span>
    <login @login-event="(loginEventType) => $emit('login-event', loginEventType)"></login>
  </span>
</template>
<script>
import Spinner from "shared/components/spinner"
import Login from "./login"
export default {
  name: "LoginLogout",
  components: {
    Spinner,
    Login
  },
  props: {
    background: {},
    AppConfig: {},
    gtag: { default: () => { return () => {} } },
    menu: { default: () => { return [] } },
    promptUnsavedChanges: { default: () => { return false } }
  },
  computed: {
    hebrew () {
      return this.$settings.hebrew
    },
    passwordedAccount() {
      return (
        this.$store.state.account.userData &&
        this.$store.state.account.userData.passWordedAccount
      );
    },
    showChangePasswordOption() {
      return false // this.AppConfig.PASSWORD_CHANGING && this.passwordedAccount;
    },
    headerDisplayName() {
      return this.$store.state.account.userData.name.split(" ")[0];
    },
    localUserData() {
      return this.$store.state.account.userData
        ? this.$store.state.account.userData
        : { name: "", email: "" };
    },
    thisRoutesName: function() {
      return this.$route.name;
    }   
  },
  watch: {
    passwordedAccount() {
      this.removeIFrame();
    }
  },
  data() {
    return {
      waitingForFirebaseMessage: false,
      showPasswordPopup: false,
      newPassword: "password",
      showUserInfo: false,
      showUnsavedChangesPopup: false
    };
  },
  methods: {
    t(he, en) {
      return this.$settings.hebrew ? he : en
    },
    menuItemClicked(item) {
      this.$emit("menu-item-clicked", item)
      this.$refs.dropdown.hide(true)
      //gtag?
    },
    waitForFirebaseMessage(args) {
      setTimeout(() => {
        if (args.out.result) {
          this.waitingForFirebaseMessage = false;
          // console.log(args.out.result)
          let message =
            "שינוי הסיסמא " +
            (args.out.result === "success" ? "" : "לא ") +
            "הצליח";
          if (args.out.result !== "success")
            message += "<br />" + args.out.result;
          // console.log(message)
          this.showPasswordMessage(message);
        } else this.waitForFirebaseMessage(args);
      }, 100);
    },
    actuallyChangePassword() {
      let args = { newPassword: this.newPassword, out: {} };
      this.waitingForFirebaseMessage = true;
      this.$store.commit("CHANGE_USER_PASSWORD", args);
      this.waitForFirebaseMessage(args);
    },
    loginClicked() {
      this.gtag("config", this.googleAnalyticsId, { page_path: "login" });
      this.$store.commit("LOGIN_REQUESTED");
      this.$emit('login-event', 'requested')
    },
    removeIFrame() {
      // console.log('running removeIFrame() - this.passwordedAccount = ' + this.passwordedAccount)
      var iframe = document.getElementById("accountchooser-iframe");
      if (iframe) {
        iframe.style.left = "0px"; // the ui will undo these style changes each time it's used...
        iframe.style.bottom = "0px"; // ...(whereas if we REMOVE the iframe, the ui won't work any more)
      }
    },
    logoutClicked () {
      //if using dropbox and morphology for now - check for changes
      if (this.promptUnsavedChanges)
        this.$emit('logout-clicked')
      else 
        this.signOut()      
    },
    signOut() {
      this.showUserInfo = false;
      this.$store.dispatch("LOGOUT");
      this.$emit('login-event', 'logout')
      if (this.$route.name === "user-settings")
        document.getElementById("back-button").click()
    },
    // the below methods are used only by the 'Pro' Nakdan
    showPasswordMessage (message) {
      window.$("#change-password-msg p").html(message)
      window.$("#change-password-msg").fadeIn()
      setTimeout(() => {
        window.$("#change-password-msg").fadeOut()
      }, 4000)
    }
  },
  mounted() {
    this.removeIFrame();
  }
};
</script>
<style scoped lang="scss">
.spacer {
  padding: 0 8px;
}

.link:hover {
  text-decoration: none;
  cursor: pointer;
}
.login-button {
  display: inline-block;
  padding: 2px 4px;
  border-radius: 2px;
  margin: -2px -3px 0 -3px;
}
.login-button:hover {
  background-color: #e3e3e3;
}
.login-dropdown-item {
  vertical-align: middle;
  cursor: pointer;
}

.login-dropdown-link {
  color: #000;
}

.login-dropdown-link:hover {
  text-decoration: none;
}

.login-dropdown-menu {
  max-width: 230px;
}

.user-data-bar {
  background-color: #d6ecff;
  color: black;
  padding: 5px;
}

.i-user-circle {
  color: white;
  font-size: 32px;
}

.fit-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.email-alignment {
  direction: ltr;
}

.settings-link {
  color: black;
}

.dropdown ::v-deep {
  .dropdown-menu {
    padding: 0;
    z-index: 9999;
  }
  .btn-hello {
    padding: 0;
    box-shadow: none !important;
    &:focus {
      outline: none;
    }
  }
  .dropdown-header {
    padding: 0;
  }
}

.user-circle-div {
  margin: 5px 4px 0 0;
}
.float-icon {
  float: right;
}
.he .float-icon {
  float: left;
}
</style>
<style>
.login-dropdown-menu{
  min-width: 15rem;
}
#logout-modal button {
  width: 50%;
}
#change-password-popup, .change-password-footer {
  justify-content: center;
}
.change-password-footer {
  border-top: 1px solid #e3e3e3;
}
.change-password-footer,
.logout-modal-footer {
  direction: rtl; /* so cancel button is ALWAYS on the right */
}
.change-password-footer > :not(:last-child),
.logout-modal-footer > :not(:last-child) { /* ALWAYS "hebrew" style (because of the above) */
  margin-left: .25rem;
  margin-right: 0;
}
.change-password-footer > :not(:first-child),
.logout-modal-footer > :not(:first-child) {
  margin-left: 0;
  margin-right: .25rem;
}
</style>