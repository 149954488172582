<template>
    <div class="morph-filter" v-hotkey="keymap">
      <div class="rounded search-input-wrap position-relative border border-dark mt-1 mb-2" @click="searchFocus">
        <ul class="d-inline w-auto p-0 m-0" id="term-list">
          <li class="d-inline-block w-auto rounded px-2 mt-1 f-narkis mr-1 bg-secondary" 
            v-for="item in morphSelectedTerms" :key="item">
            <small>{{item}}</small>
            <span @click="removeItem(item)" class="pr-2">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
          </li>
        </ul>          
        <div class="d-inline-block">
          <vue-bootstrap-typeahead
            v-model="searchTerm"
            id="filter-input-wrap"
            :data="morphOptions"
            inputClass="border-0"
            placeholder="חיפוש"
            @hit="addMorphTerm"
            @input="setFirstActive"
            ref="termAutocomplete"
          />
        </div>
      </div>
    </div>
</template>
<script>
import _ from 'lodash'
import morphologyMixins from '@/mixins/morphologyMixins'
import hebrewToEnglish from '@/mixins/HebrewToEnglish'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

export default {
  name: "morph-filter",
  mixins: [morphologyMixins, hebrewToEnglish],
  components: {
    VueBootstrapTypeahead
  },
  props: ['filtered'],
  data () {
    return {
      morphSelectedTerms: [],
      searchTerm: ''
    }
  },
  mounted () {
    document.addEventListener('keydown', this.checkHebrew)
    this.searchFocus()
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.checkHebrew)
  },
  methods: {
    checkHebrew (event) {
      if(document.activeElement === document.querySelectorAll('input[type=search]')[0])
      this.hebrewToEnglish(event, document.querySelectorAll('input[type=search]')[0])
    },
    searchFocus () {
      document.activeElement.blur()
      this.$nextTick(function () {        		
        document.getElementsByClassName("form-control")[0].focus()
      })
    },
    addMorphTerm () {
      if(this.morphSelectedTerms.indexOf(this.searchTerm) < 0)
        this.morphSelectedTerms.push(this.searchTerm)
        this.searchTerm = ''
        this.$refs.termAutocomplete.inputValue = ''   
        this.$emit('toFilter', this.morphSelectedTerms)
    },
    removeItem (item) {
      var index = this.morphSelectedTerms.indexOf(item)
      this.morphSelectedTerms.splice(index, 1)
    },
    checkDelete () {
      if (this.searchTerm == '' && this.morphSelectedTerms.length > 0)
        this.morphSelectedTerms.pop() 
    },
    setFirstActive () {
      setTimeout(() => {            
        if(document.getElementsByClassName("vbst-item")[0] !== undefined) {
          document.getElementsByClassName("vbst-item")[0].classList.add('active')
        }
      },40)      
    },
    nextFilterItem () {
      let ddList = document.getElementsByClassName("vbt-autcomplete-list")[0]
        if (ddList.hasChildNodes() && ddList.style.display !== 'none') {
          let elements = document.querySelectorAll('.vbst-item')
          var activeIndex = 0
          Array.from(elements).forEach((element, index) => {
            if (element.classList.contains('active')) {
              element.classList.remove('active')
              activeIndex = index + 1            
            }
          }) 
          if (document.getElementsByClassName("vbst-item")[activeIndex] !== undefined) 
            document.getElementsByClassName("vbst-item")[activeIndex].classList.add('active')
          else
            ddList.firstChild.classList.add('active')
      }
    },
    prevFilterItem () {
      let ddList = document.getElementsByClassName("vbt-autcomplete-list")[0]
      if (ddList.hasChildNodes() && ddList.style.display !== 'none') {
        var activeListElement = document.getElementsByClassName("active")[0]
        if (activeListElement !== null) {
          activeListElement.classList.remove('active')
          if (activeListElement.previousSibling !== null) {
            activeListElement.previousSibling.classList.add('active')
          } else {
            ddList.lastChild.classList.add('active')
          }
        }
      }

    },
    addActiveToList () {
      if (this.searchTerm.length > 0) {
        this.searchTerm = document.getElementsByClassName("active")[0].textContent
        this.addMorphTerm()
      }
    }
  },
  computed: {
    keymap () {
      var keymapObj = {
        'shift+ctrl+q': () => {
          this.searchFocus()
        },
        'down': () => {
          this.nextFilterItem()
        },
        'up': () => {
          this.prevFilterItem()
        },
        'backspace': () => {
          this.checkDelete()
        },
        'enter': () => {
          this.addActiveToList()
        }              
      }
      return keymapObj
    },
    currentWordIndex () {
      return this.$store.state.morphologyApp.currentWordIndex
    },
    isNeutralized () {
      return this.$store.state.morphologyApp.morphData[this.currentWordIndex].neutralized
    },
    selctedMorphIndex () {
      return this.$store.state.morphologyApp.morphData[this.currentWordIndex].selctedMorphIndex
    },
    prefixLength () {
      return this.$store.state.morphologyApp.morphologyApp.morphData.length > 0 && this.selctedMorphIndex > -1 ? this.$store.state.morphologyApp.morphData[this.currentWordIndex].options[this.selctedMorphIndex].prefixLength : 0
    },
    morphOptions () {
      return _.spread(_.union)(this.$store.state.morphologyApp.morphData[this.currentWordIndex].options.map(function(obj) {
        return Object.values(obj.morphOptions).flat()
      }))
    }
  },
  watch: {
    filtered (val) {
      if(val.length == 0)
        this.morphSelectedTerms = []
    },
    currentWordIndex () {
      this.searchTerm = ''
      this.$refs.termAutocomplete.inputValue = '' 
      //if (!this.isNeutralized)
        //this.searchFocus()
    },
    selctedMorphIndex () {
      this.searchFocus()
    }
  } 
}
</script>
<style lang="scss">
  .morph-filter input[type="search"] {
    background: transparent;
  }
  .vbt-autcomplete-list{
    width: 100%!important;
    right:0!important;
    box-shadow:0 1px 4px 0 rgba(0,0,0,.3)!important;
    border: 1px none;
    border-radius: 2px;
    padding-top: 1px!important;
  }
  .vbst-item.list-group-item {
    padding: .45rem 1.25rem;
    color:black!important;
    background: white;
    border: none;
    &.active {
      background: #f6f6f6
    }
    &:hover {
      background: #f6f6f6;
    }
  }
</style>
<style scoped lang="scss">
  .search-input-wrap {
    cursor: text;
    li {
      cursor: auto!important;
    }
  }
  .morph-filter{
    i {cursor: pointer}
  }
</style>
