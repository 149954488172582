<template>
<div class="projects-wrap bg-white">
  <div class="bg-background">
    <div class="container">
      <div class="row">
        <div class="col">
          <h2 class="my-2">{{hebrew ? 'ניהול פרוייקטים' : 'Project Management'}}</h2>
        </div>
        <div class="col my-2 d-flex justify-content-end">
          <b-button v-if="showDisconnect" v-b-modal.about-dropbox variant="secondary">{{ hebrew ? 'מידע נוסף' : 'More Information' }}</b-button>
          <b-modal size="lg"
                  :title="hebrew ? 'איך זה עובד' : 'How to use'"
                  hide-header-close
                  footer-class="bordered-footer border-top"
                  :ok-title="hebrew ? 'סגור' : 'Close'"
                  ok-variant="secondary"
                  body-class="scroll-modal"
                  ok-only
                  id="about-dropbox">
            <about-dropbox class="mx-4"></about-dropbox>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
  <confirm-delete ref="confirmDelete"></confirm-delete>
  <div class="container">
    <div class="row">
      <div class="col">
        <Spinner v-if="(!firebaseLoaded || loading) && signedIn"></Spinner>
        <div v-if="firebaseLoaded && signedIn">
          <div v-if="dropbox_token" class="w-100"><!-- || dropboxTokenUpdating-->
            <small><b-breadcrumb class="bg-white px-0 mb-1">
                <b-breadcrumb-item v-if="showDisconnect" to="/">{{hebrew ? 'דף הבית' : 'Home'}}</b-breadcrumb-item>
                <b-breadcrumb-item v-if="editScreen" :to="editScreen.to">{{editScreen.name}}</b-breadcrumb-item>
                <b-breadcrumb-item :to="pathItem.to"
                  v-for="(pathItem, index) in pathCrumbs"
                  :key="index"
                  :active="index+1==pathCrumbs.length"
                >
                  {{pathItem.text}}
                </b-breadcrumb-item>
            </b-breadcrumb></small>
            <div v-if="showDisconnect" class="row">
              <b-col class="mt-1 mx-auto col-10 px-4">
                <b-button
                  variant="outline-primary"
                  class="px-4"
                  @click="disconnect">
                    {{hebrew ? 'צא מחשבון ה Dropbox' : 'Log out of Dropbox'}}
                </b-button>
                <slot name="buttons-after-dropbox-logout" />
              </b-col>
            </div>
            <div class="mt-4" v-if="!loading && filesAndFolders.length === 0 && !dropboxTokenUpdating">
              <div v-if="hebrew">
                לא נמצאו בתיקייה קבצים שהמערכת יכולה לפתוח ({{ readableFileExtensions.join(', ').toUpperCase() }}). {{addToDropboxMsg}}
              </div>
              <div v-else>
                No files found in this folder that the system can open ({{ readableFileExtensions.map(e => ('.' + e)).join(', ') }}). Please add some to Dropbox!
              </div>
            </div>
            <div v-else class="table-wrapper">
              <table class="table bg-white mx-auto my-3 col-10">
                <thead>
                  <tr>
                    <th class="icon header">
                      <!--I have no idea why this works. Otherwise, the border-bottom is too thick in Chrome-->
                      <div class="d-table-cell">
                        <button class="btn btn-outline-dark" :disabled="!canGoUp" @click="goUp">
                          <i class="fas fa-level-up-alt"></i>
                        </button>
                      </div>
                    </th>
                    <th class="name-header" @click="sort('name')">{{ hebrew ? 'שם' : 'Name'}}
                      <sort :current-key="sortKey" name="name" :asc="asc"></sort>
                    </th>
                    <th class="header date-modified" @click="sort('date')">{{ hebrew ? 'תאריך שינוי' : 'Last Modified' }}
                      <sort :current-key="sortKey" name="date" :asc="asc"></sort>
                    </th>
                    <th class="trash-col header"></th>
                    <th class="header completed" @click="sort('done')">{{ hebrew ? 'הושלם' : 'Completed' }}
                      <sort :current-key="sortKey" name="done" :asc="asc"></sort>
                    </th>
                  </tr>
                </thead>
                <tr v-for="entry in filesAndFolders" :key="entry.name">
                  <td class="text-center">
                    <i v-if="entry['.tag'] === 'folder'" class="far fa-folder"></i>
                    <i v-else class="far fa-file"></i>
                  </td>
                  <td>
                    <span v-if="['OTHER_TOOL', 'SETTINGS_FILE'].includes(entry.dictaStatus)">{{entry.name}}</span>
                    <a v-else href @click.prevent="load(entry)">{{entry.name}}</a>
                  </td>
                  <td dir="ltr" class="text-left">{{entry['.tag'] === 'folder' ? '' : new Date(entry.server_modified).toLocaleString()}}</td>
                  <td class="trash-col">
                    <span v-if="entry['.tag'] !== 'folder' && entry.dictaStatus !== 'NEW'"
                          class="trash"
                          @click="$refs.confirmDelete.confirmDelete(entry)">
                      <i class="fas fa-undo" v-b-tooltip.hover title="מחיקת שינויים"></i>
                    </span>
                  </td>
                  <td class="text-center pr-3">
                    <span v-if="entry.dictaStatus === 'OTHER_TOOL'">כלי אחר</span>
                    <span v-else-if="entry.dictaStatus === 'SETTINGS_FILE'">הגדרות</span>
                    <b-form-checkbox
                      v-else-if="entry['.tag'] !== 'folder' && entry.dictaStatus !== 'NEW'"
                      switch
                      :checked="entry.dictaStatus === 'COMPLETED'"
                      :disabled="noCompletingInProjects"
                      @change="toggleCompleted(entry)"
                    ></b-form-checkbox>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div v-else>
            <!-- not connected to Dropbox -->
            <small><b-breadcrumb class="bg-white px-0 mb-1">
              <b-breadcrumb-item to="/">{{hebrew ? 'דף הבית' : 'Home'}}</b-breadcrumb-item>
              <b-breadcrumb-item v-if="editScreen" :to="editScreen.to">{{editScreen.name}}</b-breadcrumb-item>
              <b-breadcrumb-item to="/projects" active>{{hebrew ? 'ניהול פרוייקטים' : 'Dropbox'}}</b-breadcrumb-item>
            </b-breadcrumb></small>
            <div class="text-center">
              <p v-if="hebrew" class="col-8 mx-auto my-4 text-center">
                בניהול פרויקטים אנחנו מאפשרים שירות העלאת קבצים מתוך תיקיית ה-Dropbox שלכם,
                וכן לשמור את השינויים שערכתם בקובץ.
                כדי לאפשר את השירות, יש להתחבר לתיקיית ה-Dropbox הפרטית.
              </p>
              <p v-else class="col-8 mx-auto my-4">
                Project Management enables you to upload files from your Dropbox account and save your work back to Dropbox. When you connect your account to Dropbox, the files you add to Dropbox will appear here.
              </p>
              <div v-if="hebrew">
                <p>
                  <b-button v-if="authUrl" variant="primary" class="register-button" @click="connect" :href="authUrl">
                    התחברו לחשבון
                    <span dir="ltr"><i class="fab fa-dropbox"></i> Dropbox</span>
                  </b-button>
                </p>
                <p>
                  <b-button variant="outline-primary" class="register-button" @click="connect" href="https://db.tt/mGV4mPdG">
                    צרו חשבון
                    <span dir="ltr"><i class="fab fa-dropbox"></i> Dropbox</span>
                  </b-button>
                </p>
              </div>
              <div v-else>
                <p>
                  <b-button v-if="authUrl" variant="primary" class="register-button" @click="connect" :href="authUrl">Connect to <i class="fab fa-dropbox"></i> Dropbox</b-button>
                </p>
                <p>
                  <b-button variant="outline-primary" class="register-button" @click="connect" href="https://db.tt/mGV4mPdG">Register for <i class="fab fa-dropbox"></i> Dropbox</b-button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!signedIn" class="mt-5">
          <LoginScreen v-if="!loading"/>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  getDbx,
  dropboxLoad,
  dropboxSetCompleted,
  toolMustAllowLoadingJsonFiles,
  noCompletingInProjects,
  watchFolderWithStatus,
  getAccessToken, dropboxCreateFolder, getDropboxAuthUrl, disconnectFromDropbox
} from 'shared/dropbox/dropbox_utils'
import Spinner from 'shared/components/spinner'
import AboutDropbox from './AboutDropbox'
import Sort from './sort'
import LoginScreen from 'shared/account/components/LoginScreen'
import ConfirmDelete from 'shared/dropbox/ConfirmDelete'
let stopWatching = null

function compareName(a, b) {
  const nameA = a.name.toUpperCase() // ignore upper and lowercase
  const nameB = b.name.toUpperCase() // ignore upper and lowercase
  if (nameA < nameB) {
    return -1
  } else if (nameA > nameB) {
    return 1
  }
  return 0
}

function getStatusNum(entry) {
  if (entry['.tag'] === 'folder' && entry.dictaStatus === 'NEW') return 0
  return entry.dictaStatus === 'COMPLETED' ? 2 : 1
}

function tryCompare(sortKey, a, b) {
  if (sortKey === 'name') {
    return compareName(a, b)
  }
  if (sortKey === 'date') {
    if (a['.tag'] === 'folder') return 0
    const aDate = new Date(a.server_modified)
    const bDate = new Date(b.server_modified)
    return bDate - aDate
  }
  if (sortKey === 'done') {
    const aStatus = getStatusNum(a)
    const bStatus = getStatusNum(b)
    return bStatus - aStatus
  }
}

const ALL_RELEVANT_FILE_EXTENSIONS = ['docx', 'txt', 'json', 'zip']
const partnersAccess = process.env.VUE_APP_MORPHOLOGY_PARTNERS || process.env.VUE_APP_OCR_PARTNERS

export default {
  name: 'Projects',
  components: {ConfirmDelete, Sort, AboutDropbox, Spinner, LoginScreen},
  data() {
    return {
      authUrl: null,
      pathEntries: null,
      statuses: new Map(),
      loading: false,
      sortKey: 'name',
      secondaryKey: 'done',
      asc: false,
      pathCrumbs: [],
      originalTitle: '',
      dropboxTokenUpdating: false
    }
  },
  props: ['editScreen'],
  computed: {
    signedIn () {
      return this.$store.state.account.isLoggedIn
    },
    readableFileExtensions () {
      return ALL_RELEVANT_FILE_EXTENSIONS.slice(0, toolMustAllowLoadingJsonFiles() ? 3 : 2)
    },
    noCompletingInProjects () {
      return noCompletingInProjects()
    },
    firebaseLoaded () {
      return this.$store.state.account.firebaseLoaded
    },
    dropbox_token () {
      return getAccessToken()
    },
    addToDropboxMsg () {
      return process.env.VUE_APP_MORPHOLOGY_PARTNERS ? '':'נא להוסיף אותם לDropbox!'
    },
    filesAndFolders () {
      const readableFileExtensionsRegex = new RegExp('.(' + ALL_RELEVANT_FILE_EXTENSIONS.join('|') + ')$', 'i')
      let sorted = (this.pathEntries || [])
        .filter(entry =>
          (entry['.tag'] === 'folder' && !['.saved', 'Completed'].includes(entry.name))
            || readableFileExtensionsRegex.test(entry.name)
        )
        .sort((a, b) => {
          const aIsFolder = a['.tag'] === 'folder'
          const bIsFolder = b['.tag'] === 'folder'
          if (aIsFolder && !bIsFolder) return -1
          if (bIsFolder && !aIsFolder) return 1
          const primaryKey = tryCompare(this.sortKey, a, b)
          if (primaryKey !== 0) { return primaryKey }
          return tryCompare(this.secondaryKey, a, b)
        })
      if (process.env.VUE_APP_MORPHOLOGY_PARTNERS)  {
        sorted = sorted.filter(entry => (entry.dictaStatus !== 'NEW') )
      }
      if (this.asc) {
        return sorted.reverse()
      }
      return sorted
    },
    hebrew () {
      return this.$settings.hebrew
    },
    canGoUp () {
      return this.$route?.params?.pathMatch?.length > 0
    },
    showDisconnect () {
      return !partnersAccess && (this.firebaseLoaded && this.dropbox_token)
    }
  },
  methods: {
    getPartnerPath () {
      return this.$store.state.account.userData ? '/Morphology Partners/' + this.$store.state.account.userData.userId : ''
    },
    load(entry) {
      if (entry['.tag'] === 'folder') {
        const path = process.env.VUE_APP_MORPHOLOGY_PARTNERS ?
            entry.path_display.replace(this.getPartnerPath(), '') :
            entry.path_display
        this.$router.push('/projects' + path)
      }
      else {
        this.loading = true
        dropboxLoad(entry)
          .then(() => {this.loading = false})
      }
    },
    goUp() {
      const path = this.$route?.params?.pathMatch
      const up = path.substring(0, path.lastIndexOf('/'))
      this.$router.push('/projects' + up)
    },
    getFiles(route) {
      if (!this.signedIn) {
        this.loading = false
        return
      }
      this.loading = true
      const path = route?.params?.pathMatch || ''
      document.title = 'דיקטה ניהול פרוייקטים' + (path.length > 1 ? ' - ' + path.substring(1) : '')
      let pathSoFar = '/projects', pathObjects = []
      const pathItems = path.split('/').slice(1)
      for (let item of pathItems) {
        pathSoFar = pathSoFar + '/' + item
        pathObjects.push({
          text: item,
          to: pathSoFar
        })
      }
      this.pathCrumbs =  [{text: this.hebrew ? 'ניהול פרוייקטים' : 'Dropbox', to: '/projects'}].concat(pathObjects)
      if (stopWatching) stopWatching()
      const adjustedPath = process.env.VUE_APP_MORPHOLOGY_PARTNERS ? this.getPartnerPath() + path : path
      stopWatching = watchFolderWithStatus(adjustedPath, entries => {
        this.pathEntries = entries
        if (path === 'disabled-for-now' && entries.length === 0) {
          getDbx().filesUpload({
            contents: 'This folder holds your Dicta projects.\r\nAdd individual files to load into Dicta Tools, or create folders to hold projects.',
            path: '/Readme.txt'
          })
        }
        this.loading = false
      }, error => {
        if (process.env.VUE_APP_MORPHOLOGY_PARTNERS &&
            error?.error?.error?.path['.tag'] === 'not_found' &&
            (path === '' || path === '/')) {
          dropboxCreateFolder(this.getPartnerPath())
        }
        // eslint-disable-next-line no-console
        console.error(error)
        if ((error?.status === 401 || error?.error?.error['.tag'] === 'invalid_access_token')
            && !partnersAccess) {
          // eslint-disable-next-line no-console
          console.error('dropbox_token no longer valid')
          disconnectFromDropbox()
        }
        this.loading = false
      })
    },
    toggleCompleted (entry) {
      const initialStatus = entry.dictaStatus
      const completed = entry.dictaStatus === 'COMPLETED'
      const toggled = completed ? 'SAVED' : 'COMPLETED'
      dropboxSetCompleted(entry, !completed)
      .catch(() => {
        entry.dictaStatus = initialStatus
      })
      entry.dictaStatus = toggled
    },
    connect () {
      // necessary so that our app doesn't automatically go to the results page after loading saved data
      localStorage.setItem('returnToProjects', true)
    },
    disconnect () {
      disconnectFromDropbox()
    },
    sort (key) {
      if (this.sortKey === key) {
        this.asc = !this.asc
      }
      else {
        this.secondaryKey = this.sortKey
        this.sortKey = key
        this.asc = false
      }
    }
  },
  mounted() {
    getDropboxAuthUrl().then(url => this.authUrl = url)

    this.originalTitle = document.title
    if (this.dropbox_token) {
      this.getFiles(this.$route)
    } else {
      this.loading = false
    }
  },
  watch: {
    firebaseLoaded (val) {
      if (val) {
        //logged in from projects page, reload files
        if (this.dropbox_token) {
          this.getFiles(this.$route)
        }
      }
    },
    dropbox_token (val) {
     if (this.dropboxTokenUpdating && val) {
        this.getFiles(this.$route)
        this.dropboxTokenUpdating = false
      }
    },
    '$store.state.account.isLoggedIn': {
      handler: function (val) {
        if (!val && this.firebaseLoaded && !partnersAccess) {
          this.$router.push('/')
          //if partners, stay on page showing signin button
        } else {
          if (!partnersAccess) {
            //if logging in from partners page
            this.dropboxTokenUpdating = true //when token updates, load files
          } else {
            if (this.dropbox_token &&  this.filesAndFolders.length === 0) { //token already exists, load files
              this.getFiles(this.$route)
            }
          }
        }
      },
      immediate: true
    }
  },
  beforeRouteUpdate(to, from, next) {
    // stopWatching() will be called in getFiles, so no need to do it twice
    this.getFiles(to)
    next()
  },
  beforeDestroy() {
    document.title = this.originalTitle
    if (stopWatching) stopWatching()
    // stopWatching is not part of this.data; the next time we render, it should be null
    stopWatching = null
  }
}
</script>

<style scoped lang="scss">
.user-log-in {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  width: 507px;
  height: 218px;
}
.projects-wrap {
  max-height: calc(100% - 85px);
  min-height: calc(100vh - 85px);
}
.breadcrumb-item+.breadcrumb-item {
  padding-right: 0;
}
.breadcrumb-item+.breadcrumb-item:before {
  padding-left: .5rem;
}
.icon {
  width: 36px;
}
.completed {
  width: 100px;
  padding-left: 0;
  padding-right: 0;
  z-index: 9;
}
.date-modified {
  width: 13rem;
  text-align: left;
}
.register-button {
  width: 13rem;
}
.header {
  text-align: center;
  position: sticky;
  top: -1px;
  background: white;
}
.name-header {
  position: sticky;
  top: -1px;
  background: white;
}
table {
  border-spacing: 0;
  border-collapse: separate;
}
.table tr:first-of-type td {
  border: none;
}
.trash-col {
  width: 50px;
  text-align: end;
}
.trash {
  //display:none;
  visibility: hidden;
}
tr:hover .trash {
  display: inline;
  visibility: visible;
  cursor: pointer;
}
</style>
