<template>
  <div class="comments-dialog" v-hotkey="keymap">
    <b-modal
      id="comment-modal"
      v-if="show"
      v-model="show" 
      centered 
      size="lg" 
      footer-class="text-left pt-1"
      body-class="pb-1"
      header-class="d-none d-sm-block"
      ok-title="שמור וסגור"
      cancel-title="ביטול"
      @ok="setComments"
      @show="getComments"
      >
      <template slot="modal-header">הוסף הערה עבור
        "{{currentWord}}"
      </template>
      <div class="position-relative">
        <textarea
          ref="commentsText"
          v-model="morphComments"
          class="w-100 border border-dark p-2 rounded"> 
        </textarea>
        <b-btn 
          @click="morphComments=''"
          class="position-absolute bg-transparent border-0 px-3 shadow-none"
          v-if="morphComments!==''"
          style="left:0;top:0;font-size:24px;"
          >&times;</b-btn>
      </div>
      <small class="position-absolute">
        "<span :class="{'prefix':currentWord.split('|').length>1}" v-html="currentWord.split('|')[0]"></span><span v-html="currentWord.split('|')[1]"></span>" 
        {{currentSelectedWordNum}}/{{resultsLength}}
      </small>
    </b-modal>
  </div> 
</template>     

<script>
  let english = /^[A-Za-z0-9]*$/
  export default {
    name: "CommentsDialog",
    props: ["value","comments"],
    computed: {
      show: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val)
        }
      },
      currentWordIndex () {
        return this.$store.state.morphologyApp.currentWordIndex
      },
      currentWord () {
        return this.morphData[this.currentWordIndex] ? this.morphData[this.currentWordIndex].text : ""
      },
      morphData () {
        return this.$store.state.morphologyApp.morphData
      },      
      currentSelectedWordNum () {
        return this.morphData[this.currentWordIndex] ? this.morphData[this.currentWordIndex].wordIndex : 1
      }, 
      resultsLength () {
        return this.$store.state.morphologyApp.morphApiResponse.filter(ms => ms.sep === false).length
      },
      keymap () {
        return {   
          'enter': () => {
            this.setComments()
          }
        }
      }  
    },    
    data () {
      return {
        morphComments: ''
      }
    },
    methods: {
      getComments () {
        setTimeout(() => {
          this.$refs.commentsText.style.textAlign = 'right'
          this.$refs.commentsText.style.direction= 'rtl'
          document.activeElement.blur()
          this.$refs.commentsText.focus()           
          if(this.$refs.commentsText) {                    
            if (english.test(this.comments.split(" ")[0]) && this.comments.split(" ")[0] !== '' ) {
              console.log(this.comments)
              this.$refs.commentsText.style.textAlign = 'left'
              this.$refs.commentsText.style.direction= 'ltr'
            }
            this.morphComments = this.comments
          }
        },10)        
      },
      setComments () {
        if (this.show) {
          this.$store.commit("SET_CURRENT_MORPH_COMMENTS", this.morphComments)
          this.show = false
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  small{
    bottom: -30px;
  }
  textarea {
    height: 230px;
    &:focus {
      outline: none;
      border: 1px solid var(--primary)!important;
    }
  }
</style>
 