<template>
  <div>
  <b-modal :visible="show && !error"
           id="save-to-dropbox-modal"
           size="sm"
           :centered="true"
           hide-header
           no-fade
           :ok-title="hebrew ? 'שמור שינויים' : 'Save changes'"
           :cancel-title="hebrew ? 'ביטול' : 'Cancel'"
           footer-class="save-or-saving-or-saved-footer border-top"
           auto-focus-button="ok"
           @show="saveModalShow"
           @cancel="activeSave=false"
           @hide="$emit('change', false)"
           @ok="saveToDropbox()">
    <div class="text-center">
      <p class="mb-0" v-html="confirmSaveMsg"></p>
      <b-form-checkbox v-model="dropboxMarkCompleted">
        <span class="mt-1" :class="{'mx-1': true}">
          {{hebrew ? 'הפרוייקט הושלם' : 'Mark Completed'}}
        </span></b-form-checkbox>
    </div>
  </b-modal>
  <!-- show when save in progress -->
  <b-modal :visible="working && activeSave"
          id="saving-to-dropbox-modal"
          size="sm"
          :centered="true"
          hide-header
          no-fade
          :ok-title="hebrew ? 'סיום' : 'Complete'"
          :busy = "working"
          :cancel-title="hebrew ? 'ביטול' : 'Cancel'"
          footer-class="save-or-saving-or-saved-footer border-top"
         >
    <div class="text-center">
      <p v-if="hebrew" class="mb-0">השמירה מתבצעת כעת<br>בבקשה המתן עד לסיום התהליך</p>
      <p v-else class="mb-0">File is being saved.<br>Please wait</p>
      <div class="mb-n3 dropbox-spinner-wrapper">
        <spinner></spinner>
      </div>
    </div>
  </b-modal>
  <!-- show when save in complete -->
  <b-modal
          id="saved-to-dropbox-modal"
          :modal-class="!completionFailureInfo ? 'no-completion-failure-info' : ''"
          size="sm"
          no-fade
          :centered="true"
          hide-header
          auto-focus-button="ok"
          :ok-title="hebrew ? 'סיום' : 'Complete'"
          :cancel-title="hebrew ? 'ביטול' : 'Cancel'"
          footer-class="save-or-saving-or-saved-footer border-top"
          @hide="activeSave=false; completionFailureInfo = null"
         >
    <div class="text-center">
      <p class="mb-0" v-html="saveSuccessMsg"></p>
      <div v-if="completionFailureInfo">
        <p v-for="(failureInfoLine, lineIndex) in completionFailureInfo" class="mb-0"
           :key="'failureInfoLine-' + lineIndex">{{ failureInfoLine }}</p>
      </div>
    </div>
  </b-modal>
  <FormSaveError
    :showForm="(!!error && activeSave) ||  failedSaves >= 3"
    :error-msg="error"
    :showDownload="true"
    @show-error="activeSave=false;working=false"
    @clear-error="error=null"
    @download-file="downloadFile"
   >
  </FormSaveError>
  </div>
</template>
<script>
import {
  dropboxSave,
  getDisplayFolderFromPath,
  getToolName,
  clearDropboxData
} from 'shared/dropbox/dropbox_utils'
  import Spinner from 'shared/components/spinner'
  import FormSaveError from 'shared/dropbox/FormSaveError'
  const partnersAccess = process.env.VUE_APP_MORPHOLOGY_PARTNERS || process.env.VUE_APP_OCR_PARTNERS
  const savingAndCompletingStatusObj = {
    successSoGoingToProjects: false
  }
  export default {
    name: 'save-to-dropbox',
    model: {
      prop: 'show',
      event: 'change'
    },
    components: {Spinner, FormSaveError},
    props: {
      show: {}
    },
    data() {
      return {
        completionFailureInfo: null,
        working: false,
        dropboxMarkCompleted: this.$store.state.dropbox.status?.dictaStatus === 'COMPLETED',
        error: null,
        activeSave: false,
        failedSaves: 0,
        connectionDataKey: getToolName() + '-dropbox-connection-data'
      }
    },
    destroyed() {
      this.disconnectDropboxData()
    },
    computed: {
      hebrew () {
        return this.$settings && this.$settings.hebrew
      },
      confirmSaveMsg () {
        return partnersAccess ? 'האם ברצונך לבצע שמירת שינויים?' :
        this.hebrew ? 'האם ברצונך לבצע שמירת שינויים ב Dropbox?' : 'Do you want to save changes to Dropbox?'
      },
      saveSuccessMsg () {
        return partnersAccess ? 'שמירת השינויים <br>בוצעה בהצלחה' :
        this.hebrew ? 'שמירת השינויים ב Dropbox<br>בוצעה בהצלחה' : 'Changes were successfully saved to Dropbox'
      },
      dropboxStatus () {
        return this.$store.state.dropbox.status
      }
    },
    methods: {
      saveModalShow () {
        this.activeSave = true
        this.dropboxMarkCompleted = this.$store.state.dropbox.status?.dictaStatus === 'COMPLETED'
      },
      downloadFile () {
        this.$emit('download-file')
      },
      disconnectDropboxData () {
        clearDropboxData(true)
      },
      saveToDropbox () {
        if (!this.working) {
          this.working = true // i.e. show spinner
         // if (!this.activeSave) this.$emit('working', this.working) //display saving... on status bar
          savingAndCompletingStatusObj.successSoGoingToProjects = false
          if (this.dropboxMarkCompleted) this.$emit('saving-and-completing', savingAndCompletingStatusObj) // to suppress "really leave?" warnings ("emit" here so the event registers before the modal closes)
          dropboxSave(this.dropboxStatus, this.dropboxMarkCompleted, this.activeSave)
            .then((success) => {
              this.working = false // i.e. hide spinner
              if (this.dropboxMarkCompleted && this.activeSave) {
                if (success && typeof success === 'object'
                    && Object.prototype.hasOwnProperty.call(success, 'completionFailureInfo')) {
                  this.completionFailureInfo = success.completionFailureInfo
                } else {
                  savingAndCompletingStatusObj.successSoGoingToProjects = true
                  const adjustedFolder = getDisplayFolderFromPath(this.dropboxStatus.path_display)
                  this.$router.push('/projects' + adjustedFolder)
                }
              } else {
               // this.$emit('save-complete') //update status bar
                if (this.activeSave)
                  this.$bvModal.show('saved-to-dropbox-modal')
                  this.activeSave = false
              }
            })
            .catch(e => {
              this.working = false
             // this.$emit('working', 'failed')
              if (!this.activeSave) this.failedSaves++
              //only increment for save not via toolbar button - show error message after 3 failes
              this.error = e
            })
        }
      }
    }
  }
</script>
<style lang="scss">
  #saving-to-dropbox-modal,
  #saved-to-dropbox-modal.no-completion-failure-info,
  #save-to-dropbox-modal{
    button {
      width: 50%;
    }
    .modal-body {
      height: 104px;
    }
    .save-or-saving-or-saved-footer {
      justify-content: center; // duplicated intentionally
      direction: rtl; // so cancel button is ALWAYS on the right
      >:not(:last-child) { // ALWAYS "hebrew" style (because of the above)
          margin-left: .25rem;
          margin-right: 0;
      }
      >:not(:first-child) {
          margin-left: 0;
          margin-right: .25rem;
      }
    }
}
  .dropbox-spinner-wrapper {
    .v-spinner{
      position: static;
     .v-clip {
        position: static;
      }
    }
  }
</style>
