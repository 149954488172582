export default {
 name: 'prefixMixins',
 methods: {
   setPrefix(prefix) {
    var arr = prefix.length > 0 ? ['Unspecified', 'Prefix_other'] : ['Unspecified']
    switch(prefix) {
      case 'ב':
      case 'כ':
      case 'כד':
      case 'ל':
      case 'מ':
      case 'מד':
      case 'א':
      case 'אד':
      case 'בד':
      case 'בל':
      case 'לב':
      case 'לכ':
      case 'למ':
      case 'מב':
      case 'מל':
      case 'כב':
      case 'בדא':
      case 'ק':
      case 'קא':
      case 'לד':
      case 'בכ':
      case 'מכ':
      case 'ככ':
      case 'לכד':
         //PreLets Pre_Prep Pre_NotDef
        arr = ["Unspecified", "Pref_Prepositional"]
      break;
      case 'ד':
      case 'ש':
      case 'לש':        
        //PreLets Pre_RelSub
        arr = ["Unspecified", "Relativizer_Subordinating"]
      break;
      case 'דב':
      case 'בשל':
      case 'לשל':
      case 'שב':
      case 'שכ':
      case 'של':
      case 'שמ':
      case 'בש':
      case 'דכ':
      case 'דל':
      case 'דמ':
      case 'שמב':
      case 'דאד':
      case 'שאד':
      case 'כדא':
      case 'לדא':
      case 'מלדא':
      case 'מדא':
      case 'דלדא':
      case 'דק':
      case 'דקא':
      case 'אדק':
      case 'אדקא':
      case 'דאדק':
      case 'דאדקא':
      case 'שאדק':
      case 'שאדקא':
      case 'שלדקא':
      case 'דלדק':
      case 'דלדקא':
      case 'בדל':
      case 'לדל':
      case 'מדל':
      case 'דכשה':
      case 'דלכש':
      case 'שלד':
      case 'דלד':
      case 'שמד':
      case 'דמד':
      case 'שככ':
      case 'שלכ':
      case 'שמכ':
      case 'שבכ':
      case 'דכש':
      case 'בדק':
      case 'לדק':
      case 'לדקא':
      case 'מבדקא':
      case 'דמב':                                                                                                                                                                                              
        //PreLets Pre_Prep Pre_RelSub Pre_NotDef  
        ["Unspecified", "Pref_Prepositional", "Relativizer_Subordinating",  "Prefix_other"]
      break;
      case 'ה':
      case 'בה':    
        //PreLets Pre_DefArt
        arr = ["Unspecified", "Pref_definitearticle"]
      break;
      case 'ו':
      case 'וה':        
      case 'וב':
      case 'וכ':      
      case 'ומ':
      case 'וכב':
      case 'וא':
      case 'ואה':
      case 'וק':
      case 'וקא':
      case 'ולד':
      case 'ולכ':
      case 'ומכ':
      case 'וככ':
      case 'ובכ':
      case 'ודכ':
      case 'ודמ':                                                                      
        //PreLets Pre_Conj Pre_Prep Pre_NotDef 
        arr = ["Unspecified", "Pref_conjunction"]
      break;
      case 'וד':
      case 'וש':    
        //PreLets Pre_Conj Pre_RelSub  
        arr = ["Unspecified", "Pref_conjunction", "Relativizer_Subordinating"]
      break;
      case 'ודב':
      case 'וכד':
      case 'ובשל':
      case 'ושב':
      case 'ושכ':
      case 'ושל':
      case 'ושמ':
      case 'ובש':
      case 'ודא':
      case 'ואד':
      case 'ודאד':
      case 'וכדא':
      case 'ולדא':
      case 'ודק':
      case 'ודקא':
      case 'ואדק':
      case 'ואדקא':
      case 'ודאדק':
      case 'ודאדקא':
      case 'ודל':
      case 'ושמכ':
      case 'ושבכ':
      case 'ושככ':
      case 'ושלכ':
      case 'ולדק':
      case 'ולדקא':                                                                                                     
        //PreLets Pre_Conj Pre_Prep Pre_RelSub Pre_NotDef  
        arr = ["Unspecified", "Pref_conjunction", "Pref_Prepositional", "Relativizer_Subordinating"]        
      break;
      case 'וכש':
      case 'ולכש':
      case 'ומד':
      case 'ומש':
      case 'ולש':               
        //PreLets Pre_Conj Pre_Temporal
        arr = ["Unspecified", "Pref_conjunction",  "Pref_Temporal"]
      break;
      case 'וכשב':
      case 'וכשל':
      case 'וכשמ':
      case 'ולכשכ':
      case 'ולכשל':
      case 'ולכדא':
      case 'וכדק':
      case 'וכדקא':
      case 'ולכדק':
      case 'ולכדקא':
      case 'ומדק':
      case 'ומדקא':
      case 'וכדב':
      case 'וכדכ':
      case 'וכדל':
      case 'וכדמ':
      case 'ומשל':
      case 'ומשמ':
      case 'ומשב':
      case 'וכשכ':
      case 'ולכד':
      case 'ולכשב':
      case 'ולכשמ':
      case 'ובדא':
      case 'ומדא':
      case 'ובדק':
      case 'ובדקא':
      case 'ובדל':
      case 'ומשכ':                                                                                                              
        //PreLets Pre_Conj Pre_Prep Pre_Temporal Pre_NotDef
        arr = ["Unspecified", "Pref_conjunction", "Pref_Prepositional",  "Pref_Temporal"]
      break;
      case 'וכשה':
      case 'ולכשה':
      case 'ומשה':
      case 'וכשמה':
      case 'וכדה':                
        //PreLets Pre_Conj Pre_DefArt Pre_Temporal  
        arr = ["Unspecified", "Pref_conjunction", "Pref_definitearticle", "Pref_Temporal"]
      break;
      case 'ומה':
      case 'ודה':    
       //PreLets Pre_Conj Pre_DefArt Pre_Prep Pre_NotDef   
       arr = ["Unspecified", "Pref_conjunction", "Pref_definitearticle", "Pref_Prepositional"]
      break;
      case 'ושה':
        //PreLets Pre_Conj Pre_DefArt Pre_RelSub  
        arr = ["Unspecified", "Pref_conjunction", "Pref_definitearticle", "Relativizer_Subordinating"] 
      break;
      case 'ושמה':
       //PreLets Pre_Conj Pre_DefArt Pre_Prep Pre_RelSub Pre_NotDef
       arr =  ["Unspecified", "Pref_conjunction", "Pref_definitearticle",  "Pref_Prepositional", "Relativizer_Subordinating", 
      "Prefix_other"]
      break;
      case 'כש':
      case 'לכש':
      case 'מש':      
        //PreLets Pre_Temporal  
        arr = ["Unspecified", "Pref_Temporal"]
      break;
      case 'כשב':
      case 'כשל':
      case 'כשמ':
      case 'לכשב':
      case 'לכשכ':
      case 'לכשל':
      case 'דא':
      case 'מכש':
      case 'מלש':
      case 'לכדא':
      case 'מבדא':
      case 'מכדא':
      case 'כדק':
      case 'כדקא':
      case 'לכדק':
      case 'לכדקא':
      case 'מכדק':
      case 'מכדקא':                                                                     
      case 'מדק':
      case 'כדב':
      case 'כדכ':
      case 'כדל':
      case 'כדמ':
      case 'מבד':
      case 'מכד':
      case 'מלד':
      case 'משב':    
      case 'משל':
      case 'משמ':
      case 'כשכ':
      case 'לכשמ':
      case 'למב':
      case 'מבש':
      case 'למדא':
      case 'בדקא':
      case 'למדק':
      case 'למדקא':
      case 'מבדק':
      case 'מלדקא':
      case 'מדקא':
      case 'למד':
      case 'משכ':                                                                                       
        //PreLets Pre_Prep Pre_Temporal Pre_NotDef          
        arr = ["Unspecified",  "Pref_Prepositional",  "Pref_Temporal"] 
      break;
      case 'ול':
        arr = ["Unspecified", "Pref_conjunction", "Pref_Prepositional"] 
      break;  
      case 'כשה':
      case 'לכשה':
      case 'כדה':
      case 'משה':
      case 'בדה':               
        //PreLets Pre_DefArt Pre_Temporal  
        arr = ["Unspecified", "Pref_definitearticle", "Pref_Temporal"]
      break;
      case 'כשמה':
      case 'כדמה':
      case 'משמה':
      case 'לכשמה':            
        //PreLets Pre_DefArt Pre_Prep Pre_Temporal Pre_NotDef
        arr = ["Unspecified", "Pref_definitearticle","Pref_Prepositional",  "Pref_Temporal"] 
      break;
      case 'מה':
      case 'כה':
      case 'לה':
      case 'אה':            
       //PreLets Pre_DefArt Pre_Prep Pre_NotDef   
       arr =  ["Unspecified", "Pref_definitearticle", "Pref_Prepositional"] 
      break;
      case 'שה':
        //PreLets Pre_DefArt Pre_RelSub
        arr = ["Unspecified", "Pref_definitearticle", "Relativizer_Subordinating"]
      break;
      case 'שמה':
      case 'דמה':
      case 'אדה':
      case 'מדה':             
         //PreLets Pre_DefArt Pre_Prep Pre_RelSub Pre_NotDef   
        arr = ["Unspecified", "Pref_definitearticle", "Pref_Prepositional", "Relativizer_Subordinating"] 
      break;
      case 'דה':
        //PreLets Pre_DefArt Pre_RelSub
        arr = ["Unspecified", "Pref_definitearticle", "Relativizer_Subordinating"] 
      break;
      case 'שכש':
      case 'שלש':
      case 'שכד':
      case 'דכד':
      case 'שלכד':
      case 'דלכד':
      case 'דלש':
      case 'דמש':
      case 'שמש':
      case 'שלכש':
      case 'למש':                                        
        //PreLets Pre_RelSub Pre_Temporal
        arr = ["Unspecified", "Relativizer_Subordinating", "Pref_Temporal"]
      break;
      case 'בשה':
        //PreLets Pre_DefArt Pre_Prep Pre_RelSub Pre_NotDef  
       arr = ["Unspecified", "Pref_definitearticle", "Pref_Prepositional", "Relativizer_Subordinating"] 
      break;
      case 'ואדה':
      case 'ודמה':
      case 'ודאדה':        
        //PreLets Pre_Conj Pre_DefArt Pre_Prep Pre_RelSub Pre_NotDef  
        arr = ["Unspecified", "Pref_conjunction", "Pref_definitearticle", "Relativizer_Subordinating",
     "Prefix_other"] 
      break;
      case 'שכדא':
      case 'דכדא':
      case 'שלכדא':
      case 'דלכדא':
      case 'שלדא':
      case 'שכדק':
      case 'שכדקא':
      case 'דכדק':
      case 'דכדקא':
      case 'שלכדק':
      case 'שלכדקא':
      case 'דלכדק':
      case 'דלכדקא':
      case 'שלדק':
      case 'שמשב':
      case 'שכשמ':
      case 'שכשל':
      case 'שלכשל':                
      case 'שמשל':
      case 'שכשב':
      case 'שמשמ':
      case 'שמדא':
      case 'דמדא':
      case 'מלדק':
      case 'שמדק':
      case 'שמדקא':
      case 'דמדק':
      case 'דמדקא':
      case 'שכשכ':
      case 'שמשכ':                                            
        //PreLets Pre_Prep Pre_RelSub Pre_Temporal Pre_NotDef
        arr = ["Unspecified", "Pref_Prepositional", "Relativizer_Subordinating", "Pref_Temporal","Prefix_other"] 
      break;
      case 'ושכשל':
      case 'ושמשמ':
      case 'ושכשב':
      case 'ושמשל':
      case 'ושכשכ':                
        //PreLets Pre_Conj Pre_Prep Pre_RelSub Pre_Temporal Pre_NotDef
        arr = ["Unspecified", "Pref_conjunction"]
      break;
      case 'ושלכש':
      case 'ושכש':
      case 'ושמש':
      case 'ובד':            
        //PreLets Pre_Conj Pre_RelSub Pre_Temporal
        arr = ["Unspecified", "Pref_conjunction","Relativizer_Subordinating", "Pref_Temporal"]
      break;
      case 'ושכשה':
      case 'שכשה':
      case 'שלכשה':
      case 'שמשה':            
        //PreLets Pre_DefArt Pre_RelSub Pre_Temporal
        arr = ["Unspecified", "Pref_definitearticle",  "Relativizer_Subordinating", "Pref_Temporal"]
      break;
      case 'ולכשמה':
      case 'וכדמה':
        //PreLets Pre_Conj Pre_DefArt Pre_Prep Pre_Temporal Pre_NotDef      
        arr = ["Unspecified", "Pref_conjunction", "Pref_definitearticle",  "Pref_Prepositional", "Pref_Temporal",         
      "Prefix_other"] 
      break;
      case 'שכדה':
      case 'דכדה':
        //PreLets Pre_DefArt Pre_RelSub Pre_Temporal  
        arr = ["Unspecified", "Pref_definitearticle", "Relativizer_Subordinating", "Pref_Temporal"]
      break;                                                                                                        
    }
    return arr
   }
  }
}    