import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import store from './store'
import router from './router'
import dictaVueComponents from 'dicta-vue-components'
import { DocumentManager } from './DocumentManager'
import { initializeUndoRedo } from 'shared/UndoRedo'
import { dropboxInitialize } from 'shared/dropbox/dropbox_utils'

Vue.config.productionTip = false
Vue.use(dictaVueComponents, { hebrew: true, useBodyClass: true })

import VueHotkey from 'v-hotkey'
import { loadLocalData } from "@/js/localData";
Vue.use(VueHotkey)

initializeUndoRedo({
  store,
  undoableCommits: ['SET_CURRENT_WORD_INDEX', 'MOVE_MORPH_OPTION_TO_TOP', 'EDIT_MORPH_OPTION', 'ADD_MORPH_OPTION',
    'PUSH_MORPH_OPTION', 'INSERT_MORPH_OPTION', 'SET_SELECTED_MORPH', 'SET_CURRENT_MORPH_COMMENTS', 'SET_LEX', 'TOGGLE_FLAG',
    'TOGGLE_GENERAL_FLAG', 'REMOVE_MORPHOLOGY'],
  emptyStateCommit: 'RESET_RESULTS',
  baseStateCommits: ['SET_MORPH_RESULTS', 'LOAD_SAVED_DATA']
})

dropboxInitialize({
  documentManager: DocumentManager,
  store: store,
  toolName: 'morphology',
  autoClear: false
})

router.onReady(() => {
  loadLocalData(router.currentRoute.name === 'results')
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')