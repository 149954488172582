// the @mousedown handler captures double-clicks and prevents them from turning into a "selection"
// the default browser behavior is text selection of the current word
<template>
  <div class="textpanel f-narkis"
       @mousedown="mouseDown"
       @mouseup="mouseUp"
       ref="textPanel"
       >
    <TextPanelPara v-for="(para, pi) in paras"
                   :key="pi"
                   :para="para"
                   :allow-single-letter="allowSingleLetter"
                   @select="handleClick"
                   @dblclick="announceDblClick"
                   ></TextPanelPara>
    <div v-if="showSpinner" class="spinnerdiv">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import {setOptions, getNewContext} from './TextPanelContext'
import TextPanelPara from './TextPanelPara'
import manageDisplayTokens from './manageDisplayTokens'

let ctx

let vm
let debounceTimer
let lastAnnounced
let eventTimestamp = performance.now()
let rangeActive = false
let observer = null
let modalOpen = false

// moving up and down a line should return to the original token
// if the line above is short, and we started at an X position after the end of the line above,
// we still need to remember the original X position
let startingX = null

// this function doesn't use `this` because the browser doesn't call event handlers with the right `this`
function keyboardHandler(evt) {
  if (vm.disableKeys || modalOpen) return
  eventTimestamp = evt.timeStamp
  if (vm.allowSingleLetter && !(evt.shiftKey) && !(evt.ctrlKey) && !(evt.altKey)) {
    if (evt.code === 'KeyB') {
      const overflow = ctx.activeToken.letterForward()
      if (overflow && vm.selected !== vm.displayTokenCount - 1) {
        const announce = false
        vm.setSelection(vm.selected + 1, announce)
      }
      vm.announceSelection()
    } else if (evt.code === 'KeyN') {
      const overflow = ctx.activeToken.letterBackward()
      if (overflow && vm.selected !== 0) {
          const announce = false
          vm.setSelection(vm.selected - 1, announce)
          ctx.activeToken.goToLastLetter()
      }
      vm.announceSelection()
    }
  }
  // only capture plain arrow keys
  if (!(evt.shiftKey || evt.metaKey || evt.ctrlKey)) {
    if (evt.key === 'ArrowLeft') {
      vm.tokenLeft()
    } else if (evt.key === 'ArrowRight') {
      vm.tokenRight()
    }
  } else if (evt.shiftKey) {
    if (evt.key === 'ArrowUp') {
      evt.preventDefault()
      if (evt.ctrlKey || evt.metaKey) {
        vm.paraUp()
      } else {
        vm.lineUp()
      }
    } else if (evt.key === 'ArrowDown') {
      evt.preventDefault()
      if (evt.ctrlKey || evt.metaKey) {
        vm.paraDown()
      } else {
        vm.lineDown()  }
    }
  }
}

function checkForModal(mutationList) {
  for(let mutation of mutationList) {
    if (mutation.attributeName === 'class') {
      if (document.body.classList.contains('modal-open')) {
        modalOpen = true
      } else {
        modalOpen = false
      }
    }
  }
}

const base = {
  name: "TextPanel",
  components: { TextPanelPara },
  props: {
    tokens: Array,
    allowSingleLetter: {
      type: Boolean,
      default: false
    },
    announceAllNavigation: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disableKeys: {
      type: Boolean,
      default: false
    }
  },
  data() {
    ctx = getNewContext()
    if (!ctx.initialized)
      throw "Don't import TextPanel directly. Call getTextPanel()."
    lastAnnounced = null
    return {
      selected: -1,
      paras: [],
      displayTokenCount: 0,
      loadingParas: true
    }
  },
  computed: {
    showSpinner() {
      return this.loading || this.loadingParas
    }
  },
  methods: {
    mouseDown (e) {
      if (e.detail === 2) {
        e.preventDefault()
        return
      }
    },
    mouseUp () {
      const s = document.getSelection()
      if (s.type == 'Range' &&
        this.$refs.textPanel.contains(s.anchorNode)
      ) {
        // eslint-disable-next-line no-inner-declarations
        function getTokenEl(node) {
          let curnode = node
          while (curnode !== null)  {
            if (ctx.displayTokenElToVm.has(curnode)) return curnode
            curnode = curnode.parentElement
          }
        }
        const anchor = getTokenEl(s.anchorNode)
        const focus = getTokenEl(s.focusNode)
        const bitmask = anchor.compareDocumentPosition(focus)
        let first, last
        if (bitmask & Node.DOCUMENT_POSITION_PRECEDING || bitmask === 0) {
          first = focus
          last = anchor
        } else {
          first = anchor
          last = focus
        }
        s.setBaseAndExtent(first, 0, last, last.childElementCount)
        this.$emit('range-selection',
          {
            from: ctx.displayTokenElToVm.get(first).displayToken.tokenIndex,
            to: ctx.displayTokenElToVm.get(last).displayToken.tokenIndex
          })
        rangeActive = true
      }
    },
    // receives an object of the following form:
    // {
    //   command: 'move' or 'previous-editable' or 'next-editable'
    //   arguments: { tokenIndex: some number, letterIndex: some number }
    // }
    // arguments are only used if the command is 'move'
    navigate (o) {
      if (!ctx.tokenIdxToDisplayIdx) return // not initialized
      eventTimestamp = performance.now()
      switch (o.command) {
        case 'move': {
          const displayIndex = ctx.tokenIdxToDisplayIdx.get(+o.arguments.tokenIndex)
          if (displayIndex !== null) this.setSelection(displayIndex, false)
          if (ctx.activeToken) {
            ctx.activeToken.currentLetter = +(o.arguments.letterIndex || 0)
            if (this.announceAllNavigation) {
              const whetherFromClick = false
              this.announceSelection(whetherFromClick)
            }
          }
          break
        }
        case 'previous-editable':
          this.tokenRight()
          break
        case 'next-editable':
          this.tokenLeft()
          break
      }
      lastAnnounced = null
    },
    // move one token to the left or right
    tokenMove (direction) {
      // reset the remembered X position for vertical movement
      startingX = null
      let index = this.selected
      const end = this.displayTokenCount - 1
      while (direction === 1 ? index < end : index > 0) {
        index += direction
        if (ctx.allowSelection(ctx.tokenMap.get(index).token)) {
          this.setSelection(index)
          break
        }
      }
    },
    tokenLeft () {
      this.tokenMove(1)
    },
    tokenRight () {
      this.tokenMove(-1)
    },
    // move vertically
    lineMove (direction) {
      const tokenRef = ctx.activeToken?.$refs.word
      // no previous token, so can't calculate new position
      if (!tokenRef) {
        return
      }
      const rect = tokenRef.getBoundingClientRect()
      const startingY = rect.top
      // see comment at definition of startingX
      if (!startingX) {
        startingX = (rect.right + rect.left) / 2
      }
      // don't adjust this.selected directly while walking to the new token, since each change triggers watchers
      let index = this.selected
      const end = this.displayTokenCount - 1
      let curEl = tokenRef
      let movedALine = false
      let nextLineY = 0
      let lastPossibleTokenIndex = null
      let lastPossibleTokenDistance = 10000
      // loop one token at a time, looking for the first token to qualify
      while (direction === 1 ? index < end : index > 0) {
        index += direction
        // find the next element, since this is dependent on the element position
        let nextEl = direction === 1 ? curEl.nextSibling : curEl.previousSibling
        // if there is a nextEl, use that, but otherwise, walk to the next para
        if (nextEl) {
          curEl = nextEl
        } else {
          curEl = direction === 1 ?
              curEl.parentElement.nextSibling.firstChild :
              curEl.parentElement.previousSibling.lastChild
        }
        const rect = curEl.getBoundingClientRect()
        if (!movedALine && direction * (rect.top - startingY) > 10) {
          movedALine = true
          nextLineY = rect.top
        }
        if (movedALine) {
          if (direction * (rect.top - nextLineY) > 10) {
            // too far
            // if we didn't even see a single possible token yet, though, keep going
            if (lastPossibleTokenIndex) {
              index = lastPossibleTokenIndex
              break
            }
          }
          // distance between the midpoints of the candidate token and the starting token
          const distance = (startingX - (rect.left + rect.right) / 2)
          // if this is a token that qualifies as a full line away from the start
          if (direction * distance > -10) {
            // don't stop on a token that's not allowed
            if (!ctx.allowSelection(ctx.tokenMap.get(index).token)) continue
            if (lastPossibleTokenIndex !== null && Math.abs(lastPossibleTokenDistance) < Math.abs(distance)) {
              index = lastPossibleTokenIndex
            }
            break
          }
          // save this token as a fallback, e.g. if this line is short and we move 2 lines
          if (ctx.allowSelection(ctx.tokenMap.get(index).token)) {
            lastPossibleTokenIndex = index
            lastPossibleTokenDistance = distance
          }
        }
      }
      // if the loop ended at the end of the document, but the app doesn't allow the token to be selected
      if (!ctx.allowSelection(ctx.tokenMap.get(index).token)) {
        index = lastPossibleTokenIndex !== null ? lastPossibleTokenIndex : this.selected
      }
      this.setSelection(index)
    },
    lineUp () {
      this.lineMove(-1)
    },
    lineDown () {
      this.lineMove(1)
    },
    paraMove (direction) {
      const vm = this
      function getFirst(para) {
        for (let displayToken of para) {
          if (ctx.allowSelection(displayToken.token)) {
            return displayToken.displayTokenIndex
          }
        }
        return null
      }
      function firstTokenInPara (paraIndex) {
        return getFirst(vm.paras[paraIndex].words)
      }
      function lastTokenInPara (paraIndex) {
        return getFirst(vm.paras[paraIndex].words.slice().reverse())
      }
      const paraIndex = ctx.tokenMap.get(this.selected).paraIndex
      let found = false
      // The behavior isn't symmetric. Going up once moves to the beginning of the current para
      // Going down only moves to the end of the para when it's the last para
      if (direction === -1 && firstTokenInPara(paraIndex) !== this.selected) {
        const next = firstTokenInPara(paraIndex)
        if (next !== null)
          this.setSelection(next)
        found = true
      } else {
        let nextPara = paraIndex + direction
        while (nextPara >= 0 && nextPara < this.paras.length) {
          const next = firstTokenInPara(nextPara)
          if (next !== null) {
            this.setSelection(next)
            found = true
            break
          }
          nextPara += direction
        }
      }
      if (!found && direction === 1) {
        const last = lastTokenInPara(paraIndex)
        if (last !== null) {
          this.setSelection(last)
        }
      }
    },
    paraUp () {
      this.paraMove(-1)
    },
    paraDown () {
      this.paraMove(1)
    },
    handleClick(index) {
      startingX = null
      this.setSelection(index, true, true)
    },
    setSelection(selection, announce = true, click = false) {
      const oldindex = this.selected
      if (ctx.tokenMap.has(oldindex))
        ctx.tokenMap.get(oldindex).isCurrent = false
      ctx.tokenMap.get(selection).isCurrent = true
      ctx.activeToken = ctx.displayTokenVms[selection]
      this.selected = selection
      if (!click && ctx.activeToken)
        ctx.activeToken.goToFirstLetter()
      if (announce) {
        this.announceSelection(click)
      }
    },
    announceSelection(click) {
      if (!ctx.activeToken) return
      const announcement = {
        tokenIndex: ctx.activeToken.displayToken.tokenIndex,
        letterIndex: ctx.activeToken.currentLetter
      }
      if (click) {
        announcement.trigger = 'click'
      }
      if (lastAnnounced &&
          lastAnnounced.tokenIndex === announcement.tokenIndex &&
          lastAnnounced.letterIndex === announcement.letterIndex)
        return
      lastAnnounced = announcement
      if (rangeActive) {
        rangeActive = false
        document.getSelection().removeAllRanges()
        this.$emit('range-selection', { cleared: true })
      }
      // use nextTick to let this wait until later. Perhaps there are already queued
      this.$nextTick(() => {
        if (!debounceTimer && performance.now() - eventTimestamp < 16) {
          this.$emit('token-selection', announcement)
        } else {
          if (debounceTimer) clearTimeout(debounceTimer)
          debounceTimer = setTimeout(() => {
            this.$emit('token-selection', announcement)
            debounceTimer = null
          }, 200)
        }
      })
    },
    announceDblClick() {
      this.$emit('dblclick', ctx.tokenMap.get(this.selected).tokenIndex)
    },
    // this is called from initParas, but startingX isn't in scope in the manageDisplayTokens file
    reset() {
      startingX = null
    },
    ...manageDisplayTokens
  },
  mounted() {
    modalOpen = false
    vm = this
    addEventListener('keydown', keyboardHandler)
    observer = new MutationObserver(checkForModal)
    observer.observe(document.body, { attributes: true })
  },
  beforeDestroy() {
    removeEventListener('keydown', keyboardHandler)
    observer.disconnect()
  },
  watch: {
    tokens: {
      handler (newVal, oldVal) {
        this.loadTokens(newVal, oldVal, 'watch')
      },
      immediate: true
    }
  }
}
export function getTextPanel(o) {
  const options = o || {}
  setOptions(options)
  return base
}
export default base
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.textpanel {
  overflow: auto;
  background-color: #f6f6f6;
  padding: 10px;
  line-height: 1.2;
  scroll-padding: 20px;
  height: 100%;
}

/* this spinner appears within the TextPanel after the text when `loading` is true */
.spinnerdiv {
  height: 60px;
  width: 100%;
  position: relative;
}
.spinnerdiv .spinner {
    animation: spinnerDelay 0.75s 0s infinite linear;
    animation-fill-mode: both;
    display: inline-block;
    height: 35px;
    width: 35px;
    border: 2px solid #007ee5;
    border-bottom-color: transparent;
    border-radius: 100%;
    background: transparent;
    position: absolute;
    top: calc(50% - 17.5px);
    left: calc(50% - 17.5px);
  }

  @keyframes spinnerDelay {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
