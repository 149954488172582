<template>
    <div id="more-results-tools" v-show="showMoreResultsTools">
        <div class="bg-background position-relative h-100" style="z-index:9999999">
            <div class="more-results-tools-header bg-secondary">
                <span @click="$emit('hide-more-results-tools')" style="cursor: pointer">
                   <i v-if="hebrew" class="fas fa-arrow-right"></i>
                   <i v-else class="fas fa-arrow-left pl-3"></i>
                </span>
                <span class="p-2">{{ hebrew ? 'חזרה' : 'Back' }}</span>
            </div>
            <h3 >{{hebrew ? 'הגדרות תצוגה' : 'Display Settings'}}</h3>
            <div class="tool-section container">
                <div class="tool-wrapper row">
                    <div class="col-5 tool-label py-1">{{hebrew ? 'גודל טקסט' : 'Font size'}}</div>
                    <div class="col-7 tool-content">
                        <span v-bind:class="[{'opacity-30':!canIncreaseFont}, 'text-primary rounded-circle border border-primary']">
                            <i @click="increaseFont" class="fas fa-plus" />
                        </span>
                        <span class="p-2">{{ fontSizeOption }}</span>
                        <span v-bind:class="[{'opacity-30':!canDecreaseFont}, 'text-primary rounded-circle border border-primary']">
                            <i @click="decreaseFont" class="fas fa-minus" />
                        </span>
                    </div>
                </div>
            </div>
            <h3>{{ moreResultsMode.hideablesName }}</h3>
            <div class="tool-section container">
                <div class="tool-wrapper row">
                    <div class="col-8 tool-label">{{ (hebrew ? 'הצג ' : 'Display ') + moreResultsMode.hideablesName.toLowerCase() }}</div>
                    <div class="col-4 tool-content">
                        <b-form-checkbox v-model="showHideablesOption"  />
                    </div>
                </div>
            </div>
            <div class="more-results-tools-footer">
                <b-button
                    block
                    variant="primary"
                    size="lg"
                    @click="saveChangesClicked">{{hebrew ? 'שמור שינויים' : 'Save Changes'}}
                </b-button>
            </div>
        </div>    
        <div class="modal-backdrop"></div>
    </div>
</template>

<script>
    import styleGlobals from "shared/js/forTextPanel/styleGlobals"
    export default {
        name: 'MobileTextPanelMoreTools',
        props: ['moreResultsMode'],    
        data() {
            return {
                fontSizeOption: this.moreResultsMode.fontSize,
                showHideablesOption: this.moreResultsMode.showHideables
            }
        },
        computed: {
            hebrew () {
                return this.$settings.hebrew
            },
            canIncreaseFont () {
                return this.fontSizeOption < styleGlobals.MAX_FONT_SIZE
            },
            canDecreaseFont () {
                return this.fontSizeOption > styleGlobals.MIN_FONT_SIZE
            },
            passedShowHideables () {
                return this.moreResultsMode.showHideables
            },
            showMoreResultsTools () {
                return this.moreResultsMode.showMoreResultsTools
            }
        },
        watch: {
            passedShowHideables (newPassedShowHideables) {
                this.showHideablesOption = newPassedShowHideables
            },
        },
        methods: {
            increaseFont () {
                if (this.canIncreaseFont) this.fontSizeOption ++
            },
            decreaseFont () {
                if (this.canDecreaseFont) this.fontSizeOption --
            },
            saveChangesClicked () {
                if (this.fontSizeOption !== this.moreResultsMode.fontSize) {
                    this.$emit('change-font-size', this.fontSizeOption)
                }
                if (this.showHideablesOption !== this.moreResultsMode.showHideables) {
                    this.$emit('change-show-hideables', this.showHideablesOption)
                }
                this.$emit('hide-more-results-tools')
            }
        }
    }
</script>

<style lang="scss">
    @media (max-width: 767px) {
        .custom-checkbox{
            .custom-control-label:after, .custom-control-label:before {
                width: 1.5rem;
                height: 1.5rem;
                top: 0;
            }
        }
    } 
</style>
<style scoped>
    /* DocumentSettingsGenre-specific CSS goes here */
    .bg-background{
        background:#f6f6f6;
    }
    #more-results-tools {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 90%;
        z-index: 999;
    }
    .he #more-results-tools {
        left: 0px;
        right: initial;
    }
    h3 {
        margin: 0 15px 5px;
    }
    .more-results-tools-header {
        display: flex;
        align-items: center;
        height: 40px;
        padding-right: 15px;
        margin-bottom: 40px;
    }
    .tool-section {
        border-top: solid 1px lightgray;
        border-bottom: solid 1px lightgray;
        background-color: white;
        margin-bottom: 15px;
    }
    .rounded-circle{
        border-width: 2px!important;
        display: inline-block;
    }
    .rounded-circle i{
        height: 28px;
        width: 28px;
        text-align: center;
        line-height: 28px;
    }
    .opacity-30{
        opacity:0.3;
    }
    .tool-wrapper {
        border-radius: 6px;
        box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
        border: solid 1px #d8d8d8;
        margin: 15px 0;
        height: 64px;
        display: flex;
        align-items: center;
    }
    .tool-content {
        text-align: right;
    }
    .he .tool-content {
        text-align: left;
    }    
    .more-results-tools-footer {
        background-color: white;
        position: fixed;
        bottom: 0px;
        height: 70px;
        width: 90%;
        padding: 15px;
        border-top: solid 2px lightgray;
        text-align: center;
    }
</style>
