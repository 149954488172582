import {dropboxLoad, predict} from "shared/dropbox/dropbox_utils";
import { Mutations } from 'shared/dropbox/store'

export default {
    name: 'dropboxMixins',
    data() {
        return {
            dropboxEntries: [],
        }
    },
    methods: {
        switchPage(direction) {
            if (!this.dropboxStatus.saving) {
                const entry = direction === 1 ? this.nextPage : this.prevPage
                if (this.dropboxStatus.edited) {
                    const answer = window.confirm('נא לאשר שאתם באמת רוצים לצאת')
                    if (!answer) return
                }
                this.$store.commit(Mutations.SET_DROPBOX_LOADING, true)
                dropboxLoad(entry)
                    .then(() => {
                        this.$store.commit(Mutations.SET_DROPBOX_LOADING, false)
                        this.dropboxMarkCompleted = this.$store.state.dropbox.status?.dictaStatus === 'COMPLETED'
                    })
            }
        },
        updateFolderStatus(entries) {
            const dropboxEntries = entries.filter(entry =>
                entry.name.endsWith('.json') || (entry.dictaFiles.length > 0 && entry.name.endsWith('.txt')) &&
                ['NEW', 'SAVED', 'COMPLETED'].includes(entry.dictaStatus))
            dropboxEntries.sort((a, b) => {
                const nameA = a.name.toUpperCase() // ignore upper and lowercase
                const nameB = b.name.toUpperCase() // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1
                } else if (nameA > nameB) {
                    return 1
                }
                return 0
            })
            this.dropboxEntries = dropboxEntries
        }
    },
    computed: {
        nextPage() {
            const nextIndex = this.currentPageIndex + 1
            return this.dropboxEntries.length - 1 >= nextIndex ? this.dropboxEntries[nextIndex] : null
        },
        prevPage() {
            const prevIndex = this.currentPageIndex - 1
            return prevIndex >= 0 ? this.dropboxEntries[prevIndex] : null
        },
        currentPageIndex() {
            return this.dropboxStatus ? this.dropboxEntries.findIndex(entry => entry.name === this.dropboxStatus.name) : 0
        },
        loading() {
            return this.$store.state.dropbox.dropboxLoading
        },
    },
    watch: {
      nextPage(val) {
          predict(val.path_display)
          predict(val.path_display.replace(/json$/, 'png'))
      }
    }
}
