import { RunStates } from './runStates'

export const StateChanges = {
  // ** front end *
  LOAD_DOCUMENT_FILE: 'LOAD_DOCUMENT_FILE',
  EDIT_DOCUMENT_TEXT: 'EDIT_DOCUMENT_TEXT',
  SET_TEXT_FOR_NAKDAN_API_CALL: 'SET_TEXT_FOR_NAKDAN_API_CALL',
  SET_CURRENT_WORD_INDEX: 'SET_CURRENT_WORD_INDEX',
  SET_CURRENT_LETTER_INDEX: 'SET_CURRENT_LETTER_INDEX',
  SET_WORD_AND_LETTER: 'SET_WORD_AND_LETTER',
  VALIDATION_FAILURE_POPUP_SHOWN: 'VALIDATION_FAILURE_POPUP_SHOWN',
  DISABLE_LOGIN_POPUP: 'DISABLE_LOGIN_POPUP',

  UPDATE_USER_DICTIONARY: 'UPDATE_USER_DICTIONARY',
  SET_DISABLE_UPLOAD_DICTIONARY_PROMPT: 'SET_DISABLE_UPLOAD_DICTIONARY_PROMPT',
  SET_DISABLE_BACK_TO_TEXT_PREP_PROMPT: 'SET_DISABLE_BACK_TO_TEXT_PREP_PROMPT',
  SET_TEXT_TYPE: 'SET_TEXT_TYPE',

  // ** concerning APIs *
  NAKDAN_RUNNING: 'NAKDAN_RUNNING',
  NAKDAN_CONTINUING: 'NAKDAN_CONTINUING',
  NAKDAN_COMPLETE: 'NAKDAN_COMPLETE',
  NAKDAN_TIMED_OUT: 'NAKDAN_TIMED_OUT',
  NAKDAN_FAILED: 'NAKDAN_FAILED',
  SET_NAKDAN_RESULTS: 'SET_NAKDAN_RESULTS',
  NAKDAN_NOT_RUN: 'NAKDAN_NOT_RUN',

  FIREBASE_RECEIVED_UPDATE: 'FIREBASE_RECEIVED_UPDATE',
  CHANGE_USER_PASSWORD: 'CHANGE_USER_PASSWORD',
  LOGIN_REQUESTED: 'LOGIN_REQUESTED',
  LOGIN_CONTINUING: 'LOGIN_CONTINUING',
  LOGIN_COMPLETE: 'LOGIN_COMPLETE',
  LOGIN_CANCELLED: 'LOGIN_CANCELLED',
  LOGGED_OUT: 'LOGGED_OUT',

  /*morphology*/
  SET_MORPH_RESULTS: 'SET_MORPH_RESULTS',
  SET_CURRENT_MORPH_INDEX: 'SET_CURRENT_MORPH_INDEX',
  SET_CURRENT_GROUP_MORPH_INDEX: 'SET_CURRENT_GROUP_MORPH_INDEX',
  SET_SELECTED_MORPH: 'SET_SELECTED_MORPH',
  SET_GROUP_SELECTED_MORPH: 'SET_GROUP_SELECTED_MORPH',
  ADD_MORPH_OPTION: 'ADD_MORPH_OPTION',
  PUSH_MORPH_OPTION: 'PUSH_MORPH_OPTION',
  EDIT_MORPH_OPTION: 'EDIT_MORPH_OPTION',
  SET_MORPH_OPTIONS: 'SET_MORPH_OPTIONS',
  INSERT_MORPH_OPTION: 'INSERT_MORPH_OPTION',
  INITIALIZE_GROUP: 'INITIALIZE_GROUP',
  ADD_GROUP_MORPH_OPTION: 'ADD_GROUP_MORPH_OPTION',
  SET_GROUP_MORPH_OPTION: 'SET_GROUP_MORPH_OPTION',
  REMOVE_MORPHOLOGY: 'REMOVE_MORPHOLOGY',
  REMOVE_GROUP_MORPHOLOGY: 'REMOVE_GROUP_MORPHOLOGY',
  MOVE_MORPH_OPTION_TO_TOP: 'MOVE_MORPH_OPTION_TO_TOP',
  MOVE_MORPH_OPTION_TO_BOTTOM: 'MOVE_MORPH_OPTION_TO_BOTTOM',
  SET_CURRENT_MORPH_COMMENTS: 'SET_CURRENT_MORPH_COMMENTS',
  SET_GROUP: 'SET_GROUP',
  SET_NEUTRALIZED: 'SET_NEUTRALIZED',
  TOGGLE_GENERAL_FLAG: 'TOGGLE_GENERAL_FLAG',
  TOGGLE_LEX_ENABLED: 'TOGGLE_LEX_ENABLED',
  TOGGLE_WORDS_TO_TAG: 'TOGGLE_WORDS_TO_TAG',
  SET_PREFIX: 'SET_PREFIX',
  RESET_RESULTS: 'RESET_RESULTS',
  SET_WORD_NIKUD: 'SET_WORD_NIKUD',
  SET_TERM_NAMES: 'SET_TERM_NAMES',
  LOAD_SAVED_DATA: 'LOAD_SAVED_DATA',
  ADD_MORPH_RESULTS: 'ADD_MORPH_RESULTS',
  ADD_MORPH_RESULTS_FROM_JSON: 'ADD_MORPH_RESULTS_FROM_JSON',
  SET_ALT_RESULTS: 'SET_ALT_RESULTS',
  REMOVE_ALT_RESULTS: 'REMOVE_ALT_RESULTS',
  UPDATE_SINGLE_MORPH: 'UPDATE_SINGLE_MORPH'
}

export const Actions = {
  RUN_MORPH_ON_WORD: 'RUN_MORPH_ON_WORD',
  RUN_MORPHOLOGY: 'RUN_MORPHOLOGY',
}

export const BlankState = {
  validationResult: null,
  nakdanApiState: RunStates.NOT_RUN,
  originalDocument: {
    id: -0.1,
    text: '',
    source: {
      fileBase64Contents: null,
      type: null,
      runArray: [],
      wordToRunAndPosMap: []
    },
    identifiedGenre: null
  },
  tempUserDictionary: {
    entryPresent: {},
    negativeEntryPresent: {}
  },
  /* login */
  hideLoginPopup: false,
  isLoggedIn: false,
  userData: null,
  loginInProgress: false,
  canDelete: true,
  //recentNeutralizedRange: [],
  recentGroupedRange: [],
  shortMorphTerms: false,
  lastLoadedState: {},
  alternateResults: [],
  morphologyApp: {
    validationResult: null,
    textForNakdanApiCall: '',
    postSubmissionOptions: {
      disableGoHomePrompt: false,
      disableBackToTextPrepPrompt: false,
      disableUploadDictionaryPrompt: true
    },
    textType: 'rabbinic',
    morphApiResponse: [],
    morphData: [],
    currentWordIndex: 0,
    lastWordIndex: 0,
    lastWordKey: 0
  }
}

