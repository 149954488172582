/* ***************************************
This mixin "exposes" the methods:
  * scrollResultsWindow() [for TextPanel]
  * moveToNextToken() [for TextPanel]
  * moveToPreviousToken() [for TextPanel]
  * moveToTokenMethod() [for TextPanel]
(all else should be considered "internal")
*************************************** */

import scrollParentToChild from 'shared/js/scrollParentToChild'
import {getTokenPartIndex} from './utils'

export default {
  name: 'textPanelNavigationMixins',
  methods: {
    scrollResultsWindow () {
      const resultsWindow = document.getElementsByClassName('main-results-box')[0]
      if (resultsWindow.children.length) { // only this necessary - now there's only a p if there's a token
        const currentTokenPartIndex = getTokenPartIndex(this.localCurrentToken, this.currentLetterIndex)
        const currentLetterSpan = document.querySelector('[id*=tokenPart-' + currentTokenPartIndex
          + '-ofToken-' + this.currentTokenIndex + '-letterpos-' + this.currentLetterIndex + ']')
        // console.log('currentLetterSpan:')
        // console.log(currentLetterSpan)
        if (currentLetterSpan) scrollParentToChild(resultsWindow, currentLetterSpan, 'extraPx=8') // lineHeight - span height
      }
    },
    moveToNextToken (mode = 'auto') { // currently, text panel never uses auto, but leaving it for now
      var workingTokenObj = this.currentTokenIndex >= 0 // if index = -1 then workingTokenObj = null
                              ? this.allTokens[this.currentTokenIndex] : null
      var workingTokenIndex = this.currentTokenIndex
      if (workingTokenObj && mode === 'auto'  && !(workingTokenObj.neutralized)) {
        while (workingTokenIndex < this.allTokens.length && !(workingTokenObj.isEditable)) {
          workingTokenIndex += 1 // if positioned IN a sep, auto-jump OUT of it
          if (workingTokenIndex < this.allTokens.length) workingTokenObj = this.allTokens[this.currentTokenIndex]
        }
      }
      var foundCandidate = false
      while (workingTokenIndex < this.allTokens.length && !foundCandidate) {
        workingTokenIndex += 1
        if (workingTokenIndex < this.allTokens.length) {
          workingTokenObj = this.allTokens[workingTokenIndex]
          if (workingTokenObj.isEditable || mode !== 'auto') foundCandidate = true
        }
      }
      if (foundCandidate) {
        this.currentTokenIndex = workingTokenIndex
        this.currentLetterIndex = 0
        this.$emit('token-selection', {
          tokenIndex: workingTokenIndex,
          letterIndex: 0
        })
        this.scrollResultsWindow()
      }
    },
    moveToPreviousToken (mode = 'auto') { // currently, text panel never uses auto, but leaving it for now
      var workingTokenObj = this.allTokens[this.currentTokenIndex]
      var workingTokenIndex = this.currentTokenIndex
      var foundCandidate = false
      while (workingTokenIndex >= 0 && !foundCandidate) {
        workingTokenIndex -= 1
        if (workingTokenIndex >= 0) {
          workingTokenObj = this.allTokens[workingTokenIndex]
          if (workingTokenObj.isEditable || mode !== 'auto') foundCandidate = true
        }
      }
      if (foundCandidate) {
        this.currentTokenIndex = workingTokenIndex
        this.currentLetterIndex = mode === 'auto' ? 0
          : (Array.isArray(workingTokenObj.text)
              ? workingTokenObj.text.map(p => p.part).join('') : workingTokenObj.text).split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ])/).length - 1
        this.$emit('token-selection', {
          tokenIndex: workingTokenIndex,
          letterIndex: this.currentLetterIndex
        })
        this.scrollResultsWindow()
      }
    },
    moveToTokenMethod (tokenIndex) {
      this.currentTokenIndex = tokenIndex
      this.currentLetterIndex = 0
      this.$emit('token-selection', {
        tokenIndex: tokenIndex,
        letterIndex: 0
      })
      this.scrollResultsWindow()
    }
  }
}
