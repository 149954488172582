export function fetchHack(req, opt) {
  // disabled because Dropbox is having problems
  /*const auth = opt.headers['Authorization']
  delete opt.headers['Authorization']
  const api = opt.headers['Dropbox-API-Arg']
  delete opt.headers['Dropbox-API-Arg']
  if (opt.headers['Content-Type'])
    opt.headers['Content-Type'] = 'text/plain; charset=dropbox-cors-hack'
  const url = new URL(req)
  if (api)
    url.searchParams.set('arg', api)
  if (auth)
    url.searchParams.set('authorization',  auth)*/
  const url = req
  function maybeRetry(r) {
    if (!r.ok && (r.status === 500 || r.status === 400)) {
      // Dropbox has been randomly failing; retry
      // eslint-disable-next-line no-console
      console.error('retrying', r, url)
      return fetch(url, opt)
    }
    return r
  }
  return fetch(url, opt)
    .then(maybeRetry)
    .then(maybeRetry)
}
