import openXml from 'openxml'
import base64js from 'base64-js'

export function loadWordDocFromArrayBuffer (contents) {
  const doc = new openXml.OpenXmlPackage(contents)
  const encoded = base64js.fromByteArray(new Uint8Array(contents))
  // console.log('encoded:')
  // console.log(encoded)
  const source = {
    fileBase64Contents: encoded,
    type: 'docx',
    runArray: doc.mainDocumentPart().getXDocument().descendants(openXml.W.r).toArray().map(o => o.value),
    wordToRunAndPosMap: []
  }
  const docText = doc.mainDocumentPart().getXDocument().descendants(openXml.W.p).toJoinedString('\n', x => x.value)
  return { source: source, text: docText }
}
