import {BlankState} from '../store/stateChanges'

export function toUserDictionaryObjForm(wordList, pinnedWordList, negativeWordList) {
  let uDict = JSON.parse(JSON.stringify(BlankState.tempUserDictionary))
  // console.log('JSON.stringify(uDict):')
  // console.log(JSON.stringify(uDict))
  wordList.forEach(dictString => {
    uDict.entryPresent[dictString] = pinnedWordList.includes(dictString) ? 'pinned': true
  })
  negativeWordList.forEach(dictString => {
    uDict.negativeEntryPresent[dictString] = true
  })
  return uDict
}
