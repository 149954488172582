<template>
  <div class="results-view">
    <ToggleHeader @headerUpdate="updateChange"></ToggleHeader>
    <div class="container" ref="bounder">
        <ResultText :changeY="absoluteTop" @dbl-click="checkClick"></ResultText>
        <div 
          id="resizer" 
          class="draggable-container" 
          v-draggable="draggableValue"
          >
        </div>    
        <MorphologyTable
          v-if="morphDataSet"       
          :changePos="positionChange"
          :dblClick="dblClick"
          id="morph-table">
        </MorphologyTable>
    </div>
    <back-to-text-prep-prompt
      :back-to-text-prep-prompt-props="backToTextPrepPromptProps"
      @hide-back-to-text-prep-prompt="backToTextPrepPromptProps.showBackToTextPrepPrompt = false"
      @back-to-text-prep="navigateNext" />
  </div>  
</template>

<script>
import ToggleHeader from 'shared/components/toggleHeader'
import ResultText from '@/components/ResultText.vue'
import MorphologyTable from '@/components/MorphologyTable.vue'
import { Draggable } from 'draggable-vue-directive'
import BackToTextPrepPrompt from 'shared/components/TextAndToolsPanel/BackToTextPrepPrompt'
import {loadingPromise, saveLocalData, loadLocalData} from "@/js/localData";

let interval = null

export default {
  name: 'Results',
  components: {
    ResultText,
    MorphologyTable,
    ToggleHeader,
    BackToTextPrepPrompt
  },
  data () {
    return {
      dblClick: false,
      laoding: false,
      prevRoute: '',
      positionChange: window.innerHeight - window.innerHeight/1.95 - 130,
      absoluteTop: window.innerHeight/1.95,
      draggableValue: {
        onDragEnd: this.onDragEnd,
        onPositionChange: this.onPosChanged,
        boundingElement: 'bounder-element',
        boundingRectMargin: {'top':140, 'left':0, 'bottom':165, 'right':0}
      },
      backToTextPrepPromptProps: {showBackToTextPrepPrompt: false, navTo: '', proceed: false}
    }
  },
  directives: {
    Draggable,
  },    
  mixins: [],
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('beforeunload', this.beforeUnloadHandler)
    window.clearInterval(interval)
  },
  mounted () {
    loadingPromise.then(() => {
      this.$nextTick(function () {
        if (!this.morphDataSet && !this.dropboxStatus) {
          this.$router.push({ name: "text-edit" })
        }
        if (!this.dropboxStatus) { //get token from dropbox and reload if navigating from breadcrumbs on projects page
          if(this.prevRoute.name === 'Projects')
            this.loadFromDropbox()
        }
        interval = setInterval(this.saveChanges, 60000)      
      })
    })
    document.body.classList.add("results")
    window.addEventListener('resize', this.onResize)
    this.draggableValue.boundingElement = this.$refs.bounder
    window.addEventListener('beforeunload', this.beforeUnloadHandler)
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  beforeRouteLeave (to, from , next) {   
    let projectPath =  to.params.pathMatch !== '' && to.params.pathMatch !== undefined    
    if (this.homePromptDisabled || this.backToTextPrepPromptProps.proceed || !this.morphDataSet || projectPath || (this.dropboxStatus && this.dropboxStatus.has_zip_in_completed)) {
    //if going home clear state
      if (to.path === '/') {
        this.$store.commit('RESET_RESULTS')         
      }
      next()
    } else {    
      next(false)
      this.backToTextPrepPromptProps.navTo = to.path
      this.backToTextPrepPromptProps.showBackToTextPrepPrompt = true
    }    
  },
  computed: {
    homePromptDisabled () {
      return this.$store.state.morphologyApp.postSubmissionOptions.disableBackToTextPrepPrompt
    },
    morphDataSet () {
      return this.$store.state.morphologyApp.morphData.length > 0
    },
    dropboxStatus () {
      return this.$store.state.dropbox.status
    }
  },
  methods: {
    loadFromDropbox () {
      loadLocalData(true)
    },  
    navigateNext (disabled) {
      this.backToTextPrepPromptProps.proceed = true
      if (disabled) 
        this.$store.commit('SET_DISABLE_BACK_TO_TEXT_PREP_PROMPT', true)
        this.$router.push(this.backToTextPrepPromptProps.navTo)
    },
    checkClick (val) {
      this.dblClick = val
    },
    updateChange () {
      var tableHeight = document.getElementById('morph-table').offsetTop
      var dragElm = document.getElementById("resizer")
      if (document.body.classList.contains('d-header-none')) {    
        this.positionChange--
        this.draggableValue.boundingRectMargin = {'top':220, 'left':0, 'bottom':60, 'right':0}
      } 
      else {
        this.positionChange++
        this.draggableValue.boundingRectMargin = {'top':140, 'left':0, 'bottom':165, 'right':0}
        this.onPosChanged()
      }
      dragElm.style.top = tableHeight-20+"px"
    },
    onResize () {
      if (document.body.classList.contains('d-header-none')) {
        this.absoluteTop = window.innerHeight - this.positionChange - 76
      } else {
        this.absoluteTop = window.innerHeight - this.positionChange - 130
      }
      setTimeout(() => {          
        //this.updateChange()
        this.absoluteTop--
        this.positionChange++
        var tableHeight = document.getElementById('morph-table').offsetTop
        var dragElm = document.getElementById("resizer")
        dragElm.style.top = tableHeight-20+"px"
      }, 28)  
    },
    onPosChanged: function(positionDiff, absolutePosition) {
      //this.positionChange = positionDiff.y      
      if(absolutePosition) {
        this.absoluteTop = absolutePosition.top - 100
        this.positionChange = window.innerHeight - this.absoluteTop - 120
      }
    },
    onDragEnd: function() {
      var tableHeight = document.getElementById('morph-table').offsetTop
      var dragElm = document.getElementById("resizer")
      dragElm.style.top = tableHeight-20+"px"        
    },
    beforeUnloadHandler () {
      this.saveChanges()
    },
    saveChanges() {
      saveLocalData()
    }
  }
}
</script>

<style scoped lang="scss">
  .draggable-container {
    width: 100%;
    padding: 10px;
    margin-bottom: -15px;
    background: transparent;   
    left:0;
    top:0;
    overflow: hidden;
    cursor: row-resize;        
  }
</style>