<!--
Button placed on top of screen to hide/show header
-->
<template>
  <div class="toggle-header d-none d-md-block container position-relative">
    <button 
      :class="{'dropbox-loaded':dropboxStatus}"
      class="position-absolute" 
      @click="toggleHeader">
      <i v-if="headerHidden" class="fas fa-angle-down"></i>
      <i v-else class="fas fa-angle-up"></i>      
    </button>
  </div>
</template>        

<script>
  export default {
    name: "ToggleHeader",
    data () {
      return {
        headerHidden: document.body.classList.contains('d-header-none')
      }
    },
    methods: {
      toggleHeader () {
        document.body.classList.toggle("d-header-none")
        this.headerHidden = !this.headerHidden
        this.$emit("headerUpdate")
      }
    },
    computed: {
      dropboxStatus () {
        return this.$store.state.dropbox.status
      }
    }  
  }
</script>

<style scoped lang="scss">
  button {
    width: 36px;
    height: 35px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    border: none;
    font-size: 20px;
    left: -50px;
    top: -5px;
    z-index: 9;
    &:focus {
      outline: none;
    }
  }
  .he  button {
    left: auto;
    right: -50px;
  }
</style>
 