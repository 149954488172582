<template>
  <div class="container">
    <main-input-container :lock-text="$route.params.lockText" />        
  </div>
</template>

<script>
import MainInputContainer from '@/components/MainInputContainer.vue'
//import {Actions} from '@/store/stateChanges'

export default {
  name: 'EditText',
  components: {
    MainInputContainer
  },
  mounted () {
    document.body.classList.remove("results")  
  }
}
</script>
