import { Actions, StateChanges } from './stateChanges'
import { AppConfig } from '../appConfig'
import axios from 'axios'
import { callServerIncrementally } from 'shared/js/callServerIncrementally'

const MORPH_SERVER = AppConfig.SERVER

axios.defaults.headers = {
  'Content-Type': 'text/plain;charset=UTF-8'
}
const axiosInstance = axios.create({ timeout: 60000 })

export default {
  [Actions.RUN_MORPHOLOGY]: function ({ commit, state }) {
    function runSingleChunk(text) {
      const requestObj = {
        task: 'morph',
        data: text,
        genre: state.morphologyApp.textType,
        keepnikud: true,
        keepqq: true,
        keepmetagim: true
      }
      return axiosInstance.post(MORPH_SERVER, JSON.stringify(requestObj))
    }

    function addResults(data, last) {
      commit(StateChanges.ADD_MORPH_RESULTS, data)
      if (!last) {
        commit(StateChanges.NAKDAN_CONTINUING)
      }
    }

    commit(StateChanges.NAKDAN_RUNNING)
    const textForApi = state.morphologyApp.textForNakdanApiCall.trim()
    commit(StateChanges.SET_MORPH_RESULTS, [])
    callServerIncrementally({
      text: textForApi,
      requestFunction: runSingleChunk,
      responseFunction: addResults
    })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error)
        if (error.code === 'ECONNABORTED') {
          commit(StateChanges.NAKDAN_TIMED_OUT)
        } else {
          commit(StateChanges.NAKDAN_FAILED)
        }
      })
  },
  [Actions.RUN_MORPH_ON_WORD]: function ({ commit, state }) {
    let text = state.morphologyApp.morphApiResponse[state.morphologyApp.currentWordIndex].word
    const requestObj = {
      task: 'morph',
      data: text,
      genre: state.morphologyApp.textType,
      keepnikud: false,
      keepqq: true,
      keepmetagim: true,
      //    addmorph: true,
      newjson: true
    }
    axiosInstance.post(MORPH_SERVER, JSON.stringify(requestObj))
      .then(function (response) {
        commit(StateChanges.SET_ALT_RESULTS, response.data)
      })
      .catch(function (error) {
        if (error.code === 'ECONNABORTED') {
          commit(StateChanges.NAKDAN_TIMED_OUT)
        } else {
          console.log(error)
          commit(StateChanges.NAKDAN_FAILED)
        }
      })
  }
}
