import firebase from 'firebase/app'
import 'firebase/auth'
import {ChangeTypes, StateChanges} from './constants'
import {receiveCallbacks} from './firebaseModule'
import Vue from 'vue'

// const FIREBASE_KEY_BADCHARS = [".", "#", "$", "/", "[", "]", "\n"]

// as can be seen at the top of login.vue, the login process is essentially a modal,
// which renders when loginInProgress is true, but it's not a BOOTSTRAP modal, so
// it doesn't add the 'modal-open' class to the body element. Since that class is the way
// shared components (e.g. NewTextPanel/TextPanel for now) determine whether a modal is open,
// it needs to be added or removed "manually" here whenever loginInProgress is changed
function changeLoginInProgressAndReflectInDOM (state, newLoginInProgress) {
  state.loginInProgress = newLoginInProgress
  if (newLoginInProgress) {
    const loginURL = new URL('https://accounts.dicta.org.il/login' +
      (Vue.prototype.$settings.hebrew ? '-he' : '') + '/')
    loginURL.search = new URLSearchParams({
      v: 1,
      r: window.location,
      if: window.location.origin + '/iframe'
    })
    window.location = loginURL
  }
  document.body.classList[newLoginInProgress ? 'add' : 'remove']('modal-open')

}

const accountMutations = {
  [StateChanges.LOGIN_REQUESTED] (state) {
    changeLoginInProgressAndReflectInDOM(state, true)
    // StateSaver.saveState(state)
  },
  [StateChanges.LOGIN_COMPLETE] (state) {
    // firebaseStore('permissionToUseData',
    //   window.localStorage.hasOwnProperty('permission-to-use-pro-nakdan-data') ?
    //     window.localStorage['permission-to-use-pro-nakdan-data'] : 'false')
    changeLoginInProgressAndReflectInDOM(state, false)
    state.isLoggedIn = true
    const allUserData = firebase.auth().currentUser
    // console.log('allUserData:')
    // console.log(allUserData)
    state.userData = {
      name: allUserData.displayName,
      email: allUserData.email,
      passWordedAccount: allUserData.providerData[0].providerId === 'password',
      userId: allUserData.uid
    }
    // StateSaver.saveState(state)
  },
  [StateChanges.LOGIN_CANCELLED] (state) {
    changeLoginInProgressAndReflectInDOM(state, false)
    // StateSaver.saveState(state)
  },
  [StateChanges.LOGIN_CONTINUING] (state) {
    changeLoginInProgressAndReflectInDOM(state, true)
  },
  [StateChanges.LOGGED_OUT] (state) {
    state.isLoggedIn = false
    state.userData = null
    state.firebaseLoaded = true
    state.sync = {}
    // state.userDictionary = JSON.parse(JSON.stringify(BlankState.userDictionary))
    // StateSaver.saveState(state)
  },
  [StateChanges.CHANGE_USER_PASSWORD] (state, args) {
    var user = firebase.auth().currentUser
    user.updatePassword(args.newPassword).then(function() {
      // console.log('Password update successful.')
      args.out.result = 'success'
    }).catch(function(error) {
      // console.error('Error while attempting to update password:', error)
      args.out.result = error.message
    })
  },
  [StateChanges.FIREBASE_INITIALIZE_SYNC] (state, value) {
    state.sync = value
  },
  [StateChanges.FIREBASE_RECEIVED_UPDATE] (state, {keyName, path, value, changeType}) {
    const callback = receiveCallbacks[keyName]
    // we need to call Vue.set/Vue.delete on the object we're modifying, so reduce is used to find that object
    const parentObj = path ? path.reduce((obj, prop) => obj[prop], state.sync) : state.sync
    switch(changeType) {
      case ChangeTypes.SET: Vue.set(parentObj, keyName, callback ? callback(value) : value); break
      case ChangeTypes.REMOVE: Vue.delete(parentObj, keyName); break
    }
  },
  [StateChanges.FIREBASE_LOADED] (state) {
    state.firebaseLoaded = true
  }
}

export default accountMutations
