import readFile from '../js/readFile'
import jszip from 'jszip'

export const loadingZipContentsMessage = '** loading zip contents... **'

async function buildZipContentEntriesArray(zipContents, remainingFilenames) {
  if (!(remainingFilenames.length)) return []
  const filename = remainingFilenames[0]
  const content = await zipContents.file(filename).async('blob').then(async (fileBlob) => {
    const resultFromReadFile = await readFile({ file: fileBlob, name: filename })
    return resultFromReadFile.docText
  })
  const remainingContentEntries = await buildZipContentEntriesArray(zipContents, remainingFilenames.slice(1))
  return [[filename, content]].concat(remainingContentEntries)
}

export async function readZipContentsWithLoadAsync(jszipLoadAsyncReadable) {
  const allContentsObj = await jszip.loadAsync(jszipLoadAsyncReadable).then(async (zipContents) => {
    let contentEntries = await buildZipContentEntriesArray(zipContents, Object.keys(zipContents.files))
    return Object.fromEntries(contentEntries)
  })
  return allContentsObj
}
