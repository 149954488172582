<template>
  <div class="user-log-in text-center rounded m-auto bg-white pt-4" >
    <h1 class="pt-2">{{hebrew ? 'התחבר / הירשם' : 'Login / Sign up'}}</h1>
    <p v-if="hebrew">כדי להתחיל, התחבר / הירשם לאיזור האישי</p>
    <p v-else>Log in or sign up to the Personal area</p>
    <b-btn 
      @click="loginClicked"
      variant="primary" 
      class="mt-3"
      size="lg">
      <small><i-user class="mx-1"/> </small>
      {{hebrew ? 'התחבר / הירשם' : 'Login / Sign up'}}
    </b-btn>
  </div>
</template>

<script>
  export default {
    name: "LoginLogout",
    components: {
    },
    mounted () {
    },
    methods: {        
      loginClicked () {
        this.$store.commit("LOGIN_REQUESTED")
      }
    },
    computed: {
      hebrew () {
        return this.$settings.hebrew
      }  
    }
  }
</script>