<!-- loosely based on vue-spinner -->
<template>
  <div v-bind:class="devMode ? 'bg-danger' : 'bg-primary position-relative second-header-wrap'">
    <div class="container second-header text-white">
      <div class="row">
        <div class="col-6">
          <slot v-if="specialRightSide" name="special-right-side" />
          <div v-else class="logo-div" @click="$emit('logo-clicked')">  
            <div class="logo">
              <slot name="logo" />
              <div class="d-inline-block mx-2">{{ title }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 text-left" v-bind:class="{'center-left-side': centerLeftSide}">
          <slot name="show-shortcuts" />
          <slot name="show-how-to-use" />
        </div>
      </div>
    </div>
  </div>    
</template>

<script>
import {isThisAppInDevMode} from 'shared/js/devMode'
export default {
  name: 'InnerHeader',
  computed: { devMode () { return isThisAppInDevMode(this.appName) } },
  props: {
    appName: {},
    title: {},
    specialRightSide: {
      default: false
    },
    centerLeftSide: {
      default: false
    }
  }
}
</script>

<style lang="scss">
  .second-header-wrap {
    box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 32%);
    z-index: 10;
  }
  .second-header {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .logo-div {
    font-size: 18px;
    height: 36px;
  }

  .logo {
    img {
      max-width: 36px;
      max-height: 100%;
      vertical-align: top;
      cursor: pointer;
    }
    div {
      line-height: 36px;
      cursor: pointer;
    }
  }
  .center-left-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
</style>
