<template>
  <div
    class="results-toolbar container position-relative"
    :class="{'nakdan-internal':internalNakdan}"
    v-hotkey="keymap">
    <div v-if="ifCopyingAims.USER_CONTROLLED || showCopyWithOption"
          class="copy-options-popup results-click-only-here"
          v-show="showCopyOptions">
      <b-form-checkbox v-model="copyWithSelection" v-if="showCopyWithOption">
          העתק טקסט עם פרוט מורפולוגי
      </b-form-checkbox>
      <b-form-checkbox v-model="copyWithAims" v-else>
          {{ (hebrew ? 'העתק טקסט עם  ' : 'Copy text with ') + textPanelToolProps.hideablesName.toLowerCase() }}
      </b-form-checkbox>
    </div>
    <div class="row d-flex flex-nowrap">
      <div class="tool-section" v-if="dropboxStatus && !partnersAccess" @click="saveToDropbox()">
        <span v-b-tooltip.hover :title="hebrew ? 'שמירה בDropbox \n(Ctrl+Shift+S)' : 'Save to Dropbox \n(Ctrl+Shift+S)' ">
        <b-btn
          variant="link" :disabled="dropboxStatus.saving" class="toolbar-item" id="dropbox-btn"><i class="fab fa-dropbox"/></b-btn>
        </span>
      </div>
      <div class="tool-section" v-if="dropboxStatus && partnersAccess" @click="saveToDropbox()">
        <span v-b-tooltip.hover :title="hebrew ? 'שמירה \n(Ctrl+Shift+S)' : 'Save \n(Ctrl+Shift+S)' ">
        <b-btn
          variant="link" class="toolbar-item" id="save-file-btn"><i class="fas fa-save" style="font-size: 16px;"/></b-btn>
        </span>
      </div>
      <save-to-dropbox
        v-if="dropboxModule"
        @text-copied="$emit('text-copied')"
        @download-file="actuallyDownload"
        @saving-and-completing="(statusObj) => $emit('saving-and-completing', statusObj)"
        ref="dropboxSave"
        v-model="showSaveToDropbox"/>
        <b-modal
          v-if="showDropboxConnect"
          id="dropbox-login-modal"
          size="sm"
          hide-backdrop
          hide-header
          body-class="text-center"
          footer-class="border border-top border-secondary py-2"
          v-model="showDropboxConnect"
          ok-only
          :ok-title="hebrew ? 'הבנתי' : 'ok'"
        >
        <div v-if="partnersAccess">
          כדי לבצע שמירה,
          <br>
          יש לייבא טקסט.
        </div>
        <div v-else>
          <span v-if="hebrew">
          כדי לשמור ב Dropbox,<br>
          יש לייבא טקסט מ Dropbox
          </span>
          <span v-else>
            In order to save to Dropbox,<br>Please import text.
          </span>
         </div>
        </b-modal>
      <div class="tool-section export-tool-section col-auto copy-btn">
        <b-button variant="link" class="font-size-inherit toolbar-item selection-non-clearer"
          @click="copy"
          v-b-tooltip.hover :title=" hebrew ? '(Ctrl-C) העתק' : 'Copy (Ctrl-C)'"><i-clone></i-clone>
        </b-button>
        <b-button v-if="ifCopyingAims.USER_CONTROLLED || showCopyWithOption" variant="link" class="font-size-inherit toolbar-item toolbar-subitem results-click-only-here selection-non-clearer" v-bind:disabled="!textPanelToolProps.allowShowingHideables && !showCopyWithOption" @click="copyOptionsButtonClicked"><i-caret-down></i-caret-down></b-button>
      </div>
      <div class="tool-section export-tool-section download-tool-section col-auto">
        <b-button variant="link" class="font-size-inherit toolbar-item" @click="download" v-b-tooltip.hover
          :title="hebrew ? '(Ctrl-D) הורד' : 'Download (Ctrl-D)'"><i-download></i-download>
        </b-button>
      </div>
      <div class="tool-section zoom-tool-section col-auto">
        <b-button variant="link" class="font-size-inherit toolbar-item" @click="zoomIn" v-b-tooltip.hover
          :disabled="textPanelToolProps.fontSize >= styleGlobals.MAX_FONT_SIZE"
          :title="hebrew ? '(Shift-+) הגדל' : 'Increase font size (Shift-+)'"><i-search-plus></i-search-plus>
        </b-button>
        <span class="px-1"></span>
        <b-button variant="link" class="font-size-inherit toolbar-item" @click="zoomOut" v-b-tooltip.hover
          :disabled="textPanelToolProps.fontSize <= styleGlobals.MIN_FONT_SIZE"
          :title="hebrew ? '(Shift--) הקטן' : 'Decrease font size (Shift--)'"><i-search-minus></i-search-minus>
        </b-button>
      </div>
      <div class="tool-section undoredo-tool-section col-auto">
        <span v-b-tooltip.hover :title="hebrew ? '(Shift-Z) ביטול' : 'Undo (Shift-Z)' ">
          <b-button variant="link" class="font-size-inherit toolbar-item" v-bind:disabled="!canUndo" @click="undo">
            <i-share v-if="hebrew"></i-share>
            <i-reply v-else></i-reply>
        </b-button></span>
        <span class="px-1"></span>
        <span v-b-tooltip.hover :title="hebrew ? '(Shift-Y) בצע מחדש' : 'Redo (Shift-Y)' ">
          <b-button variant="link" class="font-size-inherit toolbar-item" v-bind:disabled="!canRedo" @click="redo">
            <i-reply v-if="hebrew"></i-reply>
            <i-share v-else></i-share>
          </b-button></span>
      </div>
      <div v-if="editNikudPopup.PRESENT && internalNakdan" class="px-1 tool-section">
          <b-button variant="link"
                    @click="openEditNikudPopup"
                    class="font-size-inherit tool-section-btn selection-non-clearer"
                    v-b-tooltip :title="'(Shift-E) ' + (hebrew ? 'עריכת טקסט' : 'Edit text')"><i-pencil-alt></i-pencil-alt></b-button>
      </div>
      <div v-if="internalNakdan" class="px-1 tool-section">
          <b-button variant="link"
                    @click="setFlag"
                    class="font-size-inherit tool-section-btn selection-non-clearer"
                    v-b-tooltip title="הוספת דגל"><small><i class="fas fa-flag"></i></small></b-button>
      </div>
      <div class="tool-section col-auto aims-btn" @click.prevent="setShowAims(!localShowAims)">
        <b-form-checkbox inline class="pr-1" v-bind:disabled="!textPanelToolProps.allowShowingHideables" v-bind:checked="localShowAims">
        {{internalNakdan ? '' : hebrew ? 'הצג ' : 'Display ' }}{{ textPanelToolProps.hideablesName.toLowerCase() }}
        </b-form-checkbox>
      </div>
      <div class="tool-section col-auto"  dir="ltr" v-if="resultsLength>0">
        <input
          type="number"
          ref="wordPosition"
          v-model="wordNum"
          @change="onWordNumChange"
          :size="resultsLength.toString().length+1"
          min="1"
          :max="resultsLength"
          class="text-right mr-1"
      /> / {{resultsLength}}
      <i v-if="loading" class="fas fa-spin fa-circle-notch"></i>
      </div>
      <div v-if="editNikudPopup.PRESENT && !internalNakdan" class="tool-section col-auto">
          <b-button variant="link"
                    @click="openEditNikudPopup"
                    class="font-size-inherit tool-section-btn selection-non-clearer"
                    v-b-tooltip :title="'(Shift-E) ' + (hebrew ? 'עריכת טקסט' : 'Edit text')"><i-pencil-alt></i-pencil-alt> {{ hebrew ? 'עריכת טקסט' : 'Edit text' }}</b-button>
      </div>
      <slot /> <!-- for extended toolbar -->
      <div class="tool-section back-tool-section col pl-1">
        <span class="saved-state-msg">{{ savedStateMessage }}</span>
        <b-button id="loadNewTextButton" variant="link"
        v-bind:class="[hebrew ? 'mr-auto' : 'ml-auto', 'font-size-inherit tool-section-btn']"
        v-b-tooltip title = "Alt+N"
        @click="backToTextPrep">
          {{ internalNakdan && hebrew ? 'טקסט חדש' : hebrew ? 'טען טקסט חדש' : 'Enter new text' }}
            <span v-if="hebrew"><i-arrow-alt-circle-left/></span>
            <span v-else><i class="far fa-arrow-alt-circle-right"></i></span>
          </b-button>
      </div>
    </div>
    <b-modal
            v-if="showStillLoading"
            id="text-still-loading"
            size="sm"
            hide-backdrop
            hide-header
            body-class="text-center"
            footer-class="border border-top border-secondary py-2"
            v-model="showStillLoading"
            ok-only
            ok-title="הבנתי"
          >
          עדיין לא סיימנו לטעון את הטקסט.<br>
          נסה שוב בעוד מספר דקות...
    </b-modal>
    <b-modal
            v-if="showMaxpassed"
            id="text-exceeded-modal"
            size="sm"
            hide-backdrop
            hide-header
            body-class="text-center"
            footer-class="border border-top border-secondary py-2"
            v-model="showMaxpassed"
            ok-only
            ok-title="הבנתי"
          >מספר המילה שהזנת אינו קיים<br/>בטקסט
    </b-modal>
    <b-modal centered
              v-model="showDownloadPopup"
              :ok-title="hebrew ? 'הורד' : 'Download'"
              :cancel-title="hebrew ? 'ביטול' : 'Cancel'"
              footer-class="download-footer"
              @ok="actuallyDownload"
              @show="testForQamatzQatan"
    >
      <template slot="modal-header"><div><i-download></i-download><span class="modal-header-title">{{hebrew ? ' הורדת קובץ' : ' Download File'}}</span></div></template>
      <div class="show-download-popup-content">
        <div class="row">
          <div class="col-4 mb-3">{{hebrew ? 'סוג קובץ' : 'Choose file type'}}</div>
          <div class="col-8 mb-3" v-bind:class="{'text-right': hebrew, 'text-left' : !hebrew}">
            <dicta-dropdown v-model="chosenDownloadTypeOption" :options="downloadTypes"
                            display-field="displayText"></dicta-dropdown>
          </div>
        </div>
        <div v-show="chosenDownloadTypeOption.fileTypeName === 'docx' && originalDocumentWasDocx"
              class="row"
        >
          <div class="col-4 mb-3">עיצוב מסמך</div>
          <div class="col-8 mb-3">
            <div @click="keepFormattingOption=true"><b-form-radio id="doKeep" :value="true" v-model="keepFormattingOption"> שמור צורת מקור</b-form-radio></div>
            <div @click="keepFormattingOption=false"><b-form-radio id="doNotKeep" :value="false" v-model="keepFormattingOption"> אל תשמור צורת מקור</b-form-radio></div>
          </div>
        </div>
        <div v-show="chosenDownloadTypeOption.fileTypeName === 'docx' && originalDocumentWasDocx && keepFormattingOption" class="px-3 py-2 small special-gray">
          ייתכנו שיבושים בהורדת קובץ Word.docx עם שמירת צורת מקור. מומלץ לוודא שהטקסט בקובץ תקין לפני שאתם ממשיכים הלאה.
        </div>
        <div class="row" v-if="ifDownloadingAims.USER_CONTROLLED" v-show="textPanelToolProps.allowShowingHideables && !(chosenDownloadTypeOption.fileTypeName === 'csv')">
          <div class="col-4 mb-3">{{ textPanelToolProps.hideablesName }}</div>
          <div class="col-8 mb-3">
            <div @click="downloadWithAimsOption=true"><b-form-radio id="withAims" :value="true" v-model="downloadWithAimsOption"> {{ hebrew ? 'שמור' : 'Include' }} {{ textPanelToolProps.hideablesName.toLowerCase() }}</b-form-radio></div>
            <div @click="downloadWithAimsOption=false"><b-form-radio id="withoutAims" :value="false" v-model="downloadWithAimsOption"> {{ hebrew ? 'אל תשמור' : 'Do not include' }} {{ textPanelToolProps.hideablesName.toLowerCase() }}</b-form-radio></div>
          </div>
        </div>
        <small class="d-block p-2 text-left bg-warning" v-if="chosenDownloadTypeOption.fileTypeName === 'csv'">
        <p>Please Note: We ask that any project which makes use of our morphological tagging output data should please provide credit to Dicta. If the result is a research publication, please reference our Nakdan article (see below) within your publication; if the result is an online site or software product, please credit us in a relevant place (e.g., in the "about" box or in the "help" section) and provide a link to our site:
        <a href="https://dicta.org.il" target="_blank">dicta.org.il</a>.</p>
        <p>The full citation of our Nakdan article is as follows:<br>
        Avi Shmidman, Shaltiel Shmidman, Moshe Koppel, Yoav Goldberg, “Nakdan: Professional Hebrew Diacritizer,” in: Proceedings of the 58th Annual Meeting of the Association for Computational Linguistics: System Demonstrations, July 2020, 197-203:
          <a href="https://www.aclweb.org/anthology/2020.acl-demos.23/" target="_blank">https://www.aclweb.org/anthology/2020.acl-demos.23/</a></p>
        </small>
        <div v-show="chosenDownloadTypeOption.fileTypeName === 'docx' && textIncludesQamatzQatan" class="py-0 qamatz-qatan-font-support-warning">
          <hr class="mt-0 mb-1" />
          * שימו לב - ישנם גופנים שאינם תומכים בקמץ קטן
        </div>
      </div>
    </b-modal>
    <back-to-text-prep-prompt
     :back-to-text-prep-prompt-props="backToTextPrepPromptProps"
     @hide-back-to-text-prep-prompt="showBackToTextPrepPrompt = false"
     @back-to-text-prep="(disablePrompt) => actuallyBackToTextPrep(disablePrompt)" />
  </div>
</template>

<script>
import modalOpen from 'shared/js/modalOpen'
import $ from 'jquery'
import styleGlobals from "shared/js/forTextPanel/styleGlobals"
import BackToTextPrepPrompt from './BackToTextPrepPrompt'
import textPanelToolMixins from './textPanelToolMixins'
import SaveToDropbox from 'shared/dropbox/SaveToDropbox'

const QAMATZ_QATAN = 'ׇ'
const allDownloadTypes = [
  {displayText: 'Word.docx', fileTypeName: 'docx'},
  {displayText: 'Text.txt', fileTypeName: 'text'},
  {displayText: 'WebPage.html', fileTypeName: 'html'},
  {displayText: 'CSV.csv', fileTypeName: 'csv'}
]

export default {
  name: 'TextPanelToolbar',
  mixins: [textPanelToolMixins],
  components: { BackToTextPrepPrompt, SaveToDropbox},
  props: {
    selectedWordNum: { default: 0 },
    textPanelToolProps: {},
    internalNakdan: { default: false },
    loading: { default: false },
    resultsLength: { default: 0 },
    disableKeymap: { default: false }
  },
  data () {
    return {
      wordNum: 1,
      styleGlobals,
      currentSelection: [],
      copyingElement: null,
      showDownloadPopup: false,
      disableBackToTextPrepPromptOption: false,
      downloadTypes: allDownloadTypes.filter(
        t => this.textPanelToolProps.downloadTypes.includes(t.fileTypeName)),
      chosenDownloadTypeOption: allDownloadTypes.filter(
        t => this.textPanelToolProps.downloadTypes.includes(t.fileTypeName))[0], // most copy of above
      keepFormattingOption: true,
      // data items below here are used only by the 'Pro' Nakdan
      showCopyOptions: false,
      copyWithAims: false,
      copyWithSelection: false,
      downloadWithAimsOption: false,
      textIncludesQamatzQatan: false,
      showDropboxConnect: false,
      showSaveToDropbox: false,
      showStillLoading: false,
      showMaxpassed: false
    }
  },
  computed: {
    dropboxModule () { //TODO: check if there is app data to save
      return this.$store && this.$store.state && this.$store.state.dropbox
    },
    dropboxStatus () {
      return this.dropboxModule && this.$store.state.dropbox.status
    },
    partnersAccess () {
      return process.env.VUE_APP_MORPHOLOGY_PARTNERS || process.env.VUE_APP_OCR_PARTNERS
    },
    showCopyWithOption () {
      return this.textPanelToolProps.showCopyWithSelected
    },
    originalDocumentWasDocx () {
      return this.textPanelToolProps.originalDocumentSource
          && this.textPanelToolProps.originalDocumentSource.type === 'docx'
    },
    editNikudPopup () {
      return this.textPanelToolProps.textPanelStyles.appFeatures.editNikudPopup
    },
    savedStateMessage () {
      return this.textPanelToolProps.showSavedStateMessage
              ? (this.hebrew ? 'השינויים נשמרו' : 'The changes have been saved') : ''
    },
    ifCopyingAims () {
      return this.textPanelToolProps.hideablesExportSettings
    },
    ifDownloadingAims () {
      return this.textPanelToolProps.hideablesExportSettings
    },
    // BOTH THE ABOVE (from SimpleVsProConfig.js): USER_CONTROLLED: NakdanType.Pro, FOLLOW_DISPLAY: NakdanType.Simple
    localShowAims () {
      return this.textPanelToolProps.showHideables
    },
    keymap () {
      if (this.disableKeymap) return {}
      var keymapObj = {
        'shift+ctrl+e': () => {
          document.activeElement.blur()
          this.wordNumFocus()
        },
        'shift+ctrl+s': () => {
          this.backgroundSaveToDropbox()
        },
        'shift+c': () => { if (!(modalOpen())) this.copy() },
        'shift+=': (e) => {
          if (!(modalOpen()) && (this.localNikudKeyboard !== 'old' || !(e.getModifierState('CapsLock')))) {
            this.zoomIn()
          }
        },
        'ctrl+=': (e) => {
          if (!(modalOpen()) && (this.localNikudKeyboard !== 'old' || !(e.getModifierState('CapsLock')))) {
            this.zoomIn()
          }
        },
        'shift+-': (e) => {
          if (!(modalOpen()) && (this.localNikudKeyboard !== 'old' || !(e.getModifierState('CapsLock')))) {
            this.zoomOut()
          }
        },
        'ctrl+-': (e) => {
          if (!(modalOpen()) && (this.localNikudKeyboard !== 'old' || !(e.getModifierState('CapsLock')))) {
            this.zoomOut()
          }
        },
        'shift+z': () => { if (!(modalOpen())) this.undo() },
        'ctrl+z': () => { if (!(modalOpen())) this.undo() },
        'shift+y': () => { if (!(modalOpen())) this.redo() },
        'ctrl+y': () => { if (!(modalOpen())) this.redo() },
        'shift+d': () => { if (!(modalOpen())) this.download() },
        'ctrl+d': () => { if (!(modalOpen())) this.download() },
        'alt+n': this.backToTextPrep,
        'enter': () => { if (this.showDownloadPopup) this.actuallyDownload() }
      }
      /* NOT HERE - FREE-EDIT-POPUP HOTKEY RUN FROM ELSEWHERE (not DEPENDENT on toolbar)
      if (this.editNikudPopup.ENABLE) keymapObj['shift+e'] = () => {
        if (!(modalOpen())) this.openEditNikudPopup()
      } */
      return keymapObj
    }
  },
  watch: {
    selectedWordNum: {
      handler(val) {
        this.wordNum = val
      },
      immediate: true
    }
  },
  methods: {
    backgroundSaveToDropbox () {
      if (this.dropboxStatus) {
        this.$refs.dropboxSave.saveToDropbox()
      } else {
       this.showDropboxConnect = true
      }
    },
    saveToDropbox () {
      this.showSaveToDropbox = true
    },
    zoomIn () {
      if (this.textPanelToolProps.fontSize < styleGlobals.MAX_FONT_SIZE) {
        this.$emit('change-font-size', this.textPanelToolProps.fontSize + 1)
      }
      document.activeElement.blur()
    },
    zoomOut () {
      if (this.textPanelToolProps.fontSize > styleGlobals.MIN_FONT_SIZE) {
        this.$emit('change-font-size', this.textPanelToolProps.fontSize - 1)
      }
      document.activeElement.blur()
    },
    onWordNumChange () {
      if (!this.loading) {
        this.showStillLoading = false
        if (this.wordNum > this.resultsLength || this.wordNum < 1) {
          this.showMaxpassed = true
        } else {
          this.$emit('get-word-num', this.wordNum)
        }
      } else {
        if (this.wordNum > this.resultsLength || this.wordNum < 1) {
          this.showStillLoading = true
        }
      }
    },
    wordNumFocus () {
      this.$nextTick(() => {
        this.$refs.wordPosition.focus()
        this.$refs.wordPosition.select()
      })
    },
    testForQamatzQatan () {
      if (this.textPanelToolProps.textPanelStyles.appFeatures.implementQamatzQatan) { // otherwise textIncludesQamatzQatan always stays false!
                                                      // (so no need for condition in font support warning)
        // console.log('running testForQamatzQatan()')
        this.$emit('update-copy-text')
        setTimeout(() => {
          this.textIncludesQamatzQatan = this.textPanelToolProps.copyText.includes(QAMATZ_QATAN)
        }, 1)
      }
    },
    openEditNikudPopup () { // relevant only in "Pro" Nakdan
      this.$emit('open-free-edit-popup')
    },
    setFlag () {
      this.$emit('set-flag')
    },
    setShowAims (a) {
      if (this.textPanelToolProps.allowShowingHideables) this.$emit('change-show-hideables', a)
    },
    copy () {
      var actuallyCopyWithAims
      if (this.ifCopyingAims.USER_CONTROLLED) actuallyCopyWithAims = this.copyWithAims
      if (this.ifCopyingAims.FOLLOW_DISPLAY) actuallyCopyWithAims = this.localShowAims
      if (this.showCopyWithOption) actuallyCopyWithAims = this.copyWithSelection
      this.$emit('copy', actuallyCopyWithAims)
    },
    copyOptionsButtonClicked () {
      if (this.textPanelToolProps.allowShowingHideables || this.showCopyWithOption) this.showCopyOptions = !(this.showCopyOptions)
      document.activeElement.blur()
    },
    download () {
      if (this.textPanelToolProps.textPanelStyles.tools.USE_DOWNLOAD_POPUP) {
        this.showDownloadPopup = true
      } else {
        this.actuallyDownload()
      }
      document.activeElement.blur()
    },
    actuallyDownload () {
      this.showDownloadPopup = false
      var downloadWithAims
      if (this.ifDownloadingAims.USER_CONTROLLED) downloadWithAims = this.downloadWithAimsOption
      if (this.ifDownloadingAims.FOLLOW_DISPLAY) downloadWithAims = this.localShowAims
      this.$emit('download', {
        downloadWithHideables: downloadWithAims,
        keepFormattingOption: this.keepFormattingOption,
        fileTypeName: this.chosenDownloadTypeOption.fileTypeName
      })
    }
  },
  mounted () {
    $(document).on('click', '.results-click-only-here', function (e) {
      e.stopPropagation() // note: only works b/c elements exist at this time!
    })
    $(document).on('click', () => {
      this.showCopyOptions = false
    })
  }
}
</script>
<style lang="scss">
  #dropbox-login-modal,
  #text-still-loading,
  #text-exceeded-modal {
    margin-top: 11%;
    button{width: 100%;}
  }

  .download-footer {
    display: block;
    text-align: right;
    direction: rtl; // so cancel button is ALWAYS on the right
    >:not(:last-child) { // ALWAYS "hebrew" style (because of the above)
        margin-left: .25rem;
        margin-right: 0;
    }
    >:not(:first-child) {
        margin-left: 0;
        margin-right: .25rem;
    }
  }
  body.he .download-footer {
    text-align: left;
  }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
input {
  height: 20px;
  border:none;
  border-radius: 2px;
}
.results-toolbar {
  width: 100%;
  padding: 0;
  &.nakdan-internal {
    background: #e3e3e3;
    .toolbar-item, .tool-section, button {
      color: #4a5057;

    }
    .toolbar-item, button {
       padding-left: 4px;
       padding-right: 4px;
      &:hover {
        background: #f6f6f6;
      }
    }
    .tool-section {
      border-color: transparent;
      position: relative;
      min-width: 45px;
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 27px;
        background: #d8d8d8;
        left: -1px;

      }
    }
  }
}
.results-toolbar .row {
  /*width: 96.5%;*/
  margin: 0;
}
.tool-section {
  border-style: solid;
  border-width: 1px 1px 0 0;
  border-color: #E3E3E3;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-right: 10px;
  padding-left: 10px;
  height: 40px;
}
.tool-section:first-child {
  border-inline-start: 0;
}
.tool-section:last-child {
  border-inline-end: 0;
}
.back-tool-section {
  flex-shrink: 1;
  min-width: 10px;
}
.special-gray {
  background-color: #f6f6f6
}
.tool-section a {
  margin: 2px;
}
.selection-non-clearer {
  padding: 0;
}
.toolbar-item {
  padding: 0 4px;
}
.toolbar-subitem {
  padding: 0 2px !important;
  height: 18px;
  font-size: 12px !important;
  line-height: 18px;
}
.copy-options-popup {
  bottom: 46px;
  right: 6px;
  font-family: Arimo;
  height: 40px;
  padding: 8px 20px;
  font-size: 16px;
  line-height: 24px;
  vertical-align: top;
  position: absolute;
  z-index: 2;
  border-color: transparent;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}
.tool-section-btn {
  padding: 1px 8px;
  border-radius: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.font-size-inherit {
  font-size: inherit;
}
.show-download-popup-content {
  min-height: 200px;
}
.qamatz-qatan-font-support-warning {
  position: absolute;
  bottom: 0;
  width: calc(100% - 32px);
}
.saved-state-msg {
  color: gray;
}
.fa-dropbox{font-size:16px;padding-top:4px;}
.bg-warning {
  direction: ltr;
  a {
    color: #000;
  }
}
</style>
