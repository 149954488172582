export const StateChanges = {
  FIREBASE_INITIALIZE_SYNC: 'FIREBASE_INITIALIZE_SYNC',
  FIREBASE_RECEIVED_UPDATE: 'FIREBASE_RECEIVED_UPDATE',
  FIREBASE_LOADED: 'FIREBASE_LOADED',
  CHANGE_USER_PASSWORD: 'CHANGE_USER_PASSWORD',
  LOGIN_REQUESTED: 'LOGIN_REQUESTED',
  LOGIN_CONTINUING: 'LOGIN_CONTINUING',
  LOGIN_COMPLETE: 'LOGIN_COMPLETE',
  LOGIN_CANCELLED: 'LOGIN_CANCELLED',
  LOGGED_OUT: 'LOGGED_OUT',
}

export const Actions = {
  PROCESS_LOGIN: 'PROCESS_LOGIN',
  LOGOUT: 'LOGOUT'
}

export const ChangeTypes = {
  SET: Symbol(),
  REMOVE: Symbol(),
}
