<template>
  <b-modal
    v-model="show"
    centered
    id="login-needed-modal"
    size="sm"
    hide-header
    footer-class="border-top"
    body-class="py-3 px-4"
    :ok-title="hebrew ? 'התחבר' : 'Log in'"
    :cancel-title="hebrew ? 'סגור' : 'Cancel'"
    @cancel="cancelClicked"
    @ok="loginClicked"
  >
    <div class="text-center">
      {{hebrew ? 'על מנת שהאפשרות המורפולוגית תתווסף אוטומטית למאגר, יש להתחבר לאזור האישי' : 'Oops. Something went wrong'}}
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "LoginNeededPopup",
  props: ["value"], 
  computed: {
    hebrew () {
      return this.$settings.hebrew
    },    
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  methods: {
    cancelClicked () {
      this.$store.commit('DISABLE_LOGIN_POPUP', true)  
      this.$emit('cancelled', true)
    },
    loginClicked () {
      //this.gtag("config", this.googleAnalyticsId, { page_path: "login" })
      this.$store.commit("LOGIN_REQUESTED")
    }
  }
}
</script>
<style lang="scss">
  #login-needed-modal {
    button {
      width: 50%;
    }
  }
</style>