<template>
  <b-modal v-bind:visible="backToTextPrepPromptProps.showBackToTextPrepPrompt"
            @hidden="$emit('hide-back-to-text-prep-prompt')"
            id="back-to-text-modal"
            size="sm"
            :centered="!backToTextPrepPromptProps.mobileStyle"
            hide-header
            :ok-title="hebrew ? 'עבור' : 'Go'"
            :cancel-title="hebrew ? 'ביטול' : 'Cancel'"
            footer-class="back-to-text-footer"
            @ok="actuallyBackToTextPrep"
            auto-focus-button="ok"
            >
    <p v-if="backToTextPrepPromptProps.mobileStyle" class="reload-warning border-bottom border-secondary">
      {{hebrew ? 'בטעינת טקסט חדש, תאבדו את הטקסט הקיים' : 'In order to enter a new text, the current content will be erased. Continue?'}}
    </p>
    <p v-else class="text-center mb-0"
    v-html="hebrew ? 'בטעינת טקסט חדש, תאבדו את <br/>הטקסט הקיים' : 'In order to enter a new text, the current content will be erased. Continue?'"
    ></p>
    <span v-bind:class="{'text-center': !backToTextPrepPromptProps.mobileStyle}"><b-form-checkbox v-model="disableBackToTextPrepPromptOption">
      <span v-bind:class="{'mr-2': backToTextPrepPromptProps.mobileStyle}">
        {{hebrew ? 'אל תציג שוב' : 'Don\'t show again'}}
      </span></b-form-checkbox></span>
  </b-modal>
</template>

<script>
export default {
  name: 'BackToTextPrepPrompt',
  props: ['backToTextPrepPromptProps'],
  data () {
    return {
      disableBackToTextPrepPromptOption: false
    }
  },
  computed: {
    hebrew () {
      return this.$settings.hebrew
    }
  },
  methods: {
    actuallyBackToTextPrep () {
      this.backToTextPrepPromptProps.showBackToTextPrepPrompt = false
      this.$emit('back-to-text-prep', this.disableBackToTextPrepPromptOption)
    }
  },
  mounted () {
    this.disableBackToTextPrepPromptOption = this.backToTextPrepPromptProps.disableBackToTextPrepPrompt
  }
}
</script>
<style lang="scss">
  #back-to-text-modal{
    button {
      width: 50%;
    }
  }
  #back-to-text-modal { // NON media query material
    justify-content: center;
    .back-to-text-footer {
      justify-content: center; // duplicated intentionally
      border-top: 1px solid #e3e3e3;
      direction: rtl; // so cancel button is ALWAYS on the right
      >:not(:last-child) { // ALWAYS "hebrew" style (because of the above)
          margin-left: .25rem;
          margin-right: 0;
      }
      >:not(:first-child) {
          margin-left: 0;
          margin-right: .25rem;
      }
    }
  }
  @media (max-width: 767px) {
   #back-to-text-modal{
     .modal-dialog{
       margin:0;
       .modal-content{
        border-radius: 0;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
        .reload-warning{
          padding-bottom:13px;
        }
        .custom-checkbox .custom-control-label:after, .custom-checkbox .custom-control-label:before {
          width: 1.25rem;
          height: 1.25rem;  
        }
       }
       .back-to-text-footer{
         justify-content: left; 
         padding-top: 0;
         border:none;
         border-radius:0;
          button{
            max-width: 125px;            
          }
       }     
     }
   } 
   .he #back-to-text-modal .modal-dialog .back-to-text-footer{
     justify-content: right;
   }
  }
  #back-to-text-modal button:focus {
    box-shadow: none;
  }
</style>
