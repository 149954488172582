const englishKeyboard = {
  'א': 't',
  'ב': 'c',
  'ג': 'd',
  'ד': 's',
  'ה': 'v',
  'ו': 'u',
  'ז': 'z',
  'ח': 'j',
  'ט': 'y',
  'י': 'h',
  'כ': 'f',
  'ל': 'k',
  'מ': 'n',
  'נ': 'b',
  'ס': 'x',
  'ע': 'g',
  'פ': 'p',
  'צ': 'm',
  'ק': 'e',
  'ר': 'r',
  'ש': 'a',    
  '/': 'q',
  'ם': 'o',
  'ן': 'i',
  'ך': 'l'
}

export default {
  methods: {
    hebrewToEnglish (keyEvt, input) {
     {
      if  (/[\u0590-\u05FF]/.test(keyEvt.key)) {
        keyEvt.preventDefault()
        const startpos = input.selectionStart || 0
        const endpos = input.selectionEnd || startpos
        // replace the selection (in the case of a cursor, it's the same as a selection of 0 characters)
        input.value = input.value.substring(0, startpos) + englishKeyboard[keyEvt.key] + input.value.substring(endpos)
        // put the cursor back where the user is expecting it
        input.setSelectionRange(startpos + 1, startpos + 1)
        // trigger an input event so Vue will update v-model
        input.dispatchEvent(new Event('input', { bubbles: true }))
        return true
      }
    } return false
  }    
 }
} 