<template>
  <b-modal
    id="user-settings"
    ref="user-settings"
    centered
    size="lg"
    cancel-title="סגור"
    cancel-variant="secondary"
    ok-title="שמור שינויים"
    ok-variant="primary"
    @ok="saveChanges"
    :ok-disabled="changesToSave()"
    @show="loadData"
    no-stacking
    body-class="p-0"
    footer-class="border-top border-secondary bg-white"
  > <!-- if/when we support english we'll need a footer class to fix ok and cancel positions (like back-to-text-prompt) -->
    <template slot="modal-header">
      <div><i class="fas fa-user ml-2"></i>
        <span class="modal-header-title">אזור אישי</span>
      </div>
    </template>

    <div class="user-settings-content">
      <div>
        <div class="user-settings-set-selectors">
          <div
            v-for="(wordListSet, ws) in wordListSets"
            :key="'word-list-selector-set'+ws"
            class="user-settings-set-selector"
          >
            <div
              @click="setActiveSet(wordListSet.selectorString), setActiveTab(wordListSet.selectorString)"
              class="user-settings-set-choice"
              v-bind:class="(activeSet === wordListSet.selectorString) ? 'set-selector-active': ''"
            >{{ wordListSet.selectorLabel }}</div>
          </div>
        </div>
        <hr />
        <template v-for="(wordListSet, ws) in wordListSets">
          <div
            v-if="activeSet === wordListSet.selectorString"
            :key="'word-list-content-set'+ws"
            class="px-3 full-height d-flex flex-column"
          >
            <div v-if="activeTab === 'profile'">
              <div class="word-list-content">
                  <small>שם</small>
                  <div class="bg-secondary mb-4 p-1 mt-1 col-5 rounded">{{localUserData.name}}</div>
                  <small>דואר אלקטרוני</small>
                  <div class="bg-secondary mb-4 p-1 mt-1 col-5 email-alignment rounded">{{localUserData.email}}</div>
                  <div v-if="showDropboxId" class="profile-id">
                    <small>מזהה משתמש</small>
                    <div
                      @click="copyId"
                      dir="rtl"
                      class="bg-secondary mb-4 py-1 mt-1 col-5 rounded">{{$store.state.account.userData.userId}}
                      <i-clone
                        v-b-tooltip.hover
                        title="העתק מספר"
                        class="position-absolute"/>
                      </div>
                  </div>
              </div>
            </div>
            <div class="row mb-1" v-if="activeTab !== 'profile'">
              <div class="col-12">
                <div class="word-list-header-bar">
                  <div
                    class="header-letter"
                    v-for="letter in headerLetters"
                    :key="'header-bar-'+letter"
                    v-bind:class="(wordListSet.selectedLetter === letter && usedLetters.length) ? 'selected-letter' : (usedLetters.includes(letter) ? 'used-letter' : 'unused-letter')"
                    @click="headerLetterClicked(letter)"
                  >{{ letter }}</div>
                </div>
              </div>
            </div>
             <ul v-if="activeTab === 'word-list'" class="wordlist-file-buttons p-0 mb-2 mt-2">
                <li class="list-inline-item mr-0">
                  <div  class="d-inline text-primary ml-3" @click="visibleUploadButtonClicked">
                      <u>העלה קובץ</u>
                  </div>
                  <form style="display: none" class="d-inline-flex">
                      <label
                      style="display: none"
                      id="wordlist-file-input-button"
                      for="wordlist-file-input"
                      class=""
                      >
                      העלה קובץ
                      </label>
                      <input
                      type="file"
                      name="name"
                      id="wordlist-file-input"
                      class="inputfile inputfile-2 d-none"
                      ref="wordlistInputEl"
                      @change="loadInputtedWordlistFile"
                      />
                  </form>
                </li>
                <li class="list-inline-item" v-if="usedLetters.length">
                  <div class="d-inline text-primary" @click="downloadWordList">
                      <u>הורד קובץ</u>
                  </div>
                </li>
            </ul>
            <div class="no-words-notification text-center mt-4" v-if="!usedLetters.length && activeTab !== 'profile'">לא קיימות מילים להצגה</div>
            <div class="word-list-content" v-if="activeTab !== 'profile' && usedLetters.length">
              <div
                v-for="(letter, le) in usedLetters"
                :key="'header-'+letter"
                class="word-list-letter-wrapper"
              >
                <p
                  class="content-letter-bar"
                  v-bind:class="(wordListSet.selectedLetter === letter) ? 'selected-letter-bar' : ''"
                >
                  {{
                  letter
                  }}
                </p>
                <div
                  v-for="(entryObj, w) in wordListsByLetter[ws][letter]"
                  :key="'word-'+w+'-of-letter-'+letter"
                  class="content-word"
                  @mouseover="hoveredWord = w; hoveredLetter = le"
                  @mouseout="hoveredWord = -1; hoveredLetter = -1"
                  v-bind:class="{ 'content-word-hovered': hoveredWord === w && hoveredLetter === le }"
                >
                  <div
                    v-show="hoveredWord === w && hoveredLetter === le"
                    style="float: left; cursor: pointer"
                  >
                    <span
                      v-show="w > 0 && withoutNikudEqual(wordListsByLetter[ws][letter][w - 1].displayText, entryObj.displayText)"
                      class="dictionary-button"
                      @click="makeWordTopOption(entryObj.dictString)"
                    >
                      <i-arrow-up class="up-arrow"></i-arrow-up>
                    </span>
                      <span
                        @click="i=wordListSet.wordList.indexOf(entryObj.dictString); wordListSet.wordList=wordListSet.wordList.slice(0, i).concat(wordListSet.wordList.slice(i+1)); removeFromList(entryObj.dictString, ws)"
                      >
                      <span class="dictionary-button">
                        <i-times></i-times>
                      </span>
                    </span>
                  </div>
                  <p class="mb-2" style="width:97%;"><span v-html="display(entryObj.displayText, ws, true)" /><span v-if="entryObj.afterWordConditionString" style="color:pink"> {{ entryObj.afterWordConditionString }}</span></p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div v-show="activeTab === 'nikud-keyboard'">
        <h3 style="color: black">בחר מקלדת ניקוד</h3>
        <ul class="keyboard-list">
          <li>
            <p>
              <input type="radio" id="use-blind-keyboard" value="blind" v-model="myNikudKeyboard" />
              שיטה
              ישנה
            </p>
            <!-- <img src="@/assets/keyboard-keys-blind.png" /> -->
          </li>
          <li>
            <p>
              <input type="radio" id="use-old-keyboard" value="old" v-model="myNikudKeyboard" />
              שיטה
              ישנה
            </p>
            <!-- <img src="@/assets/keyboard-keys-old.png" /> -->
          </li>
          <li>
            <p>
              <input type="radio" id="use-new-keyboard" value="new" v-model="myNikudKeyboard" />
              שיטה
              חדשה
            </p>
            <!-- <img src="@/assets/keyboard-keys-new.png" /> -->
          </li>
        </ul>
      </div>
      <div
        id="doc-uploaded-modal"
        class="doc-uploaded-modal modal-content modal-sm p-3"
        v-if="docUploadedModal"
      >
        <p class="text-center mb-2" dir="rtl">
          ניתן להעלות קובץ בפורמט json, txt.
          <br />בקובץ txt יש להזין מילה בשורה
        </p>
        <span class="text-center" dir="rtl">
          <b-form-checkbox v-model="disableUploadDictionaryPromptOption">אל תציג שוב</b-form-checkbox>
        </span>
        <div class="doc-uploaded-modal-footer row">
          <div class="col-6 px-2">
            <button
              type="button"
              class="btn btn-secondary btn-block"
              @click="hideUploadModal"
            >ביטול</button>
          </div>
          <div class="col-6 px-2">
            <form class>
              <label class="btn btn-primary btn-block mb-0" @click="modalUploadButtonClicked">
                <i class="fa fa-upload p-1"></i>
                העלה קובץ
              </label>
            </form>
            <!--<button type="button" class="btn btn-primary btn-block"><i class="fa fa-upload pl-2"></i>העלה קובץ</button>-->
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
// @ is an alias to /src
import dictionaryMixins from "@/mixins/dictionaryMixins"; // note this "reaches into" the "host app"
import _ from "lodash"; //TODO: remove
import $ from "jquery";
import { stringWithoutNikud } from "shared/js/commonHebrew";
import { toUserDictionaryObjForm } from "@/js/toUserDictionaryObjForm"; //TODO: can be shared
// import {deepdiffMapper} from '@/js/deepdiffMapper' // for debugging "changesToSave"
import conditionalOptions from 'shared/js/conditionalOptions'
const alefBetLetters = "קראטופשדגכעיחלזסבהנמצת".split("").sort();
const METEG = "ֽ";
export default {
  name: "UserSettingsPopup",
  mixins: [
    dictionaryMixins /* for removeFromList, localCurrentWordIndex, localCurrentLetterIndex, locaUserDictionary,
                        displayDictionaryText(), hostGoogleAnalyticsId and hostGtag() */
  ],
  props: ['tab', 'dictionaryHeading', 'negativeDictionaryHeading'],
  data() {
    return {
      //activeTab: this.active,
      //activeSet: this.active,
      myNikudKeyboard: "old",
      headerLetters: alefBetLetters,
      docUploadedModal: false,
      hoveredLetter: -1,
      hoveredWord: -1,
      wordListSets: [
        {
          selectorLabel: "פרופיל",
          selectorString: "profile",
          selectedLetter: "א",
          wordList: [] // initialize when the modal is displayed
        },
        {
          selectorLabel: this.dictionaryHeading ? this.dictionaryHeading : "מאגר מילים",
          selectorString: "word-list",
          selectedLetter: "א",
          wordList: [] // initialize when the modal is displayed
        },
        {
          selectorLabel: this.negativeDictionaryHeading ? this.negativeDictionaryHeading : "מילים פגומות",
          selectorString: "negative-word-list",
          selectedLetter: "א",
          wordList: [] // initialize when the modal is displayed
        }
      ],
      pinnedWordList: [], // initialize when the modal is displayed
      disableUploadDictionaryPromptOption: false
    }
  },
  computed: {
    activeTab(){
      return this.tab.activeTab
    },
    activeSet(){
      return this.tab.activeSet
    },
    showDropboxId () {
      return (process.env.VUE_APP_MORPHOLOGY_PARTNERS || process.env.VUE_APP_OCR_PARTNERS) && this.$store.state.account.userData
    },
    localUserData() {
        return this.$store.state.account && this.$store.state.account.userData
          ? this.$store.state.account.userData : {name: '', email: ''}
    },
    usedLetters() {
      return alefBetLetters.filter(
        letter =>
          this.activeWordListSet.wordList.filter(f => f.startsWith(letter))
            .length > 0
      );
    },
    wordLists() {
      return this.wordListSets.map(wset => wset.wordList);
    },
    activeWordListSet() {
      return this.wordListSets.filter(
        wset => this.activeSet === wset.selectorString
      )[0];
    },
    wordListsToUserDictionaryObjForm() {
      return toUserDictionaryObjForm(this.wordLists[1], this.pinnedWordList, this.wordLists[2]);
    },
    xchangesToSave() { //TODO move to mixins
      return _.isEqual(this.wordListsToUserDictionaryObjForm, this.localUserDictionary)
    },
    wordListsByLetter() {
      var listSets = [];
      this.wordLists.forEach(wordList => {
        var listSet = {};
        wordList.forEach(dictString => {
          var entryObj = conditionalOptions.optionObjFromDictString(dictString)
          entryObj['afterWordConditionString']
            = conditionalOptions.afterWordConditionString(entryObj.conditions)
          // if (entryObj.afterWordConditionString) console.log('afterString: ' + entryObj.afterWordConditionString)
          entryObj['dictString'] = dictString
          entryObj['sortingText'] = stringWithoutNikud(entryObj.displayText + ' ' + entryObj.afterWordConditionString)
          const letter = entryObj.displayText[0]
          if (!listSet[letter]) {
            listSet[letter] = [];
          }
          listSet[letter].push(entryObj)
        });
        for (let letterList of Object.values(listSet)) {
          letterList.sort((a, b) => {
            return a.sortingText.localeCompare(b.sortingText)
          })
        }
        listSets.push(listSet);
      });
      return listSets;
    }
  },
  methods: {
    loadData(){
      this.$nextTick(function () {
        this.resetData()
        if (this.$store.state.account && this.$store.state.account.isLoggedIn) {
           /*  this.hostGtag("config", this.hostGoogleAnalyticsId, {
                page_path: "usersettings"
            }); */
            //console.log('logged in')
            if (this.localUserDictionary.lettersMap)
              this.wordListSets[1].wordList = Object.values(this.localUserDictionary.lettersMap).flat()
            if (this.localUserDictionary.negativeLettersMap)
              this.wordListSets[2].wordList = Object.values(this.localUserDictionary.negativeLettersMap).flat()
            this.pinnedWordList = Object.keys(this.localUserDictionary.entryPresent)
                                        .filter(k => this.localUserDictionary.entryPresent[k] === 'pinned')
            this.disableUploadDictionaryPromptOption = this.postSubmissionsPrompt //TODO move to mixins computed
            setTimeout(() => { // wait for wordListsByLetter to update
                this.wordListsByLetter.forEach((listSet, ls) => {
                const relevantLetters = Object.keys(listSet);
                if (relevantLetters.length)
                    this.wordListSets[ls].selectedLetter = relevantLetters[0];
                });
            }, 5);

        }
      })
    },
    hideUploadModal() {
        this.docUploadedModal = false
    },
    withoutNikudEqual(w1, w2) {
      return stringWithoutNikud(w1) === stringWithoutNikud(w2);
    },
    headerLetterClicked(letter) {
      if (
        letter === this.activeWordListSet.selectedLetter ||
        !this.usedLetters.includes(letter)
      ) {
        // do nothing
      } else {
        this.activeWordListSet.selectedLetter = letter;
        setTimeout(() => {
          document
            .getElementsByClassName("selected-letter-bar")[0]
            .scrollIntoView();
        }, 5);
      }
    },
    setActiveTab(selectorString) {
      this.tab.activeTab = selectorString;
      if (
        this.activeTab === "word-list" ||
        this.activeTab === "negative-word-list"
      )
        setTimeout(() => {
          const selectedLetter = document.getElementsByClassName(
            "selected-letter-bar"
          );
          if (selectedLetter.length) selectedLetter[0].scrollIntoView();
        }, 50);
    },
    setActiveSet(selectorString) {
      this.tab.activeSet = selectorString;
      setTimeout(() => {
        const selectedLetter = document.getElementsByClassName(
          "selected-letter-bar"
        );
        if (selectedLetter.length) selectedLetter[0].scrollIntoView();
      }, 50);
    },
    makeWordTopOption(word) {
      var tempArray = this.activeWordListSet.wordList.slice(); // make a copy (esp. b/c all just strings)
      var relevantOptionsFromUserDictionary = this.activeWordListSet.wordList.filter(
        otherWord => stringWithoutNikud(otherWord) === stringWithoutNikud(word)
      );
      var o = relevantOptionsFromUserDictionary.indexOf(word);
      var tempOp = relevantOptionsFromUserDictionary[0];
      tempArray[tempArray.indexOf(tempOp)] = word;
      relevantOptionsFromUserDictionary.slice(1, o).forEach(op => {
        tempArray[tempArray.indexOf(op)] = tempOp;
        tempOp = op;
      });
      tempArray[this.activeWordListSet.wordList.indexOf(word)] = tempOp;
      this.activeWordListSet.wordList = tempArray;
    },
    downloadWordList() {
      var text = JSON.stringify(
        this.activeWordListSet.wordList.map(word => {
          return {
            beforeNikud: stringWithoutNikud(word),
            afterNikud: word
          };
        })
      );
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute(
        "download",
        "nakdan-" +
          this.activeWordListSet.selectorString +
          Math.floor(Math.random() * 100000000) +
          ".txt"
      );
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    loadInputtedWordlistFile() {
      var f = this.$refs.wordlistInputEl[0].files[0];
      if (f) {
        var r = new FileReader();
        r.onload = e => {
          var contents = e.target.result.replace(/\r/g, "");
          var newDictObj = [];
          if (!contents.length || contents[0] !== "[") {
            newDictObj = contents.split("\n");
          } else {
            var jsonDictList = JSON.parse(contents);
            jsonDictList.forEach(jsonDict => {
              if (jsonDict.hasOwnProperty("beforeNikud")) {
                var remainingInAfterNikud = jsonDict.afterNikud;
                var newAfterNikud = "";
                jsonDict.beforeNikud.split("").forEach(c => {
                  if (
                    remainingInAfterNikud.length &&
                    c === remainingInAfterNikud[0]
                  ) {
                    var i = remainingInAfterNikud
                      .slice(1)
                      .search(/[^ְֱֲֳִֵֶַָׇֹֻּֽׁׂ]/);
                    if (i === -1) {
                      newAfterNikud += remainingInAfterNikud;
                      remainingInAfterNikud = "";
                    } else {
                      newAfterNikud += remainingInAfterNikud.slice(0, i + 1);
                      remainingInAfterNikud = remainingInAfterNikud.slice(
                        i + 1
                      );
                    }
                  } else {
                    if ("אהוי".includes(c)) {
                      newAfterNikud += c + METEG;
                    } /* else
                      console.log(
                        "non-match!: " +
                          c +
                          " vs. " +
                          (!remainingInAfterNikud.length
                            ? "empty string"
                            : remainingInAfterNikud[0])
                      ); */
                  }
                });
                /* if (remainingInAfterNikud.length)
                  console.log(
                    "leftover chars in afterNikud!: " + remainingInAfterNikud
                  ); */
                newDictObj.push(newAfterNikud);
              } else newDictObj.push(jsonDict.afterNikud);
            });
          }
          this.activeWordListSet.wordList = newDictObj;
        };
        r.readAsText(f);
      } else {
        alert("Failed to load file");
      }
    },
    xsaveChanges() { //TODO move to mixins
    },
    clickHiddenUploadButton() {
      $("#wordlist-file-input-button").click();
    },
    visibleUploadButtonClicked() {
      if (this.disableUploadDictionaryPromptOption) {
        this.clickHiddenUploadButton();
      } else this.docUploadedModal = true;
    },
    modalUploadButtonClicked() {
      if (this.disableUploadDictionaryPromptOption)
        this.$store.commit("SET_DISABLE_UPLOAD_DICTIONARY_PROMPT", true);
      this.docUploadedModal = false;
      this.clickHiddenUploadButton();
    },
    copyId () {
      const el = document.createElement('textarea')
      el.id = "copyText"
      document.getElementsByClassName("modal-body")[0].appendChild(el)
      document.getElementById('copyText').value = this.$store.state.account.userData.userId
      document.getElementById('copyText').focus()
      document.getElementById('copyText').select()
      document.execCommand('copy')
      document.getElementsByClassName("modal-body")[0].removeChild(el)
      $("#text-copied-msg p").text('המזהה הועתק בהצלחה')
      $("#text-copied-msg").fadeIn()
      setTimeout(() => { $("#text-copied-msg").fadeOut() }, 2000)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* User-Settings-specific CSS goes here */
.user-settings-screen {
  text-align: right;
  background-color: #f6f6f6;
  height: 80%;
  padding-right: 10%;
  padding-left: 10%;
}
.user-settings-header {
  font-size: 18px;
  margin-bottom: 10px;
}
.user-settings-selector {
  background: #f6f6f6;
  padding: 5px;
}
.user-settings-selector {
  position: relative;
  height: calc(
    100vh - 180px
  ); /* instead of: calc(100vh - 220px); (now using two selectors) */
}
.user-settings-content {
  position: relative;
  height: calc(100vh - 180px);
}
.profile-id {
  i {
    top: 8px;
    left: 12px;
  }
  div {
    cursor: pointer;
  }
}
.tab-selector-active {
  background-color: white;
}
.user-settings-content {
  background-color: #fff;
}
.user-settings-choice {
  padding: 4px 8px;
  text-align: right;
  font-family: Arimo;
  cursor: pointer;
  border-radius: 3px;
  background-color: #d6ecff;
  color: #007ee5;
  cursor: pointer;
}
.user-settings-footer-btn {
  min-width: 121px;
  margin-right: 10px;
}
.user-settings-choice p {
  /*margin: 0;*/
}
/*
    .user-settings-content {
        padding: 10px;
    }
*/
.user-settings-content h3 {
  /*padding: 10px;*/
}
.user-settings-content img {
  /*height: 100px;*/
  /*margin-bottom: 20px;*/
  /*margin-right: 20px;*/
}
u{
  cursor:pointer;
}
.header-letter {
  text-align: center;
  width: 28px;
  border: solid 1px #e3e3e3;
  height: 28px;
  display: inline-block;
  line-height: 1.6;
}
.selected-letter {
  color: #fff;
  background-color: #007ee5;
  border-color: #007ee5;
}
.used-letter {
  color: #000;
  background-color: #fff;
  border-color: #e3e3e3;
  cursor: pointer;
}
.unused-letter {
  color: #cfd3d8;
  background-color: #fff;
  border-color: #e3e3e3;
}
.header-letter:first-child {
  border-radius: 0 2px 2px 0;
}
.header-letter:last-child {
  border-radius: 2px 0 0 2px;
}
hr {
  margin-top: 0px;
}
.word-list-content {
  width: 100%;
  height: calc(100vh - 325px);
  overflow-y: auto;
  overflow-x: hidden;
  flex: auto;
  margin-left: -10px;
}
.word-list-letter-wrapper {
  width: 105%;
}
.content-letter-bar {
  background-color: #f6f6f6;
  padding: 0 8px;
  margin-left: 8px;
}
.content-word {
  width: 96%;
  font-family: mft_narkisclassic;
  font-size: 18px;
  padding: 0 8px;
  margin-left: 8px;
}
.content-word-hovered {
  background-color: #e3e3e3;
}
.user-settings-set-selectors {
  display: block;
}
.user-settings-set-selector {
  display: inline-block;
  width: 115px;
  cursor: pointer;
  text-align: center;
  padding: 0px;
}
.user-settings-set-choice {
  padding: 8px;
  color: #c0c0c0;
}
.set-selector-active {
  border-top: 3px solid #007ee5;
  color: black !important;
}
#doc-uploaded-modal{
    top: 50%;
    margin-top:-45px;
    position: absolute;
    left: 50%;
    margin-left: -150px;
}
.doc-uploaded-modal-footer {
  border-top: 1px solid #e3e3e3;
  justify-content: center;
  padding-top: 15px;
  margin-top: 15px;
}
@media (max-width: 991px) {
  .user-settings-content,
  .user-settings-selector {
    height: auto;
  }
}
.dictionary-link {
  margin-left: 4px;
  font-family: Arimo;
  font-size: 14px;
  text-decoration: underline;
}
</style>
