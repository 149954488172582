<template>
  <div class="nikud-options-dialog" v-hotkey="keymap">
    <b-modal id="nikud-options-modal" v-if="show" v-model="show" centered size="lg" footer-class="text-left border-top"
      header-class="d-block pb-1 pt-0" ok-title="שמור וסגור" cancel-title="ביטול" @ok="replaceOptions"
      @hide="removeAlternateOptions">
      <template slot="modal-header">
        <span class="f-narkis ml-2">אָ</span>
        שינוי ניקוד
      </template>
      <spinner v-if="alternateResults.length == 0" />
      <small class="d-block mb-1">שינוי של צורת הניקוד, תשפיע על האפשרויות המורפולוגיות.</small>
      <ul class="p-0 m-0">
        <li v-for="(word, i) in alternateWordOptions" tabindex="0" ref="listItem" :key="i" @click="setActiveResult(i)"
          @keyup.tab="setActiveResult(i)"
          :class="{ 'is-active text-primary': (word.replace('|', '') === currentWord && activeIndex == -1) || (i == activeIndex && activeIndex > -1) }"
          class="result px-3 ml-3 my-2 f-narkis position-relative d-inline-block rounded-pill">
          <span :class="{ 'prefix': word.split('|').length > 1 }" v-html="word.split('|')[0]"></span><span
            v-html="word.split('|')[1]"></span>
        </li>
      </ul>
      <small class="position-absolute">
        "<span :class="{ 'prefix': currentWord.split('|').length > 1 }" v-html="currentWord.split('|')[0]"></span><span
          v-html="currentWord.split('|')[1]"></span>"
        {{ currentSelectedWordNum }}/{{ resultsLength }}
      </small>
      <b-form-input size="lg" class="my-2 border-dark" style="border-radius:4px" v-model="manualNikudVal"
        placeholder="מילה עם ניקוד"></b-form-input>
    </b-modal>
  </div>
</template>     

<script>
import Spinner from 'shared/components/spinner'
import { getMorphologyStrings, checkAramaic } from '../js/morphologies'
import { stringWithoutNikud } from 'shared/js/commonHebrew'
import { RunStates } from '@/store/runStates'
export default {
  name: "NikudOptionsDialog",
  props: ["value"],
  components: { Spinner },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val)
      }
    },
    currentWordIndex() {
      return this.$store.state.morphologyApp.currentWordIndex
    },
    morphData() {
      return this.$store.state.morphologyApp.morphData
    },
    currentSelectedWordNum() {
      return this.morphData[this.currentWordIndex] ? this.morphData[this.currentWordIndex].wordIndex : 1
    },
    currentWord() {
      return this.morphData[this.currentWordIndex] ? this.morphData[this.currentWordIndex].text : ""
    },
    alternateResults() {
      return this.$store.state.alternateResults
    },
    alternateWordOptions() {
      const unique = [...new Set(this.alternateResults.map(item => item.w))];
      return unique
    },
    resultsLength() {
      return this.$store.state.morphologyApp.morphApiResponse.filter(ms => ms.sep === false).length
    },
    nakdanError() {
      return this.$store.state.nakdanApiState !== RunStates.COMPLETE
    },
    keymap() {
      return {
        'enter': () => {
          if (this.show) {
            this.replaceOptions()
            this.$bvModal.hide('nikud-options-modal')
          }
        }
      }
    }
  },
  data() {
    return {
      activeIndex: -1,
      manualNikudVal: ''
    }
  },
  methods: {
    replaceOptions() {
      if (this.activeIndex > -1) {
        let morphArrs = this.alternateResults.filter(element => element.w === this.alternateWordOptions[this.activeIndex])
        let options = morphArrs.map(morphArr => ({
          morphOptions: getMorphologyStrings(morphArr.morph, false),
          word: morphArr.lex,
          returnedWord: morphArr.w ? morphArr.w : this.morphData[this.currentWordIndex].text,
          lexEnabled: true,
          fromServer: true,
          flag: [],
          onlyNikud: true,
          aramaic: checkAramaic(morphArr.morph, false),
          prefixLength: stringWithoutNikud(morphArr.w).indexOf('|') > -1 ? stringWithoutNikud(morphArr.w).indexOf('|') : 0
        }))
        this.$store.commit("SET_MORPH_OPTIONS", options)
        this.$store.commit("UPDATE_SINGLE_MORPH", morphArrs)
      } else if (this.manualNikudVal.trim().length > 0) {
        this.$store.commit("SET_MORPH_OPTIONS", [])
        let newObj = {
          NGramID: -1,
          word: "",
          morph: "",
          w: this.manualNikudVal
        }
        this.$store.commit("UPDATE_SINGLE_MORPH", [newObj])
      }
    },
    setActiveResult(index) {
      this.activeIndex = index
    },
    removeAlternateOptions() {
      this.$store.commit("REMOVE_ALT_RESULTS")
      this.activeIndex = -1
    }
  }
}
</script>
<style lang="scss">
#nikud-options-modal {
  .modal-header {
    font-size: 18px !important;
  }

  small {
    bottom: -33px;
  }

  .f-narkis {
    font-size: 25px;
  }

  ul {
    min-height: 150px;

    li {
      border: solid 1px #d7d7d7;
      cursor: pointer;
      height: 36px;
      line-height: 1.16;

      &.is-active {
        background-color: #d6ecff;
        border: solid 1px #d6ecff;
      }
    }
  }

  .modal-footer {
    height: 50px;
    padding: 12px;

    button {
      min-width: 107px;
      margin-bottom: 2px;
    }
  }
}
</style>
