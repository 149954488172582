const CONDITIONAL_DICTIONARY_ENTRY_SEPARATOR = "'ו'א'ח'ר'י'ו'"
const condTypes = ['nearby-word-property-value']

function optionObjFromDictString (dictString) {
  const components = dictString.split(CONDITIONAL_DICTIONARY_ENTRY_SEPARATOR)
  // assumes type is nearby-word-property-value, since it's now the only one
  return {
    displayText: components[0],
    conditions: components.slice(1).map((conditionWord, cw) => {
      return {
        type: condTypes.indexOf('nearby-word-property-value'),
        offset: cw + 1,
        property: 'originalWord',
        value: conditionWord
      }
    }),
    prefixEndIndex: -1,
    morphArray: []
  }
}

function dictStringFromOptionObj (objectObj) {
  const conditionsArray = objectObj.conditions ? objectObj.conditions : []
  if (conditionsArray.every(cond => cond.type === condTypes.indexOf('nearby-word-property-value'))) {
    return [objectObj.displayText]
              .concat(conditionsArray.sort((cond1, cond2) => cond1.offset - cond2.offset)
                                     .map(cond => cond.value)
                      ).join(CONDITIONAL_DICTIONARY_ENTRY_SEPARATOR)
  }
}

function testConditions (conditionsArray, wordsArray, currentWordIndex) {
  return conditionsArray.filter(cond => cond.type === condTypes.indexOf('nearby-word-property-value'))
                        .every(cond => (currentWordIndex + cond.offset) < wordsArray.length
                                        && wordsArray[currentWordIndex + cond.offset][cond.property] 
                                        === cond.value)
}

function dictStringFromAfterWordComponentsArray (componentsArray) {
  return componentsArray.join(CONDITIONAL_DICTIONARY_ENTRY_SEPARATOR)
}

function afterWordConditionString (conditionsArray) {
  return conditionsArray.filter(cond => cond.type === condTypes.indexOf('nearby-word-property-value')
                                        && cond.offset > 0)
                        .sort((cond1, cond2) => cond1.offset - cond2.offset)
                        .map(cond => cond.value).join(' ')
}

export default {
  optionObjFromDictString, dictStringFromOptionObj,
  testConditions, dictStringFromAfterWordComponentsArray, afterWordConditionString
}
