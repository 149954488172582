<template>
  <div id="pane-wrap" class="right-pane" v-bind:class="{'iphone': iOS,
                                                        'right-pane-desktop': mainInputStyle.Desktop,
                                                        'right-pane-mobile': mainInputStyle.Mobile,
                                                        frame: lockText,
                                                        'right-pane-nonframe': !lockText,
                                                        'border-dark': !lockText}" v-hotkey="keymap">
    <textarea
      class="main-input-box text-right"
      id="text-wrap"
      v-model="areaText"
      @input="$emit('edit-document-text', areaText)"
      @focus="hideHeader"
      @focusout ="showHeader"
      :disabled="lockText"
      :class="{ locked: lockText }"
      :placeholder=" lockText ? '' : hebrew ? 'הזן טקסט כאן' : 'Enter Hebrew text' "
     />
    <div class="clear-main-input-div" v-show="!lockText && areaText.length" @click="areaText=''"><i-times /></div>
    <form ref="fileForm" v-if="allowDocumentLoad && (!lockText || allowDocumentLoad === 'always')">
      <label for="main-file-input" class="bottom-button load-button btn btn-secondary"><i-file></i-file> {{ hebrew ? 'העלה קובץ' : 'Upload file' }}</label>
      <input type="file" name="name" id="main-file-input" class="inputfile inputfile-2 d-none" ref="mainInputEl" @change="loadInputtedFile">
    </form>
    <div v-if="submitRunnerInMainInput" class="bottom-button submit-runner-div">
      <slot name="submit-runner" />
    </div>
    <spinner v-if="loadingZipContents" />
  </div>
</template>

<script>
import $ from 'jquery'
import readFile from '../../js/readFile'
import {loadingZipContentsMessage} from 'shared/js/readZipContents'
import Spinner from 'shared/components/spinner'

export default {
  name: 'MainInput',
  components: {
    Spinner
  },
  props: {
    mainInputStyle: {
      default: () => { return { Mobile: window.innerWidth < 992, Desktop: window.innerWidth >= 992 } }
    },
    submitRunnerInMainInput: {
      default: false
    },
    startingText: {
      default: ''
    },
    allowDocumentLoad: {
      default: true
    },
    lockText: {
      //default: false
      default: () => { return  process.env.VUE_APP_MORPHOLOGY_PARTNERS }
    }
  },
  data () {
    return {
      areaText: '',
      loadingZipContents: false,
      iOS: false,
      displayPromotions: false
    }
  },
  computed: {
    hebrew () {
      return this.$settings.hebrew
    },
    keymap () {
      return {
        'ctrl+enter': () => { document.activeElement.blur() }
      }
    }
  },
  watch: {
    startingText (newStartingText) { // if it's changed, that can be to send a message
      if (newStartingText === loadingZipContentsMessage) {
        this.loadingZipContents = true
        this.areaText = ''
      } else if (this.loadingZipContents) {
        this.loadingZipContents = false
        this.areaText = newStartingText // next text after load is new text
      }
    }
  },
  methods: {
    hideHeader () {
      if(this.iOS) {
        document.getElementById('pane-wrap').classList.add('focused')
        document.getElementById('home-header').classList.add('d-none')
        var promotions = document.getElementsByClassName("promotion") != null
        if (promotions) {
          document.getElementById('promotions').classList.add('d-none')
        }
        document.getElementsByTagName('footer')[0].classList.add('d-none')
      }
    },
    showHeader () {
      if(this.iOS) {
        setTimeout(function() {
          document.getElementById('home-header').classList.remove('d-none')
          document.getElementById('pane-wrap').classList.remove('focused')
          document.getElementsByTagName('footer')[0].classList.remove('d-none')
          var promotions = document.getElementsByClassName("promotion") != null
          if (promotions) {
            document.getElementById('promotions').classList.remove('d-none')
          }
        }, 200);
      }
    },
    loadInputtedFile () {
      const f = this.$refs.mainInputEl.files[0]
      const ftype = f ? f.type : null
      readFile({
        file: f,
        name: f.name,
        type: ftype
      }).then(results => {
        results.fname = f.name
        this.$emit('load-document-file', results)
        this.areaText = results.docText
        this.$refs.fileForm.reset()
      })
    }
  },
  destroyed () {
    document.removeEventListener('resize', event, false)
  },
  mounted () {
    if (this.startingText === loadingZipContentsMessage) {
      this.loadingZipContents = true
    } else this.areaText = this.startingText
    if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)) {
      this.iOS = true
      document.body.classList.add('ios-main')
      var winHeight =  window.innerHeight
      window.addEventListener("resize", function() {
        var currentHeight = window.innerHeight
        if(currentHeight != winHeight) {
          document.body.style.height = window.innerHeight + "px";
          document.body.classList.add('ios-toolbar-gone')
        } else {
          document.body.classList.remove('ios-toolbar-gone')
        }
      }, false);
    }
    if(!this.iOS && this.mainInputStyle.Mobile){
      $(window).on('resize', function(){
        // If the current active element is a text input, we can assume the soft keyboard is visible.
        if (document.getElementById('text-wrap').clientHeight < 250) {
          document.getElementById('home-header').classList.add('d-none')
          document.getElementById('pane-wrap').classList.add('focused')
        } else {
          document.getElementById('home-header').classList.remove('d-none')
          document.getElementById('pane-wrap').classList.remove('focused')
        }
        var promotions = document.getElementsByClassName("promotion") != null
        if (promotions) {
          document.getElementById('promotions').classList.remove('d-none')
        }
      })
    }
    if (!this.iOS)
      $('.main-input-box').focus()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* DocumentSettings-specific CSS goes here */
.right-pane {
  text-align: right;
  border-radius: 4px;
  position: relative;
  background: #fff;
  padding: 5px;
}
.right-pane.frame {
  background-color: #f6f6f6;
  border-color: #D7D7D7;
}
.right-pane-nonframe {
  border: solid 1px;
}
.right-pane-desktop {
  height: 100%;
  width: 100%;
}
.right-pane-mobile {
  margin: 12px;
  height: calc(100% - 130px);
  width: calc(100% - 25px);
  &.focused {
    height: calc(100% - 75px) !important;
  }
}
.right-pane p {
  color: black;
}

.promotions-displayed {
  .right-pane-mobile {
    height: calc(100% - 210px);
  }
}

.iphone {
/* CSS specific to iOS devices */
  &.right-pane-mobile {
    height: 76%;
    &.focused{
      height: 50% !important;
    }
  }
}

.promotions-displayed {
  .iphone {
    &.right-pane-mobile {
      height: calc(76% - 80px);
    }
  }
}

.ios-toolbar-gone {
  .iphone {
    /* CSS specific to iOS devices */
      &.right-pane-mobile {
        height: 87%;
        &.focused{
            height: 54% !important;
        }
      }
  }
}

.promotions-displayed {
  &.ios-toolbar-gone {
    &.right-pane-mobile {
      height: calc(87% - 80px);
    }
  }
}

.main-input-box {
  width: 100%;
  font-family: mft_narkisclassic, serif;
  font-size: 25px;
  line-height: 30px;
  border-style: none;
  resize: none;
  padding: 6px 6px 6px 39px;
  direction: rtl;
}
.main-input-box:not(.locked) {
  height: calc(100% - 40px);
}
.main-input-box.locked {
  height: 100%;
}
.main-input-box:focus {
  outline: none;
}
.main-input-box:not(.locked) {
  height: calc(100% - 40px);
}
.main-input-box.locked {
  height: 100%;
  color: black;
  background-color: #f6f6f6;
}
.right-pane:focus-within {
  border-color: var(--primary) !important;
}
.bottom-button {
  position: absolute;
  bottom: 10px;
  margin-bottom: 0;
}
.load-button {
  left: 15px;
}
.he .load-button {
  right: 15px;
  left: auto;
}
.submit-runner-div {
  right: 0px;
  bottom: 0px !important;
}
.he .submit-runner-div {
  left: 0px;
  right: inherit;
}
.clear-main-input-div {
  position: absolute;
  top: 12px;
  left: 13px;
  height: 23px;
  width: 22px;
  text-align: center;
  cursor: pointer;
}
</style>
