<template>
  <span @click="processClick"
        @dblclick="processDblClick"
        class="token"
        :class="displayToken.display.classes.concat(isCurrent ? 'isCurrent' : [])"
        ref="word">
    <span v-if="allowSingleLetter && isCurrent">
      <span v-for="(letter, i) in splitWord"
            :key="i"
            ref="letters"
            :class="i === currentLetter ? ['currentLetter'].concat(letter.classes || []) : letter.classes"
            >{{i === currentLetter && letter.letter === "\n" ? '↳': letter.letter}}</span>
    </span>
    <span v-else
      v-for="(part, i) in parts"
      :key="i"
      :class="part.classes"
    >{{part.text}}</span>
  </span>
</template>

<script>
import {getContext} from './TextPanelContext'
import {SPLIT_ON_ALL_BUT_NON_SPACING_MARKS_REGEX} from '../../js/commonHebrew'
let ctx
export default {
  name: "TextPanelToken",
  data() {
    ctx = getContext()
    ctx.displayTokenVms[this.displayToken.displayTokenIndex] = this
    return {
      currentLetter: 0
    }
  },
  props: {
    displayToken: Object,
    allowSingleLetter: Boolean
  },
  computed: {
    parts() {
      const baseparts = this.displayToken.display.parts
     
      if (!this.displayToken.isSplitToken) {
        return baseparts
      }
      let parts = []
      let chunkCount = 0
      for (let part of baseparts) {
        const chunked = part.text.split('\n')
        for (let chunk of chunked) {
          if (chunkCount === this.displayToken.chunk) {
            parts.push({
              text: chunk,
              classes: part.classes
            })
          }
          chunkCount++
        }
        // the last chunk doesn't have a newline, so it incremented one time too many
        chunkCount--
      }
      return parts
    },
    isCurrent() { return this.displayToken.isCurrent },
    displayTokenIndex() { return this.displayToken.displayTokenIndex },
    splitWord() {
      const baseparts = this.displayToken.display.parts
      const splitWord = baseparts.flatMap(part =>
        part.text.split(SPLIT_ON_ALL_BUT_NON_SPACING_MARKS_REGEX).map(letter => ({ letter: letter, classes: part.classes || []})))
      if (this.displayToken.isSplitToken) {
        let letters = []
        let chunkCount = 0
        for (let letter of splitWord) {
          if (chunkCount === this.displayToken.chunk) {
            letters.push(letter)
          }
          if (letter.letter === '\n') {
            chunkCount++
          }
        }
        return letters
      }
      return splitWord
    }
  },
  methods: {
    processClick(e) {
      this.displayToken.isCurrent = true
      const oldRect = this.$refs.word.getBoundingClientRect()
      // the component may re-render now, to separate spans for separate letters, so wait
      this.$nextTick(() => {
        // now check which letter was clicked
        const newRect = this.$refs.word.getBoundingClientRect()
        const offsetX = newRect.left - oldRect.left
        const offsetY =   newRect.top - oldRect.top
        if (!this.$refs.letters) {
          this.currentLetter = 0
        } else {
          this.currentLetter = this.$refs.letters.indexOf(document.elementFromPoint(e.x + offsetX, e.y + offsetY))
        }
        this.$emit('select', this.displayTokenIndex)
      })
    },
    processDblClick() {
      this.$emit('dblclick', this.displayTokenIndex)
    },
    handleIsCurrent() {
      // figure out if the node is in view, and if not, call scrollIntoView
      // IntersectionObserver is async, so using it to check visibility seems to slow everything less
      // scrollIntoView defaults to scrolling to the top. This scrolls just enough to show the word.
      // There's a scroll-padding set on the TextPanel so that it doesn't scroll just to the edge.
      const tokenEl = this.$refs.word
      if (!tokenEl) return
      const textPanel = tokenEl.parentNode.parentNode.parentNode
      const o = new IntersectionObserver((e) => {
        if (!e[0].isIntersecting) {
          this.$refs.word.scrollIntoView({block: 'nearest'})
        }
        o.disconnect()
      },  {
        root: textPanel,
        rootMargin: '-10px 0px',
        threshold: 1.0
      })
      o.observe(tokenEl)
      // alert the parent about the ref in case of lineUp/lineDown
      ctx.activeToken = this
    },
    // returns true if at the end
    letterForward() {
      if (this.currentLetter === this.splitWord.length - 1) {
        return true
      }
      this.currentLetter ++ 
      return false
    },
    // returns true if at the beginning
    letterBackward() {
      if (this.currentLetter === 0) {
        return true
      }
      this.currentLetter -- 
      return false
    },
    goToFirstLetter() {
      this.currentLetter = 0
    },
    goToLastLetter() {
      this.currentLetter = this.splitWord.length - 1
    }
  },
  mounted () {
    ctx.displayTokenElToVm.set(this.$refs.word, this)
    if (this.isCurrent) {
      this.handleIsCurrent()
    }
  },
  watch: {
    isCurrent (newVal) {
      // check if it's now become current
      if (newVal) {
        this.handleIsCurrent()
      }
    }
  }
}
</script>

<style scoped>
.token {
  cursor: pointer;
}
.isCurrent {
  background-color: #d6ecff;
  border-radius: 5px;
}
.currentLetter {
  background-color: rgb(156, 209, 255);
  border-radius: 5px;
}
</style>
