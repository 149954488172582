import backendRedirect from '../backendRedirect'

const SERVER_ROOT = '/api'
const SERVER = backendRedirect.apiTrafficManager + SERVER_ROOT

export const AppConfig = {
  SERVER_ROOT: SERVER_ROOT,
  SERVER: SERVER,
  MORPHOLOGY_LANGUAGE: 'english'
}
