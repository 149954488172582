import Vue from 'vue'
const state = Vue.observable({
  undone: [],
  done: [],
  undoRedoExecuting: false
})
let newMutation = true,
    emptyStateCommit,
    undoableCommits,
    baseStateCommits,
    store = null

const callbacks = []

function canRedo()
{
  return state.undone.length
}
function canUndo()
{
  return state.done.filter(commit => undoableCommits.includes(commit.type)).length
}

function runCallbacks(contextData) {
  if (!contextData) return
  for (let callback of callbacks) {
    callback(contextData)
  }
}

function redo() {
  if (!canRedo()) return
  state.undoRedoExecuting = true
  let mutation = state.undone.pop()
  newMutation = false
  if (Array.isArray(mutation.payload)) {
    store.commit(`${mutation.type}`, mutation.payload.slice())
  } else if (typeof mutation.payload !== 'object') {
    store.commit(`${mutation.type}`, mutation.payload)
  } else {
    store.commit(`${mutation.type}`, Object.assign({}, mutation.payload))
  }
  newMutation = true
  state.done.push(mutation)
  runCallbacks(mutation.payload?.contextData)
  setTimeout(() => {
    state.undoRedoExecuting = false
  }, 3)
}

function undo() {
  if (!canUndo()) return
  state.undoRedoExecuting = true
  const lastMutation = state.done.pop()
  state.undone.push(lastMutation)
  newMutation = false
  store.commit(emptyStateCommit)
  state.done.forEach(mutation => {
    if (Array.isArray(mutation.payload)) {
      store.commit(`${mutation.type}`, mutation.payload.slice())
    } else if (typeof mutation.payload !== 'object') {
      store.commit(`${mutation.type}`, mutation.payload)
    } else {
      store.commit(`${mutation.type}`, Object.assign({}, mutation.payload))
    }
  })
  newMutation = true
  runCallbacks(lastMutation.payload?.contextData)
  setTimeout(() => {
    state.undoRedoExecuting = false
  }, 3)
}

function initializeUndoRedo({
  store: _store,
  undoableCommits: _undoableCommits,
  emptyStateCommit: _emptyStateCommit,
  baseStateCommits: _baseStateCommits
}) {
  store = _store
  emptyStateCommit = _emptyStateCommit
  undoableCommits = _undoableCommits
  baseStateCommits = _baseStateCommits
  store.subscribe(mutation => {
    if (newMutation) {
      if (baseStateCommits.includes(mutation.type)) {
        state.done.splice(0, state.done.length)
        state.undone.splice(0, state.undone.length)
        state.done.push(mutation)
      }
      if (mutation.type !== emptyStateCommit && undoableCommits.includes(mutation.type)) {
        state.done.push(mutation)
      } // the second condition in the line below is crucial for our app to work -- DL
      if (undoableCommits.includes(mutation.type)) {
        state.undone.splice(0, state.undone.length)
      }
    }
  })
}

function registerContextCallback(callback) {
  callbacks.push(callback)
}

function unregisterContextCallback(callback) {
  callbacks.splice(callbacks.indexOf(callback), 1)
}

export {
  initializeUndoRedo,
  undo,
  redo,
  canUndo,
  canRedo,
  registerContextCallback,
  unregisterContextCallback,
  state as undoRedoState,
  undoableCommits
}
