var arrToDelete = []
export default {
  name: 'dictionaryMixins',
  computed: {
    setLocalData() {
      this.localUserDictionary = JSON.parse(JSON.stringify(this.localUserDictionaryFromState))
    },
    postSubmissionsPrompt: function () {
      return this.$store.state.morphologyApp.postSubmissionOptions.disableUploadDictionaryPrompt
    },
    localUserDictionaryFromState: function () {
      return this.$store.state.account.isLoggedIn ? this.$store.state.account.sync.morphologyDictionary : this.$store.state.tempUserDictionary
    },
  },
  methods: {
    entriesObjToLettersObj(entries) {
      const result = {}
      for (let entry in entries) {
        result[entry] = entries[entry].map(e => {
          let displayMorphs = Object.values(e.morphOptions).flat().filter(ms => ms !== 'Unspecified' && ms !== '').join()
          return entry + ': ' + displayMorphs
        })
      }
      return result
    },
    resetData() {
      // The UserSettingsPopup uses this mixin to retrieve data. It is not generic - it is written for the Nakdan,
      // and requires `lettersMap` and `negativeLettersMap` which is not used by morphology. This is generated here:
      const dictionaryCopy = JSON.parse(JSON.stringify(this.localUserDictionaryFromState))
      dictionaryCopy.lettersMap = this.entriesObjToLettersObj(dictionaryCopy.entryPresent)
      dictionaryCopy.negativeLettersMap = this.entriesObjToLettersObj(dictionaryCopy.negativeEntryPresent)
      this.localUserDictionary = dictionaryCopy
    },
    removeFromList(entry, ws) {
      let arrayObj = entry.split(':')[0]
      if (ws === 1) {
        let index = this.localUserDictionary.lettersMap[arrayObj].indexOf(entry)
        arrToDelete.push(this.localUserDictionary.entryPresent[arrayObj][index])
        this.localUserDictionary.lettersMap[arrayObj].splice(index, 1)
        this.localUserDictionary.entryPresent[arrayObj].splice(index, 1)
      } else {
        let index = this.localUserDictionary.negativeLettersMap[arrayObj].indexOf(entry)
        this.localUserDictionary.negativeEntryPresent[arrayObj].splice(index, 1)
        this.localUserDictionary.negativeLettersMap[arrayObj].splice(index, 1)
      }
    },
    display(displayText, ws, split) {
      if (split) {
        var index = 0
        var prefixLength = 0
        var lexEnabled = true
        let arrayObj = displayText.split(':')[0]
        if (ws == 1) {
          index = this.localUserDictionary.lettersMap[arrayObj].indexOf(displayText)
          const entries = this.localUserDictionary.entryPresent[arrayObj]
          if (entries) {
            if (!entries[index])
              index = 0
            prefixLength = entries[index].prefixLength
            lexEnabled = entries[index].lexEnabled
          }
        } else {
          index = this.localUserDictionary.negativeLettersMap[arrayObj].indexOf(displayText)
          prefixLength = this.localUserDictionary.negativeEntryPresent[[arrayObj]][index].prefixLength
        }
        if (prefixLength > 0) {
          let word = displayText.split(':')[0]
          word = word.replace(' ()', '')
          var splitWord = word.split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ|])/)
          var prefixWord = ''
          splitWord.forEach((item, key) => {
            if (key < prefixLength)
              prefixWord += '<span class="d-inline-block prefix">' + item + '</span>'
            else
              prefixWord += '<span d-inline-block>' + item + '</span>'
          })
          return prefixWord + ': <small>' + displayText.split(':')[1].replace(/,/g, ', ') + '</small>'
        }
        let morphWord = displayText.split(':')[0]
        morphWord = morphWord.replace(' ()', '')
        if (!lexEnabled) {
          let letWord = morphWord.match(/\(([^)]+)\)/)
          if (letWord) {
            let letText = letWord[1]
            morphWord = morphWord.replace(/\((.+?)\)/g, "<del class='text-muted'>(" + letText + ")</del>")
          }
        }
        return morphWord + ': <small>' + displayText.split(':')[1].replace(/,/g, ', ') + '</small>'
      }
    },
    // oddly, this returns `false` if there _are_ changes to save
    changesToSave() {
      // return _.isEqual(this.$store.state.userDictionary, this.localUserDictionary)
      // FIXME: the above test will always return false, and rewriting it correctly isn't currently a priority
      return false
    },
    saveChanges() {
      arrToDelete.forEach(obj => {
        this.$store.commit("UPDATE_USER_DICTIONARY", {
          contextData: { morphObj: obj, operation: 'remove' }
        }
        )
      })
      this.$store.commit("UPDATE_USER_DICTIONARY", {
        newUserDictionaryJson: this.localUserDictionary
      })
    }
  }
}
