// The different TextPanel components aren't normal Vue components. They don't work on their own.
// They are only separate components to limit how much gets rerendered by Vue when something changes.
// This file contains the shared state that all the components use.

let context = Object.assign(
    {
        allowSelection: t => t.isEditable,
        allowSingleLetterSelection: () => true,
        getDisplay: t => ({
            classes: t.classes,
            display: t.display
        }),
        initialized: false
    },
    getBlank()
)

function getBlank() {
    return {
        textPanelVm: null,
        displayTokenVms: [],
        displayTokenElToVm: new Map(),
        activeToken: null,
        // this is used to find a token using the displayIndex, even though the tokens are held in paras
        // used, for example, if `selected` changes and we want to update `isCurrent` on the tokens
        tokenMap: null,
        // used to look up a display index in navigate()
        tokenIdxToDisplayIdx: null
    }
}

export function getContext() {
    return context
}

export function getNewContext() {
    Object.assign(context, getBlank())
    return context
}

export function setOptions(o) {
    Object.assign(context, o)
    context.initialized = true
}
