<template>
  <div class="dropbox-status-bar">
    <spinner v-if="loading"></spinner>
    <small class="status-bar d-block text-right" :class="{ 'working': dropboxStatus.saving || loading }"
      v-if="dropboxStatus.name">
      <div class="d-inline-block" :class="hebrew ? 'ml-4' : 'mr-4'">
        <i :title="prevPage ? prevPage.name : ''" @click="switchLoadedPage(-1)"
          :class="{ 'disabled pe-none': !prevPage || dropboxStatus.saving || loading, 'cursor-pointer': prevPage, 'fa-caret-right': hebrew, 'fa-caret-left': !hebrew }"
          class="fas">
        </i>
        <span class="file-name d-inline-block">
          <i class="fas fa-file-alt"></i>
          {{ dropboxStatus.name.trim() }}
        </span>
        <i :title="nextPage ? nextPage.name : ''" @click="switchLoadedPage(1)"
          :class="{ 'disabled pe-none': !nextPage || dropboxStatus.saving || loading, 'cursor-pointer': nextPage, 'fa-caret-right': !hebrew, 'fa-caret-left': hebrew }"
          class="fas">
        </i>
      </div>
      <div class="d-inline-block cursor-pointer project-name-wrap"
        :class="{ 'disabled pe-none': dropboxStatus.saving || loading }" v-if="getDirectory(dropboxStatus.path_display)"
        @click="goToProject">
        <i class="fas fa-folder"></i>
        <span class="project-name" :class="hebrew ? 'mr-1' : 'ml-1'">
          {{ getDirectory(dropboxStatus.path_display).trim() }}
        </span>
      </div>
      <div class="d-inline-block mx-4">
        <b-form-checkbox switch :disabled="dropboxStatus.saving || loading" v-model="dropboxMarkCompleted"
          @change="saveFile">
          <span class="d-inline-block">
            {{ hebrew ? 'פרוייקט הושלם' : 'Mark Completed' }}
          </span>
        </b-form-checkbox>
      </div>
      <div v-if="dropboxStatus.saving" class="d-inline-block">
        <i :class="{ 'fab fa-dropbox': !morphPartners, 'fas fa-save': morphPartners }"></i>
        {{ hebrew ? "מבצע שמירה..." : "Saving..." }}
      </div>
      <div class="d-inline-block" v-if="showError && !dropboxStatus.saving">
        <i :class="{ 'fab fa-dropbox': !morphPartners, 'fas fa-save': morphPartners }"></i>
        {{ hebrew ? "השמירה נכשלה" : "Failed to save..." }}
      </div>
      <div v-if="timeSinceSave && !dropboxStatus.saving && !showError" class="d-inline-block">
        <i :class="{ 'fab fa-dropbox': !morphPartners, 'fas fa-save': morphPartners }"></i>
        {{ hebrew ? 'נשמר לפני' : 'Saved' }}
        {{ timeSinceSave / 60 >= 1 ? Math.trunc(timeSinceSave / 60) : 1 }}
        {{ hebrew ? "דק'" : "min ago" }}
      </div>
    </small>
    <div v-if="doubleOpen" class="double-open">
      הקובץ פתוח בשני מקומות. שמירה (אפילו אוטומטית) בחלון השני יכולה לדרוס את השינויים כאן!
    </div>
    <div v-if="readonly" class="readonly">
      Readonly
    </div>
    <FormSaveError :showForm="error !== null" :error-msg="error" :showDownload="false"
      @clear-error="error = null" @call-next="runCallbackFunc">
    </FormSaveError>
  </div>
</template>
<script>
import {
  dropboxSave,
  getDisplayFolderFromPath,
  getAutoSaveInterval,
  getFolder,
  watchFolderWithStatus,
  addReadonlyCB, removeReadonlyCB, setReadonly
} from "shared/dropbox/dropbox_utils";
import { Mutations } from 'shared/dropbox/store'
import FormSaveError from 'shared/dropbox/FormSaveError'
import Spinner from 'shared/components/spinner'
import dropboxMixins from 'shared/dropbox/dropboxMixins'
import {
  firebaseAddActiveFileCB,
  firebaseClearActiveFileCB,
  firebaseSetActiveFile
} from '../account/store/firebaseModule.js'
let stopWatching = null

export default {
  name: 'StatusBar',
  data() {
    return {
      interval: null,
      timeSinceSave: null,
      timerStart: Date.now(),
      dropboxMarkCompleted: this.$store.state.dropbox.status?.dictaStatus === 'COMPLETED',
      autoSaveIntervalSeconds: getAutoSaveInterval() / 1000,
      error: null,
      callbackFunc: '',
      doubleOpen: false,
      doubleOpenCB: null,
      readonly: false
    }
  },
  components: { Spinner, FormSaveError },
  mixins: [dropboxMixins],
  props: ["useEdited"],
  created() {
    this.interval = setInterval(() => {
      this.updateTime()
    }, 1000)
  },
  beforeDestroy() {
    if (stopWatching) {
      stopWatching()
      stopWatching = null
    }
  },
  mounted() {
    //when reloading file, reset last saved to prevent autosave on load
    this.$nextTick(() => {
      if (this.dropboxStatus.lastSaved) {
        const newStatus = Object.assign({}, this.dropboxStatus)
        newStatus.lastSaved = null
        this.$store.commit(Mutations.SET_DROPBOX_STATUS, newStatus)
      }
    })
    this.doubleOpenCB = firebaseAddActiveFileCB(doubleOpen => this.doubleOpen = doubleOpen)
    document.addEventListener('keydown', this.keyListener)
    addReadonlyCB(this.readonlyListener)
  },
  destroyed() {
    clearInterval(this.interval)
    firebaseClearActiveFileCB(this.doubleOpenCB)
    firebaseSetActiveFile(null)
    document.removeEventListener('keydown', this.keyListener)
    removeReadonlyCB(this.readonlyListener)
  },
  methods: {
    runCallbackFunc() { //continue with action despite save failing
      if (this.callbackFunc === 'goToProject') {
        const currentPath = this.$store.state.dropbox?.status?.path_display
        this.$router.push('/projects' + getDisplayFolderFromPath(currentPath))
      } else if (this.callbackFunc === 'next') {
        this.switchPage(1)
      } else if (this.callbackFunc === 'prev') {
        this.switchPage(-1)
      }
      this.callbackFunc = ''
    },
    switchLoadedPage(direction) {
      clearInterval(this.interval)
      if (!this.useEdited || this.dropboxStatus.edited) {
        this.switchLoadedPageAndSave(direction)
      } else {
        this.switchPage(direction)
      }
    },
    switchLoadedPageAndSave(direction) {
      this.$store.commit(Mutations.SET_DROPBOX_LOADING, true)
      dropboxSave(this.dropboxStatus, this.dropboxMarkCompleted, false)
        .then(() => {
          this.$store.commit(Mutations.SET_DROPBOX_LOADING, false)
          this.switchPage(direction)
        })
        .catch(e => {
          this.error = e
          this.callbackFunc = direction == 1 ? 'next' : 'prev'
          this.$store.commit(Mutations.SET_DROPBOX_LOADING, false)
        })
    },
    saveFile() {
      this.$nextTick(() => {
        this.saveToDropbox(false)
      })
    },
    updateTime() {
      const timerSeconds = (Date.now() - this.timerStart) / 1000
      // timeSinceSave measures seconds since this.dropboxStatus.lastSaved, which may be null if it hasn't been saved
      // use timer for initial save
      const timePassed = this.timeSinceSave || timerSeconds
      if (timePassed > this.autoSaveIntervalSeconds) {
        // Autosave, prevent spinner.
        // Don't save if a save is still in progress.
        // Always save non OCR after initial five minutes, save OCR ("useEdited") if there were edits.
        // Don't autosave if we just saved, since the tool does slow down a bit.
        // Also, if we just tried to autosave in the last 30 seconds, even if we failed, don't try again immediately
        if (
            !this.dropboxStatus.saving &&
            (!this.useEdited || this.dropboxStatus.edited) &&
            timePassed > 100 &&
            timerSeconds > 30
        ) {
          this.timerStart = Date.now()
          this.saveToDropbox(true)
        }
      }
      if (this.dropboxStatus.lastSaved)
        this.timeSinceSave = (Date.now() - this.dropboxStatus.lastSaved) / 1000
      else
        this.timeSinceSave = null
    },
    saveToDropbox(hideSpinner) {
      if (!hideSpinner)
        this.$store.commit(Mutations.SET_DROPBOX_LOADING, true)
      dropboxSave(this.dropboxStatus, this.dropboxMarkCompleted, false)
        .then(() => {
          this.$store.commit(Mutations.SET_DROPBOX_LOADING, false)
        }).catch(e => {
          if (!hideSpinner)
            this.error = e
          this.$store.commit(Mutations.SET_DROPBOX_LOADING, false)
        })
    },
    getDirectory(path) {
      let pathArr = path.split('/')
      // hide the folder name if would be the userId ("root") folder
      if (process.env.VUE_APP_MORPHOLOGY_PARTNERS && pathArr.length < 5)
        return ''
      if (process.env.VUE_APP_OCR_PARTNERS && pathArr.length < 3)
        return ''
      return pathArr[pathArr.length - 2]
    },
    goToProject() {
      if (!this.useEdited || this.dropboxStatus.edited)
        this.goToProjectAndSave()
      else {
        const currentPath = this.$store.state.dropbox?.status?.path_display
        this.$router.push('/projects' + getDisplayFolderFromPath(currentPath))
      }
    },
    goToProjectAndSave() {
      if (!this.dropboxStatus.saving) {
        this.$store.commit(Mutations.SET_DROPBOX_LOADING, true)
        dropboxSave(this.dropboxStatus, this.dropboxMarkCompleted, false)
          .then(() => {
            const currentPath = this.$store.state.dropbox?.status?.path_display
            this.$router.push('/projects' + getDisplayFolderFromPath(currentPath))
            this.$store.commit(Mutations.SET_DROPBOX_LOADING, false)
          })
          .catch(e => {
            this.error = e
            this.callbackFunc = 'goToProject'
            this.$store.commit(Mutations.SET_DROPBOX_LOADING, false)
          })
      }
      //})
    },
    keyListener(e) {
      if (e.shiftKey && e.altKey && (e.metaKey || e.ctrlKey) && e.code === 'KeyR') {
        const newVal = !this.readonly
        // if this will be readonly, then don't show a warning that it's open in two places
        firebaseSetActiveFile(newVal ? null : this.dropboxStatus.path_display)
        setReadonly(newVal)
      }
    },
    readonlyListener(val) {
      this.readonly = val
    }
  },
  computed: {
    hebrew() {
      return this.$settings && this.$settings.hebrew
    },
    dropboxStatus() {
      return this.$store.state.dropbox.status
    },
    morphPartners() {
      return process.env.VUE_APP_MORPHOLOGY_PARTNERS
    },
    showError() {
      return this.dropboxStatus?.lastSaveSucceeded === false
    }
  },
  watch: {
    dropboxStatus: {
      handler(newStatus, oldStatus) {
        if (newStatus?.path_display) {
          firebaseSetActiveFile(newStatus.path_display)
          // we might have the same file, but with an updated status, but then there's no need to change the watch
          if(newStatus.path_display !== oldStatus?.path_display) {
            this.timerStart = Date.now()
            this.timeSinceSave = null
            this.dropboxMarkCompleted = this.dropboxStatus.dictaStatus === 'COMPLETED'
            if (stopWatching) stopWatching()
            stopWatching =
                watchFolderWithStatus(getFolder(newStatus.path_display), (entries) => this.updateFolderStatus(entries))
          }
        } else {
          if (stopWatching) {
            stopWatching()
            stopWatching = null
          }
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
.status-bar {
  &.working {
    opacity: 0.5;
  }

  padding: 6px 0;

  .file-name {
    margin: 0 10px;
  }

  color: #8d8d8d;

  //font-size: 13px;
  .d-inline-block,
  i {
    vertical-align: middle;
  }

  i {
    color: #4a5057;
    font-size: 17px;

    &.fa-caret-left,
    &.fa-caret-right {
      font-size: 27px;
      color: #8d8d8d;
      top: -2px;
      position: relative;

      &:before {
        position: relative;
        z-index: 1;
        top: 2px;
      }

      &:after {
        content: '';
        position: absolute;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        left: -10px;
        top: 3px;
        background: transparent;
      }

      &.disabled {
        color: #d8d8d8;
      }

      &:hover {
        color: #4a5057;

        &:after {
          background: #e3e3e3;
        }
      }
    }

    &.fa-caret-left {
      &:after {
        left: -6px;
      }
    }
  }

  .project-name-wrap {
    &:hover {
      .project-name {
        text-decoration: underline;
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .pe-none {
    pointer-events: none;
  }
}
.double-open {
  background-color: red;
  padding: 20px;
  color: white;
  font-size: 26px;
  text-align: center;
}
.readonly {
  background-color: #D1D0D0;
  padding: 10px;
  color: black;
  font-size: 16px;
  text-align: center;
}
</style>
<style>
.status-bar .custom-control-label {
  vertical-align: middle
}
</style>
