<template>
    <header id="results-header"> 
        <div class="results-header">
            <div class="container">
                <div class="row">
                    <div class="col-4 text-right">
                        <div class="v-center">
                          <span @click="backToTextPrep" class="tool-btn mx-0 w-auto" v-bind:class="{disable: multiPaneOpen}">
                            <span v-if="hebrew"><i-arrow-right /></span>
                            <span v-else><i-arrow-left /></span>
                          </span>
                        </div>
                    </div>
                    <div class="col-8 left-items">
                        <span @click="copy" class="tool-btn" v-bind:class="{disable: multiPaneOpen}"><i-clone /></span>
                        <span @click="undo" class="tool-btn" v-bind:class="{disable: !canUndo || multiPaneOpen}"><i-share /></span>
                        <span @click="redo" class="tool-btn" v-bind:class="{disable: !canRedo || multiPaneOpen}"><i-reply /></span>
                        <span @click="showShareToolsClicked" id="share" class="tool-btn share-btn" v-bind:class="{disable: multiPaneOpen}"><i class="fas fa-share-alt" /></span>
                        <span @click="moreResultsToolsClicked" class="tool-btn  w-auto" v-bind:class="{disable: multiPaneOpen, 'ml-0': hebrew, 'mr-0': !hebrew }"><i class="fas fa-ellipsis-v" /></span>
                    </div>
                </div>
            </div>
        </div>
        <b-modal 
          size="sm"
          id="share-modal"
          centered
          hide-header
          hide-footer
          hide-backdrop
          body-class= "p-0 m-0"	
          v-model="showShareTools">
          <div class="share-tools">
            <a target="_blank" class="d-inline-block text-white rounded-circle bg-danger" v-bind:href="'mailto:?subject=נקדן&body=' + copyText">
              <i class="fas fa-envelope"></i>
            </a>
            &nbsp;
            <a 
            class="d-inline-block text-white rounded-circle bg-primary"
            target="_blank"
            v-bind:href="'https://www.facebook.com/sharer/sharer.php?u=http://nakdan.dicta.org.il/&quote=' + copyText"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
            &nbsp;
            <a target="_blank" class="d-inline-block text-white rounded-circle bg-success" v-bind:href="'https://api.whatsapp.com/send?text=' + copyText">
              <i class="fab fa-whatsapp"></i>
            </a>
          </div>
        </b-modal>
        <mobile-text-panel-more-tools 
          @change-font-size="(fontSize) => $emit('change-font-size', fontSize)"
          @change-show-hideables="(showHideables) => $emit('change-show-hideables', showHideables)"
          @hide-more-results-tools="showMoreResultsTools = false"
          :more-results-mode="moreResultsMode" />
        <back-to-text-prep-prompt
          :back-to-text-prep-prompt-props="backToTextPrepPromptProps"
          @hide-back-to-text-prep-prompt="showBackToTextPrepPrompt = false"
          @back-to-text-prep="(disablePrompt) => actuallyBackToTextPrep(disablePrompt)" />
    </header>
</template>
<script>
import MobileTextPanelMoreTools from './MobileTextPanelMoreTools'
import BackToTextPrepPrompt from './BackToTextPrepPrompt'
import textPanelToolMixins from './textPanelToolMixins'

export default {
  name: 'MobileTextPanelToolsHeader',
  mixins: [textPanelToolMixins],
  components: {
      MobileTextPanelMoreTools,
      BackToTextPrepPrompt
  },
  props: ['textPanelToolProps'],
  data () {
    return {
      showMoreResultsTools: false,
      showShareTools: false
    }
  },
  computed: {
    copyText () {
      return this.textPanelToolProps.copyText.replace(/\n/g, '%0A')
    },
    moreResultsMode () {
      return {
        showMoreResultsTools: this.showMoreResultsTools,
        fontSize: this.textPanelToolProps.fontSize,
        // "allowShowingHideables" unneeded b/c ketivmaleh unavailable in simple (which mobile always is)
        hideablesName: this.textPanelToolProps.hideablesName,
        showHideables: this.textPanelToolProps.showHideables,
      }
    },
    multiPaneOpen () {
      return this.textPanelToolProps.multiPaneOpen
    }
  },
  methods: {
    moreResultsToolsClicked () {
      if (!this.multiPaneOpen) {
        this.showMoreResultsTools = true
      }
    },
    copy () {
      if (!this.multiPaneOpen) {
        this.$emit('copy', this.textPanelToolProps.showHideables)
      }
    },
    showShareToolsClicked () {
      if (!this.multiPaneOpen) {
        this.showShareTools = !this.showShareTools  
        if (this.showShareTools) this.$emit('update-copy-text')
      }
    }
  },
  mounted () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  #share-modal  .modal-content {
    background:none;
    box-shadow: none;
    border:none;
    width: 68%;
    margin: -90% auto 0;
  }
</style>
<style scoped lang="scss">
  .results-header {
    height: 50px;
    display: flex;
    align-items: center;
    background-color: white;
  }

  .v-center {
    display: flex;
    align-items: center;
  }

  .left-items {
      display: flex;
      align-items: center;
      justify-content: flex-end;
  }
.share-tools {
  background: transparent;
  text-align: center;
  width: 100%;
  .rounded-circle{
    width:60px;
    height: 60px;
    font-size:35px;
    line-height: 60px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  }  
}


.tool-btn {
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  margin: 0 0 0 5px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  &.share-btn{
    &:not(.disable):hover{
      background:var(--secondary)
    }
  }
}
.disable {
  color: lightgray;
}
</style>
