import Vue from 'vue'
import Vuex from 'vuex'
import basicMutations from './store/basicMutations'
import { BlankState } from './store/stateChanges'
import morphActions from './store/morphActions'
import { accountModule, initializeFirebase } from 'shared/account'
import { dropboxModule } from 'shared/dropbox/store'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: JSON.parse(JSON.stringify(BlankState)),
  mutations: Object.assign(
    { ...basicMutations },
  ),
  actions: Object.assign(
    { ...morphActions },

  ),
  modules: {
    account: accountModule,
    dropbox: dropboxModule
  }
})

initializeFirebase(store, {
  appPrefix: 'morphology',
  syncFields: {
    'morphologyDictionary': {
      entryPresent: {},
      negativeEntryPresent: {}
    },
  },
  sharedSyncFields: ['dropbox_token']
})

export default store
