<template>
  <div class="result-text" :style="{ height: resizeHeight + 'px' }" :class="{ 'pt-3': !dropboxStatus }" ref="results"
    v-hotkey="keymap">
    <StatusBar v-if="dropboxStatus" />
    <div class="d-none">{{ loaded }}</div>
    <text-and-tools-panel ref="textAndToolsPanel" class="morphology-text bg-background"
      :class="{ 'dropbox-stats': dropboxStatus }" :tokens="morphData" :auto-navigation-obj="autoNavigationObj"
      :navigation-keys-between-editable-tokens-only="false" @token-selection="tokenSelected"
      :text-panel-styles="textPanelStyles" :showCopyWithSelected="true" :selected-word-num="currentSelectedWordNum"
      :results-length="resultsLength" @get-word-num="(selectedWordNum) => getWordByCount(selectedWordNum)"
      @undo="undoState" @redo="redoState" :canRedo="canRedo" :canUndo="canUndo" :loading="loading"
      @range-selection="rangeSelected" @download="actuallyDownload" :text-panel-ref="$refs.textPanel">
      <template v-slot:textpanel="props">
        <text-panel ref="textPanel" :tokens="morphData" :style="props.fstyle" :loading="loading"
          class="main-results-box" @token-selection="tokenSelected" @range-selection="rangeSelected"></text-panel>
      </template>
      <template v-slot:extended-toolbar>
        <toolbar :commentDialog="showComments" @comments-display="setShowComments" @group-selection="groupSelected"
          @neutralize="neutralizeSelected" @navigate-to="autoNavigate" @download="actuallyDownload"
          @highlight="highlightWord" @arrow-press="autoNavigate" />
      </template>
    </text-and-tools-panel>
    <div id="group-created-msg" class="notify-msg" style="display:none;">
      הקבוצה נוצרה בהצלחה
    </div>
    <b-modal id="group-clicked-modal" 
      centered size="sm" 
      hide-backdrop
      header-class="py-1 d-flex justify-content-between"
      title-tag="div"
      body-class="text-center"
      footer-class="border border-top border-secondary py-2" v-model="showGroupModal" ok-only 
      title="יצירת קבוצה"
      :ok-title="currentMorphWordObj?.wordIndex === resultsLength ? 'הבנתי' : 'בצע'"
      ok-class="selection-non-clearer" 
      :ok-disabled="groupWordsNum < 2"
      @ok="createGroup"
      @hide="resetGroupVals">
      <span v-if="currentMorphWordObj?.wordIndex === resultsLength">
      על מנת ליצור קבוצה, יש לבחור עם
      <br />
      העכבר לפחות 2 מילים
      </span>
      <span v-else class="position-relative">
        <small class="ml-1 d-block">מספר מילים בקבוצה</small>
        <b-form-spinbutton inline @change="groupRangeChange" v-model="groupBegin" vertical 
        :step="getStepSize(-1)"></b-form-spinbutton>
        <b-form-input style="width:35px;height:32px;vertical-align: bottom;" class="rounded d-inline-block text-center" type="text" disabled :value="groupWordsNum" size="sm" min="2"></b-form-input>
        <b-form-spinbutton inline v-model="groupEnd" @change="groupRangeChange" vertical 
        :step="getStepSize(+1)"></b-form-spinbutton>
        <div class="bg-background p-2 f-narkis rounded mt-3" style="min-height:65px;" v-html="groupWords"></div>
      </span>
    </b-modal>

    <div class="position-absolute" style="z-index:9999999999999999">
      <spinner v-if="downloading"></spinner>
    </div>
  </div>
</template>
<script>
import TextAndToolsPanel from 'shared/components/TextAndToolsPanel/TextAndToolsPanel'
import { getTextPanel } from 'shared/components/NewTextPanel/TextPanel'
import Toolbar from '@/components/toolbar.vue'
import UndoRedoMixins from 'shared/UndoRedo/UndoRedoMixins'
import { RunStates } from '../store/runStates'
import modalOpen from 'shared/js/modalOpen'
import StatusBar from 'shared/dropbox/StatusBar'
//import trackChanges from 'shared/account'
import { getMorphologyStrings, getPrefixLength, checkAramaic } from '../js/morphologies'
import { getDropboxSaveFile } from 'shared/dropbox/dropbox_utils'
import Spinner from 'shared/components/spinner'
import _ from 'lodash'
import jszip from 'jszip'

const TextPanel = getTextPanel()

export default {
  name: 'ResultText',
  mixins: [
    UndoRedoMixins
  ],
  components: {
    TextAndToolsPanel,
    TextPanel,
    Toolbar,
    StatusBar,
    Spinner
  },
  data() {
    return {
      loaded: false,
      justMounted: false,
      autoNavigationObj: {
        command: null,
        arguments: { tokenIndex: null }
      },
      searchWord: '',
      showGroupModal: false,
      groupSize: 1,
      groupBegin: 0,
      groupEnd: 0,
      setGroup: false,
      showComments: false,
      rangeSelectionArr: [],
      textPanelStyles: {
        Mobile: window.innerWidth < 992,
        Desktop: window.innerWidth >= 992,
        textPanel: {
          USE_LETTER_CURSOR: false,
          SHOW_LINE_BREAK_CHAR: false,
          CAN_CLICK_ON_LETTERS: false,
          START_BY_SINGLE_CLICK: true,
          START_BY_DOUBLE_CLICK: true
        },
        appFeatures: {
          showPasukFont: false,
          navigationKeysBetweenEditableTokensOnly: false,
          hideablesSuffixesDuplicated: false,
          editNikudPopup: { ENABLE: false, PRESENT: false }
        },
        tools: {
          BOTTOM: true, HEADER: false, USE_DOWNLOAD_POPUP: false
        },
      },
      downloading: false
    }
  },
  props: ['changeY'],
  computed: {
    keymap() {
      return {
        'ctrl+shift+d': () => {
          this.actuallyDownload()
        },
        'ctrl+shift+u': () => {
          this.autoNavigate(this.rangeSelectionArr[0])
          this.$store.commit('SET_GROUP', { groupRage: this.rangeSelectionArr, showToast: true }) //if clicked on existing group, ungroup
        },
        'shift+1': () => {
          if (!(modalOpen())) {
            document.activeElement.blur()
            this.neutralizeSelected()
          }
        }
      }
    },
    currentSelectedWordNum() {
      return this.currentMorphWordObj ? this.currentMorphWordObj.wordIndex : 1
    },
    resultsLength() {
      return this.$store.state.morphologyApp.morphApiResponse.filter(ms => ms.sep === false).length
    },
    dropboxStatus() {
      return this.$store.state.dropbox.status
    },
    currentWordIndex() {
      return this.$store.state.morphologyApp.currentWordIndex
    },
    currentMorphWordObj() {
      return this.morphData[this.currentWordIndex]
    },
    morphData() {
      return this.$store.state.morphologyApp.morphData
    },
    currentMorphData() {
      return this.$store.state.morphologyApp.morphData[this.currentWordIndex]
    },
    isCurrenWordNeutralized() {
      return this.morphData[this.currentWordIndex].neutralized
    },
    resizeHeight() {
      return this.changeY
    },
    loading() {
      return this.$store.state.nakdanApiState === RunStates.CONTINUING
    },
    complete() {
      return this.$store.state.nakdanApiState
    },
    groupWords() {
      let str = ''
      let wordNum = this.currentWordIndex - (this.groupBegin)
      for(let i = 0; i <= parseInt(this.groupSize); i++) { //+(parseInt(this.groupSize)-1)
        if(this.morphData[wordNum]) {
          if (this.morphData[wordNum+1].text !== '\n' && this.morphData[wordNum+1].text !== '\r')
            str += this.morphData[wordNum++].text
          else {
            str += '<span class="d-block" style="line-height: 10px;">-</span>'
            wordNum++
          }
           
        }  
        else
          break;  
      }
      return str.trim()
    },
    groupWordsNum() {
      let wordNum = this.currentWordIndex - (this.groupBegin)
      let index = 0
      for(let i = 0; i <= parseInt(this.groupSize); i++) { //+(parseInt(this.groupSize)-1)
        if(this.morphData[wordNum]) {
          
          if (!this.morphData[wordNum].sep) {
            index++
          }  
          wordNum++
        }
      }  
      return index
    }
  },
  watch: {
    dropboxStatus() {
      this.autoNavigate(this.currentWordIndex)
    },
    complete(val) {
      if (val === 'Complete') {
        if (this.morphData.length < 1) {
          this.$router.push({ name: 'Projects' })
        } else {
          this.autoNavigate(this.currentWordIndex)
        }
        // this.trackChanges()
      }
    }
  },
  methods: {
    getStepSize(dir) {
      if(dir < 0) {
        let beginArea = this.morphData[this.currentWordIndex-this.groupBegin]
        if  (
          beginArea
          && beginArea.sep
          && beginArea.text!=='\n' 
          && beginArea.text!=='\r') {
          return 2
         } else if(this.groupBegin === 0) {
          return 2
         }
         return 1

      } else {
        //morphData[currentWordIndex+groupEnd] && morphData[currentWordIndex+groupEnd].sep && morphData[currentWordIndex+groupEnd].text!=='\n' && morphData[currentWordIndex+groupEnd].text!=='\r' ? 2 : 1
        let endArea = this.morphData[this.currentWordIndex+this.groupEnd]
        if  (
          endArea
          && endArea.sep
          && endArea.text!=='\n' 
          && endArea.text!=='\r') {
          return 2
         } else if(this.groupEnd === 0) {
          return 2
         }
         return 1
      }
    },
    groupRangeChange() {
      this.groupSize = parseInt(this.groupBegin)+parseInt(this.groupEnd)
    },
    resetGroupVals() {
      this.groupBegin = 0
      this.groupEnd = 0
      this.groupSize = 1
    },
    getPartnerFile() {
      let downloadJson = JSON.stringify(this.morphData.map(element => {
        return {
          display: element.text,
          selectedMorph: JSON.stringify(element.selectedMorph)
        }
      }))
      const zip = jszip()
      zip.file('contents.json', downloadJson)
      return zip.generateAsync({
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: {
          level: 6
        }
      })
    },
    async actuallyDownload() {
      this.downloading = true
      let zip = null
      if (process.env.VUE_APP_MORPHOLOGY_PARTNERS) {
        zip = await this.getPartnerFile()
      } else {
        zip = await getDropboxSaveFile()
      }
      var element = document.createElement('a')
      const url = window.URL.createObjectURL(zip)
      element.href = url
      element.download = 'Morphology' + Math.floor(Math.random() * 100000000) + '.json.zip'
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      this.downloading = false
      document.body.removeChild(element)
      window.URL.revokeObjectURL(url)
    },
    navToSelectedWord() {
      setTimeout(() => {
        if (this.currentMorphData) {
          this.loaded = this.$refs.textAndToolsPanel !== undefined
          this.autoNavigate(this.currentWordIndex)
          this.checkCurrentInGroup()
        }
      }, 350)
    },
    setWordOptions() {
      if (this.morphData[this.currentWordIndex].options.length < 1) {
        let options = this.morphData[this.currentWordIndex].morphArrs.map(morphArr => ({
          morphOptions: getMorphologyStrings(morphArr.morph, false),
          word: morphArr.lex,
          // returnedWord will be used for the display of this morphology.
          // As such, it should be the nGram if this is an nGram, the `w` property ordinarily,
          // but apparently sometimes that's not set (?) so we fall back to the word's text string.
          returnedWord: morphArr.fMorphNGram ? morphArr.fullNGramStr.replace(/ /g, '_') :
              (morphArr.w ? morphArr.w : this.morphData[this.currentWordIndex].text),
          lexEnabled: true,
          fromServer: true,
          flag: [],
          aramaic: checkAramaic(morphArr.morph, false),
          prefixLength: getPrefixLength(morphArr.morph, false),
          onlyNikud: morphArr.fNikMatch,
          //groupOptions: morphArr.fMorphNGram,
          ...(morphArr.fMorphNGram && { NGramID: morphArr.NGramID })
        }))
        let groupOptions = options.filter(function (obj) {
          return obj.NGramID
        })
        let singleOptions = options.filter(function (obj) {
          return !obj.NGramID
        })
        if (!this.currentMorphWordObj.groupId)
          this.$store.commit("INITIALIZE_GROUP", groupOptions)
        this.$store.commit("SET_MORPH_OPTIONS", singleOptions)
      }
    },
    highlightWord(val) {
      this.searchWord = val
    },
    undoState() {
      this.undo()
    },
    redoState() {
      this.redo()
    },
    getWordByCount(wordIndex) {
      var index = this.morphData.findIndex(obj => obj.wordIndex === parseInt(wordIndex))
      this.autoNavigate(index)
    },
    setShowComments(val) {
      this.showComments = val
    },
    autoNavigate(wordIndexOrCommand) {
      const autoNavigationObj = {
        command: null,
        arguments: { tokenIndex: null }
      }
      switch (typeof wordIndexOrCommand) {
        case 'number':
          autoNavigationObj.command = 'move'
          this.$store.commit('SET_CURRENT_WORD_INDEX', wordIndexOrCommand)
          autoNavigationObj.arguments.tokenIndex = wordIndexOrCommand
          break
        case 'string':
          autoNavigationObj.command = wordIndexOrCommand
      }
      this.setWordOptions()
      if (this.justMounted) {
        setTimeout(() => {
          this.$refs.textAndToolsPanel.navigate(autoNavigationObj)
          //this.trackChanges()
        }, 20)
        this.justMounted = false
      } else {
        this.$refs.textAndToolsPanel.navigate(autoNavigationObj)
      }
    },
    setNextActive(index) {
      if (this.morphData[index] !== undefined) {
        while (this.morphData[index] !== undefined && (this.morphData[index].neutralized || this.morphData[index].sep)) {
          index++
        }
      }
      if (this.morphData[index] !== undefined) {
        this.autoNavigate(index)
      }
    },
    neutralizeSelected() {
      if (this.rangeSelectionArr.length > 0) {
        this.trimRange()
        var ranges = [] //add grouped items
        this.rangeSelectionArr.forEach(item => {
          ranges = ranges.concat(this.morphData[item].groupRange)
        })
        this.rangeSelectionArr = this.rangeSelectionArr.concat(ranges.filter((item) => this.rangeSelectionArr.indexOf(item) < 0))
        this.$store.commit('SET_NEUTRALIZED', { mutateTo: this.rangeSelectionArr, wordIndex: this.currentWordIndex })
        if (this.morphData[this.rangeSelectionArr[0]].neutralized) {
          this.setNextActive(this.rangeSelectionArr[this.rangeSelectionArr.length - 1])
        }
        else {
          this.autoNavigate(this.rangeSelectionArr[0])
        }
      }
      else { //one word selected
        if (this.currentMorphWordObj.groupId) {
          this.$store.commit('SET_NEUTRALIZED', { mutateTo: this.currentMorphWordObj.groupRange, wordIndex: this.currentWordIndex })
        }
        else {
          this.$store.commit('SET_NEUTRALIZED', { mutateTo: [this.currentWordIndex], wordIndex: this.currentWordIndex })
        }
        this.setNextActive(this.currentWordIndex)
      }
      window.getSelection().removeAllRanges()
    },
    trimRange() {
      if (this.morphData[this.rangeSelectionArr[0]].sep)
        this.rangeSelectionArr.shift()
      if (this.morphData[this.rangeSelectionArr[this.rangeSelectionArr.length - 1]].sep)
        this.rangeSelectionArr.pop()
    },
    createGroup() {
      if (this.currentMorphWordObj.wordIndex < this.resultsLength) {
        //if not last word, create group
        let arr = []
   //     let wordNum = this.currentWordIndex
   let wordNum = this.currentWordIndex - (this.groupBegin)
      for(let i = 0; i <= parseInt(this.groupSize); i++) { //+(parseInt(this.groupSize)-1)
        if(this.morphData[wordNum]) {
          arr.push(wordNum)
          wordNum++ 
        }  
          
        }
        this.rangeSelectionArr = arr
        this.groupSelected(true)
        this.rangeSelectionArr = []
        this.resetGroupVals()
      }
    },
    groupSelected(val) {
      this.removeCurrentGroupClass()
      this.setGroup = val
      if (this.rangeSelectionArr.length > 1)
        this.trimRange()
      if (this.rangeSelectionArr.length >= 2) {
        this.rangeSelectionArr.forEach(item => { //cancel neutralization
          if (this.morphData[item].neutralized) {
            this.$store.commit('SET_NEUTRALIZED', { mutateTo: [item], wordIndex: item })
          }
        })
        this.$store.commit('SET_GROUP', { groupRage: this.rangeSelectionArr, showToast: true })
        this.autoNavigate(this.rangeSelectionArr[0])
        window.getSelection().removeAllRanges()
      } else {
        if (this.currentMorphWordObj.groupRange && this.currentMorphWordObj.groupRange.length == 0 || !this.currentMorphWordObj.groupRange)
          this.showGroupModal = true //show not enough words in group modal
        else
          this.$store.commit('SET_GROUP', { groupRage: this.currentMorphWordObj.groupRange, showToast: false }) //if clicked on existing group, ungroup
      }
      this.setGroup = false
    },
    tokenSelected(tokenSelectionObj) {
      this.autoNavigate(tokenSelectionObj.tokenIndex)
      this.checkCurrentInGroup()
    },
    dblClicked(val) {
      this.autoNavigate(val.tokenIndex)
      if (val.type === 'dblclick') {
        this.$emit('dbl-click', true)
      } else {
        this.$emit('dbl-click', false)
      }
    },
    rangeSelected(rangeSelectionObj) {
      // eslint-disable-next-line no-console
      this.rangeSelectionArr = rangeSelectionObj.cleared ? [] : _.range(rangeSelectionObj.from, rangeSelectionObj.to + 1)
      this.$refs.textAndToolsPanel.currentSelection = this.rangeSelectionArr
    },
    removeCurrentGroupClass() {
      if (this.$store.state.recentGroupedRange.length > 0) {
        this.$store.state.recentGroupedRange.forEach(item => {
          if (this.morphData[item].classes.includes('grouped'))
            this.morphData[item].classes.splice(this.morphData[item].classes.indexOf('grouped'), 1)
        })
        this.$store.state.recentGroupedRange = []
      }
    },
    checkCurrentInGroup() {
      this.removeCurrentGroupClass()
      if (this.currentMorphWordObj.groupId || this.currentMorphWordObj.groupId == 0) {
        if (this.currentMorphWordObj.groupRange.indexOf(this.currentMorphWordObj.groupId) >= 0) {
          this.$store.state.recentGroupedRange = this.currentMorphWordObj.groupRange
          this.$store.state.recentGroupedRange.forEach(item => {
            if (this.morphData[item].classes.indexOf('grouped') < 0)
              this.morphData[item].classes.push('grouped')
          })
        }
      }
    },
  },
  mounted() {
    this.justMounted = true
    this.navToSelectedWord()
  }
}
</script>
<style lang="scss">

.b-form-spinbutton.flex-column  {
    border: 1px solid #bfbfbf;;
    output {
      display: none!important;
    }
    button {
      padding: 0;
      &.btn .b-icon.bi {
        font-size: 100%;
      }
    }
  }

.prefix {
  color: #ba8686;
}

.results.d-header-none header.main-header {
  display: none;
}

//@import "hightlight";
.current-token.highlight {
  background: #e10fef !important;

  span {
    background: transparent !important;
  }
}

.tooltip-wrap {
  position: fixed;
  color: black;
  background: white;
  font-size: 16px;
  padding: 5px;
  width: 283px;
  height: 90px;
  padding: 12px;
  overflow-y: auto;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  line-height: 22px;
  font-family: arimo;
  z-index: 999;
}

.main-results-box {
  flex: auto;

  .edited,
  .groupColor {
    color: #bd10e0 !important;
  }

  .flag {
    &::before {
      color: #949494 !important;
      content: "\f024";
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 900;
      font-size: 65%;
      margin-left: 3px;
      margin-right: 6px;
    }
  }

  .neutralized {
    color: #949494;
    text-decoration: line-through;
  }

  .grouped {
    background: #d6ecff;

    //border-top: 1px solid #d6ecff;
    //border-bottom: 1px solid #d6ecff;
    &.group-begin {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      &.isCurrent {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      //border-right: 1px solid #d6ecff;
    }

    &.group-end {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      &.isCurrent {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .comment {
    position: relative;

    span {
      &:first-child {
        &::before {
          color: #949494 !important;
          content: "\f27a";
          font-family: 'Font Awesome\ 5 Free';
          font-weight: 900;
          font-size: 60%;
          padding-left: 4px;
          margin-right: 7px;
        }
      }
    }

    span {
      &:first-child {
        &:hover {
          &::before {
            color: var(--primary) !important;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.result-text {
  /* resize: vertical;
    overflow: auto; */
  min-height: 16vh;

  // max-height: 67vh;
  .morphology-text {
    border-radius: 4px;
    height: 100%;
    overflow: hidden;

    &.dropbox-stats {
      height: calc(100% - 35px);
    }
  }

  .selected {
    position: relative;

    span {
      z-index: 1;
      position: relative;
    }

    &::after {
      background-color: #d6ecff;
      content: '';
      width: 100%;
      position: absolute;
      height: 28px;
      right: -4px;
      top: 4px;
      border-radius: 4px;
    }
  }

}

</style>
