import {Messages, Status} from 'shared/account/js/messages.js'
import firebase from 'firebase/app'
import {firebaseInitPromise} from 'shared/account/store/firebaseModule.js'
/* This file is used to set up communications with accounts.dicta.org.il via an iframe.
 * This method is only useful if the iframe actually shares data with the accounts.dicta.org.il website, which has
 * become problematic with recent anti-tracking changes to web browsers. In particular, we depend on Firebase
 * being able to see its login status, which it can't if the iframe sees separate storage from accounts.dicta.org.il.
 * We continue to use this anyway because sites ending with dicta.org.il are all supposed to be able to share storage,
 * and it provides a much better user experience.
 *
 * However, this is a problem for sites ending with netlify.app or localhost. As a workaround, if iframe cannot see a
 * value in localStorage that is set during login, we assume that it's partitioned and give up. Instead, we pass login
 * information via redirection. Login is done on accounts.dicta.org.il, which redirects with Firebase login data,
 * then we use that on the individual site. Logout also requires a redirect in order to remove login credentials on
 * accounts.dicta.org.il. We cannot (yet) use requestStorageAccess because it's gated behind UI interactions with the
 * iframe, which we don't currently have.
 */

const ACCOUNTS_SERVER = 'https://accounts.dicta.org.il'
const iframe = document.createElement('iframe')
iframe.style.display = 'none'
iframe.src = ACCOUNTS_SERVER + '/'
let loaded = false
let origin = ACCOUNTS_SERVER
let user = null

// this promise is resolved once Firebase finishes loading and figures out whether we're logged in on *this* site.
const firebaseLoadedPromise = firebaseInitPromise.then(() => new Promise(resolve =>
  firebase.auth().onAuthStateChanged(u => {
    resolve()
    user = u
  })))

function send(message, data) {
  iframe.contentWindow.postMessage(Object.assign({}, data, { message: message }), origin)
}

// Using a Firebase login to also log in to a second website is complicated: first user.getIdToken gives a token
// that can't be used. However, the Firebase Admin API can use that to create a custom credential which CAN be used.
// This API call simply trades the first kind of token for the second, which is sent to the iframe, which can use it to
// log in.
async function sendToken() {
  const response = await fetch('https://accounts.loadbalancer.dicta.org.il/exchangeToken', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      credential: await user.getIdToken(true)
    })
  })
  const json = await response.json()
  send(Messages.DICTA_ACCOUNTS_SEND_TOKEN, {credential: json.credential})
}

async function messageHandler(evt) {
  // eslint-disable-next-line no-console
  console.log(evt.origin, evt.data)

  // handshake section - needed to know iframe is loaded, and to give the iframe our window
  if (evt.data.message === Messages.DICTA_ACCOUNTS_CONNECT) {
    loaded = true
    send(Messages.DICTA_ACCOUNTS_CONNECT)
  }
  if (!loaded || evt.origin !== origin) return
  // handshake done

  // iframe sends us the most up-to-date login status
  if (evt.data.message === Messages.DICTA_ACCOUNTS_LOGIN_STATUS) {
    await firebaseLoadedPromise
    if (user) {
      switch (evt.data.status) {
        case Status.NEW:
          // we're logged in, but the iframe knows nothing yet, so send our token to log in inside the iframe.
          sendToken()
          break
        case Status.SIGNED_IN:
          // if we need to switch users
          if (user.uid !== evt.data.uid)
            send(Messages.DICTA_ACCOUNTS_GET_TOKEN)
          break
        // the user explicitly signed out of accounts.dicta, so sign us out, too.
        case Status.SIGNED_OUT:
          firebase.auth().signOut()
          break
      }
    } else {
      switch (evt.data.status) {
        // the first two cases we're logged out and so is the iframe, so do nothing
        case Status.NEW:
        case Status.SIGNED_OUT:
          break
        // the iframe is signed in, so get a token and log us in, too
        case Status.SIGNED_IN:
          send(Messages.DICTA_ACCOUNTS_GET_TOKEN)
          break
      }
    }
  }
  // iframe sent us a login token as we requested, so log in
  if (evt.data.message === Messages.DICTA_ACCOUNTS_SEND_TOKEN) {
    firebase.auth().signInWithCustomToken(evt.data.credential)
  }
  // we requested a logout, but the iframe cannot see the top level data, so we have to redirect to complete the logout
  if (evt.data.message === Messages.DICTA_ACCOUNTS_LOGOUT_REDIRECT) {
    const logoutURL = new URL(ACCOUNTS_SERVER + '/logout/')
    logoutURL.search = new URLSearchParams({
      v: 1,
      r: window.location
    })
    window.location = logoutURL
  }
}

// Handle the redirect parameters after a login and erase them from the URL.
// Note: this simply happens when the page is loaded. It doesn't use the iframe.
const params = new URL(window.location).searchParams
if (params.get('accounts.m') === 'login') {
  firebaseInitPromise.then(() => firebase.auth().signInWithCustomToken(params.get('accounts.d')))
  // erase the credentials from the URL
  const newUrl = new URL(window.location)
  newUrl.searchParams.delete('accounts.m')
  newUrl.searchParams.delete('accounts.d')
  history.replaceState(history.state, '', newUrl)
}

window.addEventListener('message', messageHandler)
document.body.appendChild(iframe)

export function signOut() {
  send(Messages.DICTA_ACCOUNTS_SIGN_OUT)
  firebase.auth().signOut()
}
