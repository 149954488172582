<template>
  <main-input
    class="main-input-wrap mt-3"
    :submit-runner-in-main-input="true"
    :lock-text="lockText"
    @edit-document-text="(areaText) => mainText = areaText"
    @load-document-file="(sourceAndTextObj) => mainText = sourceAndTextObj.docText">
    <template v-slot:submit-runner>
      <button 
        class="btn btn-primary btn-lg mr-auto d-inline-block position-relative ml-2"         
        :disabled="lockText && apiText==''"
        @click="runMorphology('rabbinic')"
        >החל פרוט מורפולוגי - טקסט רבני</button>
      <button 
        class="btn btn-primary btn-lg mr-auto d-inline-block position-relative" 
        :disabled="lockText && apiText==''"
        @click="runMorphology('modern')">
        החל פרוט מורפולוגי - טקסט מודרני</button>
      <small class="position-absolute text-danger" v-if="emptyText">*יש להזין טקסט</small>
      <div id="text-load-msg" class="notify-msg" style="display:none;">
        הטקסט נטען בהצלחה
      </div>
    </template>
  </main-input>
</template>
<script>
import { Actions } from '@/store/stateChanges'
import MainInput from 'shared/components/MainInput/MainInput'
export default {
  name: 'MainInputContainer',
  components: {
    MainInput
  },
  data () {
    return {
      mainText: '',
      emptyText: false,
    }
  },
  props: {
    lockText: {
      default: () => { return  process.env.VUE_APP_MORPHOLOGY_PARTNERS }
    }
  },  
  mounted () {
    if (process.env.VUE_APP_MORPHOLOGY_PARTNERS) {
      this.$router.push('projects')
    } else {
      this.textLoaded()
    }
  },
  methods: {
    textLoaded () {
      if (this.lockText && this.apiText !== '') {
        document.getElementById('text-load-msg').style.display="block"
        setTimeout(function(){
          document.getElementById('text-load-msg').style.display="none"
        }, 2000)
      } else {
        this.$store.commit('RESET_RESULTS')
      }      
      setTimeout(() => {       
        this.mainText = this.apiText
        document.getElementById('text-wrap').value = this.apiText
      }, 200)        
    },
    runMorphology (textType) {
      if (this.mainText === '') {
        this.emptyText = true
      } else {
        this.$store.commit("SET_TEXT_FOR_NAKDAN_API_CALL", this.mainText)
        this.$store.commit("SET_TEXT_TYPE", textType)
        this.$store.dispatch(Actions.RUN_MORPHOLOGY)
      }
    }
  },
  computed: {
    apiText () {
      return this.$store.state.morphologyApp.textForNakdanApiCall
    }
  }
}
</script>
<style lang="scss">
  .main-input-box {
    height: calc(100% - 55px)!important;
  }
</style>
<style lang="scss" scoped>
  .main-input-wrap {
    height: calc(100vh - 110px);
  }
  button {
    bottom: 12px;
    left: 10px;
  }    
</style>