import {clear, get, set, Store} from 'idb-keyval'
import {DocumentManager} from '@/DocumentManager'
import store from "@/store"
import {getGitCommitId} from "shared/js/getGitCommitId";

const SAVED_STATE_NAME = 'morphology-state'
const DROPBOX_DATA = 'dropboxData'
const LATEST_VERSION = '0.1'

const customKeyVal = new Store(SAVED_STATE_NAME, SAVED_STATE_NAME)

let loadingPromiseResolver
export const loadingPromise = new Promise(resolve => {
  loadingPromiseResolver = resolve
})

export async function loadLocalData(isResultsPage) {
  if (localStorage.getItem('returnToProjects')) {
    localStorage.removeItem('returnToProjects')
  } else {
    try {
      if (isResultsPage) {
        const savedStateString = await get(SAVED_STATE_NAME, customKeyVal)
        if (savedStateString) {
          if (DocumentManager.load(JSON.stringify(savedStateString))) {
            const dropboxConnectionData = await get(DROPBOX_DATA, customKeyVal)
            store.commit('SET_DROPBOX_STATUS', dropboxConnectionData?.status || null)
            store.commit('SET_PROJECT_SETTINGS', dropboxConnectionData?.projectSettings || null)
          }
        }
      }
    }
    // eslint-disable-next-line no-empty
    catch {
    }
    loadingPromiseResolver()
  }
}

export async function saveLocalData() {
  try {
    const stateToSave = store.state.morphologyApp
    const copyOfStateToSave = Object.assign({}, stateToSave, {version: LATEST_VERSION, gitCommit: getGitCommitId()})
    if (stateToSave.validationResult) delete copyOfStateToSave.validationResult
    await set(SAVED_STATE_NAME, copyOfStateToSave, customKeyVal)
    await set(DROPBOX_DATA, store.state.dropbox, customKeyVal)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    await clear(customKeyVal)
    throw "Couldn't save to IndexedDB"
  }
}