/*
This file is to allow firebaseStore to accept objects that contain characters that Firebase does not allow in keys, and
store the data by encoding on store and decoding on read. The escape character is '%', which happens to not appear in our
current database, conveniently making it unnecessary to figure out a way to handle the difference between old and new data.
There are ten characters that we need to escape (9 characters that we might want to allow in Firebase, and % itself).
This allows us to encode as %0 ... %9, and use a regex that identifies % followed by a single digit.
It is probable that we can write a higher performance version of this which makes fewer copies, but tests show that this
has acceptable performance. If the amount of data processed while a user is waiting grows significantly, we should revisit.
 */

// these characters can't appear in Firebase keys
const FIREBASE_REPLACE = '%.#$/[]\n\r\t'.split('')
// regex to find those characters
const ENCODE_REGEX = /([%.#$/\][\n\r\t])/g
// map of e.g. '#' to '%2'
const encodeMap = new Map(FIREBASE_REPLACE.map((ch, i) => [ch, '%' + i]))

export function firebaseEncodeString(str) {
  return str.replaceAll(ENCODE_REGEX, m => encodeMap.get(m))
}

function firebaseDecodeString(str) {
  return str.replaceAll(/%(\d)/g, (_, d) => FIREBASE_REPLACE[+d])
}

function isObject(value) {
  return !Array.isArray(value) && typeof value === 'object' && value !== null && value !== undefined
}

// see the MDN docs for JSON.stringify/parse to understand what these next two functions do
function replacer(key, value) {
  if (isObject(value)) {
    return Object.fromEntries(
      Object.entries(value).map(([k, v]) => [firebaseEncodeString(k), v])
    )
  }
  return value
}

function reviver(key, value) {
  if (isObject(value)) {
    return Object.fromEntries(
      Object.entries(value).map(([k, v]) => [firebaseDecodeString(k), v])
    )
  }
  return value
}


export function firebaseEncode(obj) {
  return JSON.parse(JSON.stringify(obj, replacer))
}

export function firebaseDecode(obj) {
  return JSON.parse(JSON.stringify(obj), reviver)
}
