var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.tokenPart.textPartObj.hideable) || _vm.showHideables),expression:"!(tokenPart.textPartObj.hideable) || showHideables"}],staticClass:"clickable-token",class:_vm.tokenClasses,style:(_vm.tokenPart.textPartObj.hideable ? 'color: ' + (_vm.tokenPart.textPartObj.useAlternateHideableColor
              ? _vm.alternateHideableDisplayColor : _vm.hideableDisplayColor)
           : _vm.tokenPart.textPartObj.prefix ? 'color: red' : ''),attrs:{"id":'span-A-tokenPart-' + _vm.tokenPart.textPartIndex + '-ofToken-' + _vm.tokenPart.tokenIndex + '-inPar-' + _vm.p},on:{"click":function($event){return _vm.tokenClicked(_vm.tokenPart.tokenIndex, $event)}}},_vm._l(((tp => {
                        let tpString = tp.textPartObj.part
                        if (tp.textPartObj.hasOwnProperty('firstIndexWithinPart')) {
                          tpString = _vm.tokenPart.textPartObj.part.slice(tp.textPartObj.firstIndexWithinPart,
                                                                      tp.textPartObj.lastIndexWithinPart + 1)
                        }
                        tpString = tpString.replace(/ֽ/, '')
                        if (_vm.textPanelStyles.appFeatures.hideablesSuffixesDuplicated && _vm.showHideables
                            && !(tp.textPartObj.hideable) && _vm.tpInP < (_vm.paragraph.length - 1)) {
                          const nextTextPartObj = _vm.paragraph[_vm.tpInP + 1].textPartObj
                          if (nextTextPartObj.hideable
                              && tpString[tpString.length - 1] === nextTextPartObj.part[nextTextPartObj.part.length - 1]) {
                            tpString = tpString.slice(0, -1)
                          }
                        }
                        return tpString.split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ])/)
                      })(_vm.tokenPart)),function(letterString,le){return _c('span',{key:'par-'+_vm.p+'-tokenPart-'+_vm.tpInP+'-letterString-'+le,style:(_vm.letterCursorHere && !_vm.letterAddingMode && _vm.isCurrentToken
                    && _vm.currentLetterIndex === le + _vm.tokenPart.lettersInTokenBeforeCurrentPart
                      ? 'background-color: ' + _vm.cursorColor + '; border-radius: 5px' : ''),attrs:{"id":'span-L-tokenPart-' + _vm.tokenPart.textPartIndex + '-ofToken-' + _vm.tokenPart.tokenIndex + '-letterpos-' + (le + _vm.tokenPart.lettersInTokenBeforeCurrentPart)}},[_vm._v(_vm._s(letterString === '\n' ? (_vm.showLineBreakChar && !_vm.letterAddingMode && _vm.isCurrentToken && _vm.currentLetterIndex === le + _vm.tokenPart.lettersInTokenBeforeCurrentPart ? '↳' : '') : letterString.replace(/וֹ/g, '\ufb4b'))),(_vm.letterAddingMode && _vm.isCurrentToken 
                        && _vm.currentLetterIndex === le + _vm.tokenPart.lettersInTokenBeforeCurrentPart)?_c('span',{style:(_vm.letterAddingCursorStyle)},[_vm._v("|")]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }