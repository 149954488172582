export function processSpecialDisplayProps (tokensArray, keepingHideables, hideablesSuffixesDuplicated,
                                            nonConfidentMarking, metegMode = 'no-metegs') {
  return tokensArray.map((tokenObj) => {
    var postNikudShifting = tokenObj.text // nikud shifting, if any, is currently done when MAKING tokens
    if (metegMode === 'keep-metegs' && tokenObj.textSource) postNikudShifting = tokenObj.textSource
    if (Array.isArray(postNikudShifting)) {
      postNikudShifting = tokenObj.text.map((tp, tpInToken) => {
        let tpString = (!keepingHideables && tp.hideable) ? '' : tp.part
        if (hideablesSuffixesDuplicated && keepingHideables
            && !(tp.hideable) && tpInToken < (tokenObj.text.length - 1)) {
          const nextTextPartObj = tokenObj.text[tpInToken + 1]
          if (nextTextPartObj.hideable) {
            const nextTPwithoutMetegs = nextTextPartObj.part.replace(/(.)ֽ/g, '')
            if (tpString [tpString.length - 1] === nextTPwithoutMetegs[nextTPwithoutMetegs.length - 1]) {
              tpString = tpString.slice(0, -1)
            }
          }
        }
        return tpString
      }).join('')
    }   
    const postHideableHandling = metegMode === 'keep-metegs'
      ? ((nonConfidentMarking && tokenObj.markedNonConfident ? '$' : '') + postNikudShifting)
      : postNikudShifting.replace(/(.)ֽ/g, keepingHideables ? '$1' : '')
    if(tokenObj.flagged)  {
      return '¿'+postHideableHandling
    } else {
      return postHideableHandling
    }        
  }).join('')
}
