export function getTokenPartIndex(token, letterIndex) {
  var letterIndicesInParts
  if (Array.isArray(token.text)) {
    letterIndicesInParts = []
    let beforeCurrentPart = 0
    token.text.forEach(partObj => {
      const partLettersLength = partObj.part.split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ])/).length
      letterIndicesInParts.push([...Array(partLettersLength)].map((_, i) => i + beforeCurrentPart))
      beforeCurrentPart += partLettersLength
    })
  } else letterIndicesInParts = [[...Array(token.text.split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ])/).length)].map((_, i) => i)]
  return Math.max(0, letterIndicesInParts
    .map(indices => indices.includes(letterIndex)).indexOf(true))
}

import {SPLIT_ON_ALL_BUT_NON_SPACING_MARKS_REGEX} from '../js/commonHebrew'

export function getLetterCount(text) {
  return text.split(SPLIT_ON_ALL_BUT_NON_SPACING_MARKS_REGEX).length
}