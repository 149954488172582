import Vue from 'vue'
import Router from 'vue-router'
import EditText from './views/EditText.vue'
import Results from './views/Results.vue'
import Projects from 'shared/dropbox/Projects'
import store from './store'
import {processDbxLogin} from 'shared/dropbox/dropbox_utils.js'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'text-edit',
      component: EditText
    },
    {
      path: '/results',
      name: 'results',
      component: Results
    },
    {
      path: '/projects*',
      name: 'Projects',
      component: Projects,
      props: () => ({ editScreen: store.state.morphologyApp.morphApiResponse.length > 0 ?
        {to: '/results', name:  Vue.prototype.$settings.hebrew ? 'מסך עריכה' : 'Edit screen'}
        : null })
    },
    {
      path: '/dropbox-auth',
      name: 'dropbox-auth',
      beforeEnter (to, from,  next) {
        processDbxLogin().then(
          () => next('/projects')
        )
      }
    }
  ]
})
