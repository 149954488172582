<template>
  <div class="toolbar position-relative" v-hotkey="keymap">
    <div class="tool-section" v-if="hasSelectedTags">
      <i style="cursor:pointer;" class="fas fa-caret-down rounded position-relative view-options mx-1 d-none"
        :class="{ 'active': showOptions }" @click="showOptions = !showOptions">
      </i>
      <small v-if="viewSelected"><b>{{ hebrew? 'מילים לתיוג': 'Selected words' }}:</b>
       <span dir="ltr"  class="mr-2">
        {{ currentTaggableWord }}/{{ selectedWordCount }}
      </span>
      </small>
      <small v-else>{{ hebrew? 'כל המילים': 'All words' }}</small>
      <div class="position-absolute rounded bg-white px-3 py-2 options-wrap view-options" v-if="showOptions">
        <b-form-group class="mb-0">
          <b-form-radio v-model="viewSelected" :value="true" @change="updateView">
            {{ hebrew? 'הצג את המילים לתיוג': 'Display words for tagging' }}
          </b-form-radio>
          <hr class="my-2">
          <b-form-radio v-model="viewSelected" :value="false" @change="updateView">
            {{ hebrew? 'הצג את כל המילים בטקסט': 'Display all words' }}
          </b-form-radio>
        </b-form-group>
      </div>
    </div>
    <div class="tool-section first-btn">
      <button id="prev-word" @click="prevWordClicked" v-b-tooltip.hover title="למילה הקודמת">
        <i-caret-right></i-caret-right>
      </button>
      <button id="next-word" @click="nextWordClicked" v-b-tooltip.hover title="למילה הבאה">
        <i-caret-left></i-caret-left>
      </button>
    </div>
    <div class="tool-section selection-non-clearer">
      <button id="neutralize-btn" class="selection-non-clearer" 
        v-b-tooltip.hover title="ניטרול מילה (Shift+!)"
        :disabled="viewSelected && !isTagWord && !isGrouped && hasSelectedTags"
        @click="neutralizeText">
        <del class="selection-non-clearer" :class="{ 'text-primary': (isNeutralized && !viewSelected) || (isNeutralized && viewSelected && isTagWord)}">א
        </del>
      </button>
    </div>
    <div class="tool-section">
      <button id="group-btn" @click="startGrouping" class="selection-non-clearer" v-b-tooltip.hover title="יצירת קבוצה"
        :disabled="isNeutralized">
        <i class="fas fa-object-group selection-non-clearer" style="font-size:16px;"
          :class="{ 'text-primary': isGrouped }">
        </i>
      </button>
    </div>
    <div class="tool-section">
      <button id="flag-btn" @click="toggleFlag" 
        :disabled="isNeutralized || (viewSelected && !isTagWord && !isGrouped && hasSelectedTags)">
        <i class="fa fa-flag" :class="{ 'text-primary': isFlagged }" v-b-tooltip.hover title="סימון דגל (Shift+?)"
          style="font-size:15px;">
        </i>
      </button>
    </div>
    <div class="tool-section">
      <button id="comment-btn" @click="commentsClicked" v-b-tooltip.hover 
        :disabled="(viewSelected && !isTagWord && !isGrouped && hasSelectedTags)"
        title="הוסף הערה (Ctrl+Shift+H)"><!--:disabled="isNeutralized"-->
        <i class="fas fa-comment-alt" :class="{ 'text-primary': currentComments !== '' }" style="font-size:15px;">
        </i>
      </button>
    </div>
    <div class="tool-section">
      <button id="update-nikud-btn" @click="updateNikud" v-b-tooltip.hover title="עריכת ניקוד"
        :disabled="isNeutralized || isGrouped || (viewSelected && !isTagWord && hasSelectedTags)">
        <span class="update-nikud">אָ</span>
      </button>
    </div>
    <div class="tool-section position-relative d-none">
      <div class="position-fixed w-100 h-100" @click="closeSearch" v-if="showSearch">
      </div>
      <div class="position-absolute search-wrap" v-if="showSearch">
        <SearchDialog @nav-to-search="goTo" @highlight="setSearchWord"></SearchDialog>
      </div>
      <button id="search-btn" @click="searchClicked">
        <i class="fa fa-search" :class="{ 'text-primary': showSearch }" aria-hidden="true" v-b-tooltip.hover
          title="(Ctrl+Shift+F) חיפוש" style="font-size:17px;"></i>
      </button>
    </div>
    <div class="tool-section d-none">
      <small class="ml-2 position-relative">תווי תחילית</small>
      <input type="number" v-model="prefixLength" :disabled="isNeutralized" class="text-center prefix-len" min="0"
        :max="currentWord.split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ|])/).length < 5 ? currentWord.split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ|])/).length : 5"
        size="2" />
    </div>
    <div class="tool-section position-absolute click-home-btn d-none">
      <button v-b-tooltip.hover title="Alt+N" class="w-auto px-2" @click="goToHome">טען טקסט חדש
        <i-arrow-alt-circle-left></i-arrow-alt-circle-left>
      </button>
    </div>

    <nikud-options-dialog v-model="showNikudOptions">
    </nikud-options-dialog>
    <CommentsDialog v-model="showCommentModal" :comments="currentComments" @input="emitModalVis"></CommentsDialog>
  </div>
</template>
<script>
import { Actions } from '@/store/stateChanges'
import CommentsDialog from '@/components/commentsDialog.vue'
import NikudOptionsDialog from '@/components/NikudOptionsDialog'
import SearchDialog from '@/components/search'
import modalOpen from 'shared/js/modalOpen'
import { RunStates } from '../store/runStates'
import prefixMixins from '@/mixins/prefixMixins'
import _ from 'lodash'

export default {
  name: 'Toolbar', // i.e. extended
  components: { CommentsDialog, SearchDialog, NikudOptionsDialog },
  mixins: [prefixMixins],
  data() {
    return {
      showCommentModal: false,
      showSearch: false,
      showNikudOptions: false,
      morphologyPartners: process.env.VUE_APP_MORPHOLOGY_PARTNERS,
      showOptions: false,
      viewSelected: true
    }
  },
  props: ['commentDialog'],
  methods: {
    goToHome() {
      this.$router.push({ name: "text-edit" })
    },
    goTo(val) {
      this.$emit('navigate-to', val)
    },
    updateView() {
      this.$store.commit("TOGGLE_WORDS_TO_TAG")
      this.showOptions = false
    },
    setSearchWord(val) {
      this.$emit('highlight', val)
    },
    closeSearch() {
      this.showSearch = false
    },
    searchClicked() {
      document.activeElement.blur()
      this.showSearch = !this.showSearch
    },
    emitModalVis(val) {
      this.$emit('comments-display', val)
    },
    commentsClicked() {
      //if (!this.isNeutralized) {
      this.showCommentModal = true
      //}
    },
    neutralizeText() {
      this.$emit('neutralize', true)
    },
    startGrouping() {
      this.$emit('group-selection', true)
    },
    toggleFlag() {
      if (!this.isNeutralized) {
        this.$store.commit("TOGGLE_GENERAL_FLAG", this.currentWordIndex)
      }
    },
    prevWordClicked() {
      this.$emit('arrow-press', 'previous-editable')
    },
    nextWordClicked() {
      this.$emit('arrow-press', 'next-editable')
    },
    setPrefixes(prefixLen) {
      let wordNoNikud = this.morphOptions.returnedWord.replace(/[^א-ת]/g, '')
      let prefix = wordNoNikud.substring(0, prefixLen)
      if (this.morphOptions.fromServer) {
        var newMorphOption = _.cloneDeep(this.morphOptions)
        newMorphOption.prefixLength = prefixLen
        newMorphOption.fromServer = false
        newMorphOption.morphOptions.prefixes = this.setPrefix(prefix)
        this.$store.commit("ADD_MORPH_OPTION", newMorphOption)
        this.$store.commit("SET_CURRENT_MORPH_INDEX", 0)
        if (this.userLoggedIn) {
          //item-added-msg         
          this.$store.commit("UPDATE_USER_DICTIONARY", {
            contextData: { morphIndex: 0, operation: 'add' }
          }
          )
        }
      } else {
        if (this.userLoggedIn) {
          this.$store.commit("UPDATE_USER_DICTIONARY", {
            contextData: { morphIndex: this.selctedMorphIndex, operation: 'remove' }
          }
          )
        }
        this.$store.commit("SET_PREFIX", prefixLen)
        this.morphOptions.morphOptions.prefixes = this.setPrefix(prefix)
        if (this.userLoggedIn) {
          this.$store.commit("UPDATE_USER_DICTIONARY", {
            contextData: { morphIndex: this.selctedMorphIndex, operation: 'edit' }
          }
          )
        }
      }
    },
    updateNikud() {
      this.$store.dispatch(Actions.RUN_MORPH_ON_WORD)
      this.showNikudOptions = true
    }
  },
  computed: {
    keymap() {
      return {
        'shift+ctrl+h': () => {
          document.activeElement.blur()
          this.commentsClicked()
        },
        'shift+ctrl+f': () => {
          document.activeElement.blur()
          this.searchClicked()
        },
        'esc': () => {
          document.activeElement.blur()
          this.closeSearch()
        },
        'shift+/': () => {
          if (!(modalOpen())) {
            document.activeElement.blur()
            this.toggleFlag()
          }
        },
      }
    },
    hebrew() {
      return this.$settings.hebrew
    },
    currentWordIndex() {
      return this.$store.state.morphologyApp.currentWordIndex
    },
    selctedMorphIndex() {
      return this.$store.state.morphologyApp.morphData[this.currentWordIndex].selctedMorphIndex
    },
    currentComments() {
      return this.$store.state.morphologyApp.morphData.length > 0 ? this.$store.state.morphologyApp.morphData[this.currentWordIndex].comments : ''
    },
    isGrouped() {
      return this.$store.state.morphologyApp.morphData.length > 0 ? this.$store.state.morphologyApp.morphData[this.currentWordIndex].groupId || this.$store.state.morphologyApp.morphData[this.currentWordIndex].groupId == 0 : false
    },
    isNeutralized() {
      return this.$store.state.morphologyApp.morphData.length > 0 ? this.$store.state.morphologyApp.morphData[this.currentWordIndex].neutralized : false
    },
    isTagWord() {
      return this.$store.state.morphologyApp.morphData.length > 0 ? this.$store.state.morphologyApp.morphData[this.currentWordIndex].tagWord : true
    },
    isFlagged() {
      return this.$store.state.morphologyApp.morphData.length > 0 && this.$store.state.morphologyApp.morphData[this.currentWordIndex].flagged
    },
    currentWord() {
      return this.$store.state.morphologyApp.morphData.length > 0 ? this.$store.state.morphologyApp.morphData[this.currentWordIndex].text : ''
    },
    morphData() {
      return this.$store.state.morphologyApp.morphData
    },
    morphOptions() {
      return this.morphData[this.currentWordIndex].options[this.selctedMorphIndex]
    },
    prefixLength: {
      get: function () {
        return this.morphData[this.currentWordIndex] ? this.morphData[this.currentWordIndex].options[this.selctedMorphIndex] ? this.morphData[this.currentWordIndex].options[this.selctedMorphIndex].prefixLength : 0 : 0
      },
      set: function (newValue) {
        this.setPrefixes(newValue)
      }
    },
    loading() {
      return this.$store.state.nakdanApiState === RunStates.CONTINUING
    },
    userLoggedIn() {
      return this.$store.state.account && this.$store.state.account.userData
    },
    currentTaggableWord() {
      let foundWord = this.morphData.filter(item=>item.isEditable).map(e => e.actualIndex).indexOf(this.currentWordIndex)
      return foundWord > -1 ? foundWord + 1 : '#'
    },
    selectedWordCount() {
      return this.morphData.filter(item=>item.isEditable).length
    },
    hasSelectedTags() {
      return this.morphData[0] && this.morphData[0].text.trim().includes('@@@')
    }
  },
  watch: {
    commentDialog: function (newVal) {
      this.showCommentModal = newVal
    }
   
  }
}
</script>
<style lang="scss">
#loadNewTextButton {
  margin-top: 2px;
  margin-left: 2px;
}

#group-clicked-modal {
  .btn-primary {
    width: 100%;
  }

  .modal-header .close {
    padding: 0;
    margin: 0;
    opacity: 1;
  }
}

.back-tool-section,
.undoredo-tool-section,

.aims-btn {
  display: none !important;
}

.tool-section {
  border-color: #d8d8d8 !important;
  border-top: none !important;

  button {
    &:hover:not([disabled]) {
      background: #f6f6f6;
    }
  }
}
</style>
<style lang="scss" scoped>
.toolbar {
  border-right: 1px solid #d8d8d8;
  width: calc(100% - 81px);

  .options-wrap {
    bottom: 37px;
    right: 10px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    width: 278px;
    height: 82px;
  }

  .fa-caret-down {
    width: 20px;
    height: 20px;
    font-size: 18px;
    top: 2px;
    text-align: center;

    &.active,
    &:hover {
      background: #f6f6f6;
    }
  }

  .position-fixed {
    top: 0;
    left: 0;
    z-index: 8;
  }

  .tool-section {
    padding: 7px 10px 0;
    height: 40px;
    display: inline-block;
    border-left: 1px solid #d8d8d8;

    &.click-home-btn {
      left: 0;
      border: none;
    }

    small {
      top: -1px;
    }

    button {
      width: 26px;
      height: 26px;
      border: none;
      background: none;
      text-align: center;
      padding: 0;
      border-radius: 2px;
      overflow: hidden;

      &:hover:not([disabled]) {
        background: #f6f6f6;
      }

      i,
      del {
        font-size: 19px;
        top: 2px;
        position: relative;
      }

      .update-nikud,
      del {
        font-family: Roboto, mft_narkisclassic, sans-serif;
        line-height: 17px;
        overflow: hidden;
        font-size: 25px;
      }
    }

    &.first-btn {
      border-right: 1px solid #d8d8d8;
      padding-right: 9px;
      width: 80px;

      button {
        display: inline-block !important;

        &:first-child {
          margin-left: 7px;
        }
      }
    }

    &:last-child {
      border-left: none;
    }

    .search-wrap {
      top: -58px;
      right: 0;
      z-index: 9;
    }
  }

}
</style>