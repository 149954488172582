<template>
  <div class="main-results-box text-right" v-hotkey="keymap"
       v-bind:style="{ 'font-size': fontSize + 'px', 'line-height': lineHeight + 'px' }">
    <p v-for="(paragraph, p) in paragraphsFromTokens" :key="'par'+p">
      <TextPanelToken v-for="(tokenPart, tpInP) in paragraph"
                      :key="'par-'+p+'-tokenPart-'+tpInP"
                      :tokenPart="tokenPart"
                      :tpInP="tpInP"
                      :current-letter-index="currentLetterIndex"
                      :is-current-token="tokenPart.tokenIndex === currentTokenIndex"
                      :letter-adding-mode="letterAddingMode" :p="p"
                      :paragraph="paragraph" :show-hideables="showHideables" :show-line-break-char="showLineBreakChar"
                      :text-panel-styles="textPanelStyles"
                      @token-selection="e => { currentTokenIndex = e.tokenIndex; currentLetterIndex = e.letterIndex }"
                      v-on="$listeners"
                      :non-confident-marking="nonConfidentMarking"
      />
    </p>
  </div>
</template>

<script>
import modalOpen from 'shared/js/modalOpen'
import $ from 'jquery'
import textPanelNavigationMixins from 'shared/mixins/textPanelNavigationMixins'
import specialNavigationMixins from 'shared/mixins/specialNavigationMixins'
import TextPanelToken from './TextPanelToken'

const UP = -1
const DOWN = 1

export default {
  name: 'TextPanel',
  components: {TextPanelToken},
  mixins: [
    textPanelNavigationMixins, // for moveToNextToken(), moveToPreviousToken(), moveToTokenMethod() and scrollResultsWindow()
    specialNavigationMixins // for getAdjacentParagraphTokenIndex() and moveToAdjacentLine()
  ],
  props: ['tokens', 'autoNavigationObj', 'allowLetterSelection', 'letterAddingMode', 'nonConfidentMarking',
          'showHideables', 'fontSize', 'textPanelStyles', 'firstClickOrDoubleClickDone',
          'navigationKeysBetweenEditableTokensOnly', 'specialNavigationToNonEditableTokens'],
  data () {
    return {
      currentTokenIndex: -1,
      currentLetterIndex: -1,
      currentSelection: [],
      paragraphsFromTokens: []
    }
  },
  computed: {
    allTokens () {
      return this.tokens
    },
    editableTokens () {
      return this.allTokens.filter(t => t.isEditable)
    },
    hebrew () {
        return this.$settings.hebrew
    },
    startByDoubleClick () {
      return this.textPanelStyles.textPanel.START_BY_DOUBLE_CLICK && !(this.textPanelStyles.Mobile)
    },
    showLineBreakChar () {
      return this.textPanelStyles.textPanel.SHOW_LINE_BREAK_CHAR
    },
    localCurrentToken () {
      return (this.currentTokenIndex > -1 && this.currentTokenIndex < this.allTokens.length)
              ? this.allTokens[this.currentTokenIndex] : null
    },
    lineHeight () {
      return this.fontSize + 5
    },
    autoNavigationCommand () {
      return this.autoNavigationObj ? this.autoNavigationObj.command : undefined
    },
    keymap () {
      return {
        'left': () => {
          if (!(this.letterAddingMode) && !(modalOpen())) this.moveToNextToken() // by default, to editable
        },
        'right': () => {
          if (!(this.letterAddingMode) && !(modalOpen())) this.moveToPreviousToken() // by default, to editable
        },
        'shift+up': () => { 
          if (!(this.navigationKeysBetweenEditableTokensOnly)) this.moveToPreviousLine()
        },
        'shift+down': () => { 
          if (!(this.navigationKeysBetweenEditableTokensOnly)) this.moveToNextLine()
        },
        'ctrl+shift+up': () => { 
          if (!(this.navigationKeysBetweenEditableTokensOnly)) this.moveToPreviousParagraph()
        },
        'ctrl+shift+down': () => { 
          if (!(this.navigationKeysBetweenEditableTokensOnly)) this.moveToNextParagraph()
        }
      }
    }
  },
  watch: {
    autoNavigationCommand (newAutoNavigationCommand) {
      if (newAutoNavigationCommand) this.executeAutoNavigation(this.autoNavigationObj)
      this.autoNavigationObj.command = null
    },
    currentTokenIndex () {
      this.clearCurrentSelection()
    },
    currentLetterIndex () {
      this.clearCurrentSelection()
    },
    allTokens() {
      this.paragraphsFromTokens = this.getParagraphsFromTokens()
    }
  },
  methods: {
    getParagraphsFromTokens () {
      var paragraphsFromTokens = []
      var currentParagraphTokenParts = []
      if (this.allTokens.length) {
        var tokenIndex = 0
        var currentTokenTextObjIsArray = Array.isArray(this.allTokens[tokenIndex].text)
        var lettersInTokenBeforeCurrentPart = 0
        var textPartIndex = 0
        var indexWithinPart = 0
        var currentTokenPart = {
          tokenObj: this.allTokens[tokenIndex], // for token props other than "text (parts)"
          tokenIndex: tokenIndex,
          textPartObj: currentTokenTextObjIsArray ? this.allTokens[tokenIndex].text[textPartIndex]
            : { part: this.allTokens[tokenIndex].text, hideable: false },
          textPartIndex: textPartIndex, // all if no props "start" and "end"
          lettersInTokenBeforeCurrentPart: lettersInTokenBeforeCurrentPart
        }
        var splittingTokenPart = false
        while (tokenIndex < this.allTokens.length) {
          // console.log('currentTokenPart.textPartObj:')
          // console.log(JSON.stringify(currentTokenPart.textPartObj))
          const newlinePos = currentTokenPart.textPartObj.part.slice(indexWithinPart).indexOf('\n')
          if (newlinePos !== -1) {
            if (newlinePos < currentTokenPart.textPartObj.part.slice(indexWithinPart).length - 1) {
              splittingTokenPart = true
              currentTokenPart['firstIndexWithinPart'] = indexWithinPart
              currentTokenPart['lastIndexWithinPart'] = indexWithinPart + newlinePos
              indexWithinPart += newlinePos + 1
            } else { // i.e. no (more) token splitting
              if (!currentTokenTextObjIsArray // so SURELY need to go to next token
                || textPartIndex === this.allTokens[tokenIndex].text.length - 1) {
                tokenIndex ++
                lettersInTokenBeforeCurrentPart = 0
                textPartIndex = 0
              } else {
                textPartIndex ++
                lettersInTokenBeforeCurrentPart += currentTokenPart.textPartObj.part.split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ])/).length
              }
              indexWithinPart = 0
              splittingTokenPart = false
            }
            currentParagraphTokenParts.push(currentTokenPart)
            if (tokenIndex < this.allTokens.length) {
              currentTokenTextObjIsArray = Array.isArray(this.allTokens[tokenIndex].text)
              currentTokenPart = {
                tokenObj: this.allTokens[tokenIndex],
                tokenIndex: tokenIndex,
                textPartObj: currentTokenTextObjIsArray ? this.allTokens[tokenIndex].text[textPartIndex]
                  : { part: this.allTokens[tokenIndex].text, hideable: false },
                textPartIndex: textPartIndex,
                lettersInTokenBeforeCurrentPart: lettersInTokenBeforeCurrentPart
              }
            }
            if (splittingTokenPart) {
              currentTokenPart['firstIndexWithinPart'] = indexWithinPart
              currentTokenPart['lastIndexWithinPart']
                = currentTokenPart.textPartObj.part.slice(indexWithinPart).length - 1
            }
            paragraphsFromTokens.push(currentParagraphTokenParts)
            currentParagraphTokenParts = []
          } else { // i.e. newlinePos DOES === -1
            splittingTokenPart = false
            const maxTokensInParagraph = 500
            const minTokensInAutoBrokenParagraph = 4
            if ((currentParagraphTokenParts.length
              && currentParagraphTokenParts[currentParagraphTokenParts.length - 1].tokenIndex
              - currentParagraphTokenParts[0].tokenIndex) > maxTokensInParagraph) {
              const eosStrings = ['. ', ': '] // e.o.s. = end of sentence
              const lastEOS = currentParagraphTokenParts.map((ptp, ptpIndex) => {
                return eosStrings.filter(
                  eosS => ptp.textPartObj.part.includes(eosS)
                    && ptpIndex >= minTokensInAutoBrokenParagraph).length
                  ? 'eos' : ''
              }).lastIndexOf('eos')
              // console.log('lastEOS:')
              // console.log(lastEOS)
              if (lastEOS === -1) { // i.e. so need to break right here
                // console.log('running no-last-eos clause')
                // console.log('current token part: ' + currentTokenPart.textPartObj.part)
                paragraphsFromTokens.push(currentParagraphTokenParts)
                currentParagraphTokenParts = []
              } else {
                // first, "assume" the lastEOS tokenPart EXACTLY ENDS with the EOS char
                const firstTokenPartOfSecondPar = lastEOS + 1
                const tokenPartsFromEosOn = currentParagraphTokenParts.slice(firstTokenPartOfSecondPar)
                // console.log('tokenPartsFromEosOn.length:')
                // console.log(tokenPartsFromEosOn.length)
                currentParagraphTokenParts = currentParagraphTokenParts.slice(0, firstTokenPartOfSecondPar)
                // then, see if need to "break last tokenPart of the 1st and add some to fromEOSon"
                const breakingTokenPartText = currentParagraphTokenParts[lastEOS].textPartObj.part
                var lastEosInTokenPart = Math.max(...(eosStrings.map(eoss => breakingTokenPartText.lastIndexOf(eoss) + eoss.length - 1)))
                while (lastEosInTokenPart < breakingTokenPartText.length && breakingTokenPartText[lastEosInTokenPart] === ' ') lastEosInTokenPart ++
                if (lastEosInTokenPart < breakingTokenPartText.length) {
                  const lastTokenPartObjOfFirstPar = currentParagraphTokenParts[firstTokenPartOfSecondPar - 1]
                  tokenPartsFromEosOn.unshift(Object.assign({}, lastTokenPartObjOfFirstPar, {
                    firstIndexWithinPart: lastEosInTokenPart + 1,
                    lastIndexWithinPart: breakingTokenPartText.length - 1
                  }))
                  Object.assign(lastTokenPartObjOfFirstPar, {
                    firstIndexWithinPart: 0,
                    lastIndexWithinPart: lastEosInTokenPart
                  })
                }
                paragraphsFromTokens.push(currentParagraphTokenParts)
                currentParagraphTokenParts = tokenPartsFromEosOn
              }
            } else { // i.e. NO need to break paragraph
              if (!currentTokenTextObjIsArray // so SURELY need to go to next token
                || textPartIndex === this.allTokens[tokenIndex].text.length - 1) {
                tokenIndex ++
                lettersInTokenBeforeCurrentPart = 0
                textPartIndex = 0
              } else {
                textPartIndex ++
                lettersInTokenBeforeCurrentPart += currentTokenPart.textPartObj.part.split(/(?=[^ְֱֲֳִֵֶַָׇֹֻּׁׂ])/).length
              }
              indexWithinPart = 0
              currentParagraphTokenParts.push(currentTokenPart)
              if (tokenIndex < this.allTokens.length) {
                currentTokenTextObjIsArray = Array.isArray(this.allTokens[tokenIndex].text)
                currentTokenPart = {
                  tokenObj: this.allTokens[tokenIndex],
                  tokenIndex: tokenIndex,
                  textPartObj: currentTokenTextObjIsArray ? this.allTokens[tokenIndex].text[textPartIndex]
                    : { part: this.allTokens[tokenIndex].text, hideable: false },
                  textPartIndex: textPartIndex,
                  lettersInTokenBeforeCurrentPart: lettersInTokenBeforeCurrentPart
                }
              }
            }
          }
        }
      }
      if (currentParagraphTokenParts.length) { // actually shouldn't be possible any more, but ...
        paragraphsFromTokens.push(currentParagraphTokenParts)
      }
      return paragraphsFromTokens
    },
    navigate (a) {
      this.executeAutoNavigation(a)
    },
    executeAutoNavigation (autoNavigationObj) {
      const newTokenIndex = autoNavigationObj.arguments.tokenIndex
      if (newTokenIndex !== null && newTokenIndex !== this.currentTokenIndex) {
        this.currentTokenIndex = newTokenIndex
        this.currentLetterIndex = 0
      }
      autoNavigationObj.arguments.tokenIndex = null
      const newLetterIndex = autoNavigationObj.arguments.letterIndex
      if (newLetterIndex !== null && newLetterIndex !== this.currentLetterIndex) {
        this.currentLetterIndex = newLetterIndex
      }
      autoNavigationObj.arguments.letterIndex = null
      if (autoNavigationObj.command !== 'move') {
        if (autoNavigationObj.command === 'next-editable') {
          this.moveToNextToken() // by default, to editable
        } else if (autoNavigationObj.command === 'previous-editable') { // actually now the only thing left
          this.moveToPreviousToken() // by default, to editable
        }
        // in a regular move the below lines aren't necessary, b/c the autonavigation is AFTER app's token selection
        this.$emit('token-selection', {
          tokenIndex: this.currentTokenIndex,
          letterIndex: this.currentLetterIndex
        })
      }
      this.scrollResultsWindow()
    },
    moveToNextParagraph () {
      const toMoveTo = this.getAdjacentParagraphTokenIndex(DOWN)
      if (toMoveTo !== this.currentTokenIndex) this.moveToTokenMethod(toMoveTo)
    },
    moveToPreviousParagraph () { // more precisely, the "most recent" paragraph beginning
      const toMoveTo = this.getAdjacentParagraphTokenIndex(UP)
      if (toMoveTo !== this.currentTokenIndex) this.moveToTokenMethod(toMoveTo)
    },
    moveToNextLine () {
      const whetherMoved = this.moveToAdjacentLine(DOWN)
      if (whetherMoved) this.scrollResultsWindow()
    },
    moveToPreviousLine () {
      const whetherMoved = this.moveToAdjacentLine(UP)
      if (whetherMoved) this.scrollResultsWindow()
    },
    tryToGetRangeSelection () {
      var workingIndices = []
      var currentSelection = window.getSelection()
      if (typeof currentSelection !== 'undefined' && currentSelection.anchorNode !== null) {
        const end1Id = currentSelection.anchorNode.parentElement.id
        const end2Id = currentSelection.focusNode.parentElement.id
        // console.log('selection between ' + end1Id + ' and ' + end2Id)
        if (end1Id.includes('-ofToken-') && end2Id.includes('-ofToken-')) {
          const end1Index = parseInt(end1Id.split('-')[5]) // skips 'span-X-tokenPart-tp-ofToken-' prefix
          const end2Index = parseInt(end2Id.split('-')[5]) // skips 'span-X-tokenPart-tp-ofToken-' prefix
          const higher = Math.max(end1Index, end2Index)
          const lower = Math.min(end1Index, end2Index)
          // console.log('I.E. tokens ' + lower + ' through ' + higher + ' selected')
          workingIndices = [...Array(higher - lower + 1).keys()].map(i => i + lower)
        }
      }
      return workingIndices // If no selection: empty array (as initialized)
    },
    updateCurrentSelection () {
      var newSelection = this.tryToGetRangeSelection()
      if (JSON.stringify(newSelection) !== JSON.stringify(this.currentSelection)) {
        this.currentSelection = newSelection
        this.$emit('range-selection', this.currentSelection)
      }
    },
    clearCurrentSelection () {
      if (this.currentSelection.length) {
        this.currentSelection = []
        this.$emit('range-selection', this.currentSelection)
      }
      document.getSelection().removeAllRanges()
    },
    blockDoubleClick () {
      setTimeout(() => {
        this.currentSelection = []
        if (document.selection && document.selection.empty) {
          document.selection.empty()
        } else if (window.getSelection) {
          var sel = window.getSelection()
          sel.removeAllRanges()
        }
      }, 1)
    },
  },
  beforeDestroy () {
    window.onkeydown = null
    $(document).unbind('copy')
    $(document).unbind('dblclick', this.blockDoubleClick)
  },
  mounted () {
    window.onkeydown = (e) => { 
      if (!(modalOpen())) {
        return !([32, 37, 38, 39, 40].includes(e.keyCode)) // suppress spacebar and arrow key scrolling
      }
    }
    $(document).on('mouseup', (e) => {
      if (!(e.target.className.split(' ').includes('selection-non-clearer'))) this.updateCurrentSelection()
    })
    $(document).on('mousedown', (e) => { 
      if (!(e.target.className.split(' ').includes('selection-non-clearer'))) this.clearCurrentSelection()
    })
    $(document).bind('copy', this.copyToClipboard)
    $(document).on('dblclick', this.blockDoubleClick)
    if (this.autoNavigationObj && this.autoNavigationObj.command) this.executeAutoNavigation(this.autoNavigationObj)
    if (this.currentTokenIndex >= 0) {
      setTimeout(() => {
        this.scrollResultsWindow()
      }, 50)
    }
    if (this.allTokens) {
      this.paragraphsFromTokens = this.getParagraphsFromTokens()
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-results-box {
  flex: auto;
  height: 100%;
  width: 100%;
  font-family: mft_narkisclassic;
/* (now handled dynamically) font-size: 25px;
  line-height: 30px; */
  border-style: none;
  padding: 10px;
  overflow-y: auto;
  direction: rtl;
}
.results-side-mobile .main-results-box{
  padding-right:15px;
  padding-left:15px;
  height: 100vh;
  max-height: 100%;
}
.click-event-root * {
  pointer-events: none;
}
</style>
