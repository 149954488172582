export default (parent, child, extraPxString = 'extraPx=0') => {
  const extraPx = parseInt(extraPxString.split('=')[1])
  // console.log('extraPx:')
  // console.log(extraPx)
	// Where is the parent on page
	var parentRect = parent.getBoundingClientRect();
	// What can you see?
	var parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth
	};
  // Where is the child
  var childRect = child.getBoundingClientRect();
  // console.log('parentRect:')
  // console.log(parentRect)
  // console.log('childRect:')
  // console.log(childRect)
	// Is the child viewable?
	var isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);
  // if you can't see the child try to scroll parent
	if (!isViewable) {
    // scroll by offset relative to parent
    // console.log('old parent.scrollTop:')
    // console.log(parent.scrollTop)
    if (childRect.top >= parentRect.top) { // first match bottom, then top, so latter wins if "both needed"
      parent.scrollTop = Math.min(parent.scrollHeight - parentViewableArea.height, // this is the max scrollTop
                                  parent.scrollTop + childRect.bottom 
                                                    - (parentRect.top + parentViewableArea.height) + extraPx)
    }
    parentRect = parent.getBoundingClientRect();
    childRect = child.getBoundingClientRect();
    if (childRect.top < parentRect.top) {
      parent.scrollTop = Math.max(0, parent.scrollTop + childRect.top - parentRect.top - extraPx)
    }
    // console.log('new parent.scrollTop:')
    // console.log(parent.scrollTop)
	}
}
