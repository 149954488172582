import {getContext} from './TextPanelContext'
let displayCounter = null
let ctx = getContext()
let oldValLength = 0
let oldValLast = null

function newPara(...startingWords) {
  return {
    words: [...startingWords],
    hasForcedBreak: false
  }
}

let finishPrevious = null

export default {
  // This function sets up a basic `displayToken` structure, which is the structure used for all
  // token handling in TextPanel.
  // The only tricky part is that it creates a Vue watch to see if the token display changes,
  // with `immediate: true` so that `getDisplay` doesn't have to be called twice
  getDisplayToken(token, selected) {
    // the watch will only update `display`
    // `displayToken` is actually copied when the token is split between lines, but
    // all the copies will share a single `display`, which can be updated a single time
    const display = {
      parts: [],
      classes: []
    }
    const displayToken = {
      token: token,
      display: display,
      isCurrent: selected === displayCounter,
      // this will be corrected later
      tokenIndex: -1,
      displayTokenIndex: displayCounter,
      // this may be corrected later
      isSplitToken: false,
      // only needed if isSplitToken becomes true
      chunk: 0,
      // this will be corrected later
      paraIndex: -1
    }
    this.$watch(() => ctx.getDisplay(token),
      newval => {
        // make display consistent; always an array, always with a classes array
        const normalizedDisplay = Array.isArray(newval.display) ? newval.display : [{ text: newval.display }]
          .map(part => ({
            text: part.text,
            classes: part.classes || []
          }))
        // same idea with classes
        const normalizedClasses = newval.classes || []
        // need to update the members of `display` and not `display` itself so that all the components using it
        // receive the updated values
        display.parts = normalizedDisplay
        display.classes = normalizedClasses
      }, { immediate: true })
    return displayToken
  },
  // convert some tokens into paras, probably only a slice of the tokens prop
  // this can't be called out of order, i.e. there's precondition that all tokens before tokens[0] have already
  // been assigned to paras.

  addParas(tokens, selected, oldTokenCount) {
    const oldParaCount = this.paras.length > 0 ? this.paras.length - 1 : 0
    let currentPara = newPara()
    const paras = [currentPara]
    tokens.forEach((current, i) => {
      const displayToken = this.getDisplayToken(current, selected)
      const text = displayToken.display.parts.reduce((text, part) => text + part.text, '')
      const tokenIndex = i + oldTokenCount
      displayToken.tokenIndex = tokenIndex
      displayToken.paraIndex = oldParaCount + paras.length - 1
      ctx.tokenIdxToDisplayIdx.set(tokenIndex, displayCounter)
      if (currentPara.words.length > 1000) {
        currentPara.hasForcedBreak = true
        currentPara = newPara()
        paras.push(currentPara)
        displayToken.paraIndex += 1
      }
      currentPara.words.push(displayToken)
      ctx.tokenMap.set(displayCounter, displayToken)
      displayCounter++
      if (/\n/.test(text)) {
        const chunks = text.split('\n')
        const lastchunkEmpty = (chunks[chunks.length - 1] === '')
        if (chunks.length > 1) {
          displayToken.isSplitToken = true
        }
        chunks.shift()
        chunks.forEach((_, i) => {
          const chunkNumber = i + 1
          if (chunkNumber === chunks.length && lastchunkEmpty) {
            currentPara = newPara()
            paras.push(currentPara)
          } else {
            const chunkDisplayToken = Object.assign(
              {},
              displayToken,
              {
                chunk: chunkNumber,
                displayTokenIndex: displayCounter,
                isCurrent: selected === displayCounter,
                paraIndex: oldParaCount + paras.length
              })
            ctx.tokenMap.set(displayCounter, chunkDisplayToken)
            displayCounter++
            currentPara = newPara(chunkDisplayToken)
            paras.push(currentPara)
          }
        })
      }
    })
    this.displayTokenCount = displayCounter
    return paras
  },
  loadTokens(newVal, oldVal) {
    if (this.loadingParas && finishPrevious) {
      // previous load underway; speed it up, so we don't get conflicts
      finishPrevious()
    }
    let addSlice = oldValLength !== 0 &&
      newVal && newVal.length > oldValLength &&
      newVal[oldValLength - 1] === oldValLast
    oldValLength = newVal ? newVal.length : 0
    oldValLast = oldValLength > 0 ? newVal[newVal.length - 1] : null
    if (!addSlice) {
      this.initParas()
    }
    let newParas = this.addParas(addSlice ? newVal.slice(oldVal.length) : newVal, this.selected, addSlice ? oldVal.length : 0)
    if (newParas.every(e => e.words.length === 0) && addSlice) { // no words in newParas => NOT an 'addSlice'
      this.initParas()
      newParas = this.addParas(newVal, this.selected, 0)
    }
    if (newParas.length > 0) {
      const firstPara = newParas.shift()
      if (this.paras.length > 0) {
        const existingPara = this.paras[this.paras.length - 1]
        existingPara.words = existingPara.words.concat(firstPara.words)
        existingPara.hasForcedBreak = firstPara.hasForcedBreak
      } else {
        this.paras.push(firstPara)
      }
    }
    this.loadingParas = true
    const ivlId = setInterval(() => {
      if (newParas.length === 0) {
        this.loadingParas = false
        clearInterval(ivlId)
      } else {
        this.paras.push(newParas.shift())
      }
    }, 50)
    finishPrevious = () => {
      while (newParas.length !== 0) {
        this.paras.push(newParas.shift())
      }
      this.loadingParas = false
      clearInterval(ivlId)
      finishPrevious = null
    }
  },
  initParas() {
    this.paras = []
    ctx.tokenMap = new Map()
    ctx.tokenIdxToDisplayIdx = new Map()
    displayCounter = 0
    this.reset()
  }
}
