<!-- loosely based on vue-spinner -->
<template>
  <div class="v-spinner" v-show="loading">
    <div class="v-clip"></div>
  </div>
</template>

<script>
export default {
  name: 'spinner',
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style>
  .v-spinner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
    overflow: hidden;
    position: fixed;
  }

  .v-spinner .v-clip {
    animation: v-clipDelay 0.75s 0s infinite linear;
    animation-fill-mode: both;
    display: inline-block;
    height: 35px;
    width: 35px;
    border: 2px solid #007ee5;
    border-bottom-color: transparent;
    border-radius: 100%;
    background: transparent;
    position: absolute;
    top: calc(50% - 17.5px);
    /*margin-top: -17.5px;*/
    left: calc(50% - 17.5px);
    /*margin-left: -17.5px;*/
  }

  @keyframes v-clipDelay {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
