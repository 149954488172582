import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import {StateChanges, Actions, ChangeTypes} from './constants'
import {firebaseDecode, firebaseEncode, firebaseEncodeString} from './firebaseEncode.js'

// Initialize Firebase
const config = {
  apiKey: "AIzaSyDQDLCIq5syV05gAZTkwwjXMHaQP5x17IA",
  authDomain: "dicta-nakdan.firebaseapp.com",
  databaseURL: "https://dicta-nakdan.firebaseio.com",
  projectId: "dicta-nakdan",
  storageBucket: "dicta-nakdan.appspot.com",
  messagingSenderId: "739874995267"
}
let _appPrefix
const syncFieldsMap = new Map()
let waitingForFields
export let receiveCallbacks = {}

let firebaseInitResolve = null
export const firebaseInitPromise = new Promise(resolve => firebaseInitResolve = resolve)

function isObject(obj) {
  return typeof obj === 'object' && !Array.isArray(obj) && obj !== null && obj !== undefined
}

function prefixedPath(path) {
  return (_appPrefix ? _appPrefix + '/' : '') + path
}

function addField(fieldName, { prefixed, watchChildren } = {}) {
  syncFieldsMap.set(fieldName, {
    field: fieldName,
    firebasePath: prefixed ? prefixedPath(fieldName) : fieldName,
    watchChildren: !!watchChildren,
    ref: null,
    data: null
  })
}

// this reads paths to watch in Firebase from an object that's a blank copy of store.state.accounts.sync
function readBlankObject(obj, path = '') {
  for (let key in obj) {
    const field = path + key
    if (isObject(obj[key])) {
      if (Object.keys(obj[key]).length > 0) {
        // if this key points to a sub-object, get the paths from that
        readBlankObject(obj[key], path + key + '/')
      } else {
        // this is an object, like a dictionary, so we watch the children
        addField(field, { prefixed: true, watchChildren: true })
      }
    } else {
      // it's not an object, so just watch the value
      addField(field, { prefixed: true })
    }
  }
}

const PERMISSION_TO_USE_DATA = 'permissionToUseData'

/**
 * Sets up Firebase syncing for your app
 * @param store - the app's Vuex store, called by the Firebase code to commit data it receives to the
 * 'account' key in the store.
 * @param appPrefix - we have multiple projects storing data in Firebase. Each project uses saves its data under
 * a different key in the same Firebase database.
 * @param syncFields - can be either 1) an array of fields to sync from Firebase. `permissionToUseData` is always synced and
 * shouldn't be listed in the array or 2) an object, showing a blank version of what should be synced. This will actually be
 * the first version to be sent to the store. It can have nested properties. If the leaf is an object, it will have special
 * treatment - it will wait for children of that item rather than a value. This probably won't work correctly for arrays;
 * if we need that too, this will need some revision.
 * For example:
 * `{ dictionary: { good: {}, bad: {} }, permissionToEat: false }`
 * will wait for children of `dictionary.good` and `dictionary.bad`, and for the value of `permissionToEat`.
 *
 * @param modifyReceived - by default, this code stores the data from Firebase in the Vuex store without modification.
 * If some preprocessing is necessary, `modifyReceived` allows callbacks to change the data. It should be an object in
 * which the keys are the names of the fields that get special processing, and the values are functions that take the
 * Firebase version of the data and return the version that should appear in `account.sync`.
 * @param sharedSyncFields - a list of fields that are synced that are shared between all Dicta tools. These don't use
 * the app's prefix in the Firebase database. Example: Dropbox token.
 */
export function initializeFirebase (store, {appPrefix, syncFields = [], modifyReceived, sharedSyncFields = []}) {
  _appPrefix = appPrefix
  addField(PERMISSION_TO_USE_DATA, { prefixed: true })
  if (isObject(syncFields)) {
    readBlankObject(syncFields)
  } else {
    for (let field of syncFields) {
      addField(field, { prefixed: true })
    }
  }
  for (let field of sharedSyncFields) {
    addField(field)
  }
  // a Set of fields that haven't been loaded from Firebase yet
  // used when detecting that all values have been loaded
  waitingForFields = new Set([...syncFieldsMap.values()].map(field => field.firebasePath))
  receiveCallbacks = modifyReceived || {}
  firebase.initializeApp(config)
  firebaseInitResolve()

  // create the correct data structure in the store
  // first, a function used to construct objects from arrays
  const arrayToObj = arr => Object.fromEntries(arr.map(i => [i, null]))
  const copy = obj => JSON.parse(JSON.stringify(obj))
  // then get an object from `syncFields`, either by copying if it's an object, or by using arrayToObj if it's not
  const initialStoreSync = isObject(syncFields) ? copy(syncFields) : arrayToObj(syncFields)
  // then add the rest
  Object.assign(initialStoreSync, {...arrayToObj(sharedSyncFields), [PERMISSION_TO_USE_DATA]: null})
  store.commit(StateChanges.FIREBASE_INITIALIZE_SYNC, copy(initialStoreSync))

  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      // User is signed in.
      // const displayName = user.displayName
      // var email = user.email
      // var emailVerified = user.emailVerified
      // var photoURL = user.photoURL
      // var isAnonymous = user.isAnonymous
      // var uid = user.uid
      // var providerData = user.providerData

      // if there was a logout, then state.account.sync was set to an empty object
      store.commit(StateChanges.FIREBASE_INITIALIZE_SYNC, copy(initialStoreSync))
      // This action will call `createFirebaseRefs`.
      store.dispatch(Actions.PROCESS_LOGIN)
    } else {
      // User is signed out.
      store.commit(StateChanges.LOGGED_OUT)
    }
  })
}

function processValueEvent(commitFunc, syncField, val) {
  const path = syncField.field.split('/')
  const keyName = path.pop()
  commitFunc(StateChanges.FIREBASE_RECEIVED_UPDATE, {
    keyName,
    path,
    value: firebaseDecode(val),
    changeType: ChangeTypes.SET
  })
  // If we're still waiting for the first value of fields to arrive, then mark that we received this one.
  // If it's the last, then FIREBASE_LOADED
  if (waitingForFields.size > 0 && waitingForFields.delete(syncField.firebasePath) && waitingForFields.size === 0) {
    commitFunc(StateChanges.FIREBASE_LOADED)
  }
}

function processChildEvent(commitFunc, syncField, key, val, changeType) {
  if (syncField.data) {
    if (changeType === ChangeTypes.SET) {
      // During initialization, we don't want events for every single child, so we check if the event is redundant.
      // The first 'child_added' events repeat data we already saw in the 'value' event.
        if (JSON.stringify(syncField.data[key]) === JSON.stringify(val)) {
          return
        }
        syncField.data[key] = val
    } else {
        if(!syncField.data.hasOwnProperty(key)) {
          return
        }
        // changeType is REMOVE
        delete syncField.data[key]
      }
  }
  commitFunc(StateChanges.FIREBASE_RECEIVED_UPDATE, {
    keyName: key,
    path: syncField.field.split('/'),
    value: firebaseDecode(val),
    changeType
  })
}

// set up actual callbacks from Firebase
// Also updates a list of fields that have been received so that the first time that all of them have arrived from Firebase,
// it can set `account.firebaseLoaded` to true
function bindFirebaseRef (commitFunc, syncField) {
  const ref = syncField.ref
  if (syncField.watchChildren) {
    ref.once('value', (snapshot) => {
      // save a copy of the data so we can compare and skip duplicate child updates
      syncField.data = snapshot.val() || {}
      // after initialization, just checking makes things slower for no reason, so remove the data after 5 seconds
      setTimeout(() => syncField.data = null, 5000)
      processValueEvent(commitFunc, syncField, syncField.data)
      ref.on('child_added', (snapshot) => {
        processChildEvent(commitFunc, syncField, snapshot.key, snapshot.val(), ChangeTypes.SET)
      })
      ref.on('child_changed', (snapshot) => {
        processChildEvent(commitFunc, syncField, snapshot.key, snapshot.val(), ChangeTypes.SET)
      })
      ref.on('child_removed', (snapshot) => {
        processChildEvent(commitFunc, syncField, snapshot.key, snapshot.val(), ChangeTypes.REMOVE)
      })
    })
  }
  else {
    ref.on('value', (snapshot) => {
      processValueEvent(commitFunc, syncField, snapshot.val())
    })
  }
}

// called by the login action, this sets up listeners on the various keys provided to `initializeFirebase`
export function createFirebaseRefs (commitFunc) {
  const userId = firebase.auth().currentUser.uid
  for (const syncField of syncFieldsMap.values()) {
    syncField.ref = firebase.database().ref(
      '/users/' + userId +
      '/' + syncField.firebasePath
    )
    bindFirebaseRef(commitFunc, syncField)
  }
  watchActiveFiles()
}

export function firebaseStopSync () {
  for (const syncField of syncFieldsMap.values()) {
    if (syncField.ref) {
      syncField.ref.off()
    }
    syncField.ref = null
  }
  stopWatchingActiveFiles()
}

// store data in Firebase, but only if the Firebase ref is already set up
function baseStore(keyPath, data) {
  let ref, key
  if (Array.isArray(keyPath)) {
    const encPath = keyPath.map(el => firebaseEncodeString(el))
    while (encPath.length > 0) {
      const pathItem = encPath.shift()
      key = key ? key + '/' + pathItem : pathItem
      if (syncFieldsMap.has(key)) {
        ref = syncFieldsMap.get(key).ref
        if (ref) {
          ref.child(encPath.join('/')).set(firebaseEncode(data))
          return true
        }
      }
    }
  } else {
    if (syncFieldsMap.has(keyPath)) {
      ref = syncFieldsMap.get(keyPath).ref
      if (ref) {
        ref.set(firebaseEncode(data))
        return true
      }
    }
  }

  return false
}

// store data in Firebase, but if Firebase isn't set up yet, delay until the login finishes,
/**
 * Issue a request to store `data` in Firebase in `keyPath`, which may a) be delayed until login is finished,
 * b) be interpreted differently depending on whether it's a shared key like `dropbox_token` or something specific to
 * the tool, and c) may be a key name or a path
 * @param keyPath - either a string, in which case it's the name of a key, or an array of strings, in which case it's
 * a path to a key
 * @param data
 */
export function firebaseStore (keyPath, data) {
  if (!baseStore(keyPath, data)) {
    // could be login isn't complete, so wait until it finishes
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        baseStore(keyPath, data)
        unsubscribe()
      }
    })
  }
}

let activeFilesRef
let activeFilesRefResolve = null
let activeFilesRefPromise = new Promise(resolve => activeFilesRefResolve = resolve)
let activeFilename
let activeFilenameRef
const activeFileCallbacks = []

function watchActiveFiles() {
  activeFilesRef = firebase.database().ref(
    '/users/' + firebase.auth().currentUser.uid +
    '/online/activeFiles'
  )
  activeFilesRef.on('value', snapshot => {
    const doubleOpen = Object.values(snapshot.val()|| {}).filter(val => val?.filename === activeFilename).length > 1
    for (let cb of activeFileCallbacks) {
      if (cb) cb(doubleOpen)
    }
  })
  activeFilesRefResolve()
}
function stopWatchingActiveFiles() {
  activeFilesRef.off()
  // reset the promise, so that it can be set when there's a new login
  activeFilesRefPromise = new Promise(resolve => activeFilesRefResolve = resolve)
  activeFileCallbacks.length = 0
}

export async function firebaseSetActiveFile(filename) {
  await activeFilesRefPromise
  if (activeFilename === filename) return
  if (activeFilenameRef)
    activeFilenameRef.remove()
  activeFilenameRef = null
  activeFilename = filename
  if (filename) {
    activeFilenameRef = activeFilesRef.push()
    activeFilenameRef.onDisconnect().remove()
    // we set a timestamp even though we don't use it for anything, because
    // we may eventually need to automate cleanup of old ones
    activeFilenameRef.set({
      filename,
      time: firebase.database.ServerValue.TIMESTAMP
    })
  }
}

export function firebaseAddActiveFileCB(cb) {
  activeFileCallbacks.push(cb)
  return activeFileCallbacks.length - 1
}

export function firebaseClearActiveFileCB(id) {
  activeFileCallbacks[id] = null
}
