import {undo, redo, canUndo, canRedo} from './index'
export default {
  name: 'UndoRedoMixins',
  computed: {
    canRedo() {
      return canRedo()
    },
    canUndo() {
      return canUndo()
    }
  },
  methods: {
    undo () {
      undo()
    },
    redo () {
      redo()
    }
  }
}
