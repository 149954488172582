import store from './store'
import router from './router'
import { StateChanges } from './store/stateChanges'
import { AppConfig } from './appConfig'
import { getGitCommitId } from '../../shared/js/getGitCommitId'

function load(savedStateString) {
  let savedState
  let parseError = null
  let validationResult
  try {
    savedState = JSON.parse(savedStateString)
  } catch (error) {
    parseError = error
  }
  if (parseError || typeof savedState !== 'object' || savedState === null) {
    validationResult = {
      success: false, savedStateString: savedStateString, failures: [{
        failureType: 'invalid ' + AppConfig.SAVED_STATE_NAME,
        errorData: parseError ? 'JSON parse error' : 'not an \'object\''
      }]
    }
  } else {
    store.commit(StateChanges.NAKDAN_RUNNING)
    store.commit(StateChanges.LOAD_SAVED_DATA, savedState)
    store.commit(StateChanges.NAKDAN_COMPLETE)
  }
  if (validationResult && !validationResult.success) {
    // eslint-disable-next-line no-console
    //console.log('Saved state validation results: ' + JSON.stringify(validationResult))
  }
  return !validationResult || validationResult.success
}

const LATEST_VERSION = '0.1'

function save() { //local storage save not in use
  const stateToSave = store.state.morphologyApp
  const copyOfStateToSave = Object.assign({}, stateToSave, { version: LATEST_VERSION, gitCommit: getGitCommitId() })
  if (stateToSave.validationResult) delete copyOfStateToSave.validationResult
  // JSON.stringify is slow, particularly for large objects, so call it only once
  const jsonString = JSON.stringify(copyOfStateToSave)
  return jsonString
}

function importText(mainText) {
  let parseError = null
  try {
    JSON.parse(mainText)
  } catch (error) {
    parseError = error
  }
  if (parseError) {
    if (!load(mainText).success) {
      store.commit(StateChanges.SET_TEXT_FOR_NAKDAN_API_CALL, mainText)
      router.push({ name: "text-edit", params: { lockText: true } })

    }
  } else {
    //JSON.stringify(validationResult)
    store.commit(StateChanges.ADD_MORPH_RESULTS_FROM_JSON, JSON.stringify(mainText))
  }
  store.commit(StateChanges.NAKDAN_COMPLETE)
}

function exportText() {
  return {
    useInternalFormat: true,
    extension: 'json'
  }
}

export const DocumentManager = {
  load, save, importText, exportText
}